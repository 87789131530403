export const TREATMENT_INFO_REQUEST = 'TREATMENT_INFO_REQUEST';
export const TREATMENT_INFO_SUCCESS = 'TREATMENT_INFO_SUCCESS';
export const TREATMENT_INFO_FAIL = 'TREATMENT_INFO_FAIL';

export const TREATMENT_DETAILS_REQUEST = 'TREATMENT_DETAILS_REQUEST';
export const TREATMENT_DETAILS_SUCCESS = 'TREATMENT_DETAILS_SUCCESS';
export const TREATMENT_DETAILS_FAIL = 'TREATMENT_DETAILS_FAIL';

export const TREATMENT_CREATE_REQUEST = 'TREATMENT_CREATE_REQUEST';
export const TREATMENT_CREATE_SUCCESS = 'TREATMENT_CREATE_SUCCESS';
export const TREATMENT_CREATE_FAIL = 'TREATMENT_CREATE_FAIL';
export const TREATMENT_CREATE_RESET = 'TREATMENT_CREATE_FAIL';

export const TREATMENT_UPDATE_REQUEST = 'TREATMENT_UPDATE_REQUEST';
export const TREATMENT_UPDATE_SUCCESS = 'TREATMENT_UPDATE_SUCCESS';
export const TREATMENT_UPDATE_FAIL = 'TREATMENT_UPDATE_FAIL';
export const TREATMENT_UPDATE_RESET = 'TREATMENT_UPDATE_FAIL';
