// lib
import { useSelector } from 'react-redux';

// components
import Header from '../Header/Header_ob';
import Footer from '../Footer';
import { Container } from 'react-bootstrap';

// utils
import { NotificationProvider } from '../../utilities/NotificationContext';
import PrivateRoute from '../../utilities/PrivateRoute';

const AppLayout = ({ children }) => {
  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <>
      {userInfo ? (
        <PrivateRoute>
          {' '}
          <NotificationProvider>
            {' '}
            <Header />{' '}
          </NotificationProvider>{' '}
        </PrivateRoute>
      ) : null}
      <main className="tw-py-3" style={{ overflow: 'hidden' }}>
        <Container className="tw-mx-0" fluid>
          {children}
        </Container>
      </main>
      {!userInfo ? <Footer /> : null}
    </>
  );
};

export { AppLayout };
