import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Button, Row, Col, Table, Badge } from 'react-bootstrap';
import { dateToHmDMY, dateToDMMMMY } from '../../../helpers';
import { GetStatus } from '../../../helpers/status';

import { useNavigate } from 'react-router-dom';

import DisplayAvatar from '../../../components/DisplayAvatar';
import PresentationsButton from '../../../components/PresentationsButton';

import { useTranslation } from 'react-i18next';
import { BsFillPersonFill } from 'react-icons/bs';
import Activities from '../../../components/Activities/Activities';
import { Alert } from '@mui/material';

const RecentPatientsTable = props => {
  const { patients, clinics } = props;

  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation('contentTrs');

  const { remoteVisits, loading: loadingVisitsToAssess } = useSelector(({ remoteVisitsList }) => remoteVisitsList);

  // useEffect=(()=>{

  // },[remoteVisits])

  const checkRemoteAssesmnet = id => {
    let patients_to_assess_id = [];
    remoteVisits.forEach(visit => {
      patients_to_assess_id.push(visit.patient);
    });
    return patients_to_assess_id.includes(id);
  };

  const renderAlertUseApp = () => {
    console.log('alert click');
    setShowAlert(true);
    return (
      <Alert
        severity="warning"
        onClose={() => {
          setShowAlert(false);
        }}
      >
        This is a success alert — check it out!
      </Alert>
    );
  };

  return (
    <div className="px-4">
      {showAlert && (
        <Alert
          severity="info"
          onClose={() => {
            setShowAlert(false);
          }}
        >
          {t('Alerts.useMobileApp')}
        </Alert>
      )}
      <Table responsive="sm" hover="true" className="px-2">
        <thead>
          <tr>
            <th>
              <BsFillPersonFill />
            </th>
            <th>{t('home_page.patienName')}</th>
            {/* <th className='text-center' width={'15%'}>Practice</th> */}
            <th className="text-center" width={'15%'}>
              {t('home_page.patienActivities')}
            </th>
            <th className="text-center" width={'15%'}>
              {t('home_page.patientStatuts')}
            </th>
            <th className="text-center" width={'15%'}>
              {t('home_page.patientPresentationTitle')}
            </th>
          </tr>
        </thead>
        <tbody>
          {patients.map(patient => (
            <tr key={patient.id}>
              <td>
                <DisplayAvatar user={patient} />
              </td>
              <td>
                <Row>
                  <div
                    onClick={() => {
                      if (patient.is_draft) {
                        navigate(`/patients/register/${patient.id}`);
                      } else {
                        navigate(`/patients/${patient.id}`);
                      }
                    }}
                    className="link pointer ms-0 ps-0"
                  >
                    {patient.first_name + ' ' + patient.last_name}
                  </div>
                </Row>
                <Row style={{ fontSize: 12 }} className="text-right">
                  {dateToDMMMMY(patient.birth_date)}
                </Row>
              </td>
              {/* <td className='text-center'> 
                    {clinics.map(e => e.id == patient.id)}
                  </td> */}
              <td className="text-center" onClick={() => renderAlertUseApp()}>
                {remoteVisits && checkRemoteAssesmnet(patient.id) && <Activities type="picture" count="1" />}
              </td>
              <td className="text-center">
                <GetStatus status_id={patient.status} />
              </td>
              <td className="text-center">
                {' '}
                <PresentationsButton patientId={patient.id} />{' '}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default RecentPatientsTable;
