export const passwordStrengthValidation = (password, translationFn) => {
  if (typeof password !== 'string') {
    return { isValid: false, errorMessage: translationFn('Password must be a string.', { ns: 'passwordValidation' }) };
  }

  // 1. Minimum Length
  if (password.length < 8) {
    return {
      isValid: false,
      errorMessage: translationFn('Password must be at least 8 characters long.', { ns: 'passwordValidation' }),
    };
  }

  // 2. Complexity Requirements
  var complexityRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]:;<>,.?~\\/-]).{8,}$/;
  if (!complexityRegex.test(password)) {
    return {
      isValid: false,
      errorMessage: translationFn(
        'Password must include at least one lowercase letter, one uppercase letter, one digit, and one special character' +
          ':  !@#$%^&*()_+={}[]:;<>,.?~\\ /-',
        { ns: 'passwordValidation' }
      ),
    };
  }

  // 3. Avoid Common Patterns
  var commonPatterns = /(123|abc|password|admin)/i;
  if (commonPatterns.test(password)) {
    return {
      isValid: false,
      errorMessage: translationFn('Password should not contain common patterns or easily guessable sequences.', {
        ns: 'passwordValidation',
      }),
    };
  }

  // If all checks pass, return null indicating a valid password
  return { isValid: true };
};
