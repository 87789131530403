import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './componentsCSS/Components.css';

function Footer() {
  return (
    <footer className="py-1 background-default footer-sticky">
      <Container>
        <Row>
          <Col className="text-center py-3">Copyright &copy; TIE Cast Limited</Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
