export const makeFirstLatterUp = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const makePrice = number => {
  if (number === 0) {
    return '0.00';
  }
  let str = String(number);
  let length = str.length;
  return str.substr(0, length - 2) + '.' + str.substr(length - 2);
};
