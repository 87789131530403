import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Loader from '../../components/Loader';

import { Modal } from 'react-bootstrap';
import { GrClose, GrCheckmark } from 'react-icons/gr';

import './InviteModal.css';

export default function InviteModal({ patient }) {
  const { t } = useTranslation('contentTrs');
  const [showModal, setShowModal] = useState(false);
  const [emailForm, setEmailForm] = useState('');
  const [checkboxForm, setCheckboxForm] = useState(false);
  const [submitedButton, setSubmitedButton] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setEmailForm('');
    setCheckboxForm(false);
    setSubmitedButton(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitedButton(true);
    console.log('Submit');
  };

  return (
    <React.Fragment>
      <button type="button" className="invite-btn" onClick={() => setShowModal(true)}>
        {' '}
        {t('chat.inviteButtonInvite')}{' '}
      </button>

      <Modal
        show={showModal}
        className="invite-modal"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        onHide={() => setShowModal(false)}
      >
        <Modal.Header className="invite__modal_header">
          <Modal.Title className="invite__modal_header">{t('chat.inviteTitle')}</Modal.Title>

          <GrClose onClick={closeModal} className="close-icon" />
        </Modal.Header>

        <Modal.Body className="invite__modal_body">
          <div className="invite-profile">
            <img src={patient.minimized_photo_url} alt="" className="invite-picture" />
            <h3 className="invite-name">{patient.first_name}</h3>
          </div>
          <form className="invite-form" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder={t('chat.inviteEmailPlaceholder')}
              value={emailForm}
              className={`${checkboxForm && 'disabled'}`}
              id="email"
              onChange={e => {
                setEmailForm(e.target.value);
              }}
              disabled={checkboxForm}
            />
            <p>{t('chat.inviteTextContent')}</p>

            <input
              className="invite-checkbox"
              id="onlyDoctor"
              type="checkbox"
              value={checkboxForm}
              onChange={() => {
                setCheckboxForm(!checkboxForm);
              }}
            />
            <label htmlFor="onlyDoctor">{t('chat.inviteCheckboxText')}</label>

            <button type="submit" className={`submit ${submitedButton ? 'form-sent' : ''}`}>
              {submitedButton ? t('chat.inviteAfterSubmitButton') : t('chat.inviteSubmitButton')}
              {submitedButton && <GrCheckmark className="check-icon" />}
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
