import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Row, Col, Table, Container } from 'react-bootstrap';
import './registration.css';

import Demografics from './Demografics';
import ClinicalInfoTab from './ClinicalInfoTab';
import { useParams } from 'react-router-dom';

import { createFirstVisit, listVisits } from '../../actions/visitsActions';
import { VISITS_LIST_RESET } from '../../constants/visitsConstants';

// todo: core-ui CSS import
import '../../scss/coreUI.scss';

function NewPatientScreen() {
  const match = useParams();
  const dispatch = useDispatch();
  const patientId = match.id;

  const [visitId, setVisitId] = useState('');

  // const patientCreate = useSelector(state => state.patientCreate)
  // const { error, loading, success, patient, draftPatient } = patientCreate

  // const visitCreate = useSelector(state => state.visitCreate)
  // const { error:errorAddFV, loading:loadingAddFirstVisit, success:successAddFV, visit } = visitCreate

  const visitsList = useSelector(state => state.visitsList);
  const { error: errorVL, loading: loadingVL, success: successVL, visits } = visitsList;

  useEffect(() => {
    if (errorVL) {
      dispatch(createFirstVisit(patientId));
    }
    dispatch(listVisits(patientId));
  }, [dispatch]);

  useEffect(() => {
    if (successVL) {
      setVisitId(visits[0]?.id); //0 is used to access to dictionary
      //dispatch({ type: VISITS_LIST_RESET })
    }
    if (!visitId) {
      dispatch(listVisits(patientId));
    }
  }, [dispatch, successVL, visitId]);

  return (
    <Container className="">
      <ClinicalInfoTab patientId={patientId} visitId={visitId} />
    </Container>
  );
}

export default NewPatientScreen;
