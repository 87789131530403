// hooks
import { useState, useCallback } from 'react';
import { useClinicInfoQuery } from '../hooks/useClinicInfoQuery';
import { useResendVerificationMailMutation } from '../hooks/useResendVerificationMailMutation';
import { useTranslation } from 'react-i18next';

// components
import Loader from '../../../components/Loader';
import { ClinicInfo } from './ClinicInfo';

const EmailVerificationForm = ({ verifiedState }) => {
  const { t } = useTranslation('patientSignup');
  const [info, setInfo] = useState();
  const { data, error, isLoading } = useClinicInfoQuery({ clinicId: verifiedState.clinicId });
  const { mutate } = useResendVerificationMailMutation({
    emailId: verifiedState.emailId,
    translationFn: t,
  });

  const resendVerificationMail = useCallback(() => {
    mutate(undefined, {
      onSuccess: () => {
        setInfo(t('Verification mail resent!'));
      },
      onError: () => {
        setInfo(t('Verification mail could not be sent due to internal error!'));
      },
    });
  }, [mutate, t]);

  let headerEl;
  if (error) {
    headerEl = <ClinicInfo clinicName="Orthobook" />;
  } else if (isLoading) {
    headerEl = <Loader />;
  } else {
    headerEl = <ClinicInfo clinicImageUrl={data.logo} clinicName={data['clinic_name']} />;
  }

  return (
    <div
      className="tw-flex tw-flex-col tw-w-full tw-h-full tw-items-center tw-justify-between tw-py-12 md:tw-pb-36 tw-mx-12"
      style={{ maxWidth: '440px' }}
    >
      <div>{headerEl}</div>
      <div className="tw-flex tw-flex-col tw-mt-24">
        <h2 className="tw-text-2xl tw-font-medium">{t('Verify your email')}</h2>
        <div className="tw-flex tw-flex-col tw-mt-6 tw-gap-1 tw-text-base tw-font-medium">
          <div>{t('Check your inbox.')}</div>
          <div>{t(`If you don't find it, check your spam or the promotion folder.`)}</div>
        </div>
        <div className="tw-w-full tw-rounded-2xl tw-py-2 tw-bg-slate-400 tw-text-slate-950 tw-mt-6 tw-text-center tw-cursor-not-allowed">
          {verifiedState.emailId}
        </div>
        <div className="tw-mt-3 tw-text-base tw-font-medium">
          {t('If you notice you have used a wrong email, restart the registration again.')}
        </div>
        <div className="tw-mt-6 tw-text-base tw-text-center">
          {t(`You haven't recieved any email?`)}{' '}
          <span className="tw-text-blue-500 tw-underline tw-cursor-pointer" onClick={resendVerificationMail}>
            {t('send again')}
          </span>
        </div>
        {info ? <div className="tw-mt-2 tw-text-sm tw-text-center">{info}</div> : null}
      </div>
    </div>
  );
};

export { EmailVerificationForm };
