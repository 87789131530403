import React, { useEffect, useState } from 'react';
import axios from 'axios';
import RecordsTab from '../../../components/RecordsTab';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { listRecords, listLatestRecords } from '../../../actions/visitsActions';

const PreviewPatient = props => {
  const { patient, intl, patientId, visitId } = props;
  const { t } = useTranslation('contentTrs');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // records tab variables/functions
  const [recordType, setRecordType] = useState('front_smile');

  const [type_1, setType_1] = useState([]);
  const [type_2, setType_2] = useState([]);
  const [type_3, setType_3] = useState([]);
  const [date, setDate] = useState(null);

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const recordsList = useSelector(state => state.recordsList);
  const { error: errorRL, loading: loadingRL, success: successRL, records } = recordsList;

  useEffect(() => {
    dispatch(listLatestRecords(patient.id));
  }, [dispatch, patient]);

  const [persentage, setPercentage] = useState(0);
  const [prLoad, setPrLoad] = useState('');

  const componentDidMount = () => {
    const { avatar } = this.props;
    this.setState({ avatar });
  };

  const editImg = async ({ target: { loadedFile } }, recordType) => {
    //console.log(loadedFile)
    let formData = new FormData();

    formData.append('record_type', recordType);
    formData.append('record', loadedFile);

    const option = {
      onUploadProgress: ProgressEvent => {
        const { loaded, total } = ProgressEvent;
        let percent = Math.floor((loaded * 100) / total);

        console.log(`${loaded}kb of ${total}kb | ${percent}%`);

        /*if( percent < 100 ){
            setPercentage({ uploadPercentage: percent })
          }*/
      },
    };

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios
        .put(`/api/patients/profile/${patient.id}/visits/${visitId}/upload`, formData, config, option)
        .then(res => console.log(res));

      //setRecordUploadSuccess(true)
    } catch (error) {
      console.log(error);
    }
  };

  function uploadImage(loadedFile, updateProgress) {
    console.log(loadedFile);

    let formData = new FormData();
    formData.append('record_type', recordType);
    formData.append('record', loadedFile); //

    return axios
      .post(`/api/patients/profile/${patient.id}/visits/${visitId}/upload`, loadedFile, {
        onUploadProgress: progressEvent => {
          let percentComplete = progressEvent.loaded / progressEvent.total;
          percentComplete = parseInt(percentComplete * 100);
          console.log(percentComplete);
          updateProgress(percentComplete);
        },
      })
      .then(response => response.data.id);
  }

  // const fetchData = async () => {
  //   try {
  //     const res = await apiGetLatestPatientRecords(patient.id)
  //     const copyArr = [
  //       {
  //         subtype_id: 1,
  //         photo_url: '/records/1.png',
  //         minimized_photo_url: '/records/1.png',
  //       },
  //       {
  //         subtype_id: 2,
  //         photo_url: '/records/2.png',
  //         minimized_photo_url: '/records/2.png',
  //       },
  //       {
  //         subtype_id: 3,
  //         photo_url: '/records/3.png',
  //         minimized_photo_url: '/records/3.png',
  //       },
  //       {
  //         subtype_id: 4,
  //         photo_url: '/records/4.png',
  //         minimized_photo_url: '/records/4.png',
  //       },
  //       {
  //         subtype_id: 5,
  //         photo_url: '/records/5.png',
  //         minimized_photo_url: '/records/5.png',
  //       },
  //       {
  //         subtype_id: 6,
  //         photo_url: '/records/6.png',
  //         minimized_photo_url: '/records/6.png',
  //       },
  //       {
  //         subtype_id: 7,
  //         photo_url: '/records/7.png',
  //         minimized_photo_url: '/records/7.png',
  //       },
  //       {
  //         subtype_id: 8,
  //         photo_url: '/records/8.png',
  //         minimized_photo_url: '/records/8.png',
  //       },
  //       {
  //         subtype_id: 9,
  //         photo_url: '/records/9.png',
  //         minimized_photo_url: '/records/9.png',
  //       },
  //       {
  //         subtype_id: 10,
  //         photo_url: '/records/10.png',
  //         minimized_photo_url: '/records/10.png',
  //       },
  //       {
  //         subtype_id: 11,
  //         photo_url: '/records/8.png',
  //         minimized_photo_url: '/records/8.png',
  //         name: intl.formatMessage({id: 'patientScene.teleradiography'})
  //       },
  //       {
  //         subtype_id: 12,
  //         photo_url: '/records/8.png',
  //         minimized_photo_url: '/records/8.png',
  //         name: intl.formatMessage({id: 'patientScene.orthopantomogram'})
  //       }
  //     ];
  //     res.data.records.forEach((record, index) => {
  //       if (record.subtype_id !== null) {
  //         copyArr[record.subtype_id - 1].photo_url = record.photo_url
  //         copyArr[record.subtype_id - 1].minimized_photo_url = record.minimized_photo_url
  //       } else {
  //         copyArr.push(record);
  //       }
  //     })
  //     setType_1(copyArr.splice(0,4))
  //     setType_2(copyArr.splice(0,6))
  //     setType_3(copyArr.splice(0))
  //     setDate( patient.updated_at )
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  return (
    <React.Fragment>
      <div className="display-5 ml-4 mb-3">
        <h5>
          {t('allPatientScreen.patientPreview.previewButton') + ' -  ' + patient.first_name + ' ' + patient.last_name}
        </h5>
      </div>
      {/* <RecordsTab
        type_1={type_1}
        type_2={type_2}
        type_3={type_3}
        date={date}
        readOnly={true}
        xRay={true}
      /> */}
      {records && (
        <RecordsTab
          patientId={patient.id}
          visitId={visitId}
          editImg={uploadImage}
          recordType={recordType}
          readOnly={true}
          records={records}
        />
      )}
    </React.Fragment>
  );
};

export default PreviewPatient;
