import { NOTIFICATION_CHAT, CURRENT_CHAT_PATIENT } from '../constants/notificationsConstants';

export const notificationsChatReducer = (
  state = { unreadMessageCount: 0, connectionStatus: 'Uninstantiated', unreadMessages: [] },
  action
) => {
  switch (action.type) {
    case NOTIFICATION_CHAT:
      return {
        unreadMessageCount: action.payload.unread_count,
        unreadMessages: action.payload.unread_messages,
        connectionStatus: action.payload,
      };

    default:
      return state;
  }
};

export const currentChatReducer = (state = { selectedPatient: {} }, action) => {
  switch (action.type) {
    case CURRENT_CHAT_PATIENT:
      return { selectedPatient: action.payload };

    default:
      return state;
  }
};
