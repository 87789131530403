import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Row, Col, Table, Badge } from 'react-bootstrap';

import Demografics from './Demografics';
import ClinicalInfoTab from './ClinicalInfoTab';
import { useNavigate, useParams } from 'react-router-dom';

import { createFirstVisit } from '../../actions/visitsActions';
import { createPatient } from '../../actions/patientsActions';
import { PATIENT_CREATE_RESET } from '../../constants/patientsConstants';

// todo: core-ui CSS import
import '../../scss/coreUI.scss';

function NewPatientScreen() {
  //const match = useParams()
  //const patientId= match.id

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const patientCreate = useSelector(state => state.patientCreate);
  const { error, loading, success, patient, draftPatient } = patientCreate;

  useEffect(() => {
    if (success && patient?.id) {
      dispatch(createFirstVisit(patient.id));
      navigate(`/patients/register/${patient.id}`);
      dispatch({ type: PATIENT_CREATE_RESET });
    }
  }, [dispatch, success, patient]);

  return (
    <div>
      <Demografics patientAction={createPatient} />
    </div>
  );
}

export default NewPatientScreen;
