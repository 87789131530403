import React, { useEffect, useState, useCallback } from 'react';
import axios from '../../helpers/axiosConfig';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Card } from 'react-bootstrap';
import './registration.css';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import RecordsTab from './RecordsTab';
import DiagnosisTab from './DiagnosisTab';
import TreatmentsTab from './TreatmentsTab';

import { updatePatient } from '../../actions/patientsActions';
import { listRecords } from '../../actions/visitsActions';
import { createDiagnosis } from '../../actions/diagnosisActions';
import { createTreatment } from '../../actions/treatmentActions';

function ClinicalInfoTab(props) {
  const { patientId, visitId } = props;
  const { t } = useTranslation('contentTrs');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // treatments tab variables/functions
  const [chosenTreatments, setChosenTreatments] = useState(new Set());

  const [editImageProgress, setEditImageProgress] = useState(0, '');

  // diagnosis tab variables/functions
  const [diagnosis, setDiagnosis] = useState({
    malloclusion_class: null,
    overbite: null,
    overbite_measure: null,
    overjet: null,
    overjet_measure: null,
    bite_right: null,
    bite_left: null,
    bite_anterior: null,
    crowding_superior: null,
    crowding_inferior: null,
    crowding_superior_measure: null,
    crowding_inferior_measure: null,
    facial_simmetry: null,
    lips_comptence: null,
    profile: null,
    naso_labial_angle: null,
  });

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const recordsList = useSelector(state => state.recordsList);
  const { error: errorRL, loading: loadingRL, success: successRL, records } = recordsList;

  useEffect(() => {
    if (visitId && patientId) {
      dispatch(listRecords(patientId, visitId));
    }
  }, [dispatch, visitId, patientId]);

  const editImg = useCallback(
    async (loadedFile, recordType) => {
      let formData = new FormData();
      formData.append('record_type', recordType);
      formData.append('record', loadedFile);

      try {
        const config = {
          onUploadProgress: progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total;
            percentComplete = parseInt(percentComplete * 100);
            setEditImageProgress({ percentComplete: percentComplete, recordType: recordType });
            if (percentComplete === 100) {
              setTimeout(() => {
                setEditImageProgress(0);
              }, 500);
            }
          },
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userInfo.access}`,
          },
        };
        await axios.put(`/api/patients/profile/${patientId}/visits/${visitId}/upload/`, formData, config);
        dispatch(listRecords(patientId, visitId));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, patientId, visitId]
  );

  const deleteImg = useCallback(
    async recordType => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.access}`,
          },
        };
        await axios.put(
          `/api/patients/profile/${patientId}/visits/${visitId}/remove/`,
          { record_type: recordType },
          config
        );
        dispatch(listRecords(patientId, visitId));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, patientId, visitId]
  );

  const savePatient = async () => {
    let patientActive = { is_draft: false };
    let treatments = { treatment_options: [...chosenTreatments] };

    try {
      dispatch(createDiagnosis(patientId, diagnosis));
      dispatch(createTreatment(patientId, treatments));
      await dispatch(updatePatient(patientId, patientActive)); // Wait for updatePatient to complete
      navigate(`/patients`);
    } catch (error) {
      console.error('Error while saving patient:', error);
      alert('Error while saving patient');
    }
  };

  return (
    <div>
      <Row>
        <Col></Col>
        <Col></Col>
        <Col></Col>
        <Col className="text-end" sm={4} md={4} lg={4} xl={4}>
          <Button type="submit" variant="primary" className="form-button text-end" onClick={savePatient}>
            {t('addNew_patienScreen.add_step2.continue')}
          </Button>
        </Col>
      </Row>
      <Row className="py-2">
        <Col sm={12} md={12} lg={4} className=" ">
          <Card style={{ height: '100%' }}>
            <Scrollbars
              renderTrackHorizontal={props => (
                <div {...props} style={{ display: 'none' }} className="track-horizontal" />
              )}
              disableHorizontalScrolling
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{ height: '100%', margin: 0, padding: 0, width: '100%' }}
            >
              <Card.Body className="justify-content-center">
                {records && (
                  <RecordsTab
                    patientId={patientId}
                    visitId={visitId}
                    editImg={editImg}
                    withoutDelete={true}
                    records={records}
                    deleteImg={deleteImg}
                    editImageProgress={editImageProgress}
                  />
                )}
              </Card.Body>
            </Scrollbars>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="background-default shadow radius" style={{ height: '100%' }}>
            <Scrollbars
              renderTrackHorizontal={props => (
                <div {...props} style={{ display: 'none' }} className="track-horizontal" />
              )}
              disableHorizontalScrolling
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{ height: '100%', margin: 0, padding: 0, width: '100%' }}
            >
              <Card.Body className="justify-content-center">
                <DiagnosisTab diagnosis={diagnosis} setDiagnosis={setDiagnosis} />
              </Card.Body>
            </Scrollbars>
          </Card>
        </Col>

        <Col md={4}>
          <Card>
            <Card.Body className="justify-content-center scrollY">
              <TreatmentsTab chosenTreatments={chosenTreatments} setChosenTreatments={setChosenTreatments} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ClinicalInfoTab;
