import './Activities.css';

import { ReactComponent as ChatIcon } from '../../asset/icons/chat-icon.svg';
import { ReactComponent as PictureIcon } from '../../asset/icons/picture-icon.svg';
import { ReactComponent as AlarmIcon } from '../../asset/icons/alarm-icon.svg';

const Activities = ({ type, count }) => {
  return (
    <div className="relative flex items-center justify-center">
      {type === 'chat' && <ChatIcon className="activity-icon" />}
      {type === 'picture' && <PictureIcon className="activity-icon" />}
      {type === 'alarm' && <AlarmIcon className="activity-icon" />}
      <span className="activity-number">{count}</span>
    </div>
  );
};

export default Activities;
