// lib
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

// hooks
import { useVisibilityToggle } from '../../hooks/useVisibilityToggle';

import { useTranslation } from 'react-i18next';

// components
import LinearProgress from '@mui/material/LinearProgress';
import { Image } from 'react-bootstrap';
import { BsCloudUploadFill, BsZoomIn, BsTrash, BsCloudDownloadFill } from 'react-icons/bs';
import ImageEditorModal from './ImageEditor';
import STLrenderer from './STLrenderer';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

// constants
import { base_URL_media, base_URL_static_asset } from '../../helpers/axiosConfig';
import VideoRenderer from './VideoRenderer';

// Font awesome pixel sizes relative to the multiplier.
// 1x - 14px
// 2x - 28px
// 3x - 42px
// 4x - 56px
// 5x - 70px

const ICON_SIZE = 35;
const _noop = () => {};

const RecordField = props => {
  const {
    image,
    editImg,
    downloadImg = _noop,
    classes,
    readOnly = false,
    deleteImg,
    recordType,
    editImageProgress,
    isNonUploadableField = false,
    videoUrl = '',
  } = props;
  const { records } = useSelector(state => state.recordsList);
  const isImpressionRecord = recordType?.includes('impression');
  const isSTLTypeRecord = records[recordType + '_type'] === 'stl';
  const isVideoFile = recordType?.includes('other-records') && videoUrl.trim() !== '';

  const { t } = useTranslation('contentTrs');

  const { isVisible: isImgModalVisible, show: showImgModal, hide: hideImgModal } = useVisibilityToggle(false);
  const { isVisible: isVideoModalVisible, show: showVideoModal, hide: hideVideoModal } = useVisibilityToggle(false);
  const { isVisible: isSTLReaderVisible, show: showSTLReader, hide: hideSTLReader } = useVisibilityToggle(false);

  const openFile = useCallback(() => {
    if (isImpressionRecord && isSTLTypeRecord) {
      showSTLReader();
      return;
    }
    if (isVideoFile) {
      showVideoModal();
      return;
    }
    showImgModal();
  }, [showImgModal, showVideoModal, showSTLReader, isVideoFile, isImpressionRecord, isSTLTypeRecord]);

  const [openDeleteImageModal, setOpenDeleteImageModal] = useState(false);

  // Function to open modal
  const handleOpenDeleteImageModal = useCallback(() => {
    setOpenDeleteImageModal(true);
  }, []);

  // Function to close modal
  const handleCloseDeleteImageModal = useCallback(() => {
    setOpenDeleteImageModal(false);
  }, []);

  const onDeleteImage = useCallback(() => {
    deleteImg && deleteImg(recordType);
    handleCloseDeleteImageModal(); // Close the modal after deletion
  }, [deleteImg, recordType, handleCloseDeleteImageModal]);

  const handleChange = event => {
    const loadedFile = event.target.files[0];
    editImg(loadedFile, recordType);
  };

  return (
    <div className="h-100 w-100">
      <div className={`${classes ? classes : `downloadImageRoot`} h-100 w-100`}>
        {recordType?.includes('impression') ? (
          image.photo_url ? (
            <Image src={`${base_URL_static_asset}/3Dtooth.png`} fluid className="mh-100" />
          ) : (
            <Image src={`${base_URL_static_asset}/3Dscan.png`} fluid className="mh-100" />
          )
        ) : image.minimized_photo_url ? (
          image.minimized_photo_url?.includes('/records/') &&
          (image.subtype_id === 11 || image.subtype_id === 12 || image.subtype_id === null) ? (
            <div style={{ textAlign: 'center' }}>{image.name}</div>
          ) : (
            <Image src={`${base_URL_media}${image.minimized_photo_url}`} fluid className="mh-100" />
          )
        ) : (
          <Image src={`${base_URL_static_asset}/${recordType}.png`} fluid className="mh-100" />
        )}
        <div className={image.photo_url?.includes('/records/') && readOnly ? 'display-none ' : 'downloadImageModal '}>
          {!readOnly && !isNonUploadableField && (
            <>
              <input
                id={`fileUploadButton-${recordType}`}
                type="file"
                onChange={handleChange}
                style={{ display: 'none' }}
                accept={recordType?.includes('impression') ? '.jpg, .jpeg, .stl' : '.jpg, .jpeg, .png'}
              />
              <label for={`fileUploadButton-${recordType}`}>
                <BsCloudUploadFill size={ICON_SIZE} />
              </label>
            </>
          )}

          {image.photo_url && (
            <div onClick={openFile} className="my-2">
              <BsZoomIn size={ICON_SIZE} />
            </div>
          )}

          {!readOnly && image.photo_url && (
            <div
              onClick={() => {
                downloadImg(recordType);
              }}
              className="my-2"
            >
              <BsCloudDownloadFill size={ICON_SIZE} />
            </div>
          )}
          {!readOnly && deleteImg && image.photo_url && (
            <div onClick={handleOpenDeleteImageModal} className="my-2">
              <BsTrash size={ICON_SIZE} />
            </div>
          )}
          {/* Confirmation Modal */}
          <Dialog open={openDeleteImageModal} onClose={handleCloseDeleteImageModal}>
            <DialogTitle>{t('recordField.deleteImg')}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t('recordField.deleteConfirmation')}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteImageModal} color="primary">
                {t('recordField.cancel')}
              </Button>
              <Button onClick={onDeleteImage} color="primary" autoFocus>
                {t('recordField.delete')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <ImageEditorModal
          image={image}
          isVisible={isImgModalVisible}
          handleClose={hideImgModal}
          editImg={editImg}
          recordType={recordType}
        />
        <VideoRenderer videoUrl={videoUrl} isVisible={isVideoModalVisible} handleClose={hideVideoModal} />
        <STLrenderer image={image} isVisible={isSTLReaderVisible} handleClose={hideSTLReader} />
      </div>

      {editImageProgress ? (
        editImageProgress.percentComplete > 0 && recordType === editImageProgress.recordType ? (
          <LinearProgress color="success" variant="determinate" value={editImageProgress.percentComplete} />
        ) : null
      ) : null}
    </div>
  );
};

export default RecordField;
