import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Button, Row, Col, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../components/Loader';
import Message from '../../../../components/Message';
import { useTranslation } from 'react-i18next';

import '../settings.css';

// utils
import { passwordStrengthValidation } from '../../../../utilities/passwordStrengthValidation';

import {
  getUserDetails,
  updateUserProfile,
  getDentistProfile,
  updateDentistProfile,
  getDentistInvoicing,
  updateDentistInvoicing,
} from '../../../../actions/userActions';
import { USER_UPDATE_PROFILE_RESET, DENTIST_UPDATE_PROFILE_RESET } from '../../../../constants/userConstants';
import { REQUIRED_COUNTRIES } from '../../../../constants/countryOptions';

function ProfileTab() {
  const { t } = useTranslation(['contentTrs', 'passwordValidation']);
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [title, setTitle] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [birthDate, setBirthDate] = useState('');

  const [businessName, setBusinessName] = useState('');
  const [vatNum, setVatNum] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector(state => state.userDetails);
  const { error, loading, user } = userDetails;

  const userUpdateProfile = useSelector(state => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  const dentistProfile = useSelector(state => state.dentistProfile);
  const { error: errorDentistProfile, loading: lodaingDentistProfile, dentist } = dentistProfile;

  const dentistUpdateProfile = useSelector(state => state.dentistUpdateProfile);
  const {
    error: errorUpdatingDentistProfile,
    loading: lodaingUpdatingDentistProfile,
    success: successUpdatingDentistProfile,
  } = dentistUpdateProfile;

  const dentistInvoicing = useSelector(state => state.dentistInvoicing);
  const { error: errorDentistInvoicing, loading: lodaingDentistInvoicing, invoicing } = dentistInvoicing;

  const dentistUpdateInvoicing = useSelector(state => state.dentistUpdateInvoicing);
  const {
    error: errorUpdatingDentistInvoicing,
    loading: lodaingUpdatingDentistInvoicing,
    success: successUpdatingDentistInvoicing,
  } = dentistUpdateInvoicing;

  console.log(error);

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    } else {
      if ((!user || !user.name || success) && !loading) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails('profile'));
      } else {
        setName(user.name);
        setEmail(user.email);
      }
    }
  }, [dispatch, userInfo, user, success]);

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    } else {
      if (!dentist || !dentist.first_name) {
        dispatch({ type: DENTIST_UPDATE_PROFILE_RESET });
        dispatch(getDentistInvoicing());
      } else {
        setFirstName(dentist.first_name);
        setLastName(dentist.last_name);
        setPhoneNumber(dentist.phone_number);
        setTitle(dentist.title);
        setSpecialty(dentist.specialty);
        setBirthDate(dentist.birth_date);
      }
    }
  }, [dispatch, userInfo, dentist]);

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    } else {
      if (!invoicing || !invoicing.VAT_num) {
        dispatch({ type: DENTIST_UPDATE_PROFILE_RESET });
        dispatch(getDentistProfile());
      } else {
        setBusinessName(invoicing.business_name);
        setVatNum(invoicing.VAT_num);
        setAddressLine1(invoicing.adrress_line1);
        setAddressLine2(invoicing.adrress_line2);
        setProvince(invoicing.city);
        setCity(invoicing.province);
        setZipCode(invoicing.ZIP_code);
        setCountry(invoicing.country);
      }
    }
  }, [dispatch, userInfo, invoicing]);

  // console.log(user)

  const submitHandler = e => {
    e.preventDefault();

    if (password != confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    const passwordStrengthResult = passwordStrengthValidation(password, t);
    if (!passwordStrengthResult.isValid) {
      setMessage(passwordStrengthResult.errorMessage);
      return;
    }

    dispatch(
      updateUserProfile({
        id: user._id,
        name: name,
        email: email,
        password: password,
      })
    );
    setMessage('');
  };

  const submitDentistProfileHandler = e => {
    e.preventDefault();

    dispatch(
      updateDentistProfile({
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        title: title,
        specialty: specialty,
        birth_date: birthDate,
      })
    );
    setMessage('');
  };

  const submitDentistInvoicingHandler = e => {
    e.preventDefault();

    dispatch(
      updateDentistInvoicing({
        business_name: businessName,
        VAT_num: vatNum,
        adrress_line1: addressLine1,
        adrress_line2: addressLine2,
        city: city,
        province: province,
        ZIP_code: zipCode,
        country: country,
      })
    );
    setMessage('');
  };

  return (
    <Row className="py-2">
      <Col md={4}>
        <h5>{t('settingsPage.profil.credentialColTitle')}</h5>

        {message && <Message variant="info">{message}</Message>}
        {error && <Message variant="danger">{error}</Message>}
        {loading && <Loader />}

        <Form onSubmit={submitHandler} className="py-3">
          <Form.Group controlId="email">
            <Form.Label>{t('settingsPage.profil.email')}</Form.Label>
            <Form.Control
              required
              type="email"
              placeholder={t('settingsPage.profil.fval_email')}
              value={email}
              onChange={e => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button type="submit" variant="primary" className="form-button">
            {t('settingsPage.profil.updateButton')}
          </Button>
        </Form>

        <Form onSubmit={submitHandler}>
          <Form.Group controlId="oldpassword">
            <Form.Label>{t('settingsPage.profil.oldPass')}</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder={t('settingsPage.profil.fval_oldPass')}
              value={oldPassword}
              onChange={e => setOldPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>{t('settingsPage.profil.newPass')}</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder={t('settingsPage.profil.fval_newPass')}
              value={password}
              onChange={e => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="passwordConfirm">
            <Form.Label>{t('settingsPage.profil.confirmPass')}</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder={t('settingsPage.profil.fval_confirmPass')}
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button type="submit" variant="primary" className="form-button">
            {t('settingsPage.profil.updateButton')}
          </Button>
        </Form>
      </Col>

      <Col md={4}>
        <h5>{t('settingsPage.profil.userProfilColTitle')}</h5>

        {errorDentistProfile && <Message variant="danger">{errorDentistProfile}</Message>}
        {lodaingUpdatingDentistProfile && <Loader />}

        <Form onSubmit={submitDentistProfileHandler} className="py-3">
          <Form.Group controlId="first_name">
            <Form.Label>{t('settingsPage.profil.firstname')}</Form.Label>
            <Form.Control
              required
              type="name"
              placeholder={t('settingsPage.profil.fval_fName')}
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="last_name">
            <Form.Label>{t('settingsPage.profil.lastName')}</Form.Label>
            <Form.Control
              required
              type="name"
              placeholder={t('settingsPage.profil.fval_lName')}
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="date_of_birth">
            <Form.Label>{t('settingsPage.profil.dataOb')}</Form.Label>
            <Form.Control
              required
              type="date"
              max="2003-01-01"
              placeholder="dd-mm-yyyy"
              value={birthDate}
              onChange={e => setBirthDate(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="phone_number">
            <Form.Label>{t('settingsPage.profil.phone')}</Form.Label>
            <Form.Control
              required
              type="number"
              placeholder={t('settingsPage.profil.fval_phone')}
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="title">
            <Form.Label>{t('settingsPage.profil.userTitle')}</Form.Label>
            <Form.Control
              required
              type="name"
              placeholder={t('settingsPage.profil.fval_title')}
              value={title}
              onChange={e => setTitle(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="specialty">
            <Form.Label>{t('settingsPage.profil.speciality')}</Form.Label>
            <Form.Control
              required
              type="name"
              placeholder={t('settingsPage.profil.fval_speciality')}
              value={specialty}
              onChange={e => setSpecialty(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button type="submit" variant="primary" className="form-button">
            {t('settingsPage.profil.updateButton')}
          </Button>
        </Form>
      </Col>

      <Col md={4}>
        <h5>{t('settingsPage.profil.invoiceColTitle')}</h5>

        {errorUpdatingDentistInvoicing && <Message variant="danger">{error}</Message>}
        {lodaingUpdatingDentistInvoicing && <Loader />}

        <Form onSubmit={submitDentistInvoicingHandler} className="py-3">
          <Form.Group controlId="businessName">
            <Form.Label>{t('settingsPage.profil.businessName')}</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder={t('settingsPage.profil.fval_businessName')}
              value={businessName}
              onChange={e => setBusinessName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="vatNum">
            <Form.Label>{t('settingsPage.profil.vatNumber')}</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder={t('settingsPage.profil.fval_vatNum')}
              value={vatNum}
              onChange={e => setVatNum(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="addressLine1">
            <Form.Label>{t('settingsPage.profil.Address1')}</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder={t('settingsPage.profil.fval_adress')}
              value={addressLine1}
              onChange={e => setAddressLine1(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="addressLine2">
            <Form.Label>{t('settingsPage.profil.Address2')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('settingsPage.profil.fval_adress')}
              value={addressLine2}
              onChange={e => setAddressLine2(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="city">
            <Form.Label>{t('settingsPage.profil.city')}</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder={t('settingsPage.profil.fval_city')}
              value={city}
              onChange={e => setCity(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="province">
            <Form.Label>{t('settingsPage.profil.province')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('settingsPage.profil.fval_province')}
              value={province}
              onChange={e => setProvince(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="zipCode">
            <Form.Label>{t('settingsPage.profil.zipCode')}</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder={t('settingsPage.profil.fval_zipCode')}
              value={zipCode}
              onChange={e => setZipCode(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="Country">
            <Form.Label>{t('authPages.registerNewAccount.section3_profil.country')}</Form.Label>
            <Form.Select
              required
              placeholder={t('authPages.registerNewAccount.section3_profil.fval_country')}
              value={country}
              disabled={true}
              onChange={e => setCountry(e.target.value)}
            >
              {REQUIRED_COUNTRIES.map(country => (
                <option value={country.code} key={country.code}>
                  {country.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Button type="submit" variant="primary" className="form-button">
            {t('settingsPage.profil.updateButton')}
          </Button>
        </Form>
      </Col>
    </Row>
  );
}

export default ProfileTab;
