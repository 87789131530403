import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Row, Col, Image, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import instance from '../../helpers/axiosConfig';
import RecordField from '../../components/ImageComponent/RecordField';
import { updateTreatment } from '../../actions/treatmentActions';
import { getTreatmentNotesList, updateTreatmentNotes } from '../../actions/notesActions';
import { base_URL_media, base_URL_static_asset } from '../../helpers/axiosConfig';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

function TreatmentsTab(props) {
  const { t } = useTranslation('contentTrs');
  const { intl, treatment, patientId, proposedBlock } = props;

  const dispatch = useDispatch();

  const [treatmentsList, setTreatmentList] = useState();

  // treatments tab variables/functions
  const [chosenTreatmentsFV, setChosenTreatmentsFV] = useState(new Set(treatment.treatment_options));
  const initialState =
    treatment.chosen_treatment?.length > 0 ? treatment.chosen_treatment : treatment.treatment_options;
  const [chosenTreatments, setChosenTreatments] = useState(new Set(initialState));

  // Init Toast Alert
  const [openSnack, setOpenSnack] = useState({
    open: false,
    displayElement: 'hidden',
    vertical: 'top',
    horizontal: 'center',
  });
  const [msg, setMsg] = useState(`Modifiche Salvate`);
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  // End Toast Alert

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const [updateNotes, setUpdateNotes] = useState(false);

  const treatmentNotes = useSelector(state => state.treatmentNotes);
  const { error: errorVAdd, loading: loadingVadd, treatmentNotes: notes } = treatmentNotes;

  const initialNotes = notes ? notes['description'] : '';
  const [notesBody, setNotesBody] = useState(initialNotes);

  const listRecords = async userInfo => {
    //const { userLogin: {userInfo}, } = getState()

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`/api/lookup/treatments/list`, config);

    setTreatmentList(data);
    //console.log(data)
  };

  const timezoneOffset = new Date().toLocaleString(t('landTags.langName'), {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
  //console.log(timezoneOffset);

  useEffect(() => {
    setNotesBody(notesBody + '\n' + timezoneOffset + '\n');
  }, [loadingVadd]);

  useEffect(() => {
    listRecords(userInfo);
  }, [userInfo]);

  useEffect(() => {
    let treatmentsArray = { chosen_treatment: [...chosenTreatments] };
    dispatch(updateTreatment(patientId, treatmentsArray));
  }, [chosenTreatments]);

  useEffect(() => {
    if (updateNotes) {
      dispatch(getTreatmentNotesList(patientId));
      setUpdateNotes(false);
    }
  }, [dispatch, updateNotes]);

  const submitTreatmentNotes = e => {
    e.preventDefault();

    let body = { description: notesBody };
    dispatch(updateTreatmentNotes(patientId, body));
    setUpdateNotes(true);
    setOpenSnack({ open: true, displayElement: 'visible' });
  };

  return (
    <div>
      <Row>
        <h5>{t('single_patienScreens.treatmentTabCol.title')}</h5>
      </Row>

      <Card className="p-2 pb-0 mb-2 shadow radius ">
        <Row>
          <h6>{t('single_patienScreens.treatmentTabCol.firstVisistProposal.title')}</h6>
        </Row>

        <Row className="treatmentOptionImages justify-content-around m-0 mt-1 pb-3">
          {treatmentsList
            ? treatmentsList.map(treatment => {
                return (
                  <React.Fragment key={treatment.id}>
                    {chosenTreatmentsFV.has(treatment.id) && (
                      <Card className="px-2 py-1 mb-1  radius ">
                        <Row>
                          <Col>
                            <Image src={`${base_URL_media}${treatment.icon}`} style={{ maxWidth: 30, maxHeight: 30 }} />
                          </Col>
                          <Col sm={9} md={9} lg={9} className="text-right p-0 m-0">
                            {treatment.treatment_name}
                          </Col>
                        </Row>
                      </Card>
                    )}
                  </React.Fragment>
                );
              })
            : null}
        </Row>
      </Card>

      <Card className="p-2 pb-0 mb-2 shadow radius ">
        <Row>
          <h6>{t('single_patienScreens.treatmentTabCol.chooseTreatment.title')}</h6>
        </Row>

        <Row>
          <h6>{t('single_patienScreens.treatmentTabCol.chooseTreatment.subTitle')}</h6>
        </Row>

        <Row className="treatmentOptionImages justify-content-around m-0 mt-1 pb-3">
          {treatmentsList
            ? treatmentsList.map(treatment => {
                return (
                  <React.Fragment key={treatment.id}>
                    {treatment.is_main && (
                      <Card
                        className={
                          chosenTreatments.has(treatment.id)
                            ? 'treatmentOptionImage mt-1 px-2 py-1 shadow --active'
                            : 'treatmentOptionImage mt-1 px-2 py-1'
                        }
                        onClick={() => {
                          const set = new Set(chosenTreatments);
                          if (set.has(treatment.id)) {
                            set.delete(treatment.id);
                          } else {
                            set.add(treatment.id);
                          }
                          setChosenTreatments(set);
                        }}
                      >
                        <Row>
                          <Col>
                            <Image src={`${base_URL_media}${treatment.icon}`} style={{ maxWidth: 30, maxHeight: 30 }} />
                          </Col>
                          <Col sm={9} md={9} lg={9} className="text-right p-0 m-0">
                            {treatment.treatment_name}
                          </Col>
                        </Row>
                      </Card>
                    )}
                  </React.Fragment>
                );
              })
            : null}
        </Row>

        {/* <Row className='treatmentOptionImages justify-content-around m-0 mt-1 pb-3'>
            {treatmentsList ?
                ( treatmentsList.map( (treatment) => {
                    
                    return(
                        <React.Fragment key={treatment.id}>
                        {treatment.is_main && 
                            <Col
                                sm={5}
                                className={chosenTreatments.has(treatment.id) ? 'treatmentOptionImage mt-3 --active' : 'treatmentOptionImage mt-3'}
                                onClick={() => {
                                    const set = new Set(chosenTreatments)
                                    if (set.has(treatment.id)) {
                                      set.delete(treatment.id)
                                    } else {
                                      set.add(treatment.id)
                                    }
                                    setChosenTreatments(set)
                                  }}
                                >
                                <Image src={treatment.icon} style={{maxWidth: 65, maxHeight: 65}}/>
                                <div className='font-weight-bold font-lg mt-2'>
                                    {(treatment.treatment_name)}
                                </div>
                            </Col>
                        }
                        </React.Fragment>
                    ) })
                )
                : null }
        </Row> */}

        <Row>
          <h6>{t('single_patienScreens.treatmentTabCol.chooseTreatment.others')}</h6>
        </Row>

        <Row className="treatmentOptionImages justify-content-around m-0 mt-1 pb-3">
          {treatmentsList
            ? treatmentsList.map(treatment => {
                return (
                  <React.Fragment key={treatment.id}>
                    {!treatment.is_main && (
                      <Card
                        className={
                          chosenTreatments.has(treatment.id)
                            ? 'treatmentOptionImage mt-1 px-2 py-1 shadow --active'
                            : 'treatmentOptionImage mt-1 px-2 py-1'
                        }
                        onClick={() => {
                          const set = new Set(chosenTreatments);
                          if (set.has(treatment.id)) {
                            set.delete(treatment.id);
                          } else {
                            set.add(treatment.id);
                          }
                          setChosenTreatments(set);
                        }}
                      >
                        <Row>
                          <Col>
                            <Image src={`${base_URL_media}${treatment.icon}`} style={{ maxWidth: 30, maxHeight: 30 }} />
                          </Col>
                          <Col sm={9} md={9} lg={9} className="text-right p-0 m-0">
                            {treatment.treatment_name}
                          </Col>
                        </Row>
                      </Card>
                    )}
                  </React.Fragment>
                );
              })
            : null}
        </Row>
      </Card>

      <Card className="p-2 pb-0 mb-2 shadow radius ">
        <Row>
          <h6>{t('single_patienScreens.treatmentTabCol.chooseTreatment.note')}</h6>
        </Row>

        <Row className="justify-center">
          {notes && (
            <Form onSubmit={submitTreatmentNotes}>
              <Form.Group className="p-1" controlId="treatment_notes">
                {/* <Form.Label>Anamnesis notes</Form.Label> */}
                <Form.Control as="textarea" rows={3} value={notesBody} onChange={e => setNotesBody(e.target.value)} />
              </Form.Group>

              <Button type="submit" variant="primary" className="form-button text-end">
                {t('single_patienScreens.treatmentTabCol.update')}
              </Button>
            </Form>
          )}
        </Row>
      </Card>

      <Snackbar
        style={{ visibility: openSnack.displayElement }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          {' '}
          {msg}{' '}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default TreatmentsTab;
