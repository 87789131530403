import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import '../componentsCSS/Components.css';
import { Link } from 'react-router-dom';
import { logout } from '../../actions/userActions';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Toolbar';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Menu } from '@mui/material';
import { styled } from '@mui/material/styles';

import { updateDentistProfile } from '../../actions/userActions';
import { BsFillCaretDownFill } from 'react-icons/bs';

const NavItem = styled(Button)({
  textTransform: 'none',
  fontSize: 15,
  color: 'white',
});
const Text = styled(Typography)({
  fontSize: 15,
  color: '#052646',
});

const Header = () => {
  const { userInfo } = useSelector(state => state.userLogin);
  const { unreadMessageCount } = useSelector(state => state.notificationsChat);

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation('contentTrs');
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    localStorage.setItem('lng', lng);
    dispatch(updateDentistProfile({ language: lng }));
  };

  const [langChoice, setLanguage] = useState('');

  const handleLangChange = event => {
    setLanguage(event.target.value);
    changeLanguage(event.target.value);
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // check CHeader and CNavBar fro inHeader adn withSubheader
  return (
    <>
      <Box className="header_ob sticky-top">
        <AppBar position="static" className="header_ob">
          <Toolbar disableGutters>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                flex: 1,
              }}
            >
              <Box>
                <NavItem>
                  <Link to="/" style={{ textDecoration: 'none' }}>
                    {t('navigation_menu.home')}
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/patients" style={{ textDecoration: 'none' }}>
                    {t('navigation_menu.patients')}
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/settings" style={{ textDecoration: 'none' }}>
                    {t('navigation_menu.setting')}
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/conversations/" style={{ textDecoration: 'none' }}>
                    {t('chat.title') + ' (' + unreadMessageCount + ')'}{' '}
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/download" style={{ textDecoration: 'none' }}>
                    {t('navigation_menu.download')}
                  </Link>
                </NavItem>
                {/* <NavItem>Analisys</NavItem> */}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small">Lang</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={langChoice}
                    label="Lang"
                    onChange={handleLangChange}
                  >
                    <MenuItem value="en">{t('lang.english')}</MenuItem>
                    <MenuItem value="fr">{t('lang.french')}</MenuItem>
                    <MenuItem value="it">{t('lang.italian')}</MenuItem>
                    <MenuItem value="es">{t('lang.spanish')}</MenuItem>
                    <MenuItem value="de">{t('lang.german')}</MenuItem>
                  </Select>
                </FormControl>
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="Open settings" onClick={handleOpenUserMenu}>
                    <Text>Dr. {userInfo?.name} </Text> <BsFillCaretDownFill color="black" />
                  </Tooltip>
                  <Menu
                    sx={{ mt: '30px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem key={'logout'} onClick={logoutHandler}>
                      <Typography textAlign="center">{t('navigation_menu.user_subMenu.logout')}</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default Header;
