import { CardElement, useElements } from '@stripe/react-stripe-js';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Row, Col } from 'react-bootstrap';
import ApiService from './stripeApi';
import { BsCheckCircle, BsXCircle, BsFillCaretRightSquareFill } from 'react-icons/bs';
import EditCardModal from './EditCardModal';
import Message from '../Message';

const CardForm = props => {
  const { t } = useTranslation('contentTrs');
  const { card, setCard, updatedCard, setUpdatedCard, handleSubmitCard } = props;

  const [error, setError] = useState(null);

  const [loadingPaymentMethod, setLoadingPaymentMethod] = useState('');
  const [editCard, setEditCard] = useState(false);

  // Page loads
  useEffect(() => {
    ApiService.RetrivePayentMethod()
      .then(response => {
        {
          response.data?.card ? setCard(response.data?.card) : setCard(false);
        }
      })
      .catch(error => {});
  }, []);

  // Handle real-time validation errors from the CardElement.
  const handleChange = event => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  return (
    <>
      {card ? (
        <>
          <Row>
            <Col sm={5} className=" m-1">
              <p>{t('settingsPage.suscription.selectedCard')}</p>
            </Col>
            <Col sm={5} className=" text-end m-1 ">
              <Button className="baseButton" onClick={() => setEditCard(true)}>
                {t('settingsPage.suscription.edit.button')}
              </Button>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={6}>
              <div>{t('settingsPage.suscription.circuit')}</div>
              <div>{t('settingsPage.suscription.expireDate')}</div>
              <div>{t('settingsPage.suscription.cardNumber')}</div>
            </Col>
            <Col sm={6}>
              <div>{card.brand}</div>
              <div>
                {card.exp_month}/{card.exp_year}
              </div>
              <div>**** **** **** {card.last4}</div>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <p> {t('settingsPage.suscription.edit.addCard')} </p>
          <form onSubmit={handleSubmitCard} className="stripe-form">
            <div className="form-row">
              <CardElement id="card-element" onChange={handleChange} />
              <div className="card-errors" role="alert">
                {error}
              </div>
            </div>
            <button type="submit" className="mt-3 flex justify-content-between baseButton">
              {t('settingsPage.suscription.edit.verifyCard')}
            </button>
          </form>
        </>
      )}

      <EditCardModal
        show={editCard}
        setShow={setEditCard}
        card={card}
        setCard={setCard}
        updatedCard={updatedCard}
        setUpdatedCard={setUpdatedCard}
        handleSubmitCard={handleSubmitCard}
      />
    </>
  );
};
export default CardForm;
