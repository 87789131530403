import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

import Loader from '../components/Loader';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer';

import axios, { instanceBase } from '../helpers/axiosConfig';

import { register, registerDentist } from '../actions/userActions';

// todo: core-ui CSS import
import '../scss/coreUI.scss';

function ForgotPasswordScreen() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const redirect = searchParams.get('redirect') ? searchParams.get('redirect') : '/';

  const [emailSent, setEmailSent] = useState('');
  const { t } = useTranslation('contentTrs');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [image, setImage] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [title, setTitle] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [birthDate, setBirthDate] = useState('');

  const [businessName, setBusinessName] = useState('');
  const [vatNum, setVatNum] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');

  const formData = {
    dataUser: {
      name: firstName,
      email: email,
      password: password,
    },
    dataDentistProfile: {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      title: title,
      specialty: specialty,
      birth_date: birthDate,
    },
    dataDentistInvoicing: {
      VAT_num: vatNum,
      business_name: businessName,
      adrress_line1: addressLine1,
      adrress_line2: addressLine2,
      city: city,
      province: province,
      ZIP_code: zipCode,
      country: country,
    },
  };

  const dispatch = useDispatch();

  const dentistRegister = useSelector(state => state.dentistRegister);
  const { error, loading, userInfo } = dentistRegister;

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [userInfo, redirect, navigate]);

  const submitHandler = async e => {
    e.preventDefault();

    // dispatch(registerDentist(formData))
    const { data } = await instanceBase.post(
      'api/request-reset-email/',
      //'https://dev.tie-cast.co.uk/api/users/login/',
      { email: email }
    );
    setEmailSent(true);
  };

  return (
    <div className="w-100 p-3">
      <Container inline="true" className="mb-5">
        <Link to="/login">
          <Button variant="primary">{t('authPages.backtoLogin_Button')}</Button>
        </Link>

        <h1 className="text-center mt-5 mb-5">{t('authPages.forgorPass.title')}</h1>
      </Container>

      <Container className="pt-3 pb-4 mt-5">
        {emailSent && <Message variant="info">{t('authPages.forgorPass.sendEmail_msg')}</Message>}
        {error && <Message variant="danger">{error}</Message>}
        {loading && <Loader></Loader>}

        <Form onSubmit={submitHandler}>
          <Row>
            <Col md={6}>
              <Row className="mb-2">
                <h4 className="text-left">{t('authPages.forgorPass.accountInfoSubtitle')} </h4>

                <Form.Group controlId="email">
                  <Form.Label>{t('authPages.forgorPass.email')}</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder={t('authPages.forgorPass.fval_email')}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Row>
            </Col>
          </Row>

          <Button type="submit" variant="primary">
            {t('authPages.forgorPass.confirmButton')}
          </Button>
        </Form>
      </Container>
    </div>
  );
}

export default ForgotPasswordScreen;
