export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';

export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const DENTIST_REGISTER_REQUEST = 'DENTIST_REGISTER_REQUEST';
export const DENTIST_REGISTER_SUCCESS = 'DENTIST_REGISTER_SUCCESS';
export const DENTIST_REGISTER_FAIL = 'DENTIST_REGISTER_FAIL';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET';

export const DENTIST_PROFILE_REQUEST = 'DENTIST_PROFILE_REQUEST';
export const DENTIST_PROFILE_SUCCESS = 'DENTIST_PROFILE_SUCCESS';
export const DENTIST_PROFILE_FAIL = 'DENTIST_PROFILE_FAIL';
export const DENTIST_PROFILE_RESET = 'DENTIST_PROFILE_RESET';

export const DENTIST_UPDATE_PROFILE_REQUEST = 'DENTIST_UPDATE_PROFILE_REQUEST';
export const DENTIST_UPDATE_PROFILE_SUCCESS = 'DENTIST_UPDATE_PROFILE_SUCCESS';
export const DENTIST_UPDATE_PROFILE_FAIL = 'DENTIST_UPDATE_PROFILE_FAIL';
export const DENTIST_UPDATE_PROFILE_RESET = 'DENTIST_UPDATE_PROFILE_RESET';

export const DENTIST_INVOICING_REQUEST = 'DENTIST_INVOICING_REQUEST';
export const DENTIST_INVOICING_SUCCESS = 'DENTIST_INVOICING_SUCCESS';
export const DENTIST_INVOICING_FAIL = 'DENTIST_INVOICING_FAIL';
export const DENTIST_INVOICING_RESET = 'DENTIST_INVOICING_RESET';

export const DENTIST_UPDATE_INVOICING_REQUEST = 'DENTIST_UPDATE_INVOICING_REQUEST';
export const DENTIST_UPDATE_INVOICING_SUCCESS = 'DENTIST_UPDATE_INVOICING_SUCCESS';
export const DENTIST_UPDATE_INVOICING_FAIL = 'DENTIST_UPDATE_INVOICING_FAIL';
export const DENTIST_UPDATE_INVOICING_RESET = 'DENTIST_UPDATE_INVOICING_RESET';
