import { useTranslation } from 'react-i18next';

export const ClinicInfo = ({ clinicImageUrl, clinicName }) => {
  const { t } = useTranslation('patientSignup');

  return (
    <div className="tw-flex tw-flex-col tw-items-center">
      {clinicImageUrl ? (
        <img src={clinicImageUrl} alt="Clinic logo" className="tw-w-64 tw-h-44 tw-object-cover tw-mb-3" />
      ) : null}
      {clinicName ? (
        <h1 className="tw-text-white tw-text-2xl md:tw-text-4xl tw-font-bold tw-mb-2">{clinicName}</h1>
      ) : null}
      {clinicName ? (
        <div className="tw-text-white tw-text-2xl md:tw-text-4xl tw-font-normal tw-text-center">
          {t('is inviting you to activate your patient account!')}
        </div>
      ) : null}
    </div>
  );
};
