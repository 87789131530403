// lib
import React from 'react';
import QRCode from 'react-qr-code';

// components
import Message from '../components/Message';

// icons
import androidIcon from '../asset/images/Android_apk.png';
import iosIcon from '../asset/images/ios.png';

// hooks
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function DownloadScreen() {
  const androidAppURL = 'https://play.google.com/store/apps/details?id=com.ortho.book2';
  const iosAppURL = 'https://apps.apple.com/tr/app/ortho-book-pro/id1625175074';
  const paySubscription = useSelector(state => state.paySubscription);
  const { success: sucessSubscription } = paySubscription;
  const navigate = useNavigate();
  const { t } = useTranslation('downloadPage');

  return (
    <div className="tw-flex tw-flex-col tw-w-full md:tw-h-[90vh]">
      {sucessSubscription ? (
        <div className="tw-flex-none">
          <Message variant="success">{t('Congratulations! You have activated an OrthoBook subscription!')}</Message>
        </div>
      ) : null}
      <div className="tw-flex-1 tw-flex tw-flex-col tw-gap-5 md:tw-gap-7 tw-items-center tw-justify-center">
        <h2 className="tw-text-center">{t('Scan the QR code to download our app')}</h2>
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-5 md:tw-gap-10">
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
            <div className="tw-mb-5">
              <QRCode value={androidAppURL} />
            </div>
            <img src={androidIcon} alt="Android" className="tw-h-18" />
          </div>
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
            <div className="tw-mb-5">
              <QRCode value={iosAppURL} />
            </div>
            <img src={iosIcon} alt="iOS" className="tw-h-18" />
          </div>
        </div>
        <button
          onClick={() => navigate('/')}
          className="tw-text-center tw-px-10 tw-py-4 tw-rounded-lg tw-bg-lightBlue hover:tw-bg-darkBlue tw-text-white tw-text-xl"
        >
          {t('Go Home')}
        </button>
      </div>
    </div>
  );
}

export default DownloadScreen;
