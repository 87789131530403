import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import '../settings.css';

import { createClinics } from '../../../../actions/clinicsActions';
import { REQUIRED_COUNTRIES } from '../../../../constants/countryOptions';

const AddClinicPopUp = () => {
  const { t } = useTranslation('contentTrs');
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [clinicName, setClinicName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [ZIP_code, setZIP_code] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [logo, setLogo] = useState('');
  const [title, setTitle] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [specialty, setSpecialty] = useState('');

  const submitClinicHandler = e => {
    e.preventDefault();

    dispatch(
      createClinics({
        clinic_name: clinicName,
        adrress_line1: addressLine1,
        city: city,
        ZIP_code: ZIP_code,
        country: country,
        email: email,
        phone_number: phoneNumber,
        title: title,
        specialty: specialty,
        first_name: firstName,
        last_name: lastName,
      })
    );
  };

  return (
    <>
      <Button variant="dark" onClick={handleShow}>
        + {t('settingsPage.clinicPresentation.newClinicButton')}
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} className="p-2">
        <Modal.Header closeButton>
          <Modal.Title>{t('settingsPage.clinicPresentation.clinicDetailPopup.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={submitClinicHandler} className="py-3">
            <Form.Group controlId="clinic_name">
              <Form.Label>{t('settingsPage.clinicPresentation.clinicDetailPopup.clinicName')}</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder={t('settingsPage.clinicPresentation.clinicDetailPopup.fval_clinicN')}
                value={clinicName}
                onChange={e => setClinicName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="addressLine1">
              <Form.Label>{t('settingsPage.clinicPresentation.clinicDetailPopup.fullAddress')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('settingsPage.clinicPresentation.clinicDetailPopup.fval_fAddress')}
                value={addressLine1}
                onChange={e => setAddressLine1(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="city">
              <Form.Label>{t('City')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('City')}
                value={city}
                onChange={e => setCity(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="ZIP_code">
              <Form.Label>{t('ZIP Code')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('ZIP Code')}
                value={ZIP_code}
                onChange={e => setZIP_code(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="Country">
              <Form.Label>{t('authPages.registerNewAccount.section3_profil.country')}</Form.Label>
              <Form.Select
                required
                placeholder={t('authPages.registerNewAccount.section3_profil.fval_country')}
                value={country}
                onChange={e => setCountry(e.target.value)}
              >
                {REQUIRED_COUNTRIES.map(country => (
                  <option value={country.code} key={country.code}>
                    {country.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>{t('settingsPage.clinicPresentation.clinicDetailPopup.email')}</Form.Label>
              <Form.Control
                type="email"
                placeholder={t('settingsPage.clinicPresentation.clinicDetailPopup.fval_email')}
                value={email}
                onChange={e => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="phoneNumber">
              <Form.Label>{t('settingsPage.clinicPresentation.clinicDetailPopup.phoneNumber')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('settingsPage.clinicPresentation.clinicDetailPopup.fval_phoneNumber')}
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="title">
              <Form.Label>{t('settingsPage.clinicPresentation.clinicDetailPopup.titleQualification')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('settingsPage.clinicPresentation.clinicDetailPopup.fval_title')}
                value={title}
                onChange={e => setTitle(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="firstName">
              <Form.Label>{t('settingsPage.clinicPresentation.clinicDetailPopup.firstName')}</Form.Label>
              <Form.Control
                type="name"
                placeholder={t('settingsPage.clinicPresentation.clinicDetailPopup.fval_fName')}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="lastName">
              <Form.Label>{t('settingsPage.clinicPresentation.clinicDetailPopup.lastName')}</Form.Label>
              <Form.Control
                type="name"
                placeholder={t('settingsPage.clinicPresentation.clinicDetailPopup.fval_lName')}
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="specialty">
              <Form.Label>{t('settingsPage.clinicPresentation.clinicDetailPopup.speciality')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('settingsPage.clinicPresentation.clinicDetailPopup.fval_specialty')}
                value={specialty}
                onChange={e => setSpecialty(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type="submit" variant="primary" className="form-button" onClick={handleClose}>
              {t('settingsPage.clinicPresentation.clinicDetailPopup.addClinic')}
            </Button>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('settingsPage.clinicPresentation.clinicDetailPopup.closeClinicPopup')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddClinicPopUp;
