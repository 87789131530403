import {
  ANAMNESIS_LIST_REQUEST,
  ANAMNESIS_LIST_SUCCESS,
  ANAMNESIS_LIST_FAIL,
  ANAMNESIS_CREATE_REQUEST,
  ANAMNESIS_CREATE_SUCCESS,
  ANAMNESIS_CREATE_FAIL,
  ANAMNESIS_CREATE_RESET,
  ANAMNESIS_UPDATE_REQUEST,
  ANAMNESIS_UPDATE_SUCCESS,
  ANAMNESIS_UPDATE_FAIL,
  ANAMNESIS_UPDATE_RESET,
  T_NOTES_LIST_REQUEST,
  T_NOTES_LIST_SUCCESS,
  T_NOTES_LIST_FAIL,
  T_NOTES_CREATE_REQUEST,
  T_NOTES_CREATE_SUCCESS,
  T_NOTES_CREATE_FAIL,
  T_NOTES_CREATE_RESET,
  T_NOTES_UPDATE_REQUEST,
  T_NOTES_UPDATE_SUCCESS,
  T_NOTES_UPDATE_FAIL,
  T_NOTES_UPDATE_RESET,
} from '../constants/notesConstants';

export const patientAnamnesisReducer = (state = {}, action) => {
  switch (action.type) {
    case ANAMNESIS_LIST_REQUEST:
      return { loading: true };

    case ANAMNESIS_LIST_SUCCESS:
      return { loading: false, anamnesis: action.payload };

    case ANAMNESIS_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const treatmentNotesReducer = (state = {}, action) => {
  switch (action.type) {
    case T_NOTES_LIST_REQUEST:
      return { loading: true };

    case T_NOTES_LIST_SUCCESS:
      return { loading: false, treatmentNotes: action.payload };

    case T_NOTES_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

// export const patientCreateReducer = (state = {} , action) => {
//     switch(action.type){
//         case PATIENT_CREATE_REQUEST:
//             return {loading: true }

//         case PATIENT_CREATE_SUCCESS:
//             return {loading: false, success: true, draftPatient: true, patient:action.payload }

//         case PATIENT_CREATE_FAIL:
//             return { loading: false, error: action.payload }

//         case PATIENT_CREATE_RESET:
//             return { draftPatient: false, draftId: action.payload  }

//         default:
//             return state
//     }
// }

// export const patientUpdateReducer = (state = {  }, action) => {
//     switch(action.type){
//         case PATIENT_UPDATE_REQUEST:
//             return { loading: true }

//         case PATIENT_UPDATE_SUCCESS:
//             return {loading: false, success: true }

//         case PATIENT_UPDATE_FAIL:
//             return { loading: false, error: action.payload }

//         case PATIENT_UPDATE_RESET:
//             return { }

//         default:
//             return state
//     }
// }

/*

export const clinicsProfileReducer = (state = { clinic: {} }, action) => {
    switch (action.type) {
        case CLINIC_PROFILE_REQUEST:
            return { loading: true, ...state}

        case CLINIC_PROFILE_SUCCESS:
            return { loading: false, clinic: action.payload, }

        case CLINIC_PROFILE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const clinicsUpdateReducer = (state = {  }, action) => {
    switch(action.type){
        case CLINIC_UPDATE_REQUEST:
            return { loading: true }

        case CLINIC_UPDATE_SUCCESS:
            return {loading: false, success: true }

        case CLINIC_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case CLINIC_UPDATE_RESET:
            return { }
        
        default:
            return state
    }
}



/*
export const dentistUpdateProfileReducer = (state = {  }, action) => {
    switch(action.type){
        case DENTIST_UPDATE_PROFILE_REQUEST:
            return { loading: true }

        case DENTIST_UPDATE_PROFILE_SUCCESS:
            return {loading: false, success: true }

        case DENTIST_UPDATE_PROFILE_FAIL:
            return { loading: false, error: action.payload }

        case DENTIST_UPDATE_PROFILE_RESET:
            return { }
        
        default:
            return state
    }
}

/*
export const dentistInvoicingReducer = (state = { invoicing: {} }, action) => {
    switch(action.type){
        case DENTIST_INVOICING_REQUEST:
            return { loading: true }

        case DENTIST_INVOICING_SUCCESS:
            return {loading: false, invoicing: action.payload}

        case DENTIST_INVOICING_FAIL:
            return { loading: false, error: action.payload }
        
        case DENTIST_INVOICING_RESET:
            return { invoicing: {} }
            
        default:
            return state
    }
}

export const dentistUpdateInvoicingReducer = (state = {  }, action) => {
    switch(action.type){
        case DENTIST_UPDATE_INVOICING_REQUEST:
            return { loading: true }

        case DENTIST_UPDATE_INVOICING_SUCCESS:
            return {loading: false, success: true }

        case DENTIST_UPDATE_INVOICING_FAIL:
            return { loading: false, error: action.payload }

        case DENTIST_UPDATE_INVOICING_RESET:
            return { }
        
        default:
            return state
    }
}

*/
