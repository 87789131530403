import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../../../helpers/axiosConfig';
import { useTranslation } from 'react-i18next';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { TextField } from '@mui/material';
import { ReactComponent as DocumentIcon } from '../../../../asset/icons/document.svg';
import RecordField from '../../../../components/ImageComponent/RecordField';

const PreviewPost = props => {
  const { t } = useTranslation('contentTrs');
  const { item, setBlogList } = props;
  const [show, setShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isApplyChanges, setIsApplyChanges] = useState(false);
  const [postTitle, setPostTitle] = useState(item.title || '');
  const [postContent, setPostContent] = useState(item.body || '');

  const [loadedFile, setLoadedFile] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(null);

  const handleClose = () => {
    setLoadedFile(null);
    setFileDataURL(null);
    setShow(false);
    setIsEditing(false);
  };
  const handleShow = () => setShow(true);
  const handleEdit = () => setIsEditing(true);

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;
  const userDetails = useSelector(state => state.userDetails);
  const { user } = userDetails;

  const handlePreviewNewPost = () => {
    setIsEditing(false);
    item.title = postTitle;
    item.body = postContent;
    item.image = fileDataURL;
    setIsApplyChanges(true);
  };

  const DataURIToBlob = dataURI => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  const addPost = async () => {
    try {
      let formData = new FormData();
      formData.append('title', postTitle);
      formData.append('body', postContent);
      formData.append('is_public', false);
      formData.append('blog_id', item.id);

      if (loadedFile?.editedBlob) {
        let file = DataURIToBlob(loadedFile.uri);
        formData.append('image', file, loadedFile.type);
      } else {
        formData.append('image', loadedFile);
      }

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      let spaceId = '%20';
      let apiUrl = `/api/lookup/blogs/make/${spaceId}`;

      const { data } = await axios.put(apiUrl, formData, config);
      setBlogList(data);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const loadPictureinBrowser = img_file => {
    setLoadedFile(img_file);
    let fileReader;
    fileReader = new FileReader();
    fileReader.onload = e => {
      const { result } = e.target;
      if (result) {
        setFileDataURL(result);
      }
    };
    fileReader.readAsDataURL(img_file);
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow} className="button-icon">
        <DocumentIcon />
      </Button>

      <Modal show={show} onHide={handleClose} className="modal-box" backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          {!isEditing && <Modal.Title>{item.title}</Modal.Title>}
          {isEditing && (
            <input
              type="text"
              id="title"
              className="title-post"
              value={postTitle}
              onChange={e => setPostTitle(e.target.value)}
            />
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="image-box">
            <RecordField
              image={{
                photo_url: item.image ? item.image : fileDataURL,
                minimized_photo_url: item.image ? item.image : fileDataURL, //record //dentistData.photo_url
              }}
              editImg={loadPictureinBrowser}
              readOnly={item.is_public || !isEditing}
              recordType={'blog'}
            />
          </div>
          {!isEditing && (
            <TextField
              defaultValue={item.body}
              multiline
              label="Blog"
              sx={{ m: 1, minWidth: '95%' }}
              InputProps={{
                readOnly: true,
              }}
            >
              {item.body}
            </TextField>
          )}
          {isEditing && (
            <TextField
              id="content"
              multiline
              defaultValue={item.body}
              sx={{ m: 1, minWidth: '95%' }}
              variant="outlined"
              label="Blog"
              onChange={e => setPostContent(e.target.value)}
            ></TextField>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!item.is_public && !isEditing && (
            <Button variant="primary" className="baseButton" onClick={handleEdit}>
              Edit Article
            </Button>
          )}
          {isEditing && (
            <Button variant="secondary" className="btn-dark" onClick={handlePreviewNewPost}>
              Preview Article
            </Button>
          )}
          {(isEditing || isApplyChanges) && (
            <Button variant="primary" className="baseButton" onClick={addPost}>
              Save Article
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default PreviewPost;
