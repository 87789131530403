import {
  PAY_SUBSCRIPTION_REQUEST,
  PAY_SUBSCRIPTION_SUCCESS,
  PAY_SUBSCRIPTION_FAIL,
} from '../../constants/subscriptionConstants';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import ApiService from './stripeApi';
import { paySubscription as paySubscriptionAction } from '../../actions/subscriptionActions';
import { BsCheckCircle, BsXCircle, BsFillCaretRightSquareFill } from 'react-icons/bs';
import CardForm from './CardForm';
import Message from '../Message';
import Loader from '../Loader';
import { makePrice } from '../../helpers';

import './style.css';

const CheckoutForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('contentTrs');

  const [error, setError] = useState(null);

  const email = 'abc@aaa.com';
  const stripe = useStripe();
  const elements = useElements();

  let environment_prod = true;

  let planAccess = ''; //'price_1O4TxPCwLmJe1ZA1EowEhpVw';
  if (environment_prod) {
    planAccess = 'price_1Q0jgrCwLmJe1ZA12fii46ym';
  } else {
    planAccess = 'price_1Q03bsCwLmJe1ZA1sfqwt4mr';
  }

  // Suscription
  const Plans = {
    access: {
      title: 'access',
      value: planAccess,
      cost: 20,
    },
  };

  const startPrice = 20;
  const starter_LimitPatients = 10;
  const starter_PriceforEachOtherPatient = 2;

  const standardPrice = 60;
  const standard_LimitPatients = 50;
  const standard_PriceforEachOtherPatient = 1.2;

  const proPrice = 250;
  const pro_LimitPatients = 400;
  const pro_PriceforEachOtherPatient = 0.65;

  const treatmentPrice = 20;
  const treatment_PriceforEachOtherPatient = 45;

  const [selectPlan, setSelectPlan] = useState(false);
  const [chosenPlan, setChosenPlan] = useState(Plans.access);
  const [accessPlan, setAccessPlan] = useState(Plans.access);
  const [chosenPlanCost, setChosenPlanCost] = useState(false);
  const [loadingPaymentMethod, setLoadingPaymentMethod] = useState('');
  const [card, setCard] = useState('');
  const [updatedCard, setUpdatedCard] = useState(false);
  const [newCard, setNewCard] = useState(false);

  const [priceYear, setPriceYear] = useState(0);
  const [totalPriceYear, setTotalPriceYear] = useState();
  const [currentSelectPlan, setCurrentSelectPlan] = useState({
    currentPrice: null,
    currentTotalP: null,
    currentPerPatientPrice: null,
  });
  // End Suscription

  const [quoteDraft, setQuoteDraft] = useState('');

  const [couponCode, setCouponCode] = useState('');
  const [couponAmount, setCouponAmount] = useState('');
  const [couponPercentage, setCouponPercentage] = useState('');
  const [couponDuration, setCouponDuration] = useState('');
  // const [cost, setCost] = useState(0)
  // const [discount, setDiscount] = useState(0)

  const paySubscription = useSelector(state => state.paySubscription);
  const {
    error: errorSubscription,
    loading: loadingSubmitSubscription,
    success: successSubscription,
  } = paySubscription;

  // check this link to style Stripe Element in React
  // will need to create a higher level component
  // to import the checkout form and pass the style to it
  // https://stripe.com/docs/payments/quickstart
  // const appearance = {theme: 'stripe', };

  // Page loads
  useEffect(() => {
    ApiService.RetrivePayentMethod()
      .then(response => {
        {
          response.data?.card ? setCard(response.data?.card) : setCard(false);
        }
      })
      .catch(error => {});
  }, []);

  useEffect(() => {
    ApiService.RetriveQuote()
      .then(response => {
        {
          response.data?.quote ? setQuoteDraft(response.data?.quote) : setQuoteDraft(false);
        }
      })
      .catch(error => {});
  }, []);

  useEffect(() => {
    if (successSubscription) {
      navigate(`/download`);
    }
  }, [successSubscription]);

  // Handle real-time validation errors from the CardElement.
  const handleChange = event => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  const handleSubmitCoupon = e => {
    e.preventDefault();
    // ApiService.makeQuote({
    //     email, coupon_code: couponCode})
    // .then(response => {

    //     setQuoteDraft(response.data?.quote)
    // }).catch(error => {

    // })
    ApiService.RetriveCoupon({
      email,
      coupon_code: couponCode,
    })
      .then(response => {
        if (response.data?.coupon) {
          setCouponAmount(response.data?.coupon?.amount_off);
          setCouponPercentage(response.data?.coupon?.percent_off);
          setCouponDuration(response.data?.coupon?.duration_in_months);
          console.log(response.data?.coupon);
        } else {
          console.log(response.data);
        }
      })
      .catch(error => {});
  };

  // Handle form submission.
  const handleSubmitCard = async event => {
    console.log('submit card');
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: card,
    });

    ApiService.saveStripeInfo({
      email,
      payment_method_id: paymentMethod.id,
    })
      .then(response => {
        setCard(paymentMethod.card);
        setNewCard(true);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleSubmitSubscription = async e => {
    let product = accessPlan.value;
    let pricingMethod = chosenPlan.value;
    e.preventDefault();
    // setLoadingSubmitSubscription(true)
    // ApiService.PaySubscription({})
    // .then(response => {
    //     if (response.data.quote.status === 'accepted'){
    //         navigate(`/`)
    //     }
    // }).catch(error => {

    // })
    // setLoadingSubmitSubscription(false)
    // dispatch(paySubscriptionAction({ product, pricingMethod, couponCode }));
    dispatch({
      type: PAY_SUBSCRIPTION_REQUEST,
    });
    try {
      let response = await ApiService.makeQuote({
        email,
        coupon_code: couponCode ? couponCode : undefined,
        price_id: product,
      });
      let responseQuote = await ApiService.RetriveQuote();
      responseQuote.data?.card ? setCard(responseQuote.data?.card) : setCard(false);
      if (card != false) {
        let responsePay = await ApiService.PaySubscription({ data: { product, pricingMethod, couponCode } });
        dispatch({
          type: PAY_SUBSCRIPTION_SUCCESS,
        });
      }
    } catch (error) {
      dispatch({
        type: PAY_SUBSCRIPTION_FAIL,
        payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }
  };

  const checkoutCost = () => {
    let total = null;
    if (accessPlan?.cost) {
      if (couponAmount) {
        total = accessPlan.cost - couponAmount / 100;
      } else if (couponPercentage) {
        total = accessPlan.cost * (1 - couponPercentage / 100);
      } else {
        total = accessPlan.cost;
      }
    }
    return total;
  };

  /**
     * 
    const handleTotalYearPrice = (event) => {
        setPriceYear(event.target.value);
        if(event.target.value<11){setTotalPriceYear((currentSelectPlan.currentPrice*12 + (event.target.value - event.target.value)))}
        else{setTotalPriceYear((currentSelectPlan.currentPrice + (event.target.value - currentSelectPlan.currentTotalP)*currentSelectPlan.currentPerPatientPrice)*12)}
        
        console.log(currentSelectPlan.currentTotalP)
    };*/

  const handleTotalYearPrice = event => {
    setPriceYear(event.target.value);
    if (event.target.value < 11) {
      setTotalPriceYear(startPrice * 12 + (event.target.value - event.target.value));
    } else {
      setTotalPriceYear(
        (startPrice + (event.target.value - starter_LimitPatients) * starter_PriceforEachOtherPatient) * 12
      );
    }
  };

  return (
    <div className="pb-3">
      <h2 className="tw-text-center mt-5">{t('settingsPage.suscription.title')}</h2>
      <h5 className="tw-text-center mt-2 tw-text-rose-400">{t('settingsPage.suscription.subTitle')}</h5>
      <div className="tw-flex justify-content-center tw-mt-10">
        <div className="tw-flex tw-flex-row sm:tw-w-10/12 md:tw-w-8/12 lg:tw-w-6/12 tw-space-x-5">
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full">
            <div className="tw-flex tw-flex-row tw-bg-slate-300 tw-rounded-full tw-h-12 tw-w-12 tw-justify-center tw-items-center">
              <span className="tw-text-2xl tw-block">1</span>
            </div>
          </div>
          <div className="justify-content-center tw-w-full">
            <Card sm={10} md={8} lg={6} className="mandatory-subscription-card p-2 rounded shadow" id="premium-plan">
              <Card.Body className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <Card.Title as="h5" className="mb-2">
                    <strong> Ortho Book Pro starter </strong>
                  </Card.Title>
                  <Card.Text className="my-2 tw-text-green-700" style={{ fontSize: '20px' }}>
                    €20/{t('settingsPage.suscription.month')}
                  </Card.Text>
                  <span>{t('settingsPage.suscription.otherwise')}</span>
                  <Card.Text className="my-2 tw-line-through tw-text-red" style={{ fontSize: '20px' }}>
                    €40/{t('settingsPage.suscription.month')}
                  </Card.Text>

                  <ul className="list-inline mb-2">
                    <li className="list-inline-item">🚀 {t('settingsPage.suscription.platformAccess')}</li>
                    <li className="list-inline-item">🧰 {t('settingsPage.suscription.firstVisit')}</li>
                    <li className="list-inline-item">🔄 {t('settingsPage.suscription.unlimitedPatients')}</li>
                  </ul>
                </div>
                {/* <Button className="mandatory-subscription-card-button" disabled>
                    {t('settingsPage.suscription.selectedButton')}
                  </Button> */}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <div className="tw-flex justify-content-center tw-mt-10">
        <div className="tw-flex tw-flex-row sm:tw-w-10/12 md:tw-w-8/12 lg:tw-w-6/12 tw-space-x-5">
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full">
            <div className="tw-flex tw-flex-row tw-bg-slate-300 tw-rounded-full tw-h-12 tw-w-12 tw-justify-center tw-items-center">
              <span className="tw-text-2xl tw-block">2</span>
            </div>
          </div>
          <div className="justify-content-center tw-w-full tw-shadow-lg rounded tw-border-slate-300 tw-border-opacity-50 tw-border-2 tw-p-5">
            <div className="tw-flex tw-justify-end">{card && <BsCheckCircle color={'green'} size={30} />}</div>
            {updatedCard && newCard && <Message variant="success">Card Updated</Message>}
            <CardForm
              card={card}
              setCard={setCard}
              updatedCard={updatedCard}
              setUpdatedCard={setUpdatedCard}
              handleSubmitCard={handleSubmitCard}
            />
          </div>
        </div>
      </div>

      <div className="tw-flex justify-content-center tw-mt-10">
        <div className="tw-flex tw-flex-row sm:tw-w-10/12 md:tw-w-8/12 lg:tw-w-6/12 tw-space-x-5">
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full">
            <div className="tw-flex tw-flex-row tw-bg-slate-300 tw-rounded-full tw-h-12 tw-w-12 tw-justify-center tw-items-center">
              <span className="tw-text-2xl tw-block">3</span>
            </div>
          </div>
          <div className="justify-content-center tw-w-full tw-shadow-lg rounded tw-border-slate-300 tw-border-opacity-50 tw-border-2 tw-p-5">
            <Form onSubmit={handleSubmitCoupon} className="stripe-form">
              <Form.Label>{t('settingsPage.suscription.applyPromo')}</Form.Label>
              <Row>
                <Col sm={8}>
                  <Form.Group controlId="couponCode">
                    <Form.Control
                      className="form-control-sm"
                      inline="true"
                      type="name"
                      placeholder={t('settingsPage.suscription.enterCouponCode')}
                      value={couponCode}
                      onChange={e => setCouponCode(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col sm={2}>
                  <Button type="submit" className="baseButton btn-sm" inline="true">
                    {' '}
                    {t('settingsPage.suscription.applyButton')}{' '}
                  </Button>
                </Col>
              </Row>
            </Form>

            <div className="my-3">
              <Row className="mb-1">
                <Col sm={8}> {t('settingsPage.suscription.cost')} </Col>
                {/* <Col sm={4}> € {quoteDraft?.amount_subtotal ? makePrice(quoteDraft.amount_subtotal) : makePrice(0)}</Col> */}
                <Col sm={4}> € {accessPlan?.cost ? makePrice(accessPlan.cost * 100) : makePrice(0)}</Col>
              </Row>
              <Row className="mb-1">
                <Col sm={8}> {t('settingsPage.suscription.discount')} </Col>
                {/* <Col sm={4}> € {quoteDraft?.total_details?.amount_discount ? makePrice(quoteDraft.total_details.amount_discount) : makePrice(0)}  ({quoteDraft?.total_details?.amount_discount && (quoteDraft.total_details.amount_discount/quoteDraft.amount_subtotal)*100}%)</Col> */}
                <Col sm={4}>
                  {' '}
                  {couponAmount && `€ ${makePrice(couponAmount)}`} {couponPercentage && `${couponPercentage}%`}
                </Col>
              </Row>
              {couponDuration && (
                <Row className="mb-1">
                  <Col sm={8}> </Col>
                  {/* <Col sm={4}> € {quoteDraft?.total_details?.amount_discount ? makePrice(quoteDraft.total_details.amount_discount) : makePrice(0)}  ({quoteDraft?.total_details?.amount_discount && (quoteDraft.total_details.amount_discount/quoteDraft.amount_subtotal)*100}%)</Col> */}
                  <Col sm={4}>
                    {' '}
                    for {couponDuration && (couponDuration === 1 ? `${couponDuration} mese` : `${couponDuration} mesi`)}
                  </Col>
                </Row>
              )}
              <Row className="mb-1">
                <Col sm={8}> {t('settingsPage.suscription.total')} </Col>
                <Col sm={4}> € {accessPlan?.cost ? makePrice(checkoutCost() * 100) : makePrice(0)}</Col>
              </Row>
            </div>

            <Row>
              <Col>
                <Button
                  className="mt-3 flex justify-content-between baseButton"
                  disabled={card?.last4 && chosenPlan?.title ? false : true}
                  onClick={handleSubmitSubscription}
                >
                  {t('settingsPage.suscription.payButton')}
                </Button>
              </Col>
              <Col className="text-start mx-1">{loadingSubmitSubscription && <Loader size="50px"></Loader>}</Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CheckoutForm;
