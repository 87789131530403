// hooks
import { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import Loader from '../../../components/Loader';
import { InviteInfo } from './InviteInfo';
import { FormInput } from '../../../components/FormInput';

const LoginAgain = ({ verifiedState, onNext: _onSubmit, isLoading: isSubmitInProgress, error: submitError }) => {
  const { t } = useTranslation('patientSignup');
  const { t: tp } = useTranslation('contentTrs');
  const [{ uidb64, token, email, password, setSignupStep, data }, setState] = useState(verifiedState);

  let isPending = false;
  let isSuccess = data?.valid_invitation;
  let error = false;

  const onFieldChange = useCallback((value, fieldId) => {
    setState(prevState => ({ ...prevState, [fieldId]: value }));
  }, []);

  const onSubmit = () => {
    _onSubmit({ email, password });
  };

  let headerEl;
  if (error) {
    headerEl = <InviteInfo clinicName="Orthobook" />;
  } else if (isPending) {
    headerEl = <Loader />;
  } else {
    headerEl = isSuccess && (
      <InviteInfo
        isInvitatioValid={data?.valid_invitation}
        clinicName={data['inviter'].first_name + ' ' + data['inviter'].last_name}
      />
    );
  }

  return (
    <div
      className="tw-flex tw-flex-col tw-w-full tw-h-full tw-items-center tw-justify-between tw-py-12 md:tw-pb-36 tw-mx-12"
      style={{ maxWidth: '440px' }}
    >
      <div>{headerEl}</div>
      <div className="tw-w-full">
        <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full">
          <FormInput
            id="email"
            type="text"
            value={email}
            label={t('email')}
            error={submitError.uidb64}
            onChange={onFieldChange}
          />
          <FormInput
            id="password"
            type="password"
            value={password}
            label={t('password')}
            error={submitError.wrongCredentials}
            onChange={onFieldChange}
          />
          <button
            className="tw-w-full tw-bg-lightBlue hover:tw-bg-darkBlue tw-py-6 tw-rounded-20 tw-text-white tw-transition-colors"
            onClick={onSubmit}
            disabled={isSubmitInProgress}
          >
            {isSubmitInProgress ? <Loader /> : t('Continue')}
          </button>
        </div>
        <div className="tw-py-3 tw-mt-1">
          {tp('authPages.login.forgorPass')} <Link to={'/forgot_password'}> {tp('authPages.login.resetPass')}</Link>
        </div>
      </div>
    </div>
  );
};

export { LoginAgain };
