import {
  REMOTE_VISITS_REQUEST,
  REMOTE_VISITS_SUCCESS,
  REMOTE_VISITS_FAIL,
  PHOTOS_VISITS_REQUEST,
  PHOTOS_VISITS_SUCCESS,
  PHOTOS_VISITS_FAIL,
  RESPOND_PHOTOS_SUCCESS,
} from '../constants/remoteVisitsConstants';

const INITIAL_STATE = {
  remoteVisits: [],
  photosVisits: {},
  loading: false,
};

export const remoteVisitsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REMOTE_VISITS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REMOTE_VISITS_SUCCESS:
      return {
        ...state,
        loading: false,
        remoteVisits: action.payload,
      };

    case REMOTE_VISITS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PHOTOS_VISITS_REQUEST:
      return {
        ...state,
        loading: true,
        photosVisits: {},
      };
    case PHOTOS_VISITS_SUCCESS:
      return {
        ...state,
        loading: false,
        photosVisits: action.payload,
      };

    case PHOTOS_VISITS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESPOND_PHOTOS_SUCCESS:
      return {
        ...state,
        loading: false,
        photosVisits: {
          ...action.payload,
          minimized_records: state.photosVisits.minimized_records,
        },
      };

    default:
      return state;
  }
};
