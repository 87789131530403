// import axios from "axios";
import axios from '../helpers/axiosConfig';
import {
  PATIENTS_LIST_REQUEST,
  PATIENTS_LIST_SUCCESS,
  PATIENTS_LIST_FAIL,
  PATIENT_PROFILE_REQUEST,
  PATIENT_PROFILE_SUCCESS,
  PATIENT_PROFILE_FAIL,
  PATIENT_CREATE_REQUEST,
  PATIENT_CREATE_SUCCESS,
  PATIENT_CREATE_FAIL,
  // PATIENT_CREATE_RESET,
  PATIENT_UPDATE_REQUEST,
  PATIENT_UPDATE_SUCCESS,
  PATIENT_UPDATE_FAIL,
  PATIENT_DELETE_SUCCESS,
  PATIENT_DELETE_FAIL,
  PATIENT_DELETE_REQUEST,
  // PATIENT_UPDATE_RESET,
} from '../constants/patientsConstants';

// These is a collection of Redux-free actions

export const listPatients =
  (keyword = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: PATIENTS_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      console.log(keyword, 'action patient');

      const { data } = await axios.get(`/api/patients/${keyword}`, config);
      //console.log(data)

      dispatch({
        type: PATIENTS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PATIENTS_LIST_FAIL,
        payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }
  };

export const getPatientProfile = id => async (dispatch, getState) => {
  try {
    dispatch({ type: PATIENT_PROFILE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`/api/patients/profile/${id}`, config);
    //console.log(data)

    dispatch({
      type: PATIENT_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENT_PROFILE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const createPatient = newPatient => async (dispatch, getState) => {
  try {
    dispatch({
      type: PATIENT_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(`/api/patients/add/`, newPatient, config);

    dispatch({
      type: PATIENT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENT_CREATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const updatePatient = (patientId, patient) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PATIENT_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(`/api/patients/profile/update/${patientId}`, patient, config);

    dispatch({
      type: PATIENT_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENT_UPDATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const deletePatient = (patientId, deletePatientPhrase) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PATIENT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.delete(`/api/patients/profile/update/${patientId}`, {
      data: deletePatientPhrase,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    });

    dispatch({
      type: PATIENT_DELETE_SUCCESS,
      payload: patientId,
    });
  } catch (error) {
    dispatch({
      type: PATIENT_DELETE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
    throw error;
  }
};

/*
export const clinicsDetails = (id) => async (dispatch, getState) => {
    try{
        
        dispatch({type: CLINIC_PROFILE_REQUEST})

        const {
            userLogin: {userInfo},
        } =getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(
            //`/api/users/profile/clinics/${id}`,
            `/api/users/profile/clinics/${id}`,
            config
        )

        dispatch({
            type: CLINIC_PROFILE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CLINIC_PROFILE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateClinics = (clinic) => async (dispatch, getState) => {
    try{
        dispatch({
            type: CLINIC_UPDATE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } =getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.put(
            `/api/users/profile/clinics/update/${clinic._id}`,
            clinic,
            config
        )

        dispatch({
            type: CLINIC_UPDATE_SUCCESS,
            payload: data
        })
       
    } catch (error) {
        dispatch({
            type: CLINIC_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createClinics = (newClinic) => async (dispatch, getState) => {
    try{
        dispatch({
            type: CLINIC_CREATE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } =getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.post(
            `/api/users/profile/clinics/addnew/`,
            newClinic,
            config
        )

        dispatch({
            type: CLINIC_CREATE_SUCCESS,
            payload:data,
        })

    } catch (error) {
        dispatch({
            type: CLINIC_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

*/
