import { useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { SIGNUP_STEPS } from '../constants/signupSteps';

// hooks
import { usePatientAuthMutation } from './usePatientAuthMutation';
import { useTranslation } from 'react-i18next';

// utils
import { passwordStrengthValidation } from '../../../utilities/passwordStrengthValidation';

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('-');
}

const usePatientSignup = () => {
  const { uuId, clinicId } = useParams();
  const [signupStep, setSignupStep] = useState(SIGNUP_STEPS.CLINIC_PATIENT_VERIFICATION);
  const [errorState, setErrorState] = useState({});
  const { mutate, isLoading } = usePatientAuthMutation();
  const { t } = useTranslation(['patientSignup', 'passwordValidation']);

  const signupStepRef = useRef(signupStep);
  signupStepRef.current = signupStep;
  const verifiedState = useRef({ clinicId, patientId: uuId });

  const handleClinicPatientValidation = useCallback(
    ({ clinicId, patientId }) => {
      // remove error state
      setErrorState(prev => ({ ...prev, clinicId: undefined, patientId: undefined }));

      if (!clinicId || !patientId) {
        setErrorState({
          clinicId: clinicId ? undefined : t('Clinic id is requierd!'),
          patientId: patientId ? undefined : t('Patient id is required!'),
        });
        return;
      }

      // hit the mutation
      mutate(
        { clinicId, patientId },
        {
          onSuccess: () => {
            verifiedState.current = { clinicId, patientId };
            setSignupStep(SIGNUP_STEPS.DATE_OF_BIRTH_VERIFICATION);
          },
          onError: () => {
            setErrorState({
              clinicId: t('Incorrect clinic id!'),
              patientId: t('Incorrect patient id!'),
            });
          },
        }
      );
    },
    [mutate, t]
  );

  const handleBirthdayVerification = useCallback(
    ({ clinicId, patientId, birthDate }) => {
      // remove error state
      setErrorState(prev => ({ ...prev, birthDate: undefined }));

      if (!birthDate) {
        setErrorState(prev => ({
          ...prev,
          birthDate: t('Date of birth is required!'),
        }));
        return;
      }

      // hit the mutation
      mutate(
        { clinicId, patientId, birthDate: formatDate(birthDate) },
        {
          onSuccess: response => {
            verifiedState.current = { clinicId, patientId, birthDate, emailId: response.data?.email };
            setSignupStep(SIGNUP_STEPS.TERMS_AND_CONDITION_VERIFICATION);
          },
          onError: () => {
            setErrorState(prev => ({ ...prev, birthDate: t('Incorrect date of birth!') }));
          },
        }
      );
    },
    [mutate, t]
  );

  const handlePasswordVerification = useCallback(
    ({ clinicId, patientId, birthDate, emailId, password, confirmPassword }) => {
      // remove error state
      setErrorState(prev => ({ ...prev, emailId: undefined, password: undefined, confirmPassword: undefined }));

      if (!emailId || !password || !confirmPassword) {
        setErrorState(prev => ({
          ...prev,
          password: password ? undefined : t('Password is requierd!'),
          confirmPassword: confirmPassword ? undefined : t('This field is required!'),
          emailId: emailId ? undefined : t('Email is required!'),
        }));
        return;
      }

      // validate password
      if (password !== confirmPassword) {
        setErrorState(prev => ({ ...prev, confirmPassword: t('Passwords do not match!') }));
        return;
      }

      const passwordStrengthResult = passwordStrengthValidation(password, t);
      if (!passwordStrengthResult.isValid) {
        setErrorState(prev => ({ ...prev, password: passwordStrengthResult.errorMessage }));
        return;
      }

      // If password validated, validate emailId and password
      mutate(
        { clinicId, patientId, birthDate: formatDate(birthDate), emailId, password },
        {
          onSuccess: response => {
            verifiedState.current = { clinicId, patientId, birthDate, emailId, password };
            localStorage.setItem('userInfo', JSON.stringify(response.data?.token ?? {}));
            setSignupStep(SIGNUP_STEPS.EMAIL_VERIFICATION);
          },
          onError: () => {
            setErrorState(prev => ({ ...prev, emailId: t('Incorrect email!') }));
          },
        }
      );
    },
    [mutate, t]
  );

  const onNext = useCallback(
    ({ clinicId, patientId, password, confirmPassword, emailId, birthDate }) => {
      switch (signupStepRef.current) {
        case SIGNUP_STEPS.CLINIC_PATIENT_VERIFICATION: {
          handleClinicPatientValidation({ clinicId, patientId });
          break;
        }
        case SIGNUP_STEPS.DATE_OF_BIRTH_VERIFICATION: {
          handleBirthdayVerification({ clinicId, patientId, birthDate });
          break;
        }
        case SIGNUP_STEPS.TERMS_AND_CONDITION_VERIFICATION: {
          setSignupStep(SIGNUP_STEPS.PASSWORD_VERIFICATION);
          break;
        }
        case SIGNUP_STEPS.PASSWORD_VERIFICATION: {
          handlePasswordVerification({ clinicId, patientId, password, confirmPassword, emailId, birthDate });
          break;
        }
        default:
          break;
      }
    },
    [handleClinicPatientValidation, handleBirthdayVerification, handlePasswordVerification]
  );

  return {
    signupStep,
    verifiedState: verifiedState.current,
    onNext,
    isLoading,
    error: errorState,
  };
};

export { usePatientSignup };
