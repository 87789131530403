//style imports
import './scss/style.scss';

//functional imports
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//utilities imports
import PrivateRoute from './utilities/PrivateRoute';
import PrivateRouteSubscribed from './utilities/PrivateRouteSubscribed';

// layout
import { AppLayout } from './components/layout/AppLayout';

//screens imports
import HomeScreen2 from './screens/HomeScene/HomeScreen2';
import StripeScreen from './screens/StripeScreen';
import SettingsScreen from './screens/SettingsScene/SettingsScreen';
import { ChatScreen } from './screens/Chat/ChatScreen';
import HomeScreen from './screens/HomeScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import { PatientSignup } from './screens/PatientSignupDoctorInvitation';
import { PatientSignupFamilyInvitation } from './screens/PatientSignupFamilyInvitation';
import { ParentSignupFamilyInvitation } from './screens/ParentSignupFamilyInvitation';
import { AuthScreen } from './screens/AuthScreen';
import { OralCheckDownloadScreen } from './screens/OralCheckDownloadScreen';
import PatientsScreen from './screens/PatientsScreen/PatientsScreen';
import PatientScreen from './screens/PatientScreen/PatientScreen';
import NewPatientScreen from './screens/NewPatient/NewPatientScreen';
import NewPatientScreen2 from './screens/NewPatient/NewPatientScreen2';
import DownloadScreen from './screens/DownloadScreen';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/patient/signup/:uuId/:clinicId" element={<PatientSignup />} /> // this is the route for the //
        patient signup via doctor inviation using activation codes
        <Route path="/verify-email/:uuId/:token" element={<AuthScreen />} />
        <Route
          path="/oralcheck/patients/family-invitation/:uidb64/:token"
          element={<PatientSignupFamilyInvitation />}
        />
        // this is the route for the patient signup via invitation from a parent
        <Route path="/oralcheck/parents/family-invitation/:uidb64/:token" element={<ParentSignupFamilyInvitation />} />
        // this is the route for the parent signup via invitation from a patient
        <Route path="/oralcheck/signup/download/" element={<OralCheckDownloadScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/register" element={<RegisterScreen />} />
        <Route path="/forgot_password" element={<ForgotPasswordScreen />} />
        <Route path="/password-reset/:token1/:token2" element={<ResetPasswordScreen />} />
        <Route
          path="/"
          element={
            <AppLayout>
              <PrivateRouteSubscribed>
                {' '}
                <HomeScreen2 />{' '}
              </PrivateRouteSubscribed>
            </AppLayout>
          }
        />
        <Route
          path="/payment"
          element={
            <AppLayout>
              <PrivateRoute>
                {' '}
                <StripeScreen />{' '}
              </PrivateRoute>
            </AppLayout>
          }
        />
        <Route
          path="/2"
          element={
            <AppLayout>
              <PrivateRouteSubscribed>
                {' '}
                <HomeScreen />{' '}
              </PrivateRouteSubscribed>
            </AppLayout>
          }
        />
        <Route
          path="/patients"
          element={
            <AppLayout>
              <PrivateRouteSubscribed>
                {' '}
                <PatientsScreen />{' '}
              </PrivateRouteSubscribed>
            </AppLayout>
          }
        />
        <Route
          path="/patients/:id"
          element={
            <AppLayout>
              <PrivateRouteSubscribed>
                {' '}
                <PatientScreen />{' '}
              </PrivateRouteSubscribed>
            </AppLayout>
          }
        />
        <Route
          path="/patients/register"
          element={
            <AppLayout>
              <PrivateRouteSubscribed>
                {' '}
                <NewPatientScreen />{' '}
              </PrivateRouteSubscribed>
            </AppLayout>
          }
        >
          {' '}
        </Route>
        <Route
          path="/patients/register/:id"
          element={
            <AppLayout>
              <PrivateRouteSubscribed>
                {' '}
                <NewPatientScreen2 />{' '}
              </PrivateRouteSubscribed>
            </AppLayout>
          }
        />
        <Route
          path="/settings"
          element={
            <AppLayout>
              <PrivateRoute>
                {' '}
                <SettingsScreen />{' '}
              </PrivateRoute>
            </AppLayout>
          }
        />
        <Route
          path="conversations/"
          element={
            <AppLayout>
              <PrivateRoute>
                {' '}
                <ChatScreen />{' '}
              </PrivateRoute>
            </AppLayout>
          }
        />
        <Route
          path="conversations/:conversationName"
          element={
            <AppLayout>
              <PrivateRoute>
                {' '}
                <ChatScreen />{' '}
              </PrivateRoute>
            </AppLayout>
          }
        />
        <Route
          path="/download"
          element={
            <AppLayout>
              <PrivateRouteSubscribed>
                {' '}
                <DownloadScreen />{' '}
              </PrivateRouteSubscribed>
            </AppLayout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
