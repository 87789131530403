import React from 'react';
import Avatar from 'react-avatar';
import instance from '../helpers/axiosConfig';
import { base_URL_media } from '../helpers/axiosConfig';
import { Image } from 'react-bootstrap';

const DisplayAvatar = props => {
  const { user, style } = props;
  return (
    <div className="logoImgContainer" style={style}>
      {user.minimized_photo_url ? (
        <Image src={`${base_URL_media}${user.minimized_photo_url}`} className="c-avatar-img" alt="" />
      ) : (
        <Avatar name={`${user.first_name} ${user.last_name}`} round={true} size="35" color="#4BC1CB" />
      )}
    </div>
  );
};

export default DisplayAvatar;
