import React, { useState, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { IconButton, Typography, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { deleteVisit } from '../../../actions/visitsActions';

function DeleteVistModal(props) {
  const { patientId, visitId, showVisitDeleteConfirmationModal, setShowVisitDeleteConfirmationModal } = props;

  const [password, setPassword] = useState('');
  const dispatch = useDispatch();

  const { t } = useTranslation('contentTrs');

  const confirmDeleteVisit = async () => {
    try {
      await dispatch(deleteVisit(patientId, visitId, password));
      setShowVisitDeleteConfirmationModal(false);
    } catch (err) {
      console.log('Incorrect Password', err);
      alert('Incorrect password');
    }
  };

  return (
    showVisitDeleteConfirmationModal && (
      <div className="tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-gray-900 tw-bg-opacity-50 tw-z-50">
        <div className="tw-bg-white tw-rounded-lg tw-p-6 tw-w-128">
          <IconButton onClick={() => setShowVisitDeleteConfirmationModal(false)} className="tw-float-right">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" className="tw-mb-4">
            {t('single_patienScreens.visit.delete.deleteVisitConfirmationMsg')}
          </Typography>
          <p>
            {t('single_patienScreens.visit.delete.confirmPassword')}
            <br />
          </p>
          <TextField
            fullWidth
            label={t('single_patienScreens.visit.delete.confirmPassLabel')}
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            autoFocus={true} // Add the autofocus attribute here
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
            }}
          />
          <DialogActions className="tw-mt-2">
            <Button
              variant="contained"
              color="error"
              onClick={confirmDeleteVisit}
              className="tw-mr-200"
              disabled={password === ''}
            >
              {t('single_patienScreens.visit.delete.deleteVisitConfirmation')}
            </Button>
            <Button variant="contained" color="primary" onClick={() => setShowVisitDeleteConfirmationModal(false)}>
              {t('single_patienScreens.visit.delete.cancel')}
            </Button>
          </DialogActions>
        </div>
      </div>
    )
  );
}

export default DeleteVistModal;
