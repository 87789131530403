export const DENTIST_CLINICS_REQUEST = 'DENTIST_CLINICS_REQUEST';
export const DENTIST_CLINICS_SUCCESS = 'DENTIST_CLINICS_SUCCESS';
export const DENTIST_CLINICS_FAIL = 'DENTIST_CLINICS_FAIL';

export const CLINIC_PROFILE_REQUEST = 'CLINIC_PROFILE_REQUEST';
export const CLINIC_PROFILE_SUCCESS = 'CLINIC_PROFILE_SUCCESS';
export const CLINIC_PROFILE_FAIL = 'CLINIC_PROFILE_FAIL';

export const CLINIC_CREATE_REQUEST = 'CLINIC_CREATE_REQUEST';
export const CLINIC_CREATE_SUCCESS = 'CLINIC_CREATE_SUCCESS';
export const CLINIC_CREATE_FAIL = 'CLINIC_CREATE_FAIL';
export const CLINIC_CREATE_RESET = 'CLINIC_CREATE_FAIL';

export const CLINIC_UPDATE_REQUEST = 'CLINIC_UPDATE_REQUEST';
export const CLINIC_UPDATE_SUCCESS = 'CLINIC_UPDATE_SUCCESS';
export const CLINIC_UPDATE_FAIL = 'CLINIC_UPDATE_FAIL';
export const CLINIC_UPDATE_RESET = 'CLINIC_UPDATE_FAIL';
