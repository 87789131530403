import { useState, useRef, useCallback, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import { SIGNUP_STEPS } from '../constants/signupSteps';

// hooks
import { usePatientAuthQuery } from './usePatientAuthQuery';
import { useTranslation } from 'react-i18next';

// utils
import { passwordStrengthValidation } from '../../../utilities/passwordStrengthValidation';

// function formatDate(date) {
//   var d = new Date(date),
//     month = '' + (d.getMonth() + 1),
//     day = '' + d.getDate(),
//     year = d.getFullYear();

//   if (month.length < 2) month = '0' + month;
//   if (day.length < 2) day = '0' + day;

//   return [day, month, year].join('-');
// }

const usePatientSignup = () => {
  const navigate = useNavigate();
  const { clinicId } = useParams();
  const { uidb64, token } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  let patient_code = searchParams.get('patient_code').replace(/\//g, '');

  const [signupStep, setSignupStep] = useState(SIGNUP_STEPS.CLINIC_PATIENT_VERIFICATION);
  const [errorState, setErrorState] = useState({});
  const { mutate, isPending: isLoading } = usePatientAuthQuery();

  const { t } = useTranslation(['patientSignup', 'passwordValidation']);

  const signupStepRef = useRef(signupStep);
  signupStepRef.current = signupStep;
  const verifiedState = useRef({ uidb64, token, patient_code, setSignupStep });

  const handleInvitationValidation = useCallback(
    ({ uidb64, token, patient_code }) => {
      setErrorState(prev => ({ ...prev, uidb64: undefined, token: undefined, patient_code: undefined }));

      if (!uidb64 || !token || !patient_code) {
        setErrorState({
          error: true,
          valid_invitation: false,
        });
        return;
      }

      // hit the mutation
      mutate(
        { uidb64, token, patient_code },
        {
          onSuccess: response => {
            verifiedState.current = { uidb64, token, patient_code, data: response, email: response?.email };
            // if (response.account_exists) {
            //   setSignupStep(SIGNUP_STEPS.LOGIN);
            // } else if (response?.account_exists === false) {
            //   setSignupStep(SIGNUP_STEPS.TERMS_AND_CONDITION_VERIFICATION);
            // }
            if (response?.account_exists === false) {
              setSignupStep(SIGNUP_STEPS.CLINIC_PATIENT_VERIFICATION);
            }
          },
          onError: error => {
            if (error.response?.status === 400) {
              verifiedState.current = { data: error.response };
              setSignupStep(SIGNUP_STEPS.CLINIC_PATIENT_VERIFICATION);
            }
          },
        }
      );
    },
    [mutate, t]
  );

  const verifyEmailandAccountAvailability = useCallback(
    ({ uidb64, token, patient_code, email }) => {
      setErrorState(prev => ({ ...prev, uidb64: undefined, token: undefined, patient_code: undefined }));

      if (!uidb64 || !token || !patient_code || !email) {
        setErrorState({
          error: true,
          valid_invitation: false,
        });
        return;
      }

      // hit the mutation
      mutate(
        { uidb64, token, patient_code, email },
        {
          onSuccess: response => {
            verifiedState.current = { uidb64, token, patient_code, data: response, email: email };

            if (response?.account_exists === false) {
              setSignupStep(SIGNUP_STEPS.TERMS_AND_CONDITION_VERIFICATION);
            }
          },
          onError: error => {
            if (error.response?.status === 400) {
              verifiedState.current = { data: error.response };
              setSignupStep(SIGNUP_STEPS.CLINIC_PATIENT_VERIFICATION);
            }
            if (error.response?.status === 409) {
              if (error.response?.data?.account_exists === true) {
                verifiedState.current = { uidb64, token, patient_code, data: error.response?.data, email: email };
                setSignupStep(SIGNUP_STEPS.LOGIN);
              }
            }
          },
        }
      );
    },
    [mutate, t]
  );

  const validateCredentialsExistingUser = useCallback(
    ({ uidb64, token, patient_code, email, password }) => {
      setErrorState(prev => ({
        ...prev,
        uidb64: undefined,
        token: undefined,
        patient_code: undefined,
        email: undefined,
        password: undefined,
        wrongCredentials: undefined,
      }));

      if (!uidb64 || !token || !patient_code || !email || !password) {
        setErrorState({
          error: true,
          valid_invitation: false,
          password: password ? undefined : t('Password is requierd!'),
          email: email ? undefined : t('Email is required!'),
        });
        return;
      }

      // hit the mutation
      mutate(
        { uidb64, token, patient_code, email, password },
        {
          onSuccess: response => {
            // verifiedState.current = { uidb64, token, patient_code, data: response, email: email };
            navigate('/oralcheck/signup/download/');
          },
          onError: error => {
            if (error.response?.status === 400) {
              setErrorState(prev => ({
                ...prev,
                wrongCredentials: t('something went wrong, check your credentials.'),
              }));
            }
            if (error.response?.status === 401) {
              setErrorState(prev => ({
                ...prev,
                wrongCredentials: t('we could not find an account with this credentials!'),
              }));
            }
          },
        }
      );
    },
    [mutate, t]
  );

  const registerNewParent = useCallback(
    ({ uidb64, token, patient_code, email, password, confirmPassword, first_name, last_name, birth_date }) => {
      // remove error state
      setErrorState(prev => ({
        ...prev,
        email: undefined,
        password: undefined,
        confirmPassword: undefined,
        first_name: undefined,
        last_name: undefined,
        birth_date: undefined,
        querryError: undefined,
      }));

      if (!email || !password || !confirmPassword || !first_name || !last_name || !birth_date) {
        setErrorState(prev => ({
          ...prev,
          password: password ? undefined : t('Password is requierd!'),
          confirmPassword: confirmPassword ? undefined : t('This field is required!'),
          email: email ? undefined : t('Email is required!'),
          first_name: first_name ? undefined : t('First name is required!'),
          last_name: last_name ? undefined : t('Last name is required!'),
          birth_date: birth_date ? undefined : t('Date of birth is required!'),
        }));
        return;
      }

      // Calculate age
      const calculateAge = birthDate => {
        const dob = new Date(birthDate);
        const diff = Date.now() - dob.getTime();
        const ageDate = new Date(diff);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
      };

      // Check if under 18
      if (calculateAge(birth_date) < 18) {
        setErrorState(prev => ({ ...prev, birth_date: t('You must be at least 18 years old.') }));
        return;
      }
      if (calculateAge(birth_date) > 100) {
        setErrorState(prev => ({ ...prev, birth_date: t('Plese check again your date of birth! you are over 100!') }));
        return;
      }

      // validate password
      if (password !== confirmPassword) {
        setErrorState(prev => ({ ...prev, confirmPassword: t('Passwords do not match!') }));
        return;
      }

      const passwordStrengthResult = passwordStrengthValidation(password, t);
      if (!passwordStrengthResult.isValid) {
        setErrorState(prev => ({ ...prev, password: passwordStrengthResult.errorMessage }));
        return;
      }

      // If password validated, validate email and password
      mutate(
        { uidb64, token, patient_code, email, password, compliance_data: true, first_name, last_name, birth_date },
        {
          onSuccess: response => {
            // verifiedState.current = { uidb64, token, patient_code, data: response, email: email };
            setSignupStep(SIGNUP_STEPS.EMAIL_VERIFICATION);
          },
          onError: error => {
            setErrorState(prev => ({ ...prev, querryError: 'please try again, something went wrong!' }));
          },
        }
      );
    },
    [mutate, t]
  );

  const onNext = useCallback(
    ({ uidb64, token, patient_code, password, confirmPassword, email, emailId, first_name, last_name, birth_date }) => {
      switch (signupStepRef.current) {
        case SIGNUP_STEPS.CLINIC_PATIENT_VERIFICATION: {
          verifyEmailandAccountAvailability({ uidb64, token, patient_code, email });
          break;
        }
        case SIGNUP_STEPS.LOGIN: {
          validateCredentialsExistingUser({ uidb64, token, patient_code, email, password });
          break;
        }
        case SIGNUP_STEPS.TERMS_AND_CONDITION_VERIFICATION: {
          setSignupStep(SIGNUP_STEPS.PARENT_REGISTER_FORM);
          break;
        }
        case SIGNUP_STEPS.PARENT_REGISTER_FORM: {
          registerNewParent({
            uidb64,
            token,
            patient_code,
            email,
            password,
            confirmPassword,
            first_name,
            last_name,
            birth_date,
          });
          break;
        }
        default:
          break;
      }
    },
    [registerNewParent]
  );

  useEffect(() => {
    // Automatically trigger handleInvitationValidation if the current step matches
    // and possibly other initial parameters are correctly set
    if (signupStepRef.current === SIGNUP_STEPS.CLINIC_PATIENT_VERIFICATION) {
      handleInvitationValidation({ uidb64, token, patient_code });
    }
  }, [handleInvitationValidation, uidb64, token, patient_code]);

  return {
    signupStep,
    verifiedState: verifiedState.current,
    onNext,
    isLoading,
    error: errorState,
  };
};

export { usePatientSignup };
