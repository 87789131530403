import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Button, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import { InputLabel, InputAdornment, Input, FormControl as UIForm, MenuItem, Select } from '@mui/material';
import { makeFirstLatterUp } from '../../../helpers';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
// import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';

import { listClinics } from '../../../actions/clinicsActions';

import { BsSearch } from 'react-icons/bs';

const FiltersBlock = props => {
  const { intl, filters, setFilters, searchByParameter } = props;

  let navigate = useLocation();
  const dispatch = useDispatch();

  const clinicsList = useSelector(state => state.clinicsList);
  const { clinics } = clinicsList;
  const { t } = useTranslation('contentTrs');
  // const patientCreate = useSelector(state => state.patientCreate)
  // const { error, loading, success:successPatient, patient, draftPatient } = patientCreate

  useEffect(() => {
    dispatch(listClinics());
  }, [dispatch]);

  // const { malocclusionClasses, statuses, treatmentOptions } = useSelector(state => state.options)

  const malocclusionClasses = [
    { id: 0, name: 'Class I' },
    { id: 1, name: 'Class II, div 1' },
    { id: 2, name: 'Class II, div 2' },
    { id: 3, name: 'Class III' },
  ];

  return (
    <React.Fragment>
      {/* <Col sm={12} lg={6} xl={4}> */}
      <Row>
        <Col className="" sm={12} lg={6} xl={4}>
          <UIForm
            variant="standard"
            size="normal"
            sx={{ m: 1, minWidth: '80%' }}
            onChange={e => {
              setFilters({ ...filters, search_term: e.target.value });
            }}
          >
            <InputLabel htmlFor="input-with-icon-adornment">{t('allPatientScreen.searchBar.title_name')}</InputLabel>
            <Input
              id="input-with-icon-adornment"
              startAdornment={
                <InputAdornment position="start">
                  <BsSearch />
                </InputAdornment>
              }
            />
          </UIForm>
          {/* <div>
            {t('allPatientScreen.searchBar.title_name')}
            </div>
            <InputGroup className="mb-3">
              <InputGroup.Text id="search-field"><BsSearch/></InputGroup.Text>
              <FormControl
                placeholder={t('allPatientScreen.searchBar.name')}
                aria-label={t('allPatientScreen.searchBar.name')}
                aria-describedby="search-field"
                value={filters.search_term}
                onChange={(e) => {setFilters({...filters, search_term: e.target.value})} }
              />
          </InputGroup> */}
        </Col>
        {/* <Col className='' md={12} >
              <div>
                Diagnosis {/* {intl.formatMessage({id: "patientsScene.filtersBlock.diagnosis"})} 
              </div>
              <Select
                name="form-field-diagnoses"
                placeholder={'search by malocclusione'}
                value={malocclusionClasses.map(item => {
                  if (filters.diagnoses.includes(item.id)) {
                    return {
                      value: item.id,
                      label: item.name
                    }
                  }
                })}
                options={malocclusionClasses.map(item => {
                  return {
                    value: item.id,
                    label: item.name
                  }
                })}
                onChange={(item) => {
                  if (item.length > 0) {
                    setFilters({ ...filters, diagnoses: [item[item.length - 1].value] });
                  } else {
                    setFilters({ ...filters, diagnoses: [] });
                  }
                  searchByParameter('diagnosis',item[item.length - 1].value)
                }}
                isMulti
              />
            </Col> */}
        {/* </Row>
      </Col> */}

        {/* <Col sm={12} lg={6} xl={4}>
        <Row> */}
        <Col className="mb-3" sm={12} lg={6} xl={4}>
          <UIForm variant="standard" sx={{ m: 1, minWidth: '80%' }}>
            <InputLabel id="demo-simple-select-standard-label">{t('allPatientScreen.searchBar.pratice')}</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={filters.clinics[0]}
              onChange={item => {
                console.log('selection', typeof item.target.value);
                setFilters({ ...filters, clinics: [item.target.value] });
                searchByParameter('clinic', item.target.value);
              }}
              label="Age"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {clinics.map(item => {
                return <MenuItem value={item.id}>{item.clinic_name}</MenuItem>;
              })}
            </Select>
          </UIForm>
        </Col>
        {/*  <Col className='' md={12} >
            <div>
              {intl.formatMessage({id: "patientsScene.filtersBlock.treatment"})}
            </div>
            <Select
              name="form-field-name3"
              placeholder={intl.formatMessage({id: "patientsScene.filtersBlock.treatment"})}
              value={treatmentOptions.map(item => {
                if (filters.treatment_options.includes(item.id)) {
                  return {
                    value: item.id,
                    label: makeFirstLatterUp(item.name)
                  }
                }
              })}
              options={treatmentOptions.map(item => {
                return {
                  value: item.id,
                  label: makeFirstLatterUp(item.name)
                }
              })}
              onChange={(item) => {
                let idArray = [];
                for (let i = 0; i < item.length; i++) {
                  idArray.push(item[i].value)
                }
                setFilters({ ...filters, treatment_options: idArray });
              }}
              isMulti
            />
          </Col> */}
      </Row>
      {/* </Col> */}

      {/* <Col sm={12} md={12} lg={12} xl={4}>
        <Row>
          <Col sm={6} md={6} lg={12}>
            {intl.formatMessage({id: "patientsScene.filtersBlock.lastUpdate"})}
            <DateRangePicker
              onChange={
                (item) => {
                  setFilters({
                    ...filters,
                    min_updated_at: item ? item[0] : null,
                    max_updated_at: item ? item[1] : null
                  })}}
              value={[filters.min_updated_at, filters.max_updated_at]}
              format='dd.MM.y'
              showLeadingZeros={true}
              calendarIcon={null}
              dayPlaceholder='dd'
              monthPlaceholder='mm'
              yearPlaceholder='yyyy'
              rangeDivider='-'
            />
          </Col>
          <Col sm={6} md={6} lg={12}>
            {intl.formatMessage({id: "patientsScene.filtersBlock.patientAdded"})}
            <DateRangePicker
              onChange={
                (item) => {
                  setFilters({
                    ...filters,
                    min_created_at: item ? item[0] : null,
                    max_created_at: item ? item[1] : null
                  })}}
              value={[filters.min_created_at, filters.max_created_at]}
              format='dd.MM.y'
              showLeadingZeros={true}
              calendarIcon={null}
              dayPlaceholder='dd'
              monthPlaceholder='mm'
              yearPlaceholder='yyyy'
              rangeDivider='-'
            />
          </Col>
        </Row>
      </Col> */}
    </React.Fragment>
  );
};

export default FiltersBlock;
