export const REMOTE_VISITS_REQUEST = 'REMOTE_VISITS_REQUEST';
export const REMOTE_VISITS_SUCCESS = 'REMOTE_VISITS_SUCCESS';
export const REMOTE_VISITS_FAIL = 'REMOTE_VISITS_FAIL';

export const PHOTOS_VISITS_REQUEST = 'PHOTOS_VISITS_REQUEST';
export const PHOTOS_VISITS_SUCCESS = 'PHOTOS_VISITS_SUCCESS';
export const PHOTOS_VISITS_FAIL = 'PHOTOS_VISITS_FAIL';

export const RESPOND_PHOTOS_REQUEST = 'RESPOND_PHOTOS_REQUEST';
export const RESPOND_PHOTOS_SUCCESS = 'RESPOND_PHOTOS_SUCCESS';
export const RESPOND_PHOTOS_FAIL = 'RESPOND_PHOTOS_FAIL';
