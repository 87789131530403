import React, { useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap'; // Assuming you're using react-bootstrap for modals
import { useTranslation } from 'react-i18next';

export default function VideoRenderer(props) {
  const { t } = useTranslation('contentTrs');
  const { videoUrl, isVisible = false, handleClose: _handleClose } = props;

  const handleClose = useCallback(() => {
    _handleClose();
  }, [_handleClose]);

  return (
    <>
      <Modal
        show={isVisible}
        onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={false}
        className="p-2 modal_video"
        size="lg"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <video controls>
            <source src={videoUrl} type="video/mp4" />
            {t('single_patienScreens.recordTabCol.videoRenderBrowserErrorMsg')}
          </video>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('single_patienScreens.recordTabCol.close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
