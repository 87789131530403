import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../../../helpers/axiosConfig';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';

import PreviewPost from './PreviewPost';
import CreatePost from './CreatePost';
import Loader from '../../../../components/Loader';

import { ReactComponent as DeleteIcon } from '../../../../asset/icons/delete.svg';
import { ReactComponent as UpIcon } from '../../../../asset/icons/up.svg';
import { ReactComponent as DownIcon } from '../../../../asset/icons/down.svg';
import { ReactComponent as DotsIcon } from '../../../../asset/icons/dots.svg';

const ActiveBlogs = () => {
  const [blogKey, setBlogKey] = useState('all');
  const [blogList, setBlogList] = useState([]);
  const [blogPublicList, setBlogPublicList] = useState([]);
  const [blogPersonalList, setBlogPersonalList] = useState([]);
  const [selectionList, setSelectionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadSelection, setLoadSelection] = useState(false);

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;
  const userDetails = useSelector(state => state.userDetails);
  const { user } = userDetails;

  useEffect(() => {
    fetchBlogList();
    fetchSelectionList();
  }, []);

  useEffect(() => {
    let newPublicList = [];
    let newPrivateList = [];
    blogList.map(item => {
      if (item.is_public === true) {
        newPublicList.push(item);
      }
      if (item.is_public === false) {
        newPrivateList.push(item);
      }
    });
    setBlogPublicList(newPublicList);
    setBlogPersonalList(newPrivateList);
  }, [blogList]);

  // console.log(blogList,blogPublicList,blogPersonalList,user)

  const fetchBlogList = async () => {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(`/api/lookup/blogs/list`, config);

      setBlogList(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const fetchSelectionList = async () => {
    try {
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(`/api/lookup/blogs/selection`, config);
      console.log('get selection', data, data[0]);
      setSelectionList(data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteBlogPost = async id => {
    try {
      console.log('deleting');
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.delete(`/api/lookup/blogs/make/${id}`, config);
      setBlogList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSort = () => {
    let _blogList = [...blogList];

    const draggedItemContent = _blogList.splice(dragItem.current, 1)[0];
    console.log(dragItem, draggedItemContent);

    _blogList.splice(dragOverItem.current, 0, draggedItemContent);

    dragItem.current = null;
    dragOverItem.current = null;

    setBlogList(_blogList);
  };

  const handleSelectPost = async id => {
    setLoadSelection(true);
    let currentSelection = selectionList.selection || [];

    if (currentSelection?.includes(id)) {
      currentSelection = currentSelection.filter(e => {
        return e != id;
      });
    } else {
      currentSelection.push(id);
    }

    let body = { selection: currentSelection };
    console.log(typeof currentSelection, currentSelection);
    try {
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.put(`/api/lookup/blogs/selection/`, body, config);
      setSelectionList(data);
      setLoadSelection(false);
    } catch (error) {
      console.log(error);
      setLoadSelection(false);
    }
  };

  const handleUpItem = (id, index) => {
    if (index === 0) {
      return;
    }

    let _blogList = [...blogList];
    let tempItem = _blogList.find(item => item.id === id);

    _blogList = _blogList.filter(item => item.id !== id);
    _blogList.splice(index - 1, 0, tempItem);

    setBlogList(_blogList);
  };

  const handleDownItem = (id, index) => {
    let _blogList = [...blogList];

    if (_blogList.length === index) {
      return;
    }

    let tempItem = _blogList.find(item => item.id === id);

    _blogList = _blogList.filter(item => item.id !== id);
    _blogList.splice(index + 1, 0, tempItem);

    setBlogList(_blogList);
  };

  const selectedItem = id => {
    let returnValue = false;
    selectionList?.selection?.map(item => {
      if (item == id) {
        returnValue = true;
      }
    });

    return returnValue;
  };

  return (
    <>
      <Row className="active-blogs-row">
        <Col md={8}>
          <Tabs id="controlled-tab" activeKey={blogKey} onSelect={k => setBlogKey(k)} className="mb-3 tabs">
            <Tab eventKey="all" title="All">
              <div className="list-container">
                {isLoading && <Loader />}
                {blogList.map((item, index) => (
                  <div key={index} className={`list-item ${!item.is_public ? 'item-private' : ''}`}>
                    <Form.Check
                      // value={item.title}
                      type="radio"
                      aria-label={item.title}
                      label=""
                      className="checkbox-circle"
                      checked={selectedItem(item.id)}
                      onChange={() => {
                        handleSelectPost(item.id);
                      }}
                      onClick={() => {
                        if (selectedItem(item.id)) {
                          handleSelectPost(item.id);
                        }
                      }}
                    />
                    <h6>{item.title}</h6>
                    <div className="button-list">
                      <PreviewPost item={item} setBlogList={setBlogList} />
                      {!item.is_public && (
                        <button
                          type="button"
                          className="button-icon"
                          onClick={() => {
                            deleteBlogPost(item.id);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      )}
                      <div className="action-btn">
                        <button type="button" onClick={() => handleUpItem(item.id, index)}>
                          <UpIcon />
                        </button>
                        <button
                          type="button"
                          className="move"
                          draggable
                          onDragStart={e => (dragItem.current = index)}
                          onDragEnter={e => (dragOverItem.current = index)}
                          onDragEnd={handleSort}
                          onDragOver={e => e.preventDefault()}
                        >
                          <DotsIcon />
                        </button>
                        <button type="button" onClick={() => handleDownItem(item.id, index)}>
                          <DownIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Tab>
            <Tab eventKey="public" title="Public">
              <div className="list-container">
                {isLoading && <Loader />}
                {blogPublicList.map((item, index) => (
                  <div key={index} className={`list-item ${!item.is_public ? 'item-private' : ''}`}>
                    <Form.Check
                      value={item.title}
                      type="radio"
                      aria-label={item.title}
                      label=""
                      className="checkbox-circle"
                      onChange={() => {}}
                      checked={selectedItem(item.id)}
                    />
                    <h6>{item.title}</h6>
                    <div className="button-list">
                      <PreviewPost item={item} setBlogList={setBlogList} />
                      {!item.is_public && (
                        <button
                          type="button"
                          className="button-icon"
                          onClick={() => {
                            deleteBlogPost(item.id);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      )}
                      <div className="action-btn">
                        <button type="button" onClick={() => handleUpItem(item.id, index)}>
                          <UpIcon />
                        </button>
                        <button
                          type="button"
                          className="move"
                          draggable
                          onDragStart={e => (dragItem.current = index)}
                          onDragEnter={e => (dragOverItem.current = index)}
                          onDragEnd={handleSort}
                          onDragOver={e => e.preventDefault()}
                        >
                          <DotsIcon />
                        </button>
                        <button type="button" onClick={() => handleDownItem(item.id, index)}>
                          <DownIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Tab>
            <Tab eventKey="personal" title="Personal">
              <div className="list-container">
                {isLoading && <Loader />}
                {blogPersonalList.map((item, index) => (
                  <div key={index} className={`list-item ${!item.is_public ? 'item-private' : ''}`}>
                    <Form.Check
                      value={item.title}
                      type="radio"
                      aria-label={item.title}
                      label=""
                      className="checkbox-circle"
                      onChange={() => {}}
                      checked={selectedItem(item.id)}
                    />
                    <h6>{item.title}</h6>
                    <div className="button-list">
                      <PreviewPost item={item} setBlogList={setBlogList} />
                      {!item.is_public && (
                        <button
                          type="button"
                          className="button-icon"
                          onClick={() => {
                            deleteBlogPost(item.id);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      )}
                      <div className="action-btn">
                        <button type="button" onClick={() => handleUpItem(item.id, index)}>
                          <UpIcon />
                        </button>
                        <button
                          type="button"
                          className="move"
                          draggable
                          onDragStart={e => (dragItem.current = index)}
                          onDragEnter={e => (dragOverItem.current = index)}
                          onDragEnd={handleSort}
                          onDragOver={e => e.preventDefault()}
                        >
                          <DotsIcon />
                        </button>
                        <button type="button" onClick={() => handleDownItem(item.id, index)}>
                          <DownIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Tab>
          </Tabs>
        </Col>
        <Col md={4}>
          <h6 className="hint-title">Hint</h6>
          <hr className="hr-line" />
          <p>
            Select and activate Blog articles to be shown in your Clinic info page. <br />
            <br />
            You can change the order of appearence by drag and dropping the articles. <br /> <br />
            You can also create (than edit or delete) your own articles by clicking on the Create New button.
          </p>
        </Col>
        <hr />
        <Col md={8} className="custom-align-right">
          {loadSelection && <Loader size={'1'} />}
          <CreatePost setBlogList={setBlogList} />
        </Col>
      </Row>
    </>
  );
};
export default ActiveBlogs;
