// hooks
import { useMemo, useState } from 'react';
import { useClinicInfoQuery } from '../hooks/useClinicInfoQuery';
import { useTranslation } from 'react-i18next';

// components
import Loader from '../../../components/Loader';
import { ClinicInfo } from './ClinicInfo';

import layout1 from '../images/layout_1.png';
import layout2 from '../images/layout_2.png';
import layout3 from '../images/layout_3.png';
import layout4 from '../images/layout_4.png';
import layout5 from '../images/layout_5.png';
import layout6 from '../images/layout_6.png';
import layout7 from '../images/layout_7.png';
import layout8 from '../images/layout_8.png';
import layout9 from '../images/layout_9.png';

const TermsAndConditionForm = ({ verifiedState, onNext: _onSubmit }) => {
  const { t } = useTranslation('patientSignup');
  const [isSelected, setIsSelected] = useState(false);
  const { data, error, isLoading } = useClinicInfoQuery({ clinicId: verifiedState.clinicId });

  const onChange = event => {
    const _isSelected = event.target.value;
    setIsSelected(_isSelected);
  };

  const onSubmit = () => {
    if (isSelected) {
      _onSubmit({});
    }
  };

  let headerEl;
  if (error) {
    headerEl = <ClinicInfo clinicName="Orthobook" />;
  } else if (isLoading) {
    headerEl = <Loader />;
  } else {
    headerEl = <ClinicInfo clinicImageUrl={data.logo} />;
  }

  const clauses = useMemo(
    () => [
      {
        image: layout1,
        text: t('This summary helps you understand our Privacy Policy. Please, always read the full policy.'),
      },
      {
        image: layout2,
        text: t('Personal Information: We collect your full name, date of birth and country of residence.'),
      },
      {
        image: layout3,
        text: t(
          'Medical Information: medical records, such as x-rays, pictures or notes that you or your doctor may provide.'
        ),
      },
      {
        image: layout4,
        text: t('Your information is needed for the app to work and to put you in touch with your doctor when needed.'),
      },
      {
        image: layout5,
        text: t('We send you important updates and analyse how you use our app so we can make it better for you.'),
      },
      {
        image: layout6,
        text: t('We only share your information with doctors and healthcare professionals who are helping you.'),
      },
      {
        image: layout7,
        text: t(
          'If your child uses our service, you can manage their account. You have the right to access and control their personal and medical data until they reach the digital consent age.'
        ),
      },
      {
        image: layout8,
        text: t(
          'We use encryption and other measures to keep your data secure. Only you and authorised professionals can access it.'
        ),
      },
      {
        image: layout9,
        text: t(
          'You have control of your data. You can ask to see it, change it, or have it deleted. Your doctor may retain a copy when required by law.'
        ),
      },
    ],
    [t]
  );

  return (
    <div
      className="tw-flex tw-flex-col tw-w-full  tw-items-center tw-justify-between tw-py-12 tw-mx-6 tw-gap-4"
      style={{ maxWidth: '440px' }}
    >
      <div>{headerEl}</div>
      <div className="tw-flex tw-flex-col tw-w-full tw-rounded-lg tw-flex-1 tw-items-center tw-justify-between">
        <div className="tw-rounded-t-lg tw-text-white tw-text-xl tw-text-center tw-bg-teel tw-py-3 tw-w-full">
          {t('Our Privacy Policy & Your Rights')}
        </div>
        <div
          className="tw-bg-white tw-text-black tw-flex tw-flex-col tw-py-2 tw-px-4 tw-gap-2 tw-overflow-y-auto"
          style={{ height: '350px', maxHeight: '50%' }}
        >
          <div className="tw-w-full tw-text-center tw-text-sm tw-my-2">
            {t('Let’s review how we process your data!')}
          </div>
          {clauses.map((clause, index) => (
            <div
              key={clause.text}
              className={`tw-flex ${index % 2 === 0 ? 'tw-flex-row' : 'tw-flex-row-reverse'} tw-gap-3 tw-items-center`}
            >
              <img src={clause.image} alt="superHero photo" style={{ maxWidth: '60px' }} />
              <div
                className={`${index % 2 === 0 ? 'tw-text-right' : 'tw-text-left'} tw-text-sm tw-flex tw-flex-wrap tw-break-words`}
              >
                {clause.text}
              </div>
            </div>
          ))}
        </div>
        <div className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-h-5 tw-mt-3">
          <input
            id="remember"
            type="checkbox"
            value={isSelected}
            onChange={onChange}
            className="tw-w-4 tw-h-4 tw-border tw-border-blue-600 tw-rounded tw-flex-none focus:tw-ring-3 focus:tw-ring-blue-300 tw-cursor-pointer"
            required
          />
          <label htmlFor="remember" className="tw-ms-2 tw-text-sm tw-font-medium tw-text-gray-900">
            {t('Read and accept') + ' '}
            <a
              href="https://www.tiecast.co.uk/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="tw-text-blue-600 hover:tw-underline"
            >
              {t('Full privacy policy')}
            </a>
            {'.'}
          </label>
        </div>
        <button
          className={`tw-w-fit tw-bg-lightBlue hover:tw-bg-darkBlue tw-py-2 tw-px-5 tw-mt-2 tw-rounded-20 tw-text-white tw-transition-colors ${!isSelected ? 'tw-cursor-not-allowed' : ''}`}
          onClick={onSubmit}
          disabled={!isSelected}
        >
          {t('Complete Revision')}
        </button>
      </div>
    </div>
  );
};

export { TermsAndConditionForm };
