import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Table, Card } from 'react-bootstrap';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { listClinics } from '../../actions/clinicsActions';
import { createPatient } from '../../actions/patientsActions';

import Loader from '../../components/Loader';
import { getUserDetails } from '../../actions/userActions';

function Demografics(props) {
  const { patientAction } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('contentTrs');
  const [clinic, setClinic] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [sex, setSex] = useState('');
  const [nhsNumber, setNhsNumber] = useState('');
  const [email, setEmail] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [message, setMessage] = useState('');
  const [selectBorder, setSelectBorder] = useState(false);

  const [defaultEmailChecked, setDefaultEmailChecked] = useState(false);

  const clinicsList = useSelector(state => state.clinicsList);
  const { clinics, loading: loadingClinics } = clinicsList;

  // const patientCreate = useSelector(state => state.patientCreate)
  // const { error, loading, success:successPatient, patient, draftPatient } = patientCreate

  //const dentistProfile = useSelector(state => state.dentistProfile)
  //const { dentist } = dentistProfile;

  const [creatingPatient, setCreatingPatient] = useState(false);

  const userDetails = useSelector(state => state.userDetails);
  const { loading, user } = userDetails;

  const [defaultDentistEmail, setDefaultDentistEmail] = useState('');

  useEffect(() => {
    if ((!user || !user.name) && !loading) {
      dispatch(getUserDetails('profile'));
    } else {
      setDefaultDentistEmail(user.email);
    }
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(listClinics());
  }, [dispatch]);

  const handleValidation = () => {
    let formIsValid = true;

    if (clinic == '') {
      formIsValid = false;
      setSelectBorder(true);
    } else if (sex == '') {
      formIsValid = false;
    } else {
      setSelectBorder(false);
    }
    return formIsValid;
  };

  const submitPatientDemografics = e => {
    e.preventDefault();
    setCreatingPatient(true);

    if (handleValidation()) {
      console.log('valid');
      dispatch(
        createPatient({
          clinic_id: clinic,
          first_name: firstName,
          last_name: lastName,
          birth_date: birthDate,
          sex: sex,
          phone_number: phoneNumber,
          NHS_number: nhsNumber,
          email: email,
        })
      );
    } else {
      console.log('not valid');
    }
  };

  return (
    <Card>
      <Card.Body className="">
        <Form onSubmit={submitPatientDemografics} className="py-3">
          <Form.Group controlId="clinic" required>
            <Form.Label style={{ display: 'flex', flexDirection: 'row' }}>
              <span>{t('addNew_patienScreen.add_step1.selectClinic')}</span>{' '}
              {loadingClinics && (
                <div style={{ marginLeft: '10px', marginTop: '3px' }}>
                  {' '}
                  <Loader size="20px"></Loader>{' '}
                </div>
              )}{' '}
            </Form.Label>
            <Form.Select
              className={selectBorder && 'border border-danger'}
              required
              onChange={e => {
                setClinic(e.target.value);
              }}
            >
              <option key="blankChoice" hidden value>
                {' '}
                - - -{' '}
              </option>

              {clinics.map(clinic => (
                <option key={clinic.id} value={clinic.id}>
                  {clinic.clinic_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group controlId="nhs_number">
            <Form.Label>{t('addNew_patienScreen.add_step1.nHS_Number')}</Form.Label>
            <Form.Control
              type="name"
              placeholder={t('addNew_patienScreen.add_step1.fVal_nHS')}
              value={nhsNumber}
              onChange={e => setNhsNumber(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="first_name">
            <Form.Label>{t('addNew_patienScreen.add_step1.firstname')}</Form.Label>
            <Form.Control
              required
              type="name"
              placeholder={t('addNew_patienScreen.add_step1.fval_fName')}
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="last_name">
            <Form.Label>{t('addNew_patienScreen.add_step1.lastName')}</Form.Label>
            <Form.Control
              required
              type="name"
              placeholder={t('addNew_patienScreen.add_step1.fval_lName')}
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="date_of_birth">
            <Form.Label>{t('addNew_patienScreen.add_step1.dataOb')}</Form.Label>
            <Form.Control
              required
              type="date"
              min="1900-01-01"
              max={moment().format('YYYY-MM-DD')}
              placeholder="dd-mm-yyyy"
              value={birthDate}
              onChange={e => setBirthDate(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="sex">
            <Form.Label>{t('addNew_patienScreen.add_step1.sex.title')}</Form.Label>
            {['radio'].map(type => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label={t('addNew_patienScreen.add_step1.sex.male')}
                  name="group1"
                  type={type}
                  id={`inline-${type}-1`}
                  value="M"
                  onChange={e => setSex(e.target.value)}
                />
                <Form.Check
                  inline
                  label={t('addNew_patienScreen.add_step1.sex.female')}
                  name="group1"
                  type={type}
                  id={`inline-${type}-2`}
                  value="F"
                  onChange={e => setSex(e.target.value)}
                />
              </div>
            ))}
          </Form.Group>

          <Form.Group controlId="phone_number">
            <Form.Label>{t('addNew_patienScreen.add_step1.phone')}</Form.Label>
            <Form.Control
              type="number"
              placeholder={t('addNew_patienScreen.add_step1.fval_phone')}
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="email">
            <Form.Label>{t('addNew_patienScreen.add_step1.email')}</Form.Label>

            {defaultEmailChecked === true ? (
              <Form.Control
                disabled
                type="email"
                value={email} //Current email...
              ></Form.Control>
            ) : (
              <Form.Control
                type="email"
                placeholder={t('addNew_patienScreen.add_step1.fval_email')}
                value={email}
                onChange={e => setEmail(e.target.value)}
              ></Form.Control>
            )}
          </Form.Group>

          <br />
          <Form.Group controlId="checkbox_defaultEmail">
            {defaultEmailChecked === true ? (
              <Form.Check
                type="checkbox"
                id="default-checkbox"
                label={t('addNew_patienScreen.add_step1.useDefaultEmail')}
                onChange={e => setDefaultEmailChecked(e.target.checked)}
                onClick={() => {
                  setEmail(email);
                }}
              />
            ) : (
              <Form.Check
                type="checkbox"
                id="default-checkbox"
                label={t('addNew_patienScreen.add_step1.useDefaultEmail')}
                onChange={e => setDefaultEmailChecked(e.target.checked)}
                onClick={() => {
                  setEmail(defaultDentistEmail);
                }} // Modificare SOLO Questa Variabile passando l'email del Medico
              />
            )}
          </Form.Group>

          <Row>
            <Col>
              {creatingPatient ? (
                <>
                  <Button type="submit" variant="primary" className="form-button text-end" disabled>
                    {t('addNew_patienScreen.add_step1.continue')}
                  </Button>
                </>
              ) : (
                <Button type="submit" variant="primary" className="form-button text-end">
                  {t('addNew_patienScreen.add_step1.continue')}
                </Button>
              )}
            </Col>
            <Col sm={3} md={3} lg={2} className=" p-2">
              {creatingPatient && <Loader size="40px"></Loader>}
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default Demografics;
