export const VISITS_LIST_REQUEST = 'VISITS_LIST_REQUEST';
export const VISITS_LIST_SUCCESS = 'VISITS_LIST_SUCCESS';
export const VISITS_LIST_FAIL = 'VISITS_LIST_FAIL';
export const VISITS_LIST_RESET = 'VISITS_LIST_RESET';

export const VISITS_DETAILS_REQUEST = 'VISITS_DETAILS_REQUEST';
export const VISITS_DETAILS_SUCCESS = 'VISITS_DETAILS_SUCCESS';
export const VISITS_DETAILS_FAIL = 'VISITS_DETAILS_FAIL';

export const RECORDS_DETAILS_REQUEST = 'RECORDS_DETAILS_REQUEST';
export const RECORDS_DETAILS_SUCCESS = 'RECORDS_DETAILS_SUCCESS';
export const RECORDS_DETAILS_DELETE_SUCCESS = 'RECORDS_DETAILS_DELETE_SUCCESS';
export const RECORDS_DETAILS_FAIL = 'RECORDS_DETAILS_FAIL';
export const RECORDS_DETAILS_RESET = 'RECORDS_DETAILS_RESET';

export const OTHER_RECORDS_DETAILS_REQUEST = 'OTHER_RECORDS_DETAILS_REQUEST';
export const OTHER_RECORDS_DETAILS_SUCCESS = 'OTHER_RECORDS_DETAILS_SUCCESS';
export const OTHER_RECORDS_DETAILS_DELETE_SUCCESS = 'OTHER_RECORDS_DETAILS_DELETE_SUCCESS';
export const OTHER_RECORDS_DETAILS_FAIL = 'OTHER_RECORDS_DETAILS_FAIL';
export const OTHER_RECORDS_DETAILS_RESET = 'OTHER_RECORDS_DETAILS_RESET';

export const VISIT_CREATE_REQUEST = 'VISIT_CREATE_REQUEST';
export const VISIT_CREATE_SUCCESS = 'VISIT_CREATE_SUCCESS';
export const VISIT_CREATE_FAIL = 'VISIT_CREATE_FAIL';
export const VISIT_CREATE_RESET = 'VISIT_CREATE_FAIL';

export const VISIT_DELETE_REQUEST = 'VISIT_DELETE_REQUEST';
export const VISIT_DELETE_SUCCESS = 'VISIT_DELETE_SUCCESS';
export const VISIT_DELETE_FAIL = 'VISIT_DELETE_FAIL';
export const VISIT_DELETE_RESET = 'VISIT_DELETE_RESET';

export const VISIT_UPDATE_REQUEST = 'VISIT_UPDATE_REQUEST';
export const VISIT_UPDATE_SUCCESS = 'VISIT_UPDATE_SUCCESS';
export const VISIT_UPDATE_FAIL = 'VISIT_UPDATE_FAIL';
export const VISIT_UPDATE_RESET = 'VISIT_UPDATE_FAIL';

export const LIST_VISITS_WITH_TYPE_REQUEST = 'VISIT_LIST_OF_TYPE_REQUEST';
export const LIST_VISITS_WITH_TYPE_SUCCESS = 'VISIT_LIST_OF_TYPE_SUCCESS';
export const LIST_VISITS_WITH_TYPE_FAIL = 'VISIT_LIST_OF_TYPE_FAIL';
