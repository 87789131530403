import { useRef } from 'react';

import { ReactComponent as PicturetIcon } from '../../../asset/icons/picture-icon.svg';

import './AttachPicture.css';

function AttachPicture() {
  const inputRef = useRef(null);

  const handleUpload = () => {
    inputRef.current?.click();
  };
  return (
    <div>
      <input ref={inputRef} className="d-none" type="file" />
      <button onClick={handleUpload} className="attach-btn attach-pic">
        <PicturetIcon />
      </button>
    </div>
  );
}

export default AttachPicture;
