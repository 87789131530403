import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

import Loader from '../components/Loader';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer';
import { passwordStrengthValidation } from '../utilities/passwordStrengthValidation';

import axios, { instanceBase } from '../helpers/axiosConfig';

import { register, registerDentist } from '../actions/userActions';

// todo: core-ui CSS import
import '../scss/coreUI.scss';

function ResetPasswordScreen() {
  const { t } = useTranslation('contentTrs');
  const { t: tn } = useTranslation('patientSignup');
  const tokens = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const redirect = searchParams.get('redirect_url') ? searchParams.get('redirect_url') : '/';

  const [validLink, setValidLink] = useState('');
  const [brokenLink, setBrokenLink] = useState('');
  const [passwordUpdate, setPasswordUpdate] = useState('');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const dentistRegister = useSelector(state => state.dentistRegister);
  const { error, loading, userInfo } = dentistRegister;

  useEffect(() => {
    const checkLinkValidity = async () => {
      try {
        // Ensure tokens.token1 and tokens.token2 are defined and not null before making the request
        setBrokenLink(false);

        if (tokens.token1 && tokens.token2) {
          const response = await axios.get(`api/password-reset/${tokens.token1}/${tokens.token2}/`);
          setValidLink(response.data);
        } else {
          // Handle the case where tokens are not available
          setBrokenLink(true);
        }
      } catch (error) {
        setBrokenLink(true);
      }
    };

    checkLinkValidity();
  }, [tokens]); // Ensure tokens is a dependency if its value affects the effect

  const submitHandler = async e => {
    e.preventDefault();
    const isPasswordValid = passwordStrengthValidation(password, tn);
    let errorMessagePassword = isPasswordValid.errorMessage;
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      setConfirmPasswordError(true);
      return;
    } else if (!isPasswordValid?.isValid) {
      setErrorMessage(errorMessagePassword);
      setConfirmPasswordError(true);
      return;
    } else {
      setErrorMessage(false);
      setConfirmPasswordError(false);
    }

    try {
      const { data } = await instanceBase.patch('api/password-reset-complete', {
        password: password,
        token: tokens.token2,
        uidb64: tokens.token1,
      });
      setPasswordUpdate(true);
      setValidLink(false);
      if (data && redirect === '/oralcheck/signup/download/') {
        setTimeout(() => {
          navigate('/oralcheck/signup/download/');
        }, 1.5);
      }
    } catch (error) {
      setPasswordUpdate(false);
    }
  };

  return (
    <div className="w-100 p-3">
      <Container inline="true" className="mb-5">
        <Link to={redirect}>
          <Button variant="primary">{t('authPages.resetPass.back')}</Button>
        </Link>

        <h1 className="text-center mt-5 mb-5">{t('authPages.resetPass.title')}</h1>
      </Container>

      <Container className="pt-3 pb-4 mt-5">
        {validLink?.success && <Message variant="info">{t('authPages.resetPass.ok')}</Message>}
        {errorMessage && <Message variant="danger">{errorMessage}</Message>}
        {brokenLink && <Message variant="danger">{t('authPages.resetPass.brokenLink')}</Message>}
        {passwordUpdate && (
          <Message variant="success">
            {t('authPages.resetPass.done') + ' '}
            <Link to={redirect} className="text-info">
              Log-in
            </Link>
          </Message>
        )}
        {passwordUpdate === false && <Message variant="danger">{t('authPages.resetPass.problem')}</Message>}
        {loading && <Loader></Loader>}

        {validLink?.success && (
          <Form onSubmit={submitHandler}>
            <Row>
              <Col md={6}>
                <Row className="mb-2">
                  <h4 className="text-left">{t('authPages.resetPass.choice')}</h4>

                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      placeholder="enter password"
                      minLength="6"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId="passwordConfirm">
                    <Form.Label>{t('authPages.resetPass.confirm')}</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      placeholder={t('authPages.resetPass.confirm')}
                      minLength="6"
                      value={confirmPassword}
                      onChange={e => setConfirmPassword(e.target.value)}
                    ></Form.Control>
                    {confirmPasswordError && (
                      <Form.Control.Feedback type="invalid">{t('authPass.resetPass.match')}</Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
              </Col>
            </Row>

            <Button type="submit" variant="primary">
              {t('authPages.resetPass.updateButton')}
            </Button>
          </Form>
        )}
      </Container>
    </div>
  );
}

export default ResetPasswordScreen;
