import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

const StripeSettingsElement = ({ children }) => {
  let pk_test =
    'pk_test_51IzhKICwLmJe1ZA1J5UVcTpSajkJwOXJuxc4VdNIsEGIIZirAUDfBGPQD5Gy80RZ3dJ7JXAmGSAEd7dn6sCfRf7400dein8Ulh';
  let pk_prod =
    'pk_live_51IzhKICwLmJe1ZA1ZjhoTw7eR64MLlePTdLUhjF5MS6EI3rLr4Ye2L7fObeQNqFGjQjepx9UZ9NdmeaxEtpRVD2200qqDsO5XQ';

  const stripePromise = loadStripe(pk_prod);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    appearance,
  };

  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  // const { history } = props;
  // const { freeVersion } = useSelector(state => state.initialization)

  // useEffect(() => {
  //   if (!freeVersion) {
  //     history.push('/')
  //   }
  // }, [])

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

export default StripeSettingsElement;
