import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import CheckoutForm from '../components/stripe/CheckoutForm';
import StripeSettingsElement from '../utilities/StripeSettingsElement';

// todo: core-ui CSS import
import '../scss/coreUI.scss';

const StripeScreen = () => {
  // const stripePromise = loadStripe(pk_test);

  // const options = {
  //     appearance,
  //   };

  return (
    <StripeSettingsElement>
      <CheckoutForm />
    </StripeSettingsElement>
  );
};

export default StripeScreen;
