import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Statistics = () => {
  const { t } = useTranslation('contentTrs');
  return (
    <div className="text-center py-5">
      <h2>{t('settingsPage.statistics.comingSoonTitle')}</h2>
      <h3>{t('settingsPage.statistics.comingSoonSubTitle')}</h3>
    </div>
  );
};

export default Statistics;
