// import axios from "axios";
import axios from '../helpers/axiosConfig';
import {
  TREATMENT_INFO_REQUEST,
  TREATMENT_INFO_SUCCESS,
  TREATMENT_INFO_FAIL,

  // TREATMENT_DETAILS_REQUEST,
  // TREATMENT_DETAILS_SUCCESS,
  // TREATMENT_DETAILS_FAIL,
  TREATMENT_CREATE_REQUEST,
  TREATMENT_CREATE_SUCCESS,
  TREATMENT_CREATE_FAIL,
  // TREATMENT_CREATE_RESET,
  TREATMENT_UPDATE_REQUEST,
  TREATMENT_UPDATE_SUCCESS,
  TREATMENT_UPDATE_FAIL,
  // TREATMENT_UPDATE_RESET,
} from '../constants/treatmentConstants';

// These is a collection of Redux-free actions

export const getTreatment = patientId => async (dispatch, getState) => {
  try {
    dispatch({ type: TREATMENT_INFO_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`/api/patients/profile/${patientId}/treatment/`, config);
    //console.log(data)

    dispatch({
      type: TREATMENT_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TREATMENT_INFO_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const createTreatment = (patientId, treatments) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TREATMENT_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(`/api/patients/profile/${patientId}/treatment/addfirstvisit`, treatments, config);

    dispatch({
      type: TREATMENT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TREATMENT_CREATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const updateTreatment = (patientId, treatments) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TREATMENT_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(`/api/patients/profile/${patientId}/treatment/edit`, treatments, config);

    dispatch({
      type: TREATMENT_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TREATMENT_UPDATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

/*
export const listRecords = (patientId,visitId) => async (dispatch, getState) => {
    try{
        dispatch({type: RECORDS_DETAILS_REQUEST})

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(
            `/api/patients/profile/${patientId}/visits/${visitId}`,
            config
        )

        dispatch({
             type: RECORDS_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: RECORDS_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

/*
export const clinicsDetails = (id) => async (dispatch, getState) => {
    try{
        
        dispatch({type: CLINIC_PROFILE_REQUEST})

        const {
            userLogin: {userInfo},
        } =getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(
            //`/api/users/profile/clinics/${id}`,
            `/api/users/profile/clinics/${id}`,
            config
        )

        dispatch({
            type: CLINIC_PROFILE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CLINIC_PROFILE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateClinics = (clinic) => async (dispatch, getState) => {
    try{
        dispatch({
            type: CLINIC_UPDATE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } =getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.put(
            `/api/users/profile/clinics/update/${clinic._id}`,
            clinic,
            config
        )

        dispatch({
            type: CLINIC_UPDATE_SUCCESS,
            payload: data
        })
       
    } catch (error) {
        dispatch({
            type: CLINIC_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createClinics = (newClinic) => async (dispatch, getState) => {
    try{
        dispatch({
            type: CLINIC_CREATE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } =getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.post(
            `/api/users/profile/clinics/addnew/`,
            newClinic,
            config
        )

        dispatch({
            type: CLINIC_CREATE_SUCCESS,
            payload:data,
        })

    } catch (error) {
        dispatch({
            type: CLINIC_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

*/
