import { useMutation } from '@tanstack/react-query';

import axios from '../../../helpers/axiosConfig';

const usePatientAuthMutation = () =>
  useMutation({
    mutationFn: ({ clinicId, patientId, birthDate, emailId, password }) =>
      axios.post('api/remote/patient/auth/register/', {
        auth:
          emailId && password
            ? {
                email: emailId,
                password,
              }
            : undefined,
        from_page: emailId && password ? 'Patient_web_doctor_invite' : undefined,
        patient: {
          uuid: patientId,
          clinic_id: clinicId,
          birthday: birthDate,
        },
      }),
  });

export { usePatientAuthMutation };
