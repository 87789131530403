export const PAY_SUBSCRIPTION_REQUEST = 'PAY_SUBSCRIPTION_REQUEST';
export const PAY_SUBSCRIPTION_SUCCESS = 'PAY_SUBSCRIPTION_SUCCESS';
export const PAY_SUBSCRIPTION_FAIL = 'PAY_SUBSCRIPTION_FAIL';

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';

export const REACTIVATE_SUBSCRIPTION_REQUEST = 'REACTIVATE_SUBSCRIPTION_REQUEST';
export const REACTIVATE_SUBSCRIPTION_SUCCESS = 'REACTIVATE_SUBSCRIPTION_SUCCESS';
export const REACTIVATE_SUBSCRIPTION_FAIL = 'REACTIVATE_SUBSCRIPTION_FAIL';

export const CARD_DETAILS_REQUEST = 'CARD_DETAILS_REQUEST';
export const CARD_DETAILS_SUCCESS = 'CARD_DETAILS_SUCCESS';
export const CARD_DETAILS_FAIL = 'CARD_DETAILS_FAIL';
export const CARD_DETAILS_RESET = 'CARD_DETAILS_RESET';

export const CARD_UPDATE_PROFILE_REQUEST = 'CARD_UPDATE_PROFILE_REQUEST';
export const CARD_UPDATE_PROFILE_SUCCESS = 'CARD_UPDATE_PROFILE_SUCCESS';
export const CARD_UPDATE_PROFILE_FAIL = 'CARD_UPDATE_PROFILE_FAIL';
export const CARD_UPDATE_PROFILE_RESET = 'CARD_UPDATE_PROFILE_RESET';

export const STRIPE_INVOICE_REQUEST = 'STRIPE_INVOICE_REQUEST';
export const STRIPE_INVOICE_SUCCESS = 'STRIPE_INVOICE_SUCCESS';
export const STRIPE_INVOICE_FAIL = 'STRIPE_INVOICE_FAIL';
export const STRIPE_INVOICE_RESET = 'STRIPE_INVOICE_RESET';
