import React, { useState } from 'react';
import { Button, Modal, Form, Image } from 'react-bootstrap';

import { BsCloudUploadFill, BsZoomIn } from 'react-icons/bs';
import { IconContext } from 'react-icons';
import Avatar from 'react-avatar';
import instance from '../../helpers/axiosConfig';
import { base_URL_media } from '../../helpers/axiosConfig';

// Font awesome pixel sizes relative to the multiplier.
// 1x - 14px
// 2x - 28px
// 3x - 42px
// 4x - 56px
// 5x - 70px

const DownloadImage = props => {
  const { image, editImg, user, classes, readOnly = false, deleteImg } = props;

  const [openImgModal, setOpenImgModal] = useState(false); // intead of const [show, setShow] = useState(false);

  const handleClose = () => setOpenImgModal(false);
  const handleShow = () => setOpenImgModal(true);

  const hiddenFileInput = React.useRef(null);

  const handleClickUpload = event => {
    hiddenFileInput.current.click(); //.clikc()
    //console.log('click handle upload')
  };

  const handleChange = event => {
    //console.log('change handle upload')
    const loadedFile = event.target.files[0];
    editImg(loadedFile);
  };

  return (
    <div>
      <div className={classes ? classes : `downloadImageRoot`}>
        <div>
          {image.minimized_photo_url ? (
            image.minimized_photo_url?.includes('/records/') &&
            (image.subtype_id === 11 || image.subtype_id === 12 || image.subtype_id === null) ? (
              <div style={{ textAlign: 'center' }}>{image.name}</div>
            ) : (
              <Image src={`${base_URL_media}${image.minimized_photo_url}`} className="recordImage" />
            )
          ) : image.placeholder ? (
            <Image src={`${image.placeholder}`} className="recordPlaceholder" />
          ) : (
            <Avatar name={`${user.first_name} ${user.last_name}`} size="120" color={'#4BC1CB'} />
          )}
        </div>
        <div className={image.photo_url?.includes('/records/') && readOnly ? 'display-none' : 'downloadImageModal'}>
          {!readOnly && (
            <>
              <input
                //id="contained-button-file"
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
              />
              <label onClick={() => handleClickUpload()}>
                <BsCloudUploadFill size={42} />
              </label>
            </>
          )}

          {!image.photo_url?.includes('/records/') && image.photo_url && (
            <div
              onClick={() => {
                setOpenImgModal(true);
                console.log('click', openImgModal);
              }}
              className="my-2"
            >
              <BsZoomIn size={42} />
            </div>
          )}
          {!image.photo_url?.includes('/records/') && !readOnly && deleteImg && (
            <div onClick={deleteImg} className="my-2">
              <BsCloudUploadFill size={42} />
            </div>
          )}
        </div>
        {/*  */}
        <Modal
          show={openImgModal}
          onHide={handleClose}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          keyboard={false}
          className="p-2"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New Clinic</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Image src={image.photo_url} fluid />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default DownloadImage;
