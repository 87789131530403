import {
  PATIENTS_LIST_REQUEST,
  PATIENTS_LIST_SUCCESS,
  PATIENTS_LIST_FAIL,
  PATIENT_PROFILE_REQUEST,
  PATIENT_PROFILE_SUCCESS,
  PATIENT_PROFILE_FAIL,
  PATIENT_CREATE_REQUEST,
  PATIENT_CREATE_SUCCESS,
  PATIENT_CREATE_FAIL,
  PATIENT_CREATE_RESET,
  PATIENT_UPDATE_REQUEST,
  PATIENT_UPDATE_SUCCESS,
  PATIENT_UPDATE_FAIL,
  PATIENT_UPDATE_RESET,
  PATIENT_DELETE_REQUEST,
  PATIENT_DELETE_SUCCESS,
  PATIENT_DELETE_FAIL,
  PATIENT_DELETE_RESET,
} from '../constants/patientsConstants';

export const patientsListReducer = (state = { patients: [] }, action) => {
  switch (action.type) {
    case PATIENTS_LIST_REQUEST:
      return { loading: true, patients: [] };

    case PATIENTS_LIST_SUCCESS:
      return {
        loading: false,
        patients: action.payload.patients,
        page: action.payload.page,
        pages: action.payload.pages,
      };

    case PATIENT_DELETE_SUCCESS:
      let updatedPatients = state.patients.filter(patient => patient.id !== action.payload);
      return { loading: false, patients: updatedPatients };

    case PATIENTS_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const patientProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_PROFILE_REQUEST:
      return { loading: true };

    case PATIENT_PROFILE_SUCCESS:
      return { loading: false, patient: action.payload };

    case PATIENT_PROFILE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const patientCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_CREATE_REQUEST:
      return { loading: true };

    case PATIENT_CREATE_SUCCESS:
      return { loading: false, success: true, draftPatient: true, patient: action.payload };

    case PATIENT_CREATE_FAIL:
      return { loading: false, error: action.payload };

    case PATIENT_CREATE_RESET:
      return { draftPatient: false, draftId: action.payload };

    default:
      return state;
  }
};

export const patientUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_UPDATE_REQUEST:
      return { loading: true };

    case PATIENT_UPDATE_SUCCESS:
      return { loading: false, success: true, patient: action.payload };

    case PATIENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case PATIENT_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

/*

export const clinicsProfileReducer = (state = { clinic: {} }, action) => {
    switch (action.type) {
        case CLINIC_PROFILE_REQUEST:
            return { loading: true, ...state}

        case CLINIC_PROFILE_SUCCESS:
            return { loading: false, clinic: action.payload, }

        case CLINIC_PROFILE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const clinicsUpdateReducer = (state = {  }, action) => {
    switch(action.type){
        case CLINIC_UPDATE_REQUEST:
            return { loading: true }

        case CLINIC_UPDATE_SUCCESS:
            return {loading: false, success: true }

        case CLINIC_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case CLINIC_UPDATE_RESET:
            return { }
        
        default:
            return state
    }
}



/*
export const dentistUpdateProfileReducer = (state = {  }, action) => {
    switch(action.type){
        case DENTIST_UPDATE_PROFILE_REQUEST:
            return { loading: true }

        case DENTIST_UPDATE_PROFILE_SUCCESS:
            return {loading: false, success: true }

        case DENTIST_UPDATE_PROFILE_FAIL:
            return { loading: false, error: action.payload }

        case DENTIST_UPDATE_PROFILE_RESET:
            return { }
        
        default:
            return state
    }
}

/*
export const dentistInvoicingReducer = (state = { invoicing: {} }, action) => {
    switch(action.type){
        case DENTIST_INVOICING_REQUEST:
            return { loading: true }

        case DENTIST_INVOICING_SUCCESS:
            return {loading: false, invoicing: action.payload}

        case DENTIST_INVOICING_FAIL:
            return { loading: false, error: action.payload }
        
        case DENTIST_INVOICING_RESET:
            return { invoicing: {} }
            
        default:
            return state
    }
}

export const dentistUpdateInvoicingReducer = (state = {  }, action) => {
    switch(action.type){
        case DENTIST_UPDATE_INVOICING_REQUEST:
            return { loading: true }

        case DENTIST_UPDATE_INVOICING_SUCCESS:
            return {loading: false, success: true }

        case DENTIST_UPDATE_INVOICING_FAIL:
            return { loading: false, error: action.payload }

        case DENTIST_UPDATE_INVOICING_RESET:
            return { }
        
        default:
            return state
    }
}

*/
