//Maintain and add any new record types added here.
const allRecordTypes = [
  'front_smile',
  'front_relaxed',
  'lateral_smile',
  'lateral_relaxed',
  'intraoral_occlucion_front',
  'intraoral_occlucion_ovj',
  'intraoral_occlucion_left',
  'intraoral_occlucion_right',
  'intraoral_arch_superior',
  'intraoral_arch_inferior',
  'xray_tele',
  'xray_opt',
  'impression_upper_arch',
  'impression_lower_arch',
  'impression_occlusion',
];

//Check whether all records in record Field are empty
export const areRecordsEmpty = records => {
  const isOtherRecordsEmpty = Array.isArray(records.other_records) && records.other_records.length === 0;

  const areAllRecordTypesEmptyOrNull = allRecordTypes.every(key => {
    const value = records[key];
    return value === null || value === '';
  });

  // Return true if all specified fields are empty or null
  return isOtherRecordsEmpty && areAllRecordTypesEmptyOrNull;
};
