import React, { useEffect, useState } from 'react';
// import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Row, Col, Container } from 'react-bootstrap';

import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { listPatients } from '../../actions/patientsActions';
import PatientsTable from './components/PatientsTable';
import PreviewPatient from './components/PreviewPatient';
import FiltersBlock from './components/FiltersBlock';

// todo: core-ui CSS import
import '../../scss/coreUI.scss';

function PatientsScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  let keyword = location.search;

  const [previewIndex, setPreviewIndex] = useState(0);
  const [renewTrigger, setRenewTrigger] = useState(false);

  const [filters, setFilters] = useState({
    limit: 10,
    page: '1',
    search_term: '',
    order_by: 'updated_at',
    order_direction: 'desc',
    min_created_at: null,
    max_created_at: null,
    min_updated_at: null,
    max_updated_at: null,
    statuses: [],
    diagnoses: [],
    clinics: [],
    treatment_options: [],
    with_presentations: '1',
  });

  const patientsList = useSelector(state => state.patientsList);
  const { error, loading, patients, page, pages } = patientsList;
  const { t } = useTranslation('contentTrs');

  useEffect(() => {
    navigate(`${location.pathname}?keyword=${filters.search_term}&page=1`);
  }, [dispatch, filters.search_term]);

  useEffect(() => {
    dispatch(listPatients(keyword));
  }, [dispatch, keyword]);

  const newPatientClick = () => {
    navigate('/patients/register');
  };

  const searchByParameter = (parameter, value) => {
    navigate(`${location.pathname}?keyword=${filters.search_term}&page=1&${parameter}=${value}`);
  };

  const openPatient = () => {
    navigate('/patients/register');
  };

  return (
    <Container className="p-3">
      <Row>
        <Col>
          <Row className="justify-content-between ml-1">
            <Col>
              <h3 className="display-5">{t('allPatientScreen.allPatient_title')}</h3>
            </Col>
            <Col className="text-end mr-5">
              <Button className="mb-3 baseButton" onClick={newPatientClick}>
                {' '}
                + {t('allPatientScreen.addPatient_button')}
              </Button>
            </Col>
          </Row>
          {patients.length === 0 && filters.search_term == '' && filters.search_term === [] ? (
            <Row className="mt-3">
              <Col sm={12} className="flex justify-content-center">
                <img src="/images/dontHavePatients.png" className="dontHavePatientsImage" />
              </Col>
              <Col sm={12} className="flex justify-content-center font-2xl mt-3">
                {t('allPatientScreen.trackTreatment')}
              </Col>
            </Row>
          ) : (
            <React.Fragment>
              <Row className="mb-3 align-items-center">
                <FiltersBlock filters={filters} setFilters={setFilters} searchByParameter={searchByParameter} />
              </Row>
              <PatientsTable
                patients={patients}
                page={page}
                pages={pages}
                previewIndex={previewIndex}
                setPreviewIndex={setPreviewIndex}
                //statuses={statuses}
                renewTrigger={renewTrigger}
                setRenewTrigger={setRenewTrigger}
                filters={filters}
                setFilters={setFilters}
                keyword={keyword}
                //total={total}
              />
            </React.Fragment>
          )}
        </Col>
        {patients.filter(obj => {
          return obj.id === previewIndex;
        })[0] && (
          <Col
            xs={12}
            md={12}
            lg={4}
            className="pl-3 ml-3 border-left previewPatient scrollY"
            // className='justify-content-center scrollY'
          >
            <Row className="sticky-top" style={{ top: 88 }}>
              <Col xs={12}>
                <PreviewPatient
                  patient={
                    patients.filter(obj => {
                      return obj.id === previewIndex;
                    })[0]
                  }
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default PatientsScreen;
