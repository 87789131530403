// import axios from "axios";
import axios from '../helpers/axiosConfig';

import {
  DENTIST_CLINICS_REQUEST,
  DENTIST_CLINICS_SUCCESS,
  DENTIST_CLINICS_FAIL,
  CLINIC_PROFILE_REQUEST,
  CLINIC_PROFILE_SUCCESS,
  CLINIC_PROFILE_FAIL,
  CLINIC_CREATE_REQUEST,
  CLINIC_CREATE_SUCCESS,
  CLINIC_CREATE_FAIL,
  CLINIC_CREATE_RESET,
  CLINIC_UPDATE_REQUEST,
  CLINIC_UPDATE_SUCCESS,
  CLINIC_UPDATE_FAIL,
  CLINIC_UPDATE_RESET,
} from '../constants/clinicsConstants';

export const listClinics = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DENTIST_CLINICS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`/api/users/profile/clinics/`, config);

    dispatch({
      type: DENTIST_CLINICS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DENTIST_CLINICS_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const clinicsDetails = id => async (dispatch, getState) => {
  try {
    dispatch({ type: CLINIC_PROFILE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      //`/api/users/profile/clinics/${id}`,
      `/api/users/profile/clinics/${id}`,
      config
    );

    dispatch({
      type: CLINIC_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLINIC_PROFILE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const updateClinics = clinic => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLINIC_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(`/api/users/profile/clinics/update/${clinic._id}`, clinic, config);

    dispatch({
      type: CLINIC_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLINIC_UPDATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const createClinics = newClinic => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLINIC_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(`/api/users/profile/clinics/addnew/`, newClinic, config);

    dispatch({
      type: CLINIC_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLINIC_CREATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};
