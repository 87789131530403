import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';

import Loader from '../../components/Loader';

import { listPatients } from '../../actions/patientsActions';
import { getDentistProfile } from '../../actions/userActions';
import { Modal } from 'react-bootstrap';
import { MdAccountCircle } from 'react-icons/md';
import { BsSearch } from 'react-icons/bs';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import './Contact.css';
import './Sidebar.css';
import './NewConversation.css';
import { ReactComponent as NewChat } from '../../asset/icons/NewChat.svg';
import { CURRENT_CHAT_PATIENT } from '../../constants/notificationsConstants';
import InviteModal from './InviteModal';

const UserResponse = {
  username: '',
  name: '',
  url: '',
};

export default function NewConversation() {
  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  // const alphabet = alpha.map((x) => String.fromCharCode(x));

  let location = useLocation();
  // let keyword = location.search
  // let keyword = ''
  const { t } = useTranslation('contentTrs');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState('');
  const [showModal, setShowModal] = useState(false);

  const { userInfo } = useSelector(state => state.userLogin);
  const { error, loading, patients, page, pages } = useSelector(state => state.patientsList);
  const {
    error: errorDentistProfile,
    loading: lodaingDentistProfile,
    dentist,
  } = useSelector(state => state.dentistProfile);

  useEffect(() => {
    let keyword_url = `?keyword=${keyword}&page=1`;
    dispatch(listPatients(keyword_url));
    dispatch(getDentistProfile());
  }, [dispatch, keyword]);

  const createConversationName = uuid => {
    return `${uuid}__${dentist?.u_uuid}`;
  };

  const startNewChat = patient => {
    setShowModal(false);
    let patientLoad = {};
    patientLoad.name = patient.first_name + ' ' + patient.last_name;
    patientLoad.profile_photo = patient.minimized_photo_url;
    dispatch({ type: CURRENT_CHAT_PATIENT, payload: patientLoad });
    navigate(`/conversations/${createConversationName(patient.u_uuid)}`);
  };

  return (
    <React.Fragment>
      <div onClick={() => setShowModal(true)}>
        {' '}
        <NewChat />{' '}
      </div>

      <Modal
        show={showModal}
        className="contact__modal_list new_chat_modal"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header className="contact__modal_header">
          <MdOutlineKeyboardBackspace
            className="back-arrow-btn"
            onClick={() => {
              setShowModal(false);
            }}
          />
          <Modal.Title className="contact__modal_header">{t('chat.newChat')}</Modal.Title>
        </Modal.Header>

        <div className="sidebar__search">
          <div className="sidebar__searchContainer">
            <IconButton size="medium" className="search-icon">
              <BsSearch />
            </IconButton>
            <input
              type="text"
              placeholder="Search"
              value={keyword}
              onChange={e => {
                setKeyword(e.target.value);
              }}
            />
          </div>
        </div>

        <Modal.Body className="contact__modal_body">
          <div className="alphabet-letter">A</div>
          {lodaingDentistProfile || loading ? (
            <Loader size="40px"></Loader>
          ) : (
            patients?.map(patient => (
              // <Link to={`/chats/${createConversationName(patient.u_uuid)}`} key={patient.uuid}>
              //     <div key={patient.uuid}>{patient.first_name + ' ' + patient.last_name} {patient.u_uuid ? ' (active)': ' (inactive)'}</div>
              // </Link>

              <div
                className={patient.u_uuid ? 'contact__modal_container' : 'contact__modal_container_inactive'}
                onClick={() => (patient.u_uuid ? startNewChat(patient) : {})}
                key={patient.uuid}
              >
                <div className="contact__modal_avatar">
                  {patient.minimized_photo_url ? (
                    <img src={patient.minimized_photo_url} />
                  ) : (
                    <MdAccountCircle color="#4F4F4F" size="30px" />
                  )}
                  {patient.u_uuid && <div className="green-dot"></div>}
                </div>
                <div className="contact__text">
                  <h2>
                    {' '}
                    {patient.first_name +
                      ' ' +
                      patient.last_name} {/* {patient.u_uuid ? ' (active)': ' (inactive)'} */}{' '}
                  </h2>
                  {!patient.u_uuid && <InviteModal patient={patient} />}
                </div>
              </div>
            ))
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
