import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Button, Row, Col, Card, Container } from 'react-bootstrap';

import Loader from '../components/Loader';
import Message from '../components/Message';
import InputGroup from '../components/InputGroup';

import FormContainer from '../components/FormContainer';

import { login } from '../actions/userActions';

// todo: core-ui CSS import
import '../scss/coreUI.scss';

function LoginScreen() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('contentTrs');
  const redirect = searchParams.get('redirect') ? searchParams.get('redirect') : '/';

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [userInfo, redirect]);

  const submitHandler = e => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <div className="tw-h-screen tw-flex tw-flex-row tw-items-center tw-justify-center c-app">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <Card className="px-0 py-4">
              <Card.Body>
                <FormContainer>
                  <h1>{t('authPages.login.title')}</h1>

                  {error && <Message variant="danger">{error}</Message>}
                  {loading && <Loader></Loader>}

                  <Form onSubmit={submitHandler} className="p-0">
                    <Form.Group controlId="email">
                      <Form.Label>{t('authPages.login.email')}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={t('authPages.login.fval_email')}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId="password">
                      <Form.Label>{t('authPages.login.password')}</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={t('authPages.login.fval_pass')}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Button type="submit" variant="primary">
                      {t('authPages.login.signIn_button')}
                    </Button>
                  </Form>

                  <Row className="py-3">
                    <Col>
                      {t('authPages.login.forgorPass')}{' '}
                      <Link to={redirect ? `/forgot_password` : '/forgot_password'}>
                        {' '}
                        {t('authPages.login.resetPass')}
                      </Link>
                    </Col>
                  </Row>

                  <Row className="py-1">
                    <Col>
                      {t('authPages.login.newCustomer')}{' '}
                      <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>
                        {t('authPages.login.register')}
                      </Link>
                    </Col>
                  </Row>

                  <Row className="py-1">
                    <Col>
                      {t('authPages.login.wantMoreInfos')}{' '}
                      <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>
                        {t('authPages.login.contactUs')}
                      </Link>
                    </Col>
                  </Row>
                </FormContainer>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LoginScreen;
