import { useTranslation } from 'react-i18next';

export const InviteInfo = ({ isInvitatioValid = false, clinicName }) => {
  const { t } = useTranslation('patientSignup');

  return (
    <div className="tw-flex tw-flex-col tw-items-center">
      {isInvitatioValid ? (
        <div className="tw-flex tw-items-center tw-justify-center tw-text-white tw-mb-3">
          <img
            src={'/statuses/verifiedStatus.svg'}
            alt="Valid Invitation"
            className="tw-w-12 tw-h-12 tw-object-cover tw-mr-3"
          />
          <h1 className="tw-text-2xl md:tw-text-4xl tw-font-bold">{t('Verified invitation')}</h1>
        </div>
      ) : (
        <div className="tw-flex tw-items-center tw-justify-center tw-text-white tw-mb-3">
          <img
            src={'/statuses/errorStatus.svg'}
            alt="Invalid Invitation"
            className="tw-w-12 tw-h-12 tw-object-cover tw-mb-3 tw-mr-3"
          />
          <h1 className="tw-text-2xl md:tw-text-4xl tw-font-bold">{t('invalid invitation')}</h1>
        </div>
      )}

      {clinicName && isInvitatioValid ? (
        <div className="tw-text-white tw-text-2xl md:tw-text-3xl tw-font-normal tw-text-center">
          {clinicName} {t('is inviting you to join Oral Check!')}
        </div>
      ) : (
        <div className="tw-text-white tw-text-2xl md:tw-text-3xl tw-font-normal tw-text-center">
          {t('looks like your link is invalid or expired, ask for a new invitation!')}
        </div>
      )}
    </div>
  );
};
