import { useState, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ReactComponent as CalendarIcon } from '../../../../asset/icons/Calendar.svg';
import './style.scss';
import axios from 'axios';

import { useSelector } from 'react-redux';

const DAY = {
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
  SUNDAY: 'sunday',
};

const initialTimeShedule = {
  [DAY.MONDAY]: {},
  [DAY.TUESDAY]: {},
  [DAY.WEDNESDAY]: {},
  [DAY.THURSDAY]: {},
  [DAY.FRIDAY]: {},
  [DAY.SATURDAY]: {},
  [DAY.SUNDAY]: {},
};

const SCHEDULE_TIME = {
  MORNING_FROM: 'morningFrom',
  MORNING_TO: 'morningTo',
  AFTERNOON_FROM: 'afternoonFrom',
  AFTERNOON_TO: 'afternoonTo',
};

function Day({ day, daySchedule, onChangeSchedule }) {
  const onChangeTimeInput = useCallback(
    e => {
      const value = e.target.value;
      const scheduledTimeId = e.target.id;
      onChangeSchedule({
        day,
        scheduledTimeId,
        value,
      });
    },
    [day, onChangeSchedule]
  );

  return (
    <Row className="row-schedule">
      <Col sm={true}>
        <span className="time-schedule-title">{day}</span>
      </Col>
      <Col sm={4} className="align-time-items">
        <input
          type="time"
          value={daySchedule[SCHEDULE_TIME.MORNING_FROM] ?? ''}
          id={SCHEDULE_TIME.MORNING_FROM}
          className="time-input"
          onChange={onChangeTimeInput}
        ></input>

        <span> - </span>
        <input
          type="time"
          value={daySchedule[SCHEDULE_TIME.MORNING_TO] ?? ''}
          id={SCHEDULE_TIME.MORNING_TO}
          className="time-input"
          onChange={onChangeTimeInput}
        ></input>
      </Col>
      <Col sm={4} className="align-time-items">
        <input
          type="time"
          value={daySchedule[SCHEDULE_TIME.AFTERNOON_FROM] ?? ''}
          id={SCHEDULE_TIME.AFTERNOON_FROM}
          className="time-input"
          onChange={onChangeTimeInput}
        ></input>
        <span> - </span>
        <input
          type="time"
          value={daySchedule[SCHEDULE_TIME.AFTERNOON_TO] ?? ''}
          id={SCHEDULE_TIME.AFTERNOON_TO}
          className="time-input"
          onChange={onChangeTimeInput}
        ></input>
      </Col>
    </Row>
  );
}

const CalendarPopup = ({ clinicId }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    fetchTimeSchedule(clinicId);
    setShow(true);
  };

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const [timeSchedule, setTimeSchedule] = useState(initialTimeShedule);

  const fetchTimeSchedule = async clinicId => {
    try {
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      const { data } = await axios.get(`/api/users/profile/clinics/${clinicId}/timetable/`, config);

      if (data.data != null) {
        const fetchedResponse = data.data;
        const adaptedTimeSchedule = Object.values(DAY).reduce((acc, day) => {
          const daySchedule = fetchedResponse[day];
          return {
            ...acc,
            [day]: {
              [SCHEDULE_TIME.MORNING_FROM]: daySchedule[0][0] ?? undefined,
              [SCHEDULE_TIME.MORNING_TO]: daySchedule[0][1] ?? undefined,
              [SCHEDULE_TIME.AFTERNOON_FROM]: daySchedule[1][0] ?? undefined,
              [SCHEDULE_TIME.AFTERNOON_TO]: daySchedule[1][1] ?? undefined,
            },
          };
        }, {});
        setTimeSchedule(adaptedTimeSchedule);
      } else {
        setTimeSchedule(initialTimeShedule);
      }
    } catch (error) {
      console.error(error);
      // setTimeSchedule(initialTimeShedule);
    }
  };

  const saveTimeTable = async () => {
    try {
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      const adaptedTimeSchedule = Object.keys(timeSchedule).reduce((acc, day) => {
        const daySchedule = timeSchedule[day];
        const morningSchedule = [
          daySchedule[SCHEDULE_TIME.MORNING_FROM] ?? null,
          daySchedule[SCHEDULE_TIME.MORNING_TO] ?? null,
        ];
        const afternoonSchedule = [
          daySchedule[SCHEDULE_TIME.AFTERNOON_FROM] ?? null,
          daySchedule[SCHEDULE_TIME.AFTERNOON_TO] ?? null,
        ];
        return {
          ...acc,
          [day]: [morningSchedule, afternoonSchedule],
        };
      }, {});

      const { data } = await axios.post(
        `/api/users/profile/clinics/${clinicId}/timetable/`,
        adaptedTimeSchedule,
        config
      );

      handleClose();
    } catch (error) {
      console.log(error);
      // setTimeSchedule(initialTimeShedule);
    }
  };

  const onChangeDaySchedule = useCallback(
    ({ day, scheduledTimeId, value }) => {
      setTimeSchedule(prevSchedule => ({
        ...prevSchedule,
        [day]: {
          ...prevSchedule[day],
          [scheduledTimeId]: value,
        },
      }));
    },
    [setTimeSchedule]
  );

  return (
    <>
      <Button className="timeScheduleIcon" onClick={handleShow}>
        <CalendarIcon />
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Clinic Weekly Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="row-schedule">
              <Col sm={true}></Col>
              <Col sm={4}>
                <Button variant="primary" className="form-button morning-btn" disabled>
                  Morning
                </Button>
              </Col>
              <Col sm={4}>
                <Button variant="primary" className="form-button afternoon-btn" disabled>
                  Afternoon
                </Button>
              </Col>
            </Row>
            <div>
              {Object.entries(timeSchedule).map(([day, daySchedule]) => (
                <Day key={day} day={day} daySchedule={daySchedule} onChangeSchedule={onChangeDaySchedule} />
              ))}
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="mx-auto form-button form-submit-btn" onClick={saveTimeTable}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CalendarPopup;
