import axios, { instanceBase } from '../helpers/axiosConfig';
import {
  REMOTE_VISITS_REQUEST,
  REMOTE_VISITS_SUCCESS,
  REMOTE_VISITS_FAIL,
  PHOTOS_VISITS_REQUEST,
  PHOTOS_VISITS_SUCCESS,
  PHOTOS_VISITS_FAIL,
  RESPOND_PHOTOS_REQUEST,
  RESPOND_PHOTOS_SUCCESS,
  RESPOND_PHOTOS_FAIL,
} from '../constants/remoteVisitsConstants';

export const fetchRemoteVisits = () => async (dispatch, getState) => {
  try {
    dispatch({ type: REMOTE_VISITS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get('/api/patients/visits_to_assess/', config);

    const sortedData = data.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    dispatch({
      type: REMOTE_VISITS_SUCCESS,
      payload: sortedData,
    });
  } catch (error) {
    dispatch({
      type: REMOTE_VISITS_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const fetchPhotosVisits =
  ({ patientId, visitId }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: PHOTOS_VISITS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      const { data } = await axios.get(`/api/patients/profile/${patientId}/visits_remote/${visitId}`, config);
      dispatch({
        type: PHOTOS_VISITS_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: PHOTOS_VISITS_FAIL,
        payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }
  };
export const respondPhotosVisits =
  ({ patientId, visitId, data }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: RESPOND_PHOTOS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      const res = await axios.post(`/api/patients/profile/${patientId}/visits_remote/${visitId}`, data, config);
      dispatch({
        type: RESPOND_PHOTOS_SUCCESS,
        payload: res.data.data,
      });
      if (data.needs_assesment == false) {
        dispatch(fetchRemoteVisits());
      }
    } catch (error) {
      dispatch({
        type: RESPOND_PHOTOS_FAIL,
        payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }
  };
