import React, { createContext, ReactNode, useContext, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useSelector, useDispatch } from 'react-redux';
import { wsURL } from '../helpers/wsConfig';

import { NOTIFICATION_CHAT } from '../constants/notificationsConstants';

const DefaultProps = {
  unreadMessageCount: 0,
  connectionStatus: 'Uninstantiated',
};

export function NotificationProvider({ children }) {
  const dispatch = useDispatch();
  const { userInfo } = useSelector(state => state.userLogin);
  const { unreadMessageCount } = useSelector(state => state.notificationsChat);

  const { readyState } = useWebSocket(userInfo ? `${wsURL}/notifications/` : null, {
    queryParams: {
      token: userInfo ? userInfo.access : '',
    },
    onOpen: () => {
      console.log('Connected to Notifications!');
    },
    onClose: () => {
      console.log('Disconnected from Notifications!');
    },
    onMessage: e => {
      const data = JSON.parse(e.data);
      switch (data.type) {
        case 'unread_count':
          dispatch({ type: NOTIFICATION_CHAT, payload: data });
          break;
        case 'new_message_notification':
          // data.unread_count = unreadMessageCount + 1
          dispatch({ type: NOTIFICATION_CHAT, payload: data });
          break;
        default:
          console.error('Unknown message type!');
          break;
      }
    },
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  return children;
}
