export const DIAGNOSIS_INFO_REQUEST = 'DIAGNOSIS_INFO_REQUEST';
export const DIAGNOSIS_INFO_SUCCESS = 'DIAGNOSIS_INFO_SUCCESS';
export const DIAGNOSIS_INFO_FAIL = 'DIAGNOSIS_INFO_FAIL';

export const DIAGNOSIS_DETAILS_REQUEST = 'DIAGNOSIS_DETAILS_REQUEST';
export const DIAGNOSIS_DETAILS_SUCCESS = 'DIAGNOSIS_DETAILS_SUCCESS';
export const DIAGNOSIS_DETAILS_FAIL = 'DIAGNOSIS_DETAILS_FAIL';

export const DIAGNOSIS_CREATE_REQUEST = 'DIAGNOSIS_CREATE_REQUEST';
export const DIAGNOSIS_CREATE_SUCCESS = 'DIAGNOSIS_CREATE_SUCCESS';
export const DIAGNOSIS_CREATE_FAIL = 'DIAGNOSIS_CREATE_FAIL';
export const DIAGNOSIS_CREATE_RESET = 'DIAGNOSIS_CREATE_FAIL';

export const DIAGNOSIS_UPDATE_REQUEST = 'DIAGNOSIS_UPDATE_REQUEST';
export const DIAGNOSIS_UPDATE_SUCCESS = 'DIAGNOSIS_UPDATE_SUCCESS';
export const DIAGNOSIS_UPDATE_FAIL = 'DIAGNOSIS_UPDATE_FAIL';
export const DIAGNOSIS_UPDATE_RESET = 'DIAGNOSIS_UPDATE_FAIL';
