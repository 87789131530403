import {
  PAY_SUBSCRIPTION_REQUEST,
  PAY_SUBSCRIPTION_SUCCESS,
  PAY_SUBSCRIPTION_FAIL,
} from '../constants/subscriptionConstants';

export const paySubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case PAY_SUBSCRIPTION_REQUEST:
      return { loading: true };

    case PAY_SUBSCRIPTION_SUCCESS:
      return { loading: false, success: true };

    case PAY_SUBSCRIPTION_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
