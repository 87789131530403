import { useState, useCallback } from 'react';

const useVisibilityToggle = initialState => {
  const [isVisible, setIsVisible] = useState(initialState);

  const show = useCallback(() => setIsVisible(true), [setIsVisible]);
  const hide = useCallback(() => setIsVisible(false), [setIsVisible]);

  const toggleVisibility = useCallback(() => setIsVisible(prevState => !prevState), [setIsVisible]);

  return {
    isVisible,
    show,
    hide,
    toggleVisibility,
  };
};

export { useVisibilityToggle };
