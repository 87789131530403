import React from 'react';
import { useTranslation } from 'react-i18next';

export const GetStatus = status => {
  const { t } = useTranslation('contentTrs');
  const { status_id } = status;
  console.log(status_id);

  switch (status_id) {
    case 0:
      return (
        <div className={'orangeStatus'}>
          <img src="/statuses/orangeStatus.svg" />
          {t('statuses.firstVisit')}
        </div>
      );
    case 1:
      return (
        <div className={'purpleStatus'}>
          <img src="/statuses/purpleStatus.svg" />
          {t('statuses.casePlaning')}
        </div>
      );
    case 2:
      return (
        <div className={'greenStatus'}>
          <img src="/statuses/greenStatus.svg" />
          {t('statuses.acceptedTreatment')}
        </div>
      );
    case 3:
      return (
        <div className={'blueStatus'}>
          <img src="/statuses/blueStatus.svg" />
          {t('statuses.treatment')}
        </div>
      );
    case 4:
      return (
        <div className={'grayStatus'}>
          <img src="/statuses/grayStatus.svg" />
          {t('statuses.finished')}
        </div>
      );
    case 5:
      return (
        <div className={'grayStatus'}>
          <img src="/statuses/grayStatus.svg" />
          {t('statuses.archived')}
        </div>
      );
    default:
      return (
        <div className={'grayStatus'}>
          <img src="/statuses/grayStatus.svg" />
          {t('statuses.archived')}
        </div>
      );
  }
};
