import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../../../helpers/axiosConfig';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import RecordField from '../../../../components/ImageComponent/RecordField';
import { TextField } from '@mui/material';

const CreatePost = ({ setBlogList }) => {
  const [show, setShow] = useState(false);
  const [titlePost, setTitlePost] = useState('Blog Article Title');
  const [contentPost, setContentPost] = useState('Article Text (max 300 char)');
  const [picturePost, setPicturePost] = useState('');
  const [loadedFile, setLoadedFile] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(null);

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const handleClose = () => {
    setLoadedFile(null);
    setFileDataURL(null);
    setShow(false);
    setTitlePost('Blog Article Title');
    setContentPost('Article Text (max 300 char)');
    setPicturePost('');
  };
  const handleShow = () => setShow(true);

  const addPost = async () => {
    try {
      let formData = new FormData();
      formData.append('title', titlePost);
      formData.append('body', contentPost);
      formData.append('is_public', false);

      if (loadedFile?.editedBlob) {
        let file = DataURIToBlob(loadedFile.uri);
        formData.append('image', file, loadedFile.type);
      } else {
        formData.append('image', loadedFile);
      }

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      let spaceId = '%20';
      let apiUrl = `/api/lookup/blogs/make/${spaceId}`;

      const { data } = await axios.post(apiUrl, formData, config);

      handleClose();
      setBlogList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const DataURIToBlob = dataURI => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  const loadPictureinBrowser = img_file => {
    setLoadedFile(img_file);
    let fileReader;
    fileReader = new FileReader();
    fileReader.onload = e => {
      const { result } = e.target;
      if (result) {
        setFileDataURL(result);
      }
    };
    fileReader.readAsDataURL(img_file);
  };

  return (
    <>
      <Button className="btn-dark" variant="primary" onClick={handleShow}>
        + Create New
      </Button>

      <Modal className="modal-box" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <input type="text" className="title-post" value={titlePost} onChange={e => setTitlePost(e.target.value)} />
        </Modal.Header>
        <Modal.Body>
          <div className="image-box">
            {
              <RecordField
                image={{
                  photo_url: fileDataURL,
                  minimized_photo_url: fileDataURL,
                }}
                editImg={loadPictureinBrowser}
                recordType={'blog'}
              />
            }
          </div>

          <TextField
            defaultValue={contentPost}
            multiline
            sx={{ m: 1, minWidth: '90%' }}
            variant="outlined"
            label="Blog"
            onChange={e => setContentPost(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className="baseButton" onClick={addPost}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CreatePost;
