import { SIGNUP_STEPS } from './signupSteps';

import { ClinicPatientVerificationForm } from '../components/ClinicPatientVerificationForm';
import { DateOfBirthVerificationForm } from '../components/DateOfBirthVerificationForm';
import { TermsAndConditionForm } from '../components/TermsAndConditionForm';
import { PasswordVerificationForm } from '../components/PasswordVerificationForm';
import { EmailVerificationForm } from '../components/EmailVerificationForm';

export const SIGNUP_STEPS_TO_COMPONENT = {
  [SIGNUP_STEPS.CLINIC_PATIENT_VERIFICATION]: ClinicPatientVerificationForm,
  [SIGNUP_STEPS.DATE_OF_BIRTH_VERIFICATION]: DateOfBirthVerificationForm,
  [SIGNUP_STEPS.TERMS_AND_CONDITION_VERIFICATION]: TermsAndConditionForm,
  [SIGNUP_STEPS.PASSWORD_VERIFICATION]: PasswordVerificationForm,
  [SIGNUP_STEPS.EMAIL_VERIFICATION]: EmailVerificationForm,
};
