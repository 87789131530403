import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../helpers/axiosConfig';
import { CardNumberElement } from '@stripe/react-stripe-js';

function PrivateRoute({ children }) {
  const navigate = useNavigate();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };

  let location = useLocation();

  const checkSucscriptionStatus = async e => {
    const { data } = await axios.get(`/api/payments/subscription_status/`, config);
  };

  if (!userInfo) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  // if (userInfo){

  //   checkSucscriptionStatus()

  // }

  return children;
}

export default PrivateRoute;
