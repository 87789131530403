import React from 'react';
import { Button, Row, Col, Container } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

const TermsOfService = props => {
  //const {intl} = props;
  const { t } = useTranslation('contentTrs');
  return (
    <Container className="mt-3">
      <Row>
        <Col>
          <Button
            className="baseButton"
            onClick={() => {
              window.open('/OrthoBook-TermsofService.pdf');
            }}
          >
            {t('settingsPage.termOfServices.termButton')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsOfService;
