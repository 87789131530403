import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Row, Col, Table, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';

import RecordField from '../../components/ImageComponent/RecordField';

import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

import { updateDiagnosis } from '../../actions/diagnosisActions';

function DiagnosisTab(props) {
  const { t: tabDiagnosisT } = useTranslation('tabDiagnosis');
  const { patientId, diagnosis } = props;

  const dispatch = useDispatch();

  const diagnosisUpdate = useSelector(state => state.diagnosisUpdate);
  const { error: errorUpdate, success: secussUpdate } = diagnosisUpdate;

  const [diagnosisForm, setDiagnosis] = useState({
    malloclusion_class: diagnosis.malloclusion_class,
    overbite: diagnosis.overbite,
    overbite_measure: diagnosis.overbite_measure,
    overjet: diagnosis.overjet,
    overjet_measure: diagnosis.overjet_measure,
    bite_right: diagnosis.bite_right,
    bite_left: diagnosis.bite_left,
    bite_anterior: diagnosis.bite_anterior,
    crowding_superior: diagnosis.crowding_superior,
    crowding_inferior: diagnosis.crowding_inferior,
    crowding_superior_measure: diagnosis.crowding_superior_measure,
    crowding_inferior_measure: diagnosis.crowding_inferior_measure,
    facial_simmetry: diagnosis.facial_simmetry,
    lips_comptence: diagnosis.lips_comptence,
    profile: diagnosis.profile,
    naso_labial_angle: diagnosis.naso_labial_angle,
  });

  // Init Toast Alert
  const [openSnack, setOpenSnack] = useState({
    open: false,
    displayElement: 'hidden',
    vertical: 'top',
    horizontal: 'center',
  });
  const [msg, setMsg] = useState(`Modifiche Salvate`);
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  // End Toast Alert

  useEffect(() => {
    // dispatch(getDiagnosis(patientId))
  }, [dispatch]);

  const submitUpdateDiagnosis = e => {
    e.preventDefault();

    dispatch(updateDiagnosis(patientId, diagnosisForm));
    setOpenSnack({ open: true, displayElement: 'visible' });
  };

  return (
    <div style={{ width: '98%' }}>
      <Row>
        <h5>{tabDiagnosisT('title')}</h5>
      </Row>
      {diagnosis && (
        <Form className="py-3" onSubmit={submitUpdateDiagnosis}>
          <Row>
            <b>{tabDiagnosisT('mallocclusion_row.title')}</b>
          </Row>
          <Form.Group
            controlId="malloclusionClass"
            className="p-4"
            style={{ backgroundColor: '#e6eded', borderRadius: '5px', marginRight: '3px', marginLeft: '3px' }}
          >
            {['radio'].map(type => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  label={tabDiagnosisT('mallocclusion_row.class1')}
                  name="group1"
                  type={type}
                  id={`occlusion-1`}
                  checked={diagnosisForm.malloclusion_class === 0}
                  value={0}
                  onChange={e => {
                    setDiagnosis({ ...diagnosisForm, malloclusion_class: 0 });
                  }}
                />
                <Form.Check
                  label={tabDiagnosisT('mallocclusion_row.class2_1')}
                  name="group1"
                  type={type}
                  id={`occlusion-21`}
                  value={1}
                  checked={diagnosisForm.malloclusion_class === 1}
                  //onChange={(e) => setMalloclusionClass(e.target.value)}
                  onChange={(
                    e //setMalloclusionClass(e.target.value)
                  ) => setDiagnosis({ ...diagnosis, malloclusion_class: 1 })}
                />
                <Form.Check
                  label={tabDiagnosisT('mallocclusion_row.class2_2')}
                  name="group1"
                  type={type}
                  id={`occlusion-22`}
                  value={2}
                  checked={diagnosisForm.malloclusion_class === 2}
                  //onChange={(e) => setMalloclusionClass(e.target.value)}
                  onChange={(
                    e //setMalloclusionClass(e.target.value)
                  ) => setDiagnosis({ ...diagnosisForm, malloclusion_class: 2 })}
                />
                <Form.Check
                  label={tabDiagnosisT('mallocclusion_row.class3')}
                  name="group1"
                  type={type}
                  id={`occlusion-3`}
                  value={3}
                  checked={diagnosisForm.malloclusion_class === 3}
                  //onChange={(e) => setMalloclusionClass(e.target.value)}
                  onChange={(
                    e //setMalloclusionClass(e.target.value)
                  ) => setDiagnosis({ ...diagnosisForm, malloclusion_class: 3 })}
                />
              </div>
            ))}
          </Form.Group>

          <Form.Label className="mt-3">
            <b>{tabDiagnosisT('bite_row.title')}</b>
          </Form.Label>

          <Form.Group
            controlId="Bite"
            className="pt-2"
            style={{ backgroundColor: '#e6eded', borderRadius: '5px', marginRight: '3px', marginLeft: '3px' }}
          >
            <Table responsive className="text-center ">
              <thead>
                <tr>
                  <th> </th>
                  <th>{tabDiagnosisT('bite_row.right')}</th>
                  <th>{tabDiagnosisT('bite_row.anterior')}</th>
                  <th>{tabDiagnosisT('bite_row.left')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-start">{tabDiagnosisT('bite_row.normal')}</td>
                  <td>
                    <Form.Check
                      label=""
                      name="groupRight"
                      type={'radio'}
                      id={`R-1`}
                      value={0}
                      checked={diagnosisForm.bite_right === 0}
                      onChange={e => setDiagnosis({ ...diagnosisForm, bite_right: 0 })}
                    />
                  </td>
                  <td>
                    <Form.Check
                      label=""
                      name="groupAnteriorBite"
                      type={'radio'}
                      id={`A-1`}
                      value={0}
                      checked={diagnosisForm.bite_anterior === 0}
                      onChange={e => setDiagnosis({ ...diagnosisForm, bite_anterior: 0 })}
                    />
                  </td>
                  <td>
                    <Form.Check
                      label=""
                      name="groupLeftBite"
                      type={'radio'}
                      id={`L-1`}
                      value={0}
                      checked={diagnosisForm.bite_left === 0}
                      onChange={e => setDiagnosis({ ...diagnosisForm, bite_left: 0 })}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-start">{tabDiagnosisT('bite_row.cross')}</td>
                  <td>
                    <Form.Check
                      label=""
                      name="groupRight"
                      type={'radio'}
                      id={`R-21`}
                      value={1}
                      checked={diagnosisForm.bite_right === 1}
                      onChange={e => setDiagnosis({ ...diagnosisForm, bite_right: 1 })}
                    />
                  </td>
                  <td>
                    <Form.Check
                      label=""
                      name="groupAnteriorBite"
                      type={'radio'}
                      id={`A-21`}
                      value={1}
                      checked={diagnosisForm.bite_anterior === 1}
                      onChange={e => setDiagnosis({ ...diagnosisForm, bite_anterior: 1 })}
                    />
                  </td>
                  <td>
                    <Form.Check
                      label=""
                      name="groupLeftBite"
                      type={'radio'}
                      id={`L-21`}
                      value={1}
                      checked={diagnosisForm.bite_left === 1}
                      onChange={e => setDiagnosis({ ...diagnosisForm, bite_left: 1 })}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-start">{tabDiagnosisT('bite_row.scissor')}</td>
                  <td>
                    <Form.Check
                      label=""
                      name="groupRight"
                      type={'radio'}
                      id={`R-22`}
                      value={2}
                      checked={diagnosisForm.bite_right === 2}
                      onChange={e => setDiagnosis({ ...diagnosisForm, bite_right: 2 })}
                    />
                  </td>
                  <td></td>
                  <td>
                    <Form.Check
                      label=""
                      name="groupLeftBite"
                      type={'radio'}
                      id={`L-22`}
                      value={2}
                      checked={diagnosisForm.bite_left === 2}
                      onChange={e => setDiagnosis({ ...diagnosisForm, bite_left: 2 })}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Form.Group>

          <Form.Group controlId="OVB and OVJ" className="pt-2 mt-3">
            <Form.Label>
              <b>{tabDiagnosisT('ovb_ovj_row.title')}</b>
            </Form.Label>
            <Row className="">
              <Col>
                <div inline="true" className="pr-2">
                  {tabDiagnosisT('ovb_ovj_row.ovb.title')}
                </div>
              </Col>
            </Row>
            <Row
              className="p-2"
              style={{ backgroundColor: '#e6eded', borderRadius: '5px', marginRight: '3px', marginLeft: '3px' }}
            >
              <Col>
                <Form.Check
                  inline
                  label={tabDiagnosisT('ovb_ovj_row.ovb.normal')}
                  name="groupOVB"
                  type={'radio'}
                  id={`OVB-1`}
                  value={0}
                  checked={diagnosisForm.overbite === 0}
                  onChange={e => setDiagnosis({ ...diagnosisForm, overbite: 0 })}
                />
              </Col>
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('ovb_ovj_row.ovb.open')}
                  name="groupOVB"
                  type={'radio'}
                  id={`OVB-2`}
                  value={1}
                  checked={diagnosisForm.overbite === 1}
                  onChange={e => setDiagnosis({ ...diagnosisForm, overbite: 1 })}
                />
              </Col>
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('ovb_ovj_row.ovb.deep')}
                  name="groupOVB"
                  type={'radio'}
                  id={`OVB-3`}
                  value={2}
                  checked={diagnosisForm.overbite === 2}
                  onChange={e => setDiagnosis({ ...diagnosisForm, overbite: 2 })}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Form.Label column>{tabDiagnosisT('ovb_ovj_row.ovb.measure')}</Form.Label>
              <Col sm={8}>
                <Form.Control
                  size="sm"
                  type="number"
                  step=".01"
                  placeholder={tabDiagnosisT('ovb_ovj_row.ovb.fVal_unity')}
                  value={diagnosisForm.overbite_measure === null ? '' : diagnosisForm.overbite_measure}
                  //onChange={(e) => setOverbiteMeasure(e.target.value)}
                  onChange={e => setDiagnosis({ ...diagnosisForm, overbite_measure: e.target.value })}
                ></Form.Control>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                <div inline="true">{tabDiagnosisT('ovb_ovj_row.ovg.title')}</div>
              </Col>
            </Row>
            <Row
              className="p-2 mb-3"
              style={{ backgroundColor: '#e6eded', borderRadius: '5px', marginRight: '3px', marginLeft: '3px' }}
            >
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('ovb_ovj_row.ovg.normal')}
                  name="groupOVJ"
                  type={'radio'}
                  id={`OVJ-1`}
                  value={0}
                  checked={diagnosisForm.overjet === 0}
                  onChange={e => setDiagnosis({ ...diagnosisForm, overjet: 0 })}
                />
              </Col>
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('ovb_ovj_row.ovg.reduced')}
                  name="groupOVJ"
                  type={'radio'}
                  id={`OVJ-2`}
                  value={1}
                  checked={diagnosisForm.overjet === 1}
                  onChange={e => setDiagnosis({ ...diagnosisForm, overjet: 1 })}
                />
              </Col>
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('ovb_ovj_row.ovg.increased')}
                  name="groupOVJ"
                  type={'radio'}
                  id={`OVJ-3`}
                  value={2}
                  checked={diagnosisForm.overjet === 2}
                  onChange={e => setDiagnosis({ ...diagnosisForm, overjet: 2 })}
                />
              </Col>
            </Row>
            <Row>
              <Form.Label column>measure (mm)</Form.Label>
              <Col sm={8}>
                <Form.Control
                  size="sm"
                  type="number"
                  step=".01"
                  placeholder="(mm)"
                  value={diagnosisForm.overjet_measure === null ? '' : diagnosisForm.overjet_measure}
                  onChange={e => setDiagnosis({ ...diagnosisForm, overjet_measure: e.target.value })}
                ></Form.Control>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group controlId="Crowding" className="pt-2 mt-3">
            <Form.Label>
              <b>{tabDiagnosisT('crowding_row.title')}</b>
            </Form.Label>
            <Row>
              <Col sm={4} md={4} lg={4}>
                <div inline="true" className="pr-2">
                  {tabDiagnosisT('crowding_row.superior.title')}
                </div>
              </Col>
            </Row>
            <Row
              className="p-2"
              style={{ backgroundColor: '#e6eded', borderRadius: '5px', marginRight: '3px', marginLeft: '3px' }}
            >
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('crowding_row.superior.yes')}
                  name="groupCrSup"
                  type={'radio'}
                  id={`CrSup-1`}
                  value={true}
                  checked={diagnosisForm.crowding_superior === true}
                  onChange={e => setDiagnosis({ ...diagnosisForm, crowding_superior: true })}
                />
              </Col>
              <Col sm={1} md={1} lg={1}>
                |
              </Col>
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('crowding_row.superior.no')}
                  name="groupCrSup"
                  type={'radio'}
                  id={`CrSup-2`}
                  value={false}
                  checked={diagnosisForm.crowding_superior === false}
                  onChange={e => setDiagnosis({ ...diagnosisForm, crowding_superior: false })}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Form.Label column>{tabDiagnosisT('crowding_row.superior.measure')}</Form.Label>
              <Col sm={8}>
                <Form.Control
                  size="sm"
                  type="number"
                  step=".01"
                  placeholder={tabDiagnosisT('crowding_row.superior.fVal_unity')}
                  value={
                    diagnosisForm.crowding_superior_measure === null ? '' : diagnosisForm.crowding_superior_measure
                  }
                  onChange={e => setDiagnosis({ ...diagnosisForm, crowding_superior_measure: e.target.value })}
                ></Form.Control>
              </Col>
            </Row>
            <Col className="mt-3">
              <div inline="true" className="pr-2">
                {tabDiagnosisT('crowding_row.inferior.title')}
              </div>
            </Col>
            <Row
              className="p-2"
              style={{ backgroundColor: '#e6eded', borderRadius: '5px', marginRight: '3px', marginLeft: '3px' }}
            >
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('crowding_row.inferior.yes')}
                  name="groupCrInf"
                  type={'radio'}
                  id={`CrInf-1`}
                  value={true}
                  checked={diagnosisForm.crowding_inferior === true}
                  onChange={e => setDiagnosis({ ...diagnosisForm, crowding_inferior: true })}
                />
              </Col>
              <Col sm={1} md={1} lg={1}>
                |
              </Col>
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('crowding_row.inferior.no')}
                  name="groupCrInf"
                  type={'radio'}
                  id={`CrInf-2`}
                  value={false}
                  checked={diagnosisForm.crowding_inferior === false}
                  onChange={e => setDiagnosis({ ...diagnosisForm, crowding_inferior: false })}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Form.Label column>{tabDiagnosisT('crowding_row.inferior.measure')}</Form.Label>
              <Col sm={8}>
                <Form.Control
                  size="sm"
                  type="number"
                  step=".01"
                  placeholder={tabDiagnosisT('crowding_row.inferior.fVal_unity')}
                  value={
                    diagnosisForm.crowding_inferior_measure === null ? '' : diagnosisForm.crowding_inferior_measure
                  }
                  onChange={e => setDiagnosis({ ...diagnosisForm, crowding_inferior_measure: e.target.value })}
                ></Form.Control>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group controlId="Simmetry" className="pt-2 mt-3">
            <Form.Label>
              <b>{tabDiagnosisT('facial_row.title')}</b>
            </Form.Label>

            <Row
              className="p-2"
              style={{ backgroundColor: '#e6eded', borderRadius: '5px', marginRight: '3px', marginLeft: '3px' }}
            >
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('facial_row.simetric')}
                  name="groupSimmetry"
                  type={'radio'}
                  id={`Simmetry-1`}
                  value={false}
                  checked={diagnosisForm.facial_simmetry === false}
                  onChange={e => setDiagnosis({ ...diagnosisForm, facial_simmetry: false })}
                />
              </Col>
              <Col sm={1} md={1} lg={1}>
                |
              </Col>
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('facial_row.asimmetric')}
                  name="groupSimmetry"
                  type={'radio'}
                  id={`Simmetry-2`}
                  value={true}
                  checked={diagnosisForm.facial_simmetry === true}
                  onChange={e => setDiagnosis({ ...diagnosisForm, facial_simmetry: true })}
                />
              </Col>
            </Row>
          </Form.Group>

          <Form.Group controlId="LipsCompetence" className="pt-2 mt-3">
            <Form.Label>
              <b>{tabDiagnosisT('lipsC_row.title')}</b>
            </Form.Label>

            <Row
              className="p-2"
              style={{ backgroundColor: '#e6eded', borderRadius: '5px', marginRight: '3px', marginLeft: '3px' }}
            >
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('lipsC_row.competent')}
                  name="groupCompetence"
                  type={'radio'}
                  id={`Competence-1`}
                  value={false}
                  checked={diagnosisForm.lips_comptence === false}
                  onChange={e => setDiagnosis({ ...diagnosisForm, lips_comptence: false })}
                />
              </Col>
              <Col sm={1} md={1} lg={1}>
                |
              </Col>
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('lipsC_row.incompetent')}
                  name="groupCompetence"
                  type={'radio'}
                  id={`Competence-2`}
                  value={true}
                  checked={diagnosisForm.lips_comptence === true}
                  onChange={e => setDiagnosis({ ...diagnosisForm, lips_comptence: true })}
                />
              </Col>
            </Row>
          </Form.Group>

          <Form.Group controlId="Profile" className="pt-2 mt-3">
            <Form.Label>
              <b>{tabDiagnosisT('profile_row.title')}</b>
            </Form.Label>
            <Row>
              <div className="pr-2">{tabDiagnosisT('profile_row.profile.title')}</div>
            </Row>
            <Row
              className="p-2 mb-3"
              style={{ backgroundColor: '#e6eded', borderRadius: '5px', marginRight: '3px', marginLeft: '3px' }}
            >
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('profile_row.profile.normal')}
                  name="groupProfile"
                  type={'radio'}
                  id={`Profile-1`}
                  value={0}
                  checked={diagnosisForm.profile === 0}
                  onChange={e => setDiagnosis({ ...diagnosisForm, profile: 0 })}
                />
              </Col>
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('profile_row.profile.concave')}
                  name="groupProfile"
                  type={'radio'}
                  id={`Profile-2`}
                  value={1}
                  checked={diagnosisForm.profile === 1}
                  onChange={e => setDiagnosis({ ...diagnosisForm, profile: 1 })}
                />
              </Col>
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('profile_row.profile.convex')}
                  name="groupProfile"
                  type={'radio'}
                  id={`Profile-3`}
                  value={2}
                  checked={diagnosisForm.profile === 2}
                  onChange={e => setDiagnosis({ ...diagnosisForm, profile: 2 })}
                />
              </Col>
            </Row>
            <Row>
              <div className="pr-2">{tabDiagnosisT('profile_row.nasoL_angle_row.title')}</div>
            </Row>
            <Row
              className="p-2 mb-3"
              style={{ backgroundColor: '#e6eded', borderRadius: '5px', marginRight: '3px', marginLeft: '3px' }}
            >
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('profile_row.nasoL_angle_row.normal')}
                  name="groupNLAngle"
                  type={'radio'}
                  id={`NLAngle-1`}
                  value={0}
                  checked={diagnosisForm.naso_labial_angle === 0}
                  onChange={e => setDiagnosis({ ...diagnosisForm, naso_labial_angle: 0 })}
                />
              </Col>
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('profile_row.nasoL_angle_row.open')}
                  name="groupNLAngle"
                  type={'radio'}
                  id={`NLAngle-2`}
                  value={1}
                  checked={diagnosisForm.naso_labial_angle === 1}
                  onChange={e => setDiagnosis({ ...diagnosisForm, naso_labial_angle: 1 })}
                />
              </Col>
              <Col>
                <Form.Check
                  inline="true"
                  label={tabDiagnosisT('profile_row.nasoL_angle_row.closed')}
                  name="groupNLAngle"
                  type={'radio'}
                  id={`NLAngle-3`}
                  value={2}
                  checked={diagnosisForm.naso_labial_angle === 2}
                  onChange={e => setDiagnosis({ ...diagnosisForm, naso_labial_angle: 2 })}
                />
              </Col>
            </Row>
          </Form.Group>

          <Button type="submit" variant="primary" className="form-button text-end">
            {tabDiagnosisT('update')}
          </Button>
        </Form>
      )}

      <Snackbar
        style={{ visibility: openSnack.displayElement }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          {' '}
          {msg}{' '}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default DiagnosisTab;
