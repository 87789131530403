import React, { useEffect, useState } from 'react';
import axios from '../../../../helpers/axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { makeFirstLatterUp, makePrice, dateToYMD, dateToDMY } from '../../../../helpers';
import CardForm from '../../../../components/stripe/CardForm';
import ApiService from '../../../../components/stripe/stripeApi';

import Message from '../../../../components/Message';

import { Form, Button, Row, Col, Card } from 'react-bootstrap';

const Subscription = props => {
  const { t } = useTranslation('contentTrs');
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  let i = 0;

  // const {dentistData} = useSelector(state => state.dentist)
  const [invoices, setInvoices] = useState([]);
  const [disabled, setDisabled] = useState(false);
  // const [editCard, setEditCard] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  const [card, setCard] = useState('');
  const [updatedCard, setUpdatedCard] = useState(false);
  const [newCard, setNewCard] = useState(false);

  const userLogin = useSelector(state => state.userLogin);

  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };

  const checkSucscriptionStatus = async e => {
    try {
      const { data } = await axios.get(`/api/payments/subscription_status/`, config);

      if (data.status) {
        setSubscriptionStatus(data.status);

        return data;
      } else {
        return false;
      }
    } catch (e) {}
  };

  const listInvoices = async e => {
    try {
      const { data } = await axios.get(`/api/payments/invoices/`, config);
      setInvoices(Object.values(data)[0]);
    } catch (e) {}
  };

  useEffect(() => {
    checkSucscriptionStatus();
  }, []);

  useEffect(() => {
    listInvoices();
  }, []);

  const handleSubmitCard = async event => {
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: card,
    });

    ApiService.saveStripeInfo({
      payment_method_id: paymentMethod.id,
    })
      .then(response => {
        setCard(paymentMethod.card);
        setNewCard(true);
      })
      .catch(error => {});
  };

  const handleCancelSubscription = async (e, action) => {
    ApiService.CancelSubscription({ action })
      .then(response => {
        setSubscriptionStatus(response.data.subscription);
      })
      .catch(error => {});
  };

  return (
    <Row>
      <Row className="justify-content-center mt-3">
        <Col sm={6} className=" p-2">
          <Row className="justify-content-center mt-3">
            <Col sm={12} md={12} lg={12} className=" p-1">
              <CardForm
                card={card}
                setCard={setCard}
                updatedCard={updatedCard}
                setUpdatedCard={setUpdatedCard}
                handleSubmitCard={handleSubmitCard}
              />
              {updatedCard && newCard && (
                <Message variant="success"> {t('settingsPage.suscription.cardUpdated')}</Message>
              )}
            </Col>
          </Row>
          {subscriptionStatus == 'active' && (
            <Button disabled={disabled} variant="danger" onClick={e => handleCancelSubscription(e, 'cancel')}>
              {t('settingsPage.suscription.cancelSubscription')}
            </Button>
          )}
          {subscriptionStatus == 'ending' && (
            <Button disabled={disabled} variant="info" onClick={e => handleCancelSubscription(e, 'reactivate')}>
              {t('settingsPage.suscription.reactivateSubscription')}
            </Button>
          )}
        </Col>
        <Col sm={6} className=" p-2 ">
          <Row>
            <Col sm={6} className=" pt-2 px-2 pb-0">
              <p>{t('settingsPage.suscription.invoice')}</p>
            </Col>

            {invoices
              ? invoices.map(invoice => {
                  console.log(invoice);
                  console.log(invoice.period);
                  console.log(invoice.created);
                  i = i + 1;
                  return (
                    <React.Fragment key={invoice.number + i}>
                      <div className="border p-3 my-2">
                        <div>
                          {t('settingsPage.suscription.data')}: {dateToDMY(new Date(invoice.created * 1000))}
                        </div>
                        <div>
                          {t('settingsPage.suscription.state.title')}: {t('settingsPage.suscription.state.payed')}
                        </div>
                        <div className="align-items-center flex">
                          {t('settingsPage.suscription.totalAmound')}: €{makePrice(invoice.total)}
                        </div>
                        <div>
                          <a href={invoice.invoice_pdf}>{t('settingsPage.suscription.downloadInvoice')}</a>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })
              : null}
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

export default Subscription;
