import { useMutation } from '@tanstack/react-query';

import instanceBase from '../../../helpers/axiosConfig';
// import axios from 'axios';

const usePatientLoginMutuation = () => {
  // Correctly set up the mutation with a configuration object
  const mutation = useMutation({
    mutationFn: async ({ email, password }) => {
      const payload = {
        email,
        password,
      };
      // Directly return the promise from axios.post
      const { data } = await instanceBase.post('/api/remote/patient/auth/login/', payload);
      return data;
    },
  });

  // Return the mutation object to use it in your component
  return mutation;
};

export { usePatientLoginMutuation };
