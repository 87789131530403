//lib
import React from 'react';

//components
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

//hooks
import { useSelector } from 'react-redux';
import { useVisibilityToggle } from '../../hooks/useVisibilityToggle';

//helpers
import { dateToHmDMY } from '../../helpers';

//css
import './Chat.css';

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const previewModalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  height: '80vh',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

export function Message({ message }) {
  const {
    isVisible: isPreviewModalVisible,
    show: showPreviewModal,
    hide: closePreviewModal,
  } = useVisibilityToggle(false);
  const { userInfo } = useSelector(state => state.userLogin);
  const {
    error: errorDentistProfile,
    loading: lodaingDentistProfile,
    dentist,
  } = useSelector(state => state.dentistProfile);

  const formatMessageTimestamp = timestamp => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString().slice(0, 5);
  };

  const hasAttachment = !!message.attachment_url;

  return (
    <React.Fragment>
      <div
        className={
          message.to_user.uuid === dentist?.u_uuid ? 'chat__message chat__messageIn' : 'chat__message chat__messageOut '
        }
      >
        <div className="chat__messageCotainer">
          {hasAttachment ? (
            <img
              src={message.attachment_url.url}
              alt="message media"
              style={{ maxWidth: '400px', cursor: 'pointer' }}
              onClick={showPreviewModal}
            />
          ) : null}
          {message.content ? <span className="chat__messageContent">{message.content}</span> : null}
          <span className="chat__messageTime">{dateToHmDMY(message.timestamp)}</span>
        </div>
      </div>
      {hasAttachment ? (
        <Modal open={isPreviewModalVisible} onClose={closePreviewModal} aria-labelledby="attachment-preview-modal">
          <Box sx={previewModalStyle}>
            <img src={message.attachment_url.url} alt="message media" style={{ maxWidth: '100%', maxHeight: '100%' }} />
          </Box>
        </Modal>
      ) : null}
    </React.Fragment>
  );
}
