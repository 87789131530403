import { useMutation } from '@tanstack/react-query';

import axios from '../../../helpers/axiosConfig';

const usePatientAuthQuery = () => {
  // Correctly set up the mutation with a configuration object
  const mutation = useMutation({
    mutationFn: async ({
      uidb64,
      token,
      patient_code,
      email,
      password,
      compliance_data,
      first_name,
      last_name,
      birth_date,
    }) => {
      const payload = {
        uidb64,
        token,
        patient_code,
        ...(email && { email }), // Include email only if it's truthy
        ...(password && { password }), // Include password only if it's truthy
        ...(first_name && { first_name }), // Include first_name only if it's truthy
        ...(last_name && { last_name }), // Include last_name only if it's truthy
        ...(birth_date && { birth_date }), // Include birth_date only if it's truthy
        ...(compliance_data && {
          // Conditionally include compliance_data
          compliance: {
            accepted_terms_and_conditions: true,
            terms_and_conditions_version: 'v1-2024-01-30',
            accepted_privacy_policy: true,
            privacy_policy_version: 'v1-2024-01-30',
          },
        }),
      };

      // Directly return the promise from axios.post
      const { data } = await axios.post('api/remote/patient/auth/parents/familyinvitationsignup/', payload);
      return data;
    },
  });

  // Return the mutation object to use it in your component
  return mutation;
};

export { usePatientAuthQuery };
