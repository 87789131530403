// hooks
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
// components
import Loader from '../../../components/Loader';
import { InviteInfo } from './InviteInfo';
import { FormInput } from '../../../components/FormInput';

const ParentRegisterForm = ({
  verifiedState,
  onNext: _onSubmit,
  isLoading: isSubmitInProgress,
  error: submitError,
}) => {
  const { t } = useTranslation('patientSignup');
  const [
    { uidb64, token, patient_code, email, password, confirmPassword, first_name, last_name, birth_date },
    setState,
  ] = useState(verifiedState);
  let data = verifiedState.data;
  let isSuccess = data?.valid_invitation;
  let isError = data?.data?.valid_invitation == false;

  const onFieldChange = useCallback((value, fieldId) => {
    setState(prevState => ({ ...prevState, [fieldId]: value }));
    submitError[fieldId] = '';
  }, []);

  const onSubmit = () => {
    _onSubmit({ uidb64, token, patient_code, email, password, confirmPassword, first_name, last_name, birth_date });
  };

  let headerEl;
  if (isError) {
    headerEl = <InviteInfo clinicName="Orthobook" />;
  } else {
    headerEl = isSuccess && (
      <InviteInfo
        isInvitatioValid={data?.valid_invitation}
        clinicName={data['inviter'].first_name + ' ' + data['inviter'].last_name}
      />
    );
  }

  return (
    <div
      className="tw-flex tw-flex-col tw-w-full tw-h-full tw-items-center tw-justify-between tw-py-12 md:tw-pb-36 tw-mx-12"
      style={{ maxWidth: '440px' }}
    >
      <div>{headerEl}</div>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full">
        <FormInput
          id="first_name"
          type="text"
          value={first_name ? first_name : null}
          label={t('First Name')}
          error={submitError.first_name}
          onChange={onFieldChange}
          required={true}
        />
        <FormInput
          id="last_name"
          type="text"
          value={last_name ? last_name : null}
          label={t('Last Name')}
          error={submitError.last_name}
          onChange={onFieldChange}
          required={true}
        />
        <FormInput
          id="birth_date"
          type="date"
          value={birth_date}
          label={t('Date of Birth')}
          error={submitError.birth_date}
          onChange={onFieldChange}
          required={true}
        />
        <FormInput
          id="email"
          type="text"
          value={email}
          label={t('Email')}
          error={submitError.email}
          onChange={onFieldChange}
          required={true}
        />
        <FormInput
          id="password"
          type="password"
          value={password}
          label={t('Password')}
          error={submitError.password}
          onChange={onFieldChange}
          required={true}
        />
        <FormInput
          id="confirmPassword"
          type="password"
          value={confirmPassword}
          label={t('Confirm Password')}
          error={submitError.confirmPassword}
          onChange={onFieldChange}
          required={true}
        />
        <div className="tw-flex tw-flex-row tw-items-center tw-h-5 tw-mt-3">
          <input
            id="remember"
            type="checkbox"
            value={true}
            checked={true}
            disabled={true}
            className="tw-w-4 tw-h-4 tw-border tw-border-blue-600 tw-rounded tw-flex-none focus:tw-ring-3 focus:tw-ring-blue-300 tw-cursor-pointer"
            required
          />
          <label htmlFor="remember" className="tw-ms-2 tw-text-sm tw-font-medium tw-text-gray-900">
            Accepted{' '}
            <a
              href="https://www.tiecast.co.uk/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="tw-text-blue-600 hover:tw-underline"
            >
              privacy policy
            </a>
            {'.'}
          </label>
        </div>

        {submitError.querryError && <Alert severity="error">{submitError.querryError}</Alert>}

        <button
          className="tw-w-full tw-bg-lightBlue hover:tw-bg-darkBlue tw-py-6 tw-rounded-20 tw-text-white tw-transition-colors"
          onClick={onSubmit}
          disabled={isSubmitInProgress}
        >
          {isSubmitInProgress ? <Loader size="20px" /> : t('Register')}
        </button>
      </div>
    </div>
  );
};

export { ParentRegisterForm };
