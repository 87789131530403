// import axios from "axios";
import axiosInterceptor from '../../helpers/axiosConfig';
import { useSelector } from 'react-redux';

const getConfig = () => {
  let authTokens = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${authTokens?.access}`,
    },
  };
  return config;
};

export default class ApiService {
  static saveStripeInfo(data = {}) {
    let config = getConfig();
    return axiosInterceptor.post(`/api/payments/save-stripe-info/`, data, config);
  }
  static RetrivePayentMethod() {
    let config = getConfig();
    return axiosInterceptor.get(`/api/payments/retrive_payment_method/`, config);
  }
  static RetriveCoupon(data = {}) {
    let config = getConfig();
    return axiosInterceptor.post(`/api/payments/retrive_coupon/`, data, config);
  }
  static RetriveQuote() {
    let config = getConfig();
    return axiosInterceptor.get(`/api/payments/retrive_subscription/`, config);
  }
  static makeQuote(data = {}) {
    let config = getConfig();
    return axiosInterceptor.post(`/api/payments/quote_subscrption/`, data, config);
  }
  static PaySubscription(data = {}) {
    let config = getConfig();
    return axiosInterceptor.post(`/api/payments/start_subscrption/`, data, config);
  }
  static CancelSubscription(data = {}) {
    let config = getConfig();
    return axiosInterceptor.post(`/api/payments/cancel/`, data, config);
  }
  static listInvoices() {
    let config = getConfig();
    return axiosInterceptor.get(`/api/payments/invoices/`, config);
  }
}
