// hooks
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

// helpers
import axios from '../helpers/axiosConfig';

// components
import Loader from '../components/Loader';

const useValidateEmailQuery = ({ uuId, token }) =>
  useQuery({
    queryKey: ['email-validation', uuId, token],
    queryFn: async () => {
      const response = await axios.get(`api/validate-email/${uuId}/${token}/`);
      return response.data;
    },
  });

const AuthScreen = () => {
  const navigate = useNavigate();
  const { token, uuId } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('patientSignup');

  const redirectUrl = searchParams.get('redirect_url') ? searchParams.get('redirect_url') : '/';

  const { isSuccess, isError, isLoading } = useValidateEmailQuery({ token, uuId });

  useEffect(() => {
    if (isSuccess && !isLoading && !isError) {
      navigate(redirectUrl);
    }
  }, [isSuccess, isLoading, isError, redirectUrl, navigate]);

  if (isLoading) {
    return (
      <div className="tw-h-screen tw-w-screen tw-flex tw-justify-center tw-items-center">
        <Loader />
      </div>
    );
  }

  if (isError) {
    return (
      <h1 className="tw-h-screen tw-w-screen tw-text-center tw-text-red tw-flex tw-justify-center tw-items-center">
        {t('Error occured while validating email, please try again!')}
      </h1>
    );
  }

  return null;
};

export { AuthScreen };
