import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { FiChevronRight } from 'react-icons/fi';

function ClinicalFolderCard(props) {
  const { t } = useTranslation('contentTrs');
  const { t: tabDiagnosisT } = useTranslation('tabDiagnosis');
  const { step, setStep } = props;

  return (
    <div>
      <Card className="px-2 py-1 mb-2 shadow radius ">
        <Card.Body className="my-0 pb-0 pt-1">
          <Row>
            <h6>{t('single_patienScreens.titleLeftSection.titleClinical')}</h6>
          </Row>

          <Row>
            <Col>
              <Button
                variant="primary"
                onClick={() => {
                  setStep('anamnesis');
                }}
                className="text-start form-button my-1 flex items-center gap-1"
              >
                <span>{t('anamnesis.title')} </span>
                <FiChevronRight size={18} />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="primary"
                onClick={() => {
                  setStep('diagnosis');
                }}
                className="text-start form-button my-1 flex items-center gap-1"
              >
                <span>{tabDiagnosisT('title')}</span>
                <FiChevronRight size={18} />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="primary"
                onClick={() => {
                  setStep('treatment');
                }}
                className="text-start form-button my-1 flex items-center gap-1"
              >
                <span>{t('treatmentTabCol.title')} </span>
                <FiChevronRight size={18} />
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="px-2 py-1 mb-2 shadow radius ">
        <Card.Body className="my-0 pb-0 pt-1">
          <Row>
            <h6>{t('single_patienScreens.titleLeftSection.titleRecord')}</h6>
          </Row>
          <Row>
            <Col>
              <Button
                variant="primary"
                onClick={() => {
                  setStep('visits');
                }}
                className="form-button text-end my-1 flex items-center gap-1"
              >
                <span>{t('visit.title')}</span>
                <FiChevronRight size={18} />
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ClinicalFolderCard;
