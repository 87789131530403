// import axios from "axios";
import axios from '../helpers/axiosConfig';
import {
  VISITS_LIST_REQUEST,
  VISITS_LIST_SUCCESS,
  VISITS_LIST_FAIL,
  RECORDS_DETAILS_REQUEST,
  RECORDS_DETAILS_SUCCESS,
  RECORDS_DETAILS_FAIL,
  VISIT_CREATE_REQUEST,
  VISIT_CREATE_SUCCESS,
  VISIT_CREATE_FAIL,
  LIST_VISITS_WITH_TYPE_REQUEST,
  LIST_VISITS_WITH_TYPE_SUCCESS,
  LIST_VISITS_WITH_TYPE_FAIL,
  OTHER_RECORDS_DETAILS_SUCCESS,
  OTHER_RECORDS_DETAILS_FAIL,
  OTHER_RECORDS_DETAILS_REQUEST,
  VISIT_DELETE_REQUEST,
  VISIT_DELETE_SUCCESS,
  VISIT_DELETE_FAIL,
} from '../constants/visitsConstants';

// These is a collection of Redux-free actions

export const listVisits =
  (patientId, remote = false) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: VISITS_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      const { data } = await axios.get(
        `/api/patients/profile/${patientId}/${remote ? 'visits_plus_remote' : 'visits'}`,
        config
      );
      //console.log(data)

      dispatch({
        type: VISITS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: VISITS_LIST_FAIL,
        payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }
  };

export const createFirstVisit = (patientId, visit) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VISIT_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(`/api/patients/profile/${patientId}/visits/addfirstvisit`, visit, config);

    dispatch({
      type: VISIT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VISIT_CREATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const addVisit = (patientId, visit) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VISIT_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(`/api/patients/profile/${patientId}/visits/add`, visit, config);

    console.log('visits add', data);

    dispatch({
      type: VISIT_CREATE_SUCCESS,
      payload: data.data,
    });

    dispatch({
      type: VISITS_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: VISIT_CREATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const deleteVisit = (patientId, visitId, password) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VISIT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const visitDetails = {
      visit_id: visitId,
      password: password,
    };

    const { data } = await axios.delete(`/api/patients/profile/${patientId}/visits/add`, {
      data: visitDetails,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    });

    dispatch({
      type: VISIT_DELETE_SUCCESS,
      payload: visitId,
    });
  } catch (error) {
    dispatch({
      type: VISIT_DELETE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
    throw error;
  }
};

export const listRecords = (patientId, visitId) => async (dispatch, getState) => {
  try {
    dispatch({ type: RECORDS_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`/api/patients/profile/${patientId}/visits/${visitId}`, config);

    dispatch({
      type: RECORDS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RECORDS_DETAILS_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const listLatestRecords = patientId => async (dispatch, getState) => {
  try {
    dispatch({ type: RECORDS_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`/api/patients/profile/${patientId}/latestrecords`, config);

    dispatch({
      type: RECORDS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RECORDS_DETAILS_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const uploadOtherRecords = (patientId, visitId, otherRecordDetails) => async (dispatch, getState) => {
  try {
    dispatch({ type: OTHER_RECORDS_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(
      `/api/patients/profile/${patientId}/visits/${visitId}/other_records/`,
      otherRecordDetails,
      config
    );

    dispatch({
      type: OTHER_RECORDS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OTHER_RECORDS_DETAILS_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};
