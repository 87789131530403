import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

import Loader from '../components/Loader';
import Message from '../components/Message';

import { registerDentist } from '../actions/userActions';
import { REQUIRED_COUNTRIES, COUNTRY } from '../constants/countryOptions';

// utils
import { passwordStrengthValidation } from '../utilities/passwordStrengthValidation';

// todo: core-ui CSS import
import '../scss/coreUI.scss';

const getCountryToVatLabelMap = translationFn => ({
  [COUNTRY.MALAYSIA]: translationFn('authPages.registerNewAccount.section3_profil.sstNumber'),
  DEFAULT: translationFn('authPages.registerNewAccount.section3_profil.vatNumber'),
});

const getVatNumberLabel = (country, translationFn) => {
  const countryToVatLabelMap = getCountryToVatLabelMap(translationFn);
  return countryToVatLabelMap[country] ?? countryToVatLabelMap.DEFAULT;
};

function RegisterScreen() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const redirect = searchParams.get('redirect') ? searchParams.get('redirect') : '/download';

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [title, setTitle] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [birthDate, setBirthDate] = useState('');

  const [businessName, setBusinessName] = useState('');
  const [vatNum, setVatNum] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');

  const formData = {
    dataUser: {
      name: firstName,
      email: email,
      password: password,
    },
    dataDentistProfile: {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      title: title,
      specialty: specialty,
      birth_date: birthDate,
    },
    dataDentistInvoicing: {
      VAT_num: vatNum,
      business_name: businessName,
      adrress_line1: addressLine1,
      adrress_line2: addressLine2,
      city: city,
      province: province,
      ZIP_code: zipCode,
      country: country,
    },
  };
  const { t } = useTranslation(['contentTrs', 'passwordValidation']);

  const dispatch = useDispatch();

  const dentistRegister = useSelector(state => state.dentistRegister);
  const { error, loading, userInfo } = dentistRegister;

  useEffect(() => {
    if (userInfo) {
      navigate('/download');
    }
  }, [userInfo, redirect, navigate]);

  const submitHandler = e => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage('passwords do not match');
      return;
    }

    const passwordStrengthResult = passwordStrengthValidation(password, t);
    if (!passwordStrengthResult.isValid) {
      setMessage(passwordStrengthResult.errorMessage);
      return;
    }

    dispatch(registerDentist(formData));
  };

  return (
    <div>
      <Container inline="true">
        <Link to="/login">
          <Button variant="primary">{t('authPages.backtoLogin_Button')}</Button>
        </Link>

        <h1 className="text-center my-3">{t('authPages.registerNewAccount.title')}</h1>
      </Container>

      <Container className="pt-3  mb-5">
        {message && <Message variant="danger">{message}</Message>}
        {error && <Message variant="danger">{error}</Message>}
        {loading && <Loader></Loader>}

        <Form onSubmit={submitHandler}>
          <Row>
            <Col md={6}>
              <Row>
                <h4 className="text-left">{t('authPages.registerNewAccount.section1_account.title')}</h4>

                <Form.Group controlId="email">
                  <Form.Label>{t('authPages.registerNewAccount.section1_account.email')}</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder={t('authPages.registerNewAccount.section1_account.fval_email')}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label>{t('authPages.registerNewAccount.section1_account.password')}</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    placeholder={t('authPages.registerNewAccount.section1_account.fval_pass')}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="passwordConfirm">
                  <Form.Label>{t('authPages.registerNewAccount.section1_account.confirmPassword')}</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    placeholder={t('authPages.registerNewAccount.section1_account.fval_ConfirmPass')}
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Row>

              <Row className="mt-3 ">
                <h4 className="text-left">{t('authPages.registerNewAccount.section2_profil.title')}</h4>

                <Form.Group controlId="name">
                  <Form.Label>{t('authPages.registerNewAccount.section2_profil.firstname')}</Form.Label>
                  <Form.Control
                    required
                    type="name"
                    placeholder={t('authPages.registerNewAccount.section2_profil.fval_fName')}
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    maxLength={145}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="last_name">
                  <Form.Label>{t('authPages.registerNewAccount.section2_profil.lastName')}</Form.Label>
                  <Form.Control
                    required
                    type="name"
                    placeholder={t('authPages.registerNewAccount.section2_profil.fval_lName')}
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    maxLength={145}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="date_of_birth">
                  <Form.Label>{t('authPages.registerNewAccount.section2_profil.dataOb')}</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    max="2003-01-01"
                    placeholder="dd-mm-yyyy"
                    value={birthDate}
                    onChange={e => setBirthDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="phone_number">
                  <Form.Label>{t('authPages.registerNewAccount.section2_profil.phone')}</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder={t('authPages.registerNewAccount.section2_profil.fval_phone')}
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="title">
                  <Form.Label>{t('authPages.registerNewAccount.section2_profil.userTitle')}</Form.Label>
                  <Form.Control
                    required
                    type="name"
                    placeholder={t('authPages.registerNewAccount.section2_profil.fval_title')}
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    maxLength={19}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="specialty">
                  <Form.Label>{t('authPages.registerNewAccount.section2_profil.speciality')}</Form.Label>
                  <Form.Control
                    required
                    type="name"
                    placeholder={t('authPages.registerNewAccount.section2_profil.fval_speciality')}
                    value={specialty}
                    onChange={e => setSpecialty(e.target.value)}
                    maxLength={95}
                  ></Form.Control>
                </Form.Group>
              </Row>
            </Col>

            <Col md={6}>
              <h4 className="text-left">{t('authPages.registerNewAccount.section3_profil.title')}</h4>

              <Form.Group controlId="country">
                <Form.Label>{t('authPages.registerNewAccount.section3_profil.country')}</Form.Label>
                <Form.Select
                  required
                  placeholder={t('authPages.registerNewAccount.section3_profil.fval_country')}
                  value={country}
                  onChange={e => setCountry(e.target.value)}
                >
                  {REQUIRED_COUNTRIES.map(country => (
                    <option value={country.code} key={country.code}>
                      {country.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group controlId="businessName">
                <Form.Label>{t('authPages.registerNewAccount.section3_profil.businessName')}</Form.Label>
                <Form.Control
                  required
                  disabled={!country}
                  type="text"
                  placeholder={t('authPages.registerNewAccount.section3_profil.fval_businessName')}
                  value={businessName}
                  onChange={e => setBusinessName(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="vatNum">
                <Form.Label>{getVatNumberLabel(country, t)}</Form.Label>
                <Form.Control
                  required
                  disabled={!country}
                  type="text"
                  placeholder={t('authPages.registerNewAccount.section3_profil.fval_vatNum')}
                  value={vatNum}
                  onChange={e => setVatNum(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="addressLine1">
                <Form.Label>{t('authPages.registerNewAccount.section3_profil.Address1')}</Form.Label>
                <Form.Control
                  required
                  disabled={!country}
                  type="text"
                  placeholder={t('authPages.registerNewAccount.section3_profil.fval_adress')}
                  value={addressLine1}
                  onChange={e => setAddressLine1(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="addressLine2">
                <Form.Label>{t('authPages.registerNewAccount.section3_profil.Address2')}</Form.Label>
                <Form.Control
                  disabled={!country}
                  type="text"
                  placeholder={t('authPages.registerNewAccount.section3_profil.fval_adress')}
                  value={addressLine2}
                  onChange={e => setAddressLine2(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="city">
                <Form.Label>{t('authPages.registerNewAccount.section3_profil.city')}</Form.Label>
                <Form.Control
                  required
                  disabled={!country}
                  type="text"
                  placeholder={t('authPages.registerNewAccount.section3_profil.fval_city')}
                  value={city}
                  onChange={e => setCity(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="province">
                <Form.Label>{t('authPages.registerNewAccount.section3_profil.province')}</Form.Label>
                <Form.Control
                  disabled={!country}
                  type="text"
                  placeholder={t('authPages.registerNewAccount.section3_profil.fval_province')}
                  value={province}
                  onChange={e => setProvince(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="zipCode">
                <Form.Label>{t('authPages.registerNewAccount.section3_profil.zipCode')}</Form.Label>
                <Form.Control
                  disabled={!country}
                  required
                  type="text"
                  placeholder={t('authPages.registerNewAccount.section3_profil.fval_zipCode')}
                  value={zipCode}
                  onChange={e => setZipCode(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group inline="true" className="my-3" controlId="formBasicCheckbox">
            <Form.Check
              inline="true"
              required
              type="checkbox"
              label={t('authPages.registerNewAccount.acceptTermCheckbox')}
            ></Form.Check>
            <a
              inline="true"
              href={'/OrthoBook-TermsofService.pdf'}
              className={'agree'}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('authPages.registerNewAccount.termOf_service')}
            </a>
          </Form.Group>

          <Button type="submit" variant="primary">
            {t('authPages.registerNewAccount.registerButton')}
          </Button>
        </Form>
      </Container>
    </div>
  );
}

export default RegisterScreen;
