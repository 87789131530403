import React from 'react';
import { Card, Row, Col, ListGroup } from 'react-bootstrap';
import instance from '../../../../helpers/axiosConfig';
import { Link } from 'react-router-dom';
import { base_URL_media } from '../../../../helpers/axiosConfig';
import CalendarPopup from './CalendarPopup';

//function Clinic({clinic}) {
class Clinic extends React.Component {
  render() {
    const { clinic, clickHandler } = this.props;
    return (
      <>
        <CalendarPopup clinicId={clinic.id} />

        <Card className="my-3 p-1 rounded" id={clinic.id} onClick={clickHandler}>
          <Card.Body>
            <Row>
              <Col sm={8} md={8} lg={8} xl={8}>
                <Link to={`/settings`}>
                  <Card.Title as="div">
                    <strong>{clinic.clinic_name}</strong>
                  </Card.Title>
                </Link>

                <Card.Text as="div">
                  {clinic.title} {clinic.first_name} {clinic.last_name}
                </Card.Text>
                <Card.Text as="div">{clinic.specialty}</Card.Text>

                <Card.Text as="div" className="mt-2">
                  {clinic.adrress_line1}
                </Card.Text>
                <Card.Text as="div" className="mb-1">
                  {clinic.city && clinic.city + ', '} {clinic.ZIP_code && clinic.ZIP_code + ', '} {clinic.country}
                </Card.Text>
                <Card.Text as="div">
                  {clinic.email} , {clinic.phone_number}
                </Card.Text>
              </Col>

              <Col sm={4} md={4} lg={4} xl={4}>
                <Link to={`/settings`}>
                  <Card.Img src={clinic.logo && `${base_URL_media}${clinic.logo}`} />
                </Link>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default Clinic;
