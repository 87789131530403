import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios, { instanceBase } from '../../helpers/axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Loader from '../../components/Loader';

import { getDentistProfile } from '../../actions/userActions';

import { Menu, MenuItem, IconButton } from '@mui/material';
import { BsFillChatTextFill, BsSearch } from 'react-icons/bs';
import { MdAccountCircle } from 'react-icons/md';
import { AiOutlineMore } from 'react-icons/ai';
import './Sidebar.css';
import NoChatSelected from './NoChatSelected';
import Contact from './Contact';
import ChatView from './ChatView';
import NewConversation from './NewConversation';

// todo: core-ui CSS import
import '../../scss/coreUI.scss';

export function ChatScreen() {
  const { t } = useTranslation('contentTrs');
  const { conversationName } = useParams();

  const dispatch = useDispatch();

  const { userInfo } = useSelector(state => state.userLogin);
  const dentistProfile = useSelector(state => state.dentistProfile);
  const { error: errorDentistProfile, loading: lodaingDentistProfile, dentist } = dentistProfile;

  const [conversations, setActiveConversations] = useState([]);

  useEffect(() => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    async function fetchUsers() {
      const { data } = await axios.get('api/conversations/', config);
      console.log(data);
      setActiveConversations(data);
    }
    fetchUsers();
    dispatch(getDentistProfile());
  }, [userInfo]);

  const createConversationName = uuid => {
    return `${uuid}__${dentist?.u_uuid}`;
  };

  const formatMessageTimestamp = timestamp => {
    if (!timestamp) return;
    const date = new Date(timestamp);
    return date.toLocaleTimeString().slice(0, 5);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div className="sidebar">
          {/* <div className="sidebar__header">
          <div className="sidebar__header_left">
            <IconButton>
              <MdAccountCircle />
            </IconButton>
            Username Here
          </div>
          <div className="sidebar__header_right">
            <IconButton>
              <MdAccountCircle />
            </IconButton>
            <IconButton>
              <BsFillChatTextFill />
            </IconButton>
            <IconButton onClick={handleClick}>
              <AiOutlineMore />
            </IconButton>
  
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>New Group</MenuItem>
              <MenuItem onClick={handleClose}>Create a room</MenuItem>
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>Archived</MenuItem>
              <MenuItem onClick={handleClose}>Starred</MenuItem>
              <MenuItem onClick={handleClose}>Settings</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>
          </div>
        </div> */}

          <div className="sidebar__search">
            <div className="sidebar__searchContainer">
              <IconButton size="medium">
                <BsSearch />
              </IconButton>
              <input type="text" placeholder="Search or start new chat" />
            </div>
            <div>
              <NewConversation />
            </div>
          </div>

          <div className="sidebar__chats">
            {lodaingDentistProfile ? (
              <Loader size="40px"></Loader>
            ) : (
              conversations?.map(c => <Contact conversation={c} key={c.name} />)
            )}
          </div>
        </div>

        {conversationName ? <ChatView /> : <NoChatSelected />}
      </div>
    </>
  );

  // return(
  //   <div>

  //   <div>
  // { (lodaingDentistProfile) ? (<Loader size='40px' ></Loader>) : (
  //       conversations?.map((c) => (
  //           <Link
  //           to={`/chats/${createConversationName(c.other_user.uuid)}`}
  //           key={c.other_user.username}
  //           >
  //           <div className="border border-gray-200 w-full p-3">
  //               <h3 className="text-xl font-semibold text-gray-800">{c.other_user.username}</h3>
  //               <div className="flex justify-between">
  //               <p className="text-gray-700">{c.last_message?.content}</p>
  //               <p className="text-gray-700">{formatMessageTimestamp(c.last_message?.timestamp)}</p>
  //               </div>
  //           </div>
  //           </Link>
  //       ))
  // )}
  //   </div>
  //   <div></div>
  //   </div>

  // )
}
