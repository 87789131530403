import { useSelector } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Loader from '../../../../components/Loader';
import Clinic from '../PresentationSettings/Clinic';
import ActiveBlogs from './ActiveBlogs';

import './style.scss';

const Blog = () => {
  const clinicsList = useSelector(state => state.clinicsList);
  const { loading, clinics } = clinicsList;

  return (
    <Container fluid className="blog-container">
      <Row>
        <Col lg={4}>
          <h5>Select Clinic</h5>

          {loading && <Loader />}

          {clinics.map(clinic => (
            <Col key={clinic.id} sm={12} md={12} lg={12} xl={12}>
              <Clinic clinic={clinic}></Clinic>
            </Col>
          ))}
        </Col>
        <Col lg={8}>
          <h5>Active Blogs</h5>
          <ActiveBlogs />
        </Col>
      </Row>
    </Container>
  );
};
export default Blog;
