import React, { useEffect, useState } from 'react';
import axios, { instanceBase } from '../../helpers/axiosConfig';
import { Form, Button, Row, Col, Modal, Card } from 'react-bootstrap';
import { Button as ButtonMaterial, Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';

import DisplayAvatar from '../../components/DisplayAvatar';
import { dateToHmDMY, dateToDMY, getAge } from '../../helpers';
import { GetStatus } from '../../helpers/status';

import themeVariables from '../../scss/_custom.scss';

import { listClinics } from '../../actions/clinicsActions';
import { getPatientProfile, updatePatient } from '../../actions/patientsActions';

import { BsFillPersonFill, BsEnvelopeFill, BsTelephoneFill } from 'react-icons/bs';
import { FaHospital } from 'react-icons/fa';

function Demografics(props) {
  const { t } = useTranslation('contentTrs');
  const { patient, setUpdateProfile } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [emailSentTo, setEmailSentTo] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const handleCloseSnack = () => {
    setShowAlert(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseEditStatus = () => setShowStatus(false);
  const handleShowEditStatus = () => setShowStatus(true);

  const [clinic, setClinic] = useState(patient.clinic);
  const [firstName, setFirstName] = useState(patient.first_name);
  const [lastName, setLastName] = useState(patient.last_name);
  const [birthDate, setBirthDate] = useState(patient.birth_date);
  let sex_value = patient.sex == 'Male' ? 'M' : 'F';
  const [sex, setSex] = useState(sex_value);
  // const [sex, setSex] = useState(patient.sex)
  const [nhsNumber, setNhsNumber] = useState(patient.NHS_number);
  const [email, setEmail] = useState(patient.email);
  const [phoneNumber, setPhoneNumber] = useState(patient.phone_number);
  const [status, setStatus] = useState(patient.status);

  const [message, setMessage] = useState('');
  const [selectBorder, setSelectBorder] = useState(false);

  const clinicsList = useSelector(state => state.clinicsList);
  const { clinics } = clinicsList;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  // const patientCreate = useSelector(state => state.patientCreate)
  // const { error, loading, success:successPatient, patient, draftPatient } = patientCreate

  useEffect(() => {
    dispatch(listClinics());
  }, [dispatch]);

  const handleValidation = () => {
    let formIsValid = true;

    if (clinic == '') {
      formIsValid = false;
      setSelectBorder(true);
    } else if (sex == '') {
      formIsValid = false;
    } else {
      setSelectBorder(false);
    }
    return formIsValid;
  };

  const updatePatientDemografics = e => {
    e.preventDefault();
    console.log('update');

    if (handleValidation()) {
      dispatch(
        updatePatient(patient.id, {
          clinic_id: clinic,
          first_name: firstName,
          last_name: lastName,
          birth_date: birthDate,
          sex: sex,
          phone_number: phoneNumber,
          NHS_number: nhsNumber,
          email: email,
        })
      );
      setUpdateProfile(true);
    } else {
    }
  };

  const updatePatientStatus = e => {
    e.preventDefault();

    dispatch(
      updatePatient(patient.id, {
        status: status,
      })
    );
    setUpdateProfile(true);
  };

  const statuses = [
    { id: 0, status: t('statuses.firstVisit') },
    { id: 1, status: t('statuses.casePlaning') },
    { id: 2, status: t('statuses.acceptedTreatment') },
    { id: 3, status: t('statuses.treatment') },
    { id: 4, status: t('statuses.finished') },
    { id: 5, status: t('statuses.archived') },
  ];

  const sendActivationCodesEmail = async item => {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`/api/remote/patient/auth/sendmail/${patient.id}`, config);
    setEmailSentTo(data.sentTo);
    setShowAlert({ open: true, displayElement: 'visible', vertical: 'top', horizontal: 'left' });
  };

  return (
    <div>
      <Card className="p-2 mb-2 shadow radius ">
        <Card.Body>
          <Row>
            <Col sm={6} md={4} lg={4}>
              <DisplayAvatar user={patient} style={{ width: '70px', height: '70px' }} />
            </Col>
            <Col>
              <Row>
                <h6 className="p-0 m-0 mb-1">
                  {firstName} {lastName}
                </h6>
              </Row>
              <Row>
                <>
                  {t('single_patienScreens.patient_data.uid')}: {patient.uuid}-{patient.id}
                </>
                <br></br>
                <>
                  {t('single_patienScreens.patient_data.nhs_number')}: {patient.NHS_number}
                </>
              </Row>
            </Col>
          </Row>
        </Card.Body>
        <hr className="my-1"></hr>
        <div style={{ display: 'flex' }}>
          {' '}
          <GetStatus status_id={patient.status} />{' '}
          <Button
            onClick={handleShowEditStatus}
            type="submit"
            style={{
              margin: '0px',
              position: 'absolute',
              right: '0',
              marginRight: '20px',
              marginTop: '-2.5px',
              float: 'right',
            }}
            variant="primary"
            className="form-button text-end"
          >
            {t('single_patienScreens.patient_data.updateStatusButton')}
          </Button>{' '}
        </div>
        <hr className="my-1"></hr>
        <Card.Body className="mb-0 pb-0">
          <Row>
            <p className="p-0 m-0 mb-1 inline-flex items-center">
              <FaHospital className="me-1 gap-1" />
              {clinics &&
                clinics.map(a => {
                  if (a.id == clinic) {
                    return a.clinic_name;
                  }
                })}
            </p>
          </Row>
          <Row className="align-items-center">
            <div className="p-0 m-0 mb-1 flex items-center">
              <BsFillPersonFill className="me-1 gap-1" />
              <span>
                {sex}, {getAge(birthDate)}
              </span>
              <span className="text-secondary ms-1"> ({dateToDMY(birthDate)})</span>
            </div>
          </Row>
          <Row>
            <p className="p-0 m-0 mb-1 gap-1">
              <BsEnvelopeFill className="inline-block align-middle mr-1" />
              <span className="inline-block align-middle">{email}</span>
            </p>
          </Row>
          <Row>
            <p className="p-0 m-0 mb-1 gap-1">
              <BsTelephoneFill className="inline-block align-middle mr-1" />
              <span className="inline-block align-middle"> {phoneNumber}</span>
            </p>
          </Row>
          <Row>
            <div className="p-0 m-0 mb-1 flex items-center">
              <BsEnvelopeFill className="p-0 m-0 mb-1 me-1" />
              <ButtonMaterial
                variant="text"
                onClick={() => sendActivationCodesEmail(patient?.id)}
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                {'send activation email'}
              </ButtonMaterial>
            </div>

            <div className="text-end py-0 my-0">
              <Button variant="primary" onClick={handleShow} className="form-button text-end my-0">
                {t('single_patienScreens.patient_data.editParient')}
              </Button>
            </div>
          </Row>
        </Card.Body>
      </Card>

      <Modal show={showStatus} onHide={handleCloseEditStatus} backdrop="static" keyboard={false} className="p-2">
        <Modal.Header closeButton>
          <Modal.Title>{t('single_patienScreens.patient_data.updatePatient_popup.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="scrollY">
          <Form onSubmit={updatePatientStatus} className="py-0">
            <Form.Group controlId="status" required>
              <Form.Label>{t('single_patienScreens.patient_data.updatePatient_popup.status')}</Form.Label>
              <Form.Select
                className={selectBorder && 'border border-danger'}
                required
                defaultValue={patient.status}
                onChange={e => {
                  setStatus(parseInt(e.target.value));
                }}
              >
                <option key="blankChoice" hidden value>
                  {' '}
                  - - -{' '}
                </option>
                {statuses.map(status => (
                  <option key={status.id} value={status.id} selected={status.id === patient.status}>
                    {status.status}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Button type="submit" variant="primary" className="form-button text-end" onClick={handleCloseEditStatus}>
              {t('single_patienScreens.patient_data.updatePatient_popup.updateButton')}
            </Button>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditStatus}>
            {t('single_patienScreens.patient_data.updatePatient_popup.close')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} className="p-2">
        <Modal.Header closeButton>
          <Modal.Title>{t('single_patienScreens.patient_data.updatePatient_popup.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="scrollY">
          <Form onSubmit={updatePatientDemografics} className="py-0">
            <Form.Group controlId="clinic" required>
              <Form.Label>{t('single_patienScreens.patient_data.updatePatient_popup.clinic')}</Form.Label>
              <Form.Select
                className={selectBorder && 'border border-danger'}
                required
                defaultValue={patient.clinic}
                onChange={e => {
                  setClinic(e.target.value);
                }}
              >
                <option key="blankChoice" hidden value>
                  {' '}
                  - - -{' '}
                </option>
                {clinics.map(clinic => (
                  <option key={clinic.id} value={clinic.id} selected={clinic.id === patient.clinic}>
                    {clinic.clinic_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="nhs_number">
              <Form.Label>{t('single_patienScreens.patient_data.updatePatient_popup.nhs_number')}</Form.Label>
              <Form.Control
                size="sm"
                type="name"
                placeholder="enter specialty"
                value={nhsNumber}
                onChange={e => setNhsNumber(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="first_name">
              <Form.Label>{t('single_patienScreens.patient_data.updatePatient_popup.firstname')}</Form.Label>
              <Form.Control
                required
                size="sm"
                type="name"
                placeholder={t('single_patienScreens.patient_data.updatePatient_popup.fval_fName')}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="last_name">
              <Form.Label>{t('single_patienScreens.patient_data.updatePatient_popup.lastName')}</Form.Label>
              <Form.Control
                required
                size="sm"
                type="name"
                placeholder={t('single_patienScreens.patient_data.updatePatient_popup.fval_lName')}
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="date_of_birth">
              <Form.Label>{t('single_patienScreens.patient_data.updatePatient_popup.dataOb')}</Form.Label>
              <Form.Control
                required
                size="sm"
                type="date"
                placeholder="dd-mm-yyyy"
                value={birthDate}
                onChange={e => setBirthDate(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="sex">
              <Form.Label>{t('single_patienScreens.patient_data.updatePatient_popup.sex.title')}</Form.Label>
              {['radio'].map(type => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label={t('single_patienScreens.patient_data.updatePatient_popup.sex.male')}
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                    value="M"
                    checked={sex === 'Male' || sex === 'M'}
                    onChange={e => setSex(e.target.value)}
                  />
                  <Form.Check
                    inline
                    label={t('single_patienScreens.patient_data.updatePatient_popup.sex.female')}
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                    value="F"
                    checked={sex === 'Female' || sex === 'F'}
                    onChange={e => setSex(e.target.value)}
                  />
                </div>
              ))}
            </Form.Group>

            <Form.Group controlId="phone_number">
              <Form.Label>{t('single_patienScreens.patient_data.updatePatient_popup.phone')}</Form.Label>
              <Form.Control
                size="sm"
                type="number"
                placeholder={t('single_patienScreens.patient_data.updatePatient_popup.fval_phone')}
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>{t('single_patienScreens.patient_data.updatePatient_popup.email')}</Form.Label>
              <Form.Control
                size="sm"
                type="email"
                placeholder={t('single_patienScreens.patient_data.updatePatient_popup.fval_email')}
                value={email}
                onChange={e => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            {/* <Button type='submit' variant='primary' className='form-button text-end' > */}
            <Button type="submit" variant="primary" className="form-button text-end" onClick={handleClose}>
              {t('single_patienScreens.patient_data.updatePatient_popup.updateButton')}
            </Button>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('single_patienScreens.patient_data.updatePatient_popup.close')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="success">
          {' '}
          {'email sent to ->' + emailSentTo}{' '}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Demografics;
