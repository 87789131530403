import React from 'react';
import { Spinner } from 'react-bootstrap';

function Loader(props) {
  const { size = '100px' } = props;

  return (
    <Spinner
      animation="border"
      role="status"
      style={{
        height: size,
        width: size,
        margin: 'auto',
        display: 'block',
      }}
    >
      <span className="tw-sr-only">Loading...</span>
    </Spinner>
  );
}

export default Loader;
