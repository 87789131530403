import { useState } from 'react';
import './AttachDocuments.css';

const AttachDocuments = ({ documents }) => {
  const [checked, setChecked] = useState([]);

  const onClickHandle = () => {
    console.log(checked);
  };

  const handleCheck = event => {
    let updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);

    let parentElement = event.target.parentElement;
    parentElement.classList.toggle('doc-list-item-checked');
  };

  return (
    <div className="doc-list-container">
      {documents.map((document, index) => {
        return (
          <label key={index} className="doc-list-item">
            <input value={document.id} type="checkbox" onChange={handleCheck} className="checkbox-round" />
            {document.title}
          </label>
        );
      })}
      <button type="button" onClick={onClickHandle} className="doc-btn">
        Send
      </button>
    </div>
  );
};
export default AttachDocuments;
