// lib
import { useRef, Suspense, useEffect } from 'react';

// components
import { Modal as MModal, Typography, Box, Button } from '@mui/material';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { Canvas, useLoader, useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';

// CSS
import '../componentsCSS/Components.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'rgba(180, 180, 200, 0.6)',
};

const Renderer = ({ url }) => {
  const stl = useLoader(STLLoader, url);

  const ref = useRef();
  const { camera } = useThree();
  useEffect(() => {
    camera.lookAt(ref.current.position);
  });
  return (
    <>
      <Suspense fallback={null}>
        <mesh ref={ref}>
          <primitive object={stl} />
          <meshStandardMaterial color={'gray'} />
        </mesh>
        <ambientLight />
        <pointLight position={[10, 10, 10000]} />
        <pointLight position={[0, 0, 0]} />
        <pointLight position={[100, 100, 100]} />
        <pointLight position={[10000, -100, 10000]} />
        <pointLight position={[-10000, -100, 10000]} />
        <pointLight position={[0, 0, -10000]} />
      </Suspense>
      <OrbitControls />
    </>
  );
};

export default function STLrenderer(props) {
  const { image, isVisible = false, handleClose } = props;

  return (
    <>
      <MModal
        open={isVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ height: '90%', m: '0%' }}>
            <Canvas camera={{ position: [10, 100, 50] }}>
              <Renderer url={image.photo_url} />
            </Canvas>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ height: '9%', m: '1%' }}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </Typography>
        </Box>
      </MModal>
    </>
  );
}
