//Todo: For more flexibility, we can add ALL_COUNTRIES_METADATA and extract required information from it when necessary.
export const COUNTRY = {
  AUSTRIA: {
    name: 'Austria',
    code: 'AT',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '43',
    flag: '🇦🇹',
    id: 1,
  },
  BELGIUM: {
    name: 'Belgium',
    code: 'BE',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '32',
    flag: '🇧🇪',
    id: 2,
  },
  BULGARIA: {
    name: 'Bulgaria',
    code: 'BG',
    currency: 'BGN',
    currencySymbol: 'лв',
    currencyName: 'Bulgarian lev',
    phoneCode: '359',
    flag: '🇧🇬',
    id: 3,
  },
  CROATIA: {
    name: 'Croatia',
    code: 'HR',
    currency: 'HRK',
    currencySymbol: 'kn',
    currencyName: 'Croatian kuna',
    phoneCode: '385',
    flag: '🇭🇷',
    id: 4,
  },
  CYPRUS: {
    name: 'Cyprus',
    code: 'CY',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '357',
    flag: '🇨🇾',
    id: 5,
  },
  CZECH_REPUBLIC: {
    name: 'Czech Republic',
    code: 'CZ',
    currency: 'CZK',
    currencySymbol: 'Kč',
    currencyName: 'Czech koruna',
    phoneCode: '420',
    flag: '🇨🇿',
    id: 6,
  },
  DENMARK: {
    name: 'Denmark',
    code: 'DK',
    currency: 'DKK',
    currencySymbol: 'kr',
    currencyName: 'Danish krone',
    phoneCode: '45',
    flag: '🇩🇰',
    id: 7,
  },
  ESTONIA: {
    name: 'Estonia',
    code: 'EE',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '372',
    flag: '🇪🇪',
    id: 8,
  },
  FINLAND: {
    name: 'Finland',
    code: 'FI',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '358',
    flag: '🇫🇮',
    id: 9,
  },
  FRANCE: {
    name: 'France',
    code: 'FR',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '33',
    flag: '🇫🇷',
    id: 10,
  },
  GERMANY: {
    name: 'Germany',
    code: 'DE',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '49',
    flag: '🇩🇪',
    id: 11,
  },
  GREECE: {
    name: 'Greece',
    code: 'GR',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '30',
    flag: '🇬🇷',
    id: 12,
  },
  HUNGARY: {
    name: 'Hungary',
    code: 'HU',
    currency: 'HUF',
    currencySymbol: 'Ft',
    currencyName: 'Hungarian forint',
    phoneCode: '36',
    flag: '🇭🇺',
    id: 13,
  },
  IRELAND: {
    name: 'Ireland',
    code: 'IE',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '353',
    flag: '🇮🇪',
    id: 14,
  },
  ITALY: {
    name: 'Italy',
    code: 'IT',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '39',
    flag: '🇮🇹',
    id: 15,
  },
  LATVIA: {
    name: 'Latvia',
    code: 'LV',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '371',
    flag: '🇱🇻',
    id: 16,
  },
  LITHUANIA: {
    name: 'Lithuania',
    code: 'LT',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '370',
    flag: '🇱🇹',
    id: 17,
  },
  LUXEMBOURG: {
    name: 'Luxembourg',
    code: 'LU',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '352',
    flag: '🇱🇺',
    id: 18,
  },
  MALTA: {
    name: 'Malta',
    code: 'MT',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '356',
    flag: '🇲🇹',
    id: 19,
  },
  NETHERLANDS: {
    name: 'Netherlands',
    code: 'NL',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '31',
    flag: '🇳🇱',
    id: 20,
  },
  POLAND: {
    name: 'Poland',
    code: 'PL',
    currency: 'PLN',
    currencySymbol: 'zł',
    currencyName: 'Polish złoty',
    phoneCode: '48',
    flag: '🇵🇱',
    id: 21,
  },
  PORTUGAL: {
    name: 'Portugal',
    code: 'PT',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '351',
    flag: '🇵🇹',
    id: 22,
  },
  ROMANIA: {
    name: 'Romania',
    code: 'RO',
    currency: 'RON',
    currencySymbol: 'lei',
    currencyName: 'Romanian leu',
    phoneCode: '40',
    flag: '🇷🇴',
    id: 23,
  },
  SAN_MARINO: {
    name: 'San Marino',
    code: 'SM',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '378',
    flag: '🇸🇲',
    id: 24,
  },
  SLOVAKIA: {
    name: 'Slovakia',
    code: 'SK',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '421',
    flag: '🇸🇰',
    id: 24,
  },
  SLOVENIA: {
    name: 'Slovenia',
    code: 'SI',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '386',
    flag: '🇸🇮',
    id: 25,
  },
  SPAIN: {
    name: 'Spain',
    code: 'ES',
    currency: 'EUR',
    currencySymbol: '€',
    currencyName: 'Euro',
    phoneCode: '34',
    flag: '🇪🇸',
    id: 26,
  },
  SWEDEN: {
    name: 'Sweden',
    code: 'SE',
    currency: 'SEK',
    currencySymbol: 'kr',
    currencyName: 'Swedish krona',
    phoneCode: '46',
    flag: '🇸🇪',
    id: 27,
  },
  UNITED_KINGDOM: {
    name: 'United Kingdom',
    code: 'GB',
    currency: 'GBP',
    currencySymbol: '£',
    currencyName: 'British pound',
    phoneCode: '44',
    flag: '🇬🇧',
    id: 28,
  },
  MALAYSIA: {
    name: 'Malaysia',
    code: 'MY',
    currency: 'MYR',
    currencySymbol: 'RM',
    currencyName: 'Malaysian ringgit',
    phoneCode: '60',
    flag: '🇲🇾',
    id: 29,
  },
};

export const REQUIRED_COUNTRIES = [
  COUNTRY.AUSTRIA,
  COUNTRY.BELGIUM,
  COUNTRY.BULGARIA,
  COUNTRY.CROATIA,
  COUNTRY.CYPRUS,
  COUNTRY.CZECH_REPUBLIC,
  COUNTRY.DENMARK,
  COUNTRY.ESTONIA,
  COUNTRY.FINLAND,
  COUNTRY.FRANCE,
  COUNTRY.GERMANY,
  COUNTRY.GREECE,
  COUNTRY.HUNGARY,
  COUNTRY.IRELAND,
  COUNTRY.ITALY,
  COUNTRY.LATVIA,
  COUNTRY.LITHUANIA,
  COUNTRY.LUXEMBOURG,
  COUNTRY.MALAYSIA,
  COUNTRY.MALTA,
  COUNTRY.NETHERLANDS,
  COUNTRY.POLAND,
  COUNTRY.PORTUGAL,
  COUNTRY.ROMANIA,
  COUNTRY.SAN_MARINO,
  COUNTRY.SLOVAKIA,
  COUNTRY.SLOVENIA,
  COUNTRY.SPAIN,
  COUNTRY.SWEDEN,
  COUNTRY.UNITED_KINGDOM,
];
