import { useState, useRef, useCallback, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import { SIGNUP_STEPS } from '../constants/signupSteps';

// hooks
import { usePatientAuthQuery } from './usePatientAuthQuery';
import { usePatientLoginMutuation } from './usePatientLogInMutuation';
import { useTranslation } from 'react-i18next';

// utils
import { passwordStrengthValidation } from '../../../utilities/passwordStrengthValidation';

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('-');
}

const usePatientSignup = () => {
  const navigate = useNavigate();
  const { clinicId } = useParams();
  const { uidb64, token } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  let patient_code = searchParams.get('patient_code').replace(/\//g, '');

  const [signupStep, setSignupStep] = useState(SIGNUP_STEPS.CLINIC_PATIENT_VERIFICATION);
  const [errorState, setErrorState] = useState({});
  const { mutate, isPending: isLoading } = usePatientAuthQuery();
  const { mutate: loginMutuation, isLoading: loginLoading } = usePatientLoginMutuation();

  const { t } = useTranslation(['patientSignup', 'passwordValidation']);

  const signupStepRef = useRef(signupStep);
  signupStepRef.current = signupStep;
  const verifiedState = useRef({ uidb64, token, patient_code, setSignupStep });

  const handleInvitationValidation = useCallback(
    ({ uidb64, token, patient_code }) => {
      setErrorState(prev => ({ ...prev, uidb64: undefined, token: undefined, patient_code: undefined }));

      if (!uidb64 || !token || !patient_code) {
        setErrorState({
          error: true,
          valid_invitation: false,
        });
        return;
      }

      // hit the mutation
      mutate(
        { uidb64, token, patient_code },
        {
          onSuccess: response => {
            verifiedState.current = { uidb64, token, patient_code, data: response, email: response?.email };
            if (response.account_exists) {
              setSignupStep(SIGNUP_STEPS.LOGIN);
            } else if (response?.account_exists === false) {
              setSignupStep(SIGNUP_STEPS.TERMS_AND_CONDITION_VERIFICATION);
            }
          },
          onError: error => {
            if (error.response?.status === 400) {
              verifiedState.current = { data: error.response };
              setSignupStep(SIGNUP_STEPS.CLINIC_PATIENT_VERIFICATION);
            }
          },
        }
      );
    },
    [mutate, t]
  );

  const handleLogIn = useCallback(
    ({ email, password }) => {
      if (!email || !password) {
        setErrorState({
          error: true,
          valid_invitation: false,
        });
        return;
      }

      // hit the mutation
      loginMutuation(
        { email, password },
        {
          onSuccess: response => {
            if (response) {
              console.log('next4', response);
              localStorage.setItem('userInfoPatient', JSON.stringify(response ?? {}));
              navigate('/oralcheck/signup/download/');
            }
          },
          onError: error => {
            if (error.response?.status === 401) {
              setErrorState(prev => ({ ...prev, wrongCredentials: t('wrong e-mail or password!') }));
            }
          },
        }
      );
    },
    [mutate, t]
  );

  const handleBirthdayVerification = useCallback(
    ({ clinicId, patientId, birthDate }) => {
      // remove error state
      setErrorState(prev => ({ ...prev, birthDate: undefined }));

      if (!birthDate) {
        setErrorState(prev => ({
          ...prev,
          birthDate: t('Date of birth is required!'),
        }));
        return;
      }

      // hit the mutation
      mutate(
        { clinicId, patientId, birthDate: formatDate(birthDate) },
        {
          onSuccess: response => {
            verifiedState.current = { emailId: response.data };
            setSignupStep(SIGNUP_STEPS.TERMS_AND_CONDITION_VERIFICATION);
          },
          onError: () => {
            setErrorState(prev => ({ ...prev, birthDate: t('Incorrect date of birth!') }));
          },
        }
      );
    },
    [mutate, t]
  );

  const handlePasswordVerification = useCallback(
    ({ uidb64, token, patient_code, email, password, confirmPassword }) => {
      // remove error state
      setErrorState(prev => ({ ...prev, email: undefined, password: undefined, confirmPassword: undefined }));

      if (!email || !password || !confirmPassword) {
        setErrorState(prev => ({
          ...prev,
          password: password ? undefined : t('Password is requierd!'),
          confirmPassword: confirmPassword ? undefined : t('This field is required!'),
          email: email ? undefined : t('Email is required!'),
        }));
        return;
      }

      // validate password
      if (password !== confirmPassword) {
        setErrorState(prev => ({ ...prev, confirmPassword: t('Passwords do not match!') }));
        return;
      }

      const passwordStrengthResult = passwordStrengthValidation(password, t);
      if (!passwordStrengthResult.isValid) {
        setErrorState(prev => ({ ...prev, password: passwordStrengthResult.errorMessage }));
        return;
      }

      // If password validated, validate email and password
      mutate(
        { uidb64, token, patient_code, email, password, compliance_data: true },
        {
          onSuccess: response => {
            verifiedState.current = { uidb64, token, patient_code, data: response, email: email };
            setSignupStep(SIGNUP_STEPS.EMAIL_VERIFICATION);
          },
          onError: error => {
            console.log('error', error);
            console.log('error', error.response?.status === 409);
            if (error.response?.status === 409) {
              verifiedState.current = { uidb64, token, patient_code, data: error.response?.data, email: email };
              console.log('error', error);
              setErrorState(prev => ({ ...prev, email: t('e-mail invalid or already in use.') }));
            }
            console.log('error', error);
            setErrorState(prev => ({ ...prev, email: t('Incorrect email!') }));
          },
        }
      );
    },
    [mutate, t]
  );

  const onNext = useCallback(
    ({ uidb64, token, patient_code, password, confirmPassword, email, emailId, birthDate }) => {
      switch (signupStepRef.current) {
        case SIGNUP_STEPS.CLINIC_PATIENT_VERIFICATION: {
          // handleInvitationValidation({ uidb64, token, patient_code });
          break;
        }
        case SIGNUP_STEPS.LOGIN: {
          handleLogIn({ email, password });
          break;
        }
        case SIGNUP_STEPS.TERMS_AND_CONDITION_VERIFICATION: {
          setSignupStep(SIGNUP_STEPS.PATIENT_REGISTER_FORM);
          break;
        }
        case SIGNUP_STEPS.PATIENT_REGISTER_FORM: {
          handlePasswordVerification({ uidb64, token, patient_code, email, password, confirmPassword });
          break;
        }
        default:
          break;
      }
    },
    [handlePasswordVerification]
  );

  useEffect(() => {
    // Automatically trigger handleInvitationValidation if the current step matches
    // and possibly other initial parameters are correctly set
    console.log('2', signupStepRef.current);
    if (signupStepRef.current === SIGNUP_STEPS.CLINIC_PATIENT_VERIFICATION) {
      handleInvitationValidation({ uidb64, token, patient_code });
    }
  }, [handleInvitationValidation, uidb64, token, patient_code]);

  return {
    signupStep,
    verifiedState: verifiedState.current,
    onNext,
    isLoading,
    error: errorState,
  };
};

export { usePatientSignup };
