import {
  TREATMENT_INFO_REQUEST,
  TREATMENT_INFO_SUCCESS,
  TREATMENT_INFO_FAIL,
  TREATMENT_DETAILS_REQUEST,
  TREATMENT_DETAILS_SUCCESS,
  TREATMENT_DETAILS_FAIL,
  TREATMENT_CREATE_REQUEST,
  TREATMENT_CREATE_SUCCESS,
  TREATMENT_CREATE_FAIL,
  TREATMENT_CREATE_RESET,
  TREATMENT_UPDATE_REQUEST,
  TREATMENT_UPDATE_SUCCESS,
  TREATMENT_UPDATE_FAIL,
  TREATMENT_UPDATE_RESET,
} from '../constants/treatmentConstants';

export const treatmentInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case TREATMENT_INFO_REQUEST:
      return { loading: true };

    case TREATMENT_INFO_SUCCESS:
      return { loading: false, success: true, treatment: action.payload };

    case TREATMENT_INFO_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const treatmentCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TREATMENT_CREATE_REQUEST:
      return { loading: true };

    case TREATMENT_CREATE_SUCCESS:
      return { loading: false, success: true };

    case TREATMENT_CREATE_FAIL:
      return { loading: false, error: action.payload };

    case TREATMENT_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

/*
export const recordsListReducer = (state = { records: {} }, action) => {
    switch (action.type) {
        case RECORDS_DETAILS_REQUEST:
            return { loading: true, ...state}

        case RECORDS_DETAILS_SUCCESS:
            return { loading: false, success:true, records: action.payload, }

        case RECORDS_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        
        case RECORDS_DETAILS_RESET:
            return {  }

        default:
            return state
    }
}

/*

export const clinicsProfileReducer = (state = { clinic: {} }, action) => {
    switch (action.type) {
        case CLINIC_PROFILE_REQUEST:
            return { loading: true, ...state}

        case CLINIC_PROFILE_SUCCESS:
            return { loading: false, clinic: action.payload, }

        case CLINIC_PROFILE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const clinicsUpdateReducer = (state = {  }, action) => {
    switch(action.type){
        case CLINIC_UPDATE_REQUEST:
            return { loading: true }

        case CLINIC_UPDATE_SUCCESS:
            return {loading: false, success: true }

        case CLINIC_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case CLINIC_UPDATE_RESET:
            return { }
        
        default:
            return state
    }
}



/*
export const dentistUpdateProfileReducer = (state = {  }, action) => {
    switch(action.type){
        case DENTIST_UPDATE_PROFILE_REQUEST:
            return { loading: true }

        case DENTIST_UPDATE_PROFILE_SUCCESS:
            return {loading: false, success: true }

        case DENTIST_UPDATE_PROFILE_FAIL:
            return { loading: false, error: action.payload }

        case DENTIST_UPDATE_PROFILE_RESET:
            return { }
        
        default:
            return state
    }
}

/*
export const dentistInvoicingReducer = (state = { invoicing: {} }, action) => {
    switch(action.type){
        case DENTIST_INVOICING_REQUEST:
            return { loading: true }

        case DENTIST_INVOICING_SUCCESS:
            return {loading: false, invoicing: action.payload}

        case DENTIST_INVOICING_FAIL:
            return { loading: false, error: action.payload }
        
        case DENTIST_INVOICING_RESET:
            return { invoicing: {} }
            
        default:
            return state
    }
}

export const dentistUpdateInvoicingReducer = (state = {  }, action) => {
    switch(action.type){
        case DENTIST_UPDATE_INVOICING_REQUEST:
            return { loading: true }

        case DENTIST_UPDATE_INVOICING_SUCCESS:
            return {loading: false, success: true }

        case DENTIST_UPDATE_INVOICING_FAIL:
            return { loading: false, error: action.payload }

        case DENTIST_UPDATE_INVOICING_RESET:
            return { }
        
        default:
            return state
    }
}

*/
