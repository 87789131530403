import moment from 'moment'; //install moment

export const dateToYMD = date => {
  return moment(date).format('YYYY-MM-DD');
};

export const dateToDMY = date => {
  return moment(date).format('DD-MM-YYYY');
};

export const dateToDMMMMY = date => {
  return moment(date).format('Do MMMM YYYY');
};

export const dateToHmDMY = date => {
  return moment(date).format('HH:mm - Do MMMM YYYY');
};

export const getAge = date => {
  return ((new Date().getTime() - new Date(date)) / (24 * 3600 * 365.25 * 1000)) | 0;
};

export const visitDate = (startDate, endDate) => {
  let start = moment(startDate);
  let end = moment(endDate);
  let days = moment.duration(start.diff(end));
  let duration = moment.duration(days, 'hours').humanize();
  return duration;
};
