import React, { useState } from 'react';

import { Form, Button, Row, Col, Table, Containerm, Modal } from 'react-bootstrap';
import { GetStatus } from '../../../helpers/status';
import { useTranslation } from 'react-i18next';

import { useNavigate, useLocation } from 'react-router-dom';
import DisplayAvatar from '../../../components/DisplayAvatar';
import Paginate from '../../../components/Paginate';
import PresentationsButton from '../../../components/PresentationsButton';

import { dateToHmDMY } from '../../../helpers';
//import { apiArchivePatient, apiDeletePatient } from '../../../api';
//import { addAlert } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';

import { BsFillPersonFill } from 'react-icons/bs';
import DeleteButton from './DeleteButton';

const PatientsTable = props => {
  const { t } = useTranslation('contentTrs');
  const navigate = useNavigate();

  const {
    keyword,
    previewIndex,
    setPreviewIndex,
    statuses,
    history,
    renewTrigger,
    setRenewTrigger,
    filters,
    setFilters,
    total = 10,
  } = props;

  const { error, loading, patients, page, pages } = useSelector(state => state.patientsList);

  // const fields = [
  //   { key: 'avatar', _style: { width: '5%'}, label: <></> },
  //   { key: 'name', _style: { width: '20%'}, label: intl.formatMessage({id: "patientsScene.table.name"}) },
  //   { key: 'status', _style: { width: '15%'}, label: intl.formatMessage({id: "patientsScene.table.status"}) },
  //   { key: 'lastUpdate', _style: { width: '15%'}, label: intl.formatMessage({id: "patientsScene.table.lastUpdate"}) },
  //   { key: 'preview', _style: { width: '5%' }, label: intl.formatMessage({id: "patientsScene.table.preview"}) },
  //   { key: 'view', _style: { width: '5%' }, label: intl.formatMessage({id: "patientsScene.table.view"}) },
  //   { key: 'options', _style: { width: '5%' }, label: intl.formatMessage({id: "patientsScene.table.options"}) },
  // ]

  const archivePatient = async patientId => {};

  const deletePatient = async () => {};

  return (
    <div>
      <Table responsive="sm" hover="true" className="px-2">
        <thead>
          <tr>
            <th width={'3%'}></th>
            <th>
              <BsFillPersonFill />
            </th>
            <th> {t('allPatientScreen.patienName')}</th>
            {/* <th className='text-center'>Practice</th> */}
            <th className="text-center"></th>
            <th className="text-center" width={'10%'}>
              {t('allPatientScreen.patientStatuts')}
            </th>
            <th className="text-center">{t('allPatientScreen.patientPreview.title')}</th>
            <th className="text-center" width={'10%'}>
              {t('allPatientScreen.patientPresentationTitle')}
            </th>
          </tr>
        </thead>
        <tbody>
          {patients?.map(patient => (
            <tr key={patient.id}>
              <td>
                <DeleteButton
                  patientId={patient.id}
                  patientFirstName={patient.first_name}
                  patientSurname={patient.last_name}
                />
              </td>
              <td>
                <DisplayAvatar user={patient} />
              </td>
              <td>
                <Row>
                  <div
                    onClick={() => {
                      if (patient.is_draft) {
                        navigate(`/patients/register/${patient.id}`);
                      } else {
                        navigate(`/patients/${patient.id}`);
                      }
                    }}
                    className="link pointer ms-0 ps-0"
                  >
                    {patient.first_name + ' ' + patient.last_name}
                  </div>
                </Row>
                <Row style={{ fontSize: 12 }} className="text-right">
                  {dateToHmDMY(patient.update_at)}
                </Row>
              </td>
              <td className="text-center"></td>
              <td className="text-center">
                {' '}
                <GetStatus status_id={patient.status} />{' '}
              </td>
              <td className="text-center">
                <Button
                  className="baseButton"
                  onClick={() => setPreviewIndex(patient.id === previewIndex ? null : patient.id)}
                >
                  {t('allPatientScreen.patientPreview.previewButton')}
                </Button>
                <Button
                  className="baseButton ms-4"
                  onClick={() => {
                    if (patient.is_draft) {
                      navigate(`/patients/register/${patient.id}`);
                    } else {
                      navigate(`/patients/${patient.id}`);
                    }
                  }}
                >
                  {t('allPatientScreen.patientPreview.openButton')}
                </Button>
              </td>
              <td className="text-center">
                {' '}
                <PresentationsButton patientId={patient.id} />{' '}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row>
        <Paginate pages={pages} page={page} keyword={keyword} />
      </Row>
    </div>
  );
};

export default PatientsTable;
