import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Card, Modal } from 'react-bootstrap';
// import { BsCheckCircle, BsXCircle, BsFillCaretRightSquareFill } from "react-icons/bs";

const EditCardModal = props => {
  const { t } = useTranslation('contentTrs');
  const { show, setShow, card, setCard, updatedCard, setUpdatedCard, handleSubmitCard } = props;

  const handleClose = () => setShow(false);

  const [error, setError] = useState(null);

  const stripe = useStripe();

  const elements = useElements();

  // Handle real-time validation errors from the CardElement.
  const handleChange = event => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  const handleSubmitAndClose = async e => {
    const paymentMethod = await handleSubmitCard(e);
    setUpdatedCard(true);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('settingsPage.suscription.edit.addCard')}</Modal.Title>
      </Modal.Header>

      <Row className="justify-content-center my-3">
        <Col sm={10} md={10} lg={10} className="shadow p-3">
          <form onSubmit={e => handleSubmitAndClose(e)} className="stripe-form">
            <div className="form-row">
              <CardElement id="card-element" onChange={handleChange} className="my-2" />
              <div className="card-errors" role="alert">
                {error}
              </div>
            </div>
            <button type="submit" className="mt-3 flex justify-content-between baseButton">
              {t('settingsPage.suscription.edit.verifyCard')}
            </button>
          </form>
        </Col>
      </Row>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t('settingsPage.suscription.edit.closeBox')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default EditCardModal;
