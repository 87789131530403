// components
import { SignupLayout } from '../components/layout/SignupLayout';

// hooks
import { useTranslation } from 'react-i18next';

const OralCheckDownloadScreen = () => {
  const { t } = useTranslation('patientSignup');

  return (
    <SignupLayout>
      <div
        className="tw-flex tw-flex-col tw-w-full tw-h-full tw-items-center tw-justify-between tw-py-12 tw-mx-12"
        style={{ maxWidth: '440px' }}
      >
        <div className="tw-flex tw-flex-col tw-items-center">
          <img
            src="/statuses/verifiedStatus.svg"
            alt="Verified icon"
            className="tw-w-10 tw-h-10 tw-object-cover tw-mb-3"
          />
          <h1 className="tw-text-white tw-text-2xl md:tw-text-4xl tw-font-bold tw-mb-2 tw-text-center">
            {t('Your account is ready!')}
          </h1>
          <div className="tw-text-white tw-text-2xl md:tw-text-4xl tw-font-normal tw-text-center">
            {t('Download the app and log-in to continue your orthodontic journey')}
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-2">
          <button className="tw-w-full tw-transition-shadow hover:tw-shadow-lg tw-bg-tangyOrange tw-py-3 tw-rounded-2xl">
            <a
              href="https://tiecast.page.link/oralcheck-donwload"
              className="tw-w-full tw-flex tw-flex-row tw-items-center tw-no-underline"
              target="_blank"
            >
              <img src="/images/download.png" alt="download icon" className="tw-w-10 tw-h-10 tw-object-cover tw-ml-3" />
              <div className="tw-self-center tw-text-white tw-font-bold tw-text-lg tw-flex-1">
                {t('Open in the app')}
              </div>
            </a>
          </button>
          <img src="/images/celebrations.svg" alt="celebrations image" className="tw-w-full" />
        </div>
      </div>
    </SignupLayout>
  );
};

export { OralCheckDownloadScreen };
