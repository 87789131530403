import React from 'react';
// import axios from 'axios'
import axios from '../helpers/axiosConfig';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { Dropdown } from 'react-bootstrap';

function PresentationsButton(props) {
  const { patientId } = props;
  const { t } = useTranslation('contentTrs');
  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const openPresentation = async presType => {
    const config = { headers: { Authorization: `Bearer ${userInfo.access}` } };
    const { data } = await axios.get(`/api/presentations/presentation/${patientId}/${presType}/mobile`, config);
    setTimeout(() => {
      window.open(data, '_blank', 'noopener,noreferrer');
    });
  };

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle className="baseButton" id="dropdown-presentations">
          {t('home_page.patientPresentationButton')}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              openPresentation('fv');
            }}
          >
            {t('home_page.presentationMenu.firstvisit')}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              openPresentation('cp');
            }}
          >
            {t('home_page.presentationMenu.treatmentPlan')}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              openPresentation('tp');
            }}
          >
            {t('home_page.presentationMenu.progress')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default PresentationsButton;
