export const ANAMNESIS_LIST_REQUEST = 'ANAMNESIS_LIST_REQUEST';
export const ANAMNESIS_LIST_SUCCESS = 'ANAMNESIS_LIST_SUCCESS';
export const ANAMNESIS_LIST_FAIL = 'ANAMNESIS_LIST_FAIL';

export const ANAMNESIS_CREATE_REQUEST = 'ANAMNESIS_CREATE_REQUEST';
export const ANAMNESIS_CREATE_SUCCESS = 'ANAMNESIS_CREATE_SUCCESS';
export const ANAMNESIS_CREATE_FAIL = 'ANAMNESIS_CREATE_FAIL';
export const ANAMNESIS_CREATE_RESET = 'ANAMNESIS_CREATE_FAIL';

export const ANAMNESIS_UPDATE_REQUEST = 'ANAMNESIS_UPDATE_REQUEST';
export const ANAMNESIS_UPDATE_SUCCESS = 'ANAMNESIS_UPDATE_SUCCESS';
export const ANAMNESIS_UPDATE_FAIL = 'ANAMNESIS_UPDATE_FAIL';
export const ANAMNESIS_UPDATE_RESET = 'ANAMNESIS_UPDATE_FAIL';

export const T_NOTES_LIST_REQUEST = 'T_NOTES_LIST_REQUEST';
export const T_NOTES_LIST_SUCCESS = 'T_NOTES_LIST_SUCCESS';
export const T_NOTES_LIST_FAIL = 'T_NOTES_LIST_FAIL';

export const T_NOTES_CREATE_REQUEST = 'T_NOTES_CREATE_REQUEST';
export const T_NOTES_CREATE_SUCCESS = 'T_NOTES_CREATE_SUCCESS';
export const T_NOTES_CREATE_FAIL = 'T_NOTES_CREATE_FAIL';
export const T_NOTES_CREATE_RESET = 'T_NOTES_CREATE_FAIL';

export const T_NOTES_UPDATE_REQUEST = 'T_NOTES_UPDATE_REQUEST';
export const T_NOTES_UPDATE_SUCCESS = 'T_NOTES_UPDATE_SUCCESS';
export const T_NOTES_UPDATE_FAIL = 'T_NOTES_UPDATE_FAIL';
export const T_NOTES_UPDATE_RESET = 'T_NOTES_UPDATE_FAIL';
