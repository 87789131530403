import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import './AttachFeatures.css';
import AttachDocuments from './AttachDocuments';
import AttachPicture from './AttachPicture';
import AttachCalendar from './AttachCalendar';

import { ReactComponent as ClipIcon } from '../../../asset/icons/clip-icon.svg';
import { ReactComponent as DocumentIcon } from '../../../asset/icons/document-icon.svg';
import { ReactComponent as CalendarIcon } from '../../../asset/icons/calendar-icon.svg';

const AttachFeatures = ({ user }) => {
  return (
    <>
      <OverlayTrigger
        trigger="click"
        key="clip"
        placement="top"
        // rootClose="true"
        overlay={
          <Popover id={`clip`} className="clip-container">
            <Popover.Body className="d-flex flex-column align-center">
              {/* Picture Feature */}
              <AttachPicture />

              {/* Schedule calendar Feature */}
              <OverlayTrigger
                trigger="click"
                rootClose="true"
                key="calendar"
                placement="left"
                overlay={
                  <Popover id={`calendar`} className="attach-container attach-cal-border">
                    <Popover.Body>
                      <AttachCalendar />
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button type="button" className="attach-btn attach-cal">
                  <CalendarIcon />
                </Button>
              </OverlayTrigger>

              {/* Document Feature */}
              <OverlayTrigger
                trigger="click"
                rootClose="true"
                key="documents"
                placement="left"
                overlay={
                  <Popover id={`documents`} className="attach-container">
                    <Popover.Body>
                      <AttachDocuments documents={user.documents} />
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button type="button" className="attach-btn attach-doc">
                  <DocumentIcon />
                </Button>
              </OverlayTrigger>
            </Popover.Body>
          </Popover>
        }
      >
        <Button type="button" className="clip-btn">
          <ClipIcon />
        </Button>
      </OverlayTrigger>
    </>
  );
};
export default AttachFeatures;
