import React from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

import { Form, Button, Image, Row, Col, Modal, Card } from 'react-bootstrap';

import { FiChevronRight } from 'react-icons/fi';
import emailIcon from '../../asset/images/email.png';

function PresentationsCard(props) {
  const { t } = useTranslation('contentTrs');
  const { patientId, step, setStep } = props;
  const patientP = useSelector(state => state.patientProfile);
  const { patient } = patientP;

  // Init Toast Alert

  const [openSnack, setOpenSnack] = useState({
    open: false,
    displayElement: 'hidden',
    vertical: 'top',
    horizontal: 'center',
  });
  const [msg, setMsg] = useState(`Email inviato con successo a ${patient?.first_name + '  ' + patient?.last_name} `);

  function handleShowToastMessage(formData_) {
    // do your API calls here
    console.log(formData_);
    // when done hide dialog, show snackbar
    setOpenSnack({ open: true, displayElement: 'visible', ...formData_ });
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  /*function Alert22(props) {       // to add in Button# // onClick={() => handleShowToastMessage({ vertical: 'top', horizontal: 'left' }) }
            return <MuiAlert elevation={6} variant="filled" {...props} />;
          }*/
  // End Toast Alert

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const openPresentation = async presType => {
    const config = { headers: { Authorization: `Bearer ${userInfo.access}` } };
    const { data } = await axios.get(`/api/presentations/presentation/${patientId}/${presType}/mobile`, config);
    setTimeout(() => {
      window.open(data, '_blank', 'noopener,noreferrer');
    });
  };

  const sendPresentationEmail = async presType => {
    const config = { headers: { Authorization: `Bearer ${userInfo.access}` } };
    const { data } = await axios.get(`/api/presentations/presentation/${patientId}/email/${presType}`, config);
    handleShowToastMessage({ vertical: 'top', horizontal: 'left' });
    return data;
  };

  return (
    <div>
      <Card className="px-2 py-1 mb-2 shadow radius ">
        <Card.Body className="my-0 pb-0 pt-1">
          <Row>
            <h6>{t('single_patienScreens.titleLeftSection.titlePresentations.title')}</h6>
          </Row>

          <Row>
            <Col>
              <Button
                onClick={() => {
                  openPresentation('fv');
                }}
                variant="primary"
                className="text-start form-button my-1 flex items-center gap-1"
              >
                <span>{t('single_patienScreens.titleLeftSection.titlePresentations.firstVisit')}</span>
                <FiChevronRight size={18} />
              </Button>
            </Col>
            <Col xs lg="2">
              <Link
                onClick={() => sendPresentationEmail('fv')}
                title={
                  t('single_patienScreens.titleLeftSection.titlePresentations.send_email_to') +
                  ` ${patient?.first_name + '  ' + patient?.last_name}`
                }
                to="#"
              >
                <Image src={emailIcon} style={{ height: 25, width: 25 }} />
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                onClick={() => {
                  openPresentation('cp');
                }}
                variant="primary"
                className="text-start form-button my-1 flex items-center gap-1"
              >
                <span>{t('single_patienScreens.titleLeftSection.titlePresentations.casePlaning')}</span>
                <FiChevronRight size={18} />
              </Button>
            </Col>
            <Col xs lg="2">
              <Link
                onClick={() => sendPresentationEmail('cp')}
                title={
                  t('single_patienScreens.titleLeftSection.titlePresentations.send_email_to') +
                  ` ${patient?.first_name + '  ' + patient?.last_name}`
                }
                to="#"
              >
                <Image src={emailIcon} style={{ height: 25, width: 25 }} />
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                onClick={() => {
                  openPresentation('tp');
                }}
                variant="primary"
                className="text-start form-button my-1 flex items-center gap-1"
              >
                <span>{t('single_patienScreens.titleLeftSection.titlePresentations.treatment')}</span>
                <FiChevronRight size={18} />
              </Button>
            </Col>
            <Col xs lg="2">
              <Link
                onClick={() => sendPresentationEmail('tp')}
                title={
                  t('single_patienScreens.titleLeftSection.titlePresentations.send_email_to') +
                  ` ${patient?.first_name + '  ' + patient?.last_name}`
                }
                to="#"
              >
                <Image src={emailIcon} style={{ height: 25, width: 25 }} />
              </Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Snackbar
        style={{ visibility: openSnack.displayElement }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          {' '}
          {msg}{' '}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default PresentationsCard;
