import axios from 'axios';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
import { refreshToken } from '../actions/userActions';
import store from '../store';

let authTokens = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
/// Base URLS
let baseURL_local = 'http://localhost:8000';
let baseURL_dev = 'https://dev.tie-cast.com';
let baseURL_prod = 'https://api-prod.tie-cast.co.uk/';
/// Media URLS
let baseURL_local_media = baseURL_local;
let baseURL_local_static_asset = baseURL_dev;
let baseURL_staging_media = '';
let baseURL_staging_static_asset = baseURL_dev;
let baseURL_prod_media = '';
let baseURL_prod_static_asset = '/records';
/// Export URL (update all three)
export const base_URL_media = baseURL_prod_media;
export const base_URL_static_asset = baseURL_prod_static_asset;
export const baseURL = baseURL_dev;

// Global Base URL
axios.defaults.baseURL = baseURL;
axios.defaults.headers.common['Authorization'] = authTokens;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export const instanceBase = axios.create({
  baseURL: baseURL,
});

const instance = axios.create({
  baseURL: baseURL,
});

// Configure interceptors && refresh call
instance.interceptors.request.use(
  async req => {
    if (!authTokens) {
      return req;
    }

    const user = jwt_decode(authTokens.access);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    req.headers.Authorization = `Bearer ${authTokens.access}`;

    if (!isExpired) {
      return req;
    } else {
      await store.dispatch(refreshToken());
      authTokens = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
      req.headers.Authorization = `Bearer ${authTokens?.access}`;
    }
    return req;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
