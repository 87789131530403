// core functional frameworks
import React, { useState, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// 3rd party component libraries
import InfiniteScroll from 'react-infinite-scroll-component';
// proprietary components
import { Message } from './Messages';

import DisplayAvatar from '../../components/DisplayAvatar';
import axios from 'axios';
import { wsURL } from '../../helpers/wsConfig';
// Actions and resources

import { IconButton } from '@mui/material';
import { MdAccountCircle, MdOutlineSentimentSatisfiedAlt, MdOutlineKeyboardVoice } from 'react-icons/md';
import { AiOutlineMore } from 'react-icons/ai';
import { GrAttachment } from 'react-icons/gr';
import { BiSend } from 'react-icons/bi';
import './Chat.css';
import AttachFeatures from './AttachFeatures/AttachFeatures';

function ChatView(props) {
  const { t } = useTranslation('contentTrs');
  const { conversationName } = useParams();
  const { conversation = '' } = props;

  const { userInfo } = useSelector(state => state.userLogin);
  const { selectedPatient } = useSelector(state => state.currentChat);

  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [messageHistory, setMessageHistory] = useState([]);
  const [page, setPage] = useState(2);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  let user = {};
  user.minimized_photo_url = selectedPatient?.profile_photo;

  const { sendJsonMessage } = useWebSocket(userInfo ? `${wsURL}/chats/${conversationName}/` : null, {
    queryParams: {
      token: userInfo ? userInfo.access : '',
    },
  });

  const placeholderInfo = {
    documents: [
      {
        id: 1,
        title: 'Informed consent',
      },
      {
        id: 2,
        title: 'Data Processing',
      },
      {
        id: 3,
        title: 'Privacy',
      },
    ],
  };

  const { readyState } = useWebSocket(userInfo ? `${wsURL}/chats/${conversationName}/` : null, {
    queryParams: {
      token: userInfo ? userInfo.access : '',
    },
    onOpen: () => {
      console.log('Connected!');
    },
    onClose: () => {
      console.log('Disconnected!');
    },
    onMessage: e => {
      const data = JSON.parse(e.data);
      switch (data.type) {
        case 'welcome_message':
          setWelcomeMessage(data.message);
          break;
        case 'chat_message_echo':
          setMessageHistory(prev => [...prev, data.message]);
          break;
        case 'last_50_messages':
          setMessageHistory(data.messages.reverse());
          setHasMoreMessages(data.has_more);
          sendJsonMessage({ type: 'read_messages' });
          break;
        default:
          console.error('Unknown message type!');
          break;
      }
    },
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  useEffect(() => {
    if (connectionStatus === 'Open') {
      sendJsonMessage({
        type: 'read_messages',
      });
    }
  }, [connectionStatus, sendJsonMessage]);

  const handleChangeMessage = e => {
    setMessage(e.target.value);
  };

  const handleSubmit = () => {
    sendJsonMessage({
      type: 'chat_message',
      message,
      //   name
    });
    // setName("");
    setMessage('');
  };

  async function fetchMessages() {
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const apiRes = await axios.get(`/api/messages/?conversation=${conversationName}&page=${page}`, config);
    if (apiRes.status === 200) {
      const data = ({
        count: data.number,
        next: data.next, // URL
        previous: data.previous, // URL
        results: data.results,
      } = apiRes.data);
      setHasMoreMessages(data.next !== null);
      setPage(page + 1);
      setMessageHistory(prev => prev.concat(data.results));
    }
  }

  return (
    <div className="chat">
      <div className="chat__header">
        <div className="chat__headerLeft">
          {selectedPatient?.profile_photo ? <DisplayAvatar user={user} /> : <MdAccountCircle />}

          <h3>{selectedPatient?.name}</h3>
        </div>
        <div className="chat__headerRight">
          {/* <IconButton>  <SearchIcon /> </IconButton> */}
          <span>The chat is currently {connectionStatus}</span>
          {/* <IconButton> <AiOutlineMore /> </IconButton> */}
        </div>
      </div>

      <div className="chat__body">
        {messageHistory.map(message => {
          return <Message key={message.id} message={message} />;
        })}
      </div>

      <div className="chat_footer">
        {/* <MdOutlineSentimentSatisfiedAlt /> */}
        {/* <GrAttachment /> */}
        <input type="message" placeholder="Message" onChange={handleChangeMessage} value={message} />
        <AttachFeatures user={placeholderInfo} />
        <div onClick={handleSubmit}>
          <BiSend size="2em" />
        </div>
        {/* <MdOutlineKeyboardVoice /> */}
      </div>
    </div>
  );
}

export default ChatView;
