import { useState } from 'react';
// import { ReactComponent as PlusIcon } from "../../../asset/icons/NewChat.svg";
import { BiPlusMedical } from 'react-icons/bi';
import { FaMinus } from 'react-icons/fa';

import './AttachCalendar.css';

const AttachCalendar = () => {
  const [inputFields, setInputFields] = useState([
    {
      date: '',
      time: '',
    },
  ]);

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        time: '',
        date: '',
      },
    ]);
  };

  const removeInputFields = index => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const handleChange = (index, event) => {
    const { value, type } = event.target;
    const list = [...inputFields];
    list[index][type] = value;
    setInputFields(list);
  };

  const onClickHandle = () => {
    // const filterInputs = (arr) => {
    //   const required = arr.filter((el) => {
    //     if (el.date && el.time) {
    //       return el;
    //     }
    //   });
    //   return required;
    // };
    // let newInputValues = filterInputs(inputValues);
    console.log(inputFields);
  };

  return (
    <div className="calendar-container">
      <div className="calendar-title-conteiner">
        <h6 className="calendar-title">Date</h6>
        <h6 className="calendar-title">Hour</h6>
      </div>

      {inputFields.map((data, index) => {
        const { date, time } = data;
        const indexPlusOne = index + 1;
        return (
          <div className="calendar-line-container" key={index}>
            <input
              type="date"
              onChange={event => handleChange(index, event)}
              value={date}
              className="calendar-first-input"
            />
            <input
              type="time"
              onChange={event => handleChange(index, event)}
              value={time}
              className="calendar-second-input"
            />

            {indexPlusOne !== inputFields.length && (
              <button className="calendar-btn calendar-minus-btn" onClick={() => removeInputFields(index)}>
                <FaMinus />
              </button>
            )}
            {indexPlusOne === inputFields.length && (
              <button className="calendar-btn" onClick={addInputField}>
                <BiPlusMedical />
              </button>
            )}
          </div>
        );
      })}

      <button type="button" onClick={onClickHandle} className="doc-btn cal-btn">
        Send
      </button>
    </div>
  );
};
export default AttachCalendar;
