import { useMutation } from '@tanstack/react-query';

import axios from '../../../helpers/axiosConfig';

const useResendVerificationMailMutation = ({ emailId, translationFn }) =>
  useMutation({
    mutationFn: () => {
      let userInfo = JSON.parse(localStorage.getItem('userInfo') ?? null);
      if (!userInfo) {
        userInfo = JSON.parse(localStorage.getItem('userInfoPatient') ?? '{}');
      }

      let ppp = JSON.parse(localStorage.getItem('userInfoPatient'));

      if (!userInfo.access) {
        return Promise.reject(translationFn('Invalid patient token!'));
      }

      return axios.post(
        '/api/send-email-validation/',
        {
          from_page: 'Parent_web_registration',
          email: emailId,
          brand: null,
        },
        {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.access}`,
          },
        }
      );
    },
  });

export { useResendVerificationMailMutation };
