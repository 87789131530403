import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import RecentPatientsTable from './components/RecentPatientsTable';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col, Container } from 'react-bootstrap';

import { listPatients } from '../../actions/patientsActions';
import Message from '../../components/Message';
// import { listClinics } from '../../actions/clinicsActions'
import { fetchRemoteVisits } from '../../actions/remoteVisitsActions';

// todo: core-ui CSS import
import '../../scss/coreUI.scss';

const HomeScreen2 = props => {
  // const { intl, history } = props;

  //const { statuses } = useSelector(state => state.options)
  //const statuses = null
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // const productList = useSelector(state => state.productList)
  // const { error:proderror, loading:loaderror, products } = productList

  const patientsList = useSelector(state => state.patientsList);
  const { error, loading, patients } = patientsList;

  const status = { statuses: 1 };
  const { statuses } = status;

  const clinicsList = useSelector(state => state.clinicsList);
  const { clinics } = clinicsList;

  const { t } = useTranslation('contentTrs');

  // useEffect(() =>{
  //           // dispatch(listClinics())
  // }, [dispatch])

  useEffect(() => {
    //dispatch(listProducts())
    dispatch(listPatients());
    dispatch(fetchRemoteVisits());
    // dispatch(listClinics())
    // console.log(patients)
  }, [dispatch]);

  const newPatientClick = () => {
    navigate('/patients/register');
  };

  return (
    <Container className="homeScene">
      {
        //patients ?
        patients?.length === 0 ? (
          <Row className="mt-3">
            <Col sm={12} className="flex justify-content-center">
              <img
                src="/images/dontHavePatients.png"
                alt="Add your first patient to get started"
                className="dontHavePatientsImage"
              />
            </Col>
            <Row className="flex justify-content-center font-2xl mt-3">
              <Col sm={12} className="flex justify-content-center font-2xl mt-3">
                <Button className="mx-2 baseButton" onClick={newPatientClick}>
                  {t('home_page.welcomeScreen.addYourFirstPatient')}
                </Button>
                <React.Fragment>
                  <p className="mt-3"> {t('home_page.welcomeScreen.trackTreatment')} </p>
                </React.Fragment>
              </Col>
            </Row>
          </Row>
        ) : (
          <React.Fragment>
            <Row>
              <Col>
                <h1 className="display-5">
                  {t('home_page.recentPatient_title')} {/** {t('home_page.')} */}
                </h1>
              </Col>
              <Col className="text-end mt-2">
                <Button className="mx-2 baseButton" onClick={newPatientClick}>
                  {' '}
                  + {t('home_page.addPatient_button')}
                </Button>
              </Col>
            </Row>
            <Row>
              <RecentPatientsTable patients={patients} statuses={statuses} clinics={clinics} />
            </Row>
            <Row></Row>
          </React.Fragment>
        ) //: <div> No patients</div>}
      }
    </Container>
  );
};

export default HomeScreen2;
