import {
  OTHER_RECORDS_DETAILS_FAIL,
  OTHER_RECORDS_DETAILS_REQUEST,
  OTHER_RECORDS_DETAILS_SUCCESS,
  RECORDS_DETAILS_DELETE_SUCCESS,
  RECORDS_DETAILS_FAIL,
  RECORDS_DETAILS_REQUEST,
  RECORDS_DETAILS_RESET,
  RECORDS_DETAILS_SUCCESS,
  VISITS_LIST_FAIL,
  VISITS_LIST_REQUEST,
  VISITS_LIST_RESET,
  VISITS_LIST_SUCCESS,
  VISIT_CREATE_FAIL,
  VISIT_CREATE_REQUEST,
  VISIT_CREATE_RESET,
  VISIT_CREATE_SUCCESS,
  VISIT_DELETE_FAIL,
  VISIT_DELETE_REQUEST,
  VISIT_DELETE_SUCCESS,
} from '../constants/visitsConstants';

import update from 'immutability-helper';

export const visitsListReducer = (state = { visits: [] }, action) => {
  switch (action.type) {
    case VISITS_LIST_REQUEST:
      return { loading: true, visits: [] };

    case VISITS_LIST_SUCCESS:
      return { loading: false, success: true, visits: action.payload };

    case VISIT_DELETE_SUCCESS:
      const visitIndex = state.visits?.doctor.findIndex(visit => visit.id === action.payload);
      return update(state, {
        visits: {
          doctor: { $splice: [[visitIndex, 1]] }, // Remove the visit at visitIndex
        },
        loading: { $set: false },
      });

    case VISITS_LIST_FAIL:
      return { loading: false, error: action.payload };

    case VISITS_LIST_RESET:
      return {};

    default:
      return state;
  }
};

export const visitsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    // case VISIT_DELETE_REQUEST:
    //   return update(state, {
    //     loading: { $set: true },
    //   });

    // case VISIT_DELETE_SUCCESS:
    //   const visitIndex = state.visits?.doctor?.findIndex(visit => visit.id === action.payload);
    //   if (visitIndex === -1 || visitIndex === undefined) {
    //     // If visitIndex is not found, return the state as is
    //     return state;
    //   }
    //   return update(state, {
    //     visits: {
    //       doctor: { $splice: [[visitIndex, 1]] }, // Remove the visit at visitIndex
    //     },
    //     loading: { $set: false },
    //   });

    // case VISIT_DELETE_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };

    default:
      return state;
  }
};

// export const visitsListWithTypeReducer = (state = { visits: [] }, action) => {
//     switch (action.type) {
//         case LIST_VISITS_WITH_TYPE_REQUEST:
//             return { loading: true, visitsWithType: [] }

//         case LIST_VISITS_WITH_TYPE_SUCCESS:
//             return { loading: false, success: true, visitsWithType: action.payload, }

//         case LIST_VISITS_WITH_TYPE_FAIL:
//             return { loading: false, error: action.payload }

//         default:
//             return state
//     }
// }

export const visitCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case VISIT_CREATE_REQUEST:
      return { loading: true };

    case VISIT_CREATE_SUCCESS:
      return { loading: false, success: true, visit: action.payload };

    case VISIT_CREATE_FAIL:
      return { loading: false, error: action.payload };

    case VISIT_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const recordsListReducer = (state = { records: {} }, action) => {
  switch (action.type) {
    case OTHER_RECORDS_DETAILS_REQUEST:
    case RECORDS_DETAILS_REQUEST:
      return { loading: true, ...state };

    case OTHER_RECORDS_DETAILS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        success: { $set: true },
        records: {
          $set: update(state.records, {
            other_records: { $set: action.payload.data },
          }),
        },
      });

    case RECORDS_DETAILS_SUCCESS:
      return { loading: false, success: true, records: action.payload };

    case RECORDS_DETAILS_DELETE_SUCCESS:
      let newRecords = { ...state.records };
      newRecords[action.payload] = null;
      newRecords.minimized_records[action.payload] = null;
      return { loading: false, success: true, records: newRecords };

    case OTHER_RECORDS_DETAILS_FAIL:
    case RECORDS_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case RECORDS_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

/*

export const clinicsProfileReducer = (state = { clinic: {} }, action) => {
    switch (action.type) {
        case CLINIC_PROFILE_REQUEST:
            return { loading: true, ...state}

        case CLINIC_PROFILE_SUCCESS:
            return { loading: false, clinic: action.payload, }

        case CLINIC_PROFILE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const clinicsUpdateReducer = (state = {  }, action) => {
    switch(action.type){
        case CLINIC_UPDATE_REQUEST:
            return { loading: true }

        case CLINIC_UPDATE_SUCCESS:
            return {loading: false, success: true }

        case CLINIC_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case CLINIC_UPDATE_RESET:
            return { }
        
        default:
            return state
    }
}



/*
export const dentistUpdateProfileReducer = (state = {  }, action) => {
    switch(action.type){
        case DENTIST_UPDATE_PROFILE_REQUEST:
            return { loading: true }

        case DENTIST_UPDATE_PROFILE_SUCCESS:
            return {loading: false, success: true }

        case DENTIST_UPDATE_PROFILE_FAIL:
            return { loading: false, error: action.payload }

        case DENTIST_UPDATE_PROFILE_RESET:
            return { }
        
        default:
            return state
    }
}

/*
export const dentistInvoicingReducer = (state = { invoicing: {} }, action) => {
    switch(action.type){
        case DENTIST_INVOICING_REQUEST:
            return { loading: true }

        case DENTIST_INVOICING_SUCCESS:
            return {loading: false, invoicing: action.payload}

        case DENTIST_INVOICING_FAIL:
            return { loading: false, error: action.payload }
        
        case DENTIST_INVOICING_RESET:
            return { invoicing: {} }
            
        default:
            return state
    }
}

export const dentistUpdateInvoicingReducer = (state = {  }, action) => {
    switch(action.type){
        case DENTIST_UPDATE_INVOICING_REQUEST:
            return { loading: true }

        case DENTIST_UPDATE_INVOICING_SUCCESS:
            return {loading: false, success: true }

        case DENTIST_UPDATE_INVOICING_FAIL:
            return { loading: false, error: action.payload }

        case DENTIST_UPDATE_INVOICING_RESET:
            return { }
        
        default:
            return state
    }
}

*/
