import React from 'react';
import { useTranslation } from 'react-i18next';
import OrthoBookCloud from '../../asset/brand/OrthoBookCloud.png';
import { GrConnectivity } from 'react-icons/gr';
import './NoChatSelected.css';

const NoChatSelected = () => {
  const { t } = useTranslation('contentTrs');

  return (
    <div className="NoChatSelected">
      <img src={OrthoBookCloud} alt="ortho book cloud connecting" />
      {/* <GrConnectivity size='20%'/> */}

      <div className="NoChatSelected__description">
        <h1>{t('chat.unselectedChatTitle')}</h1>
        <p>{t('chat.unselectedChatBody')}</p>
      </div>
    </div>
  );
};

export default NoChatSelected;
