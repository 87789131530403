// components
import { Row } from 'react-bootstrap';
import { useState } from 'react';

import { LinearProgress } from '@mui/material'; // Import LinearProgress component from Material-UI

import RecordField from '../../components/ImageComponent/RecordField';

// hooks
import { useTranslation } from 'react-i18next';

function RecordsTab(props) {
  const { editImg, deleteImg, readOnly = false, records, editImageProgress } = props;

  const [uploadProgress, setUploadProgress] = useState(0); // State to track upload progress

  const { t } = useTranslation('contentTrs');

  return (
    <div>
      <Row>
        <h5>{t('addNew_patienScreen.add_step2.tabRecords.title')}</h5>
      </Row>

      <Row>
        <h6>{t('addNew_patienScreen.add_step2.tabRecords.extra_oralRecords')}</h6>
      </Row>
      <Row className="justify-content-around" sm={5} md={5} lg={5} xl={5}>
        {['front_smile', 'front_relaxed', 'lateral_smile', 'lateral_relaxed'].map(type => {
          return (
            <div className="imageRectangle shadow my-1 relative p-0" key={Math.random() * 100}>
              <RecordField
                image={{
                  photo_url: records[type],
                  minimized_photo_url: records[type],
                }}
                editImageProgress={editImageProgress}
                editImg={editImg}
                deleteImg={deleteImg}
                recordType={type}
                readOnly={readOnly}
              />
            </div>
          );
        })}
      </Row>

      <Row>
        <h6>{t('addNew_patienScreen.add_step2.tabRecords.intra_oralRecords')}</h6>
      </Row>
      <Row className="justify-content-around" sm={12} md={6} lg={6} xl={6}>
        {[
          'intraoral_occlucion_front',
          'intraoral_occlucion_ovj',
          'intraoral_occlucion_left',
          'intraoral_occlucion_right',
          'intraoral_arch_superior',
          'intraoral_arch_inferior',
        ].map(type => {
          return (
            <div
              className={`${records[type] ? 'imageRectangle-with-photo' : 'imageRectangle'} shadow my-1 relative p-0`}
              key={Math.random() * 100}
            >
              <RecordField
                image={{
                  photo_url: records[type],
                  minimized_photo_url: records[type],
                }}
                editImageProgress={editImageProgress}
                editImg={editImg}
                deleteImg={deleteImg}
                recordType={type}
                readOnly={readOnly}
              />
            </div>
          );
        })}
      </Row>
    </div>
  );
}

export default RecordsTab;
