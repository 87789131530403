// components
import { SignupLayout } from '../../components/layout/SignupLayout';

// lib
import { usePatientSignup } from './hooks/usePatientSignup';

// constants
import { SIGNUP_STEPS_TO_COMPONENT } from './constants/signupStepToComponent';

const PatientSignupFamilyInvitation = () => {
  const { signupStep, verifiedState, isLoading, error, onNext } = usePatientSignup();
  const SignupStep = SIGNUP_STEPS_TO_COMPONENT[signupStep];

  return (
    <SignupLayout>
      <SignupStep verifiedState={verifiedState} isLoading={isLoading} error={error} onNext={onNext} />
    </SignupLayout>
  );
};

export { PatientSignupFamilyInvitation };
