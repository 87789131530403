export const PATIENTS_LIST_REQUEST = 'PATIENTS_LIST_REQUEST';
export const PATIENTS_LIST_SUCCESS = 'PATIENTS_LIST_SUCCESS';
export const PATIENTS_LIST_FAIL = 'PATIENTS_LIST_FAIL';

export const PATIENT_PROFILE_REQUEST = 'PATIENT_PROFILE_REQUEST';
export const PATIENT_PROFILE_SUCCESS = 'PATIENT_PROFILE_SUCCESS';
export const PATIENT_PROFILE_FAIL = 'PATIENT_PROFILE_FAIL';

export const PATIENT_CREATE_REQUEST = 'PATIENT_CREATE_REQUEST';
export const PATIENT_CREATE_SUCCESS = 'PATIENT_CREATE_SUCCESS';
export const PATIENT_CREATE_FAIL = 'PATIENT_CREATE_FAIL';
export const PATIENT_CREATE_RESET = 'PATIENT_CREATE_FAIL';

export const PATIENT_UPDATE_REQUEST = 'PATIENT_UPDATE_REQUEST';
export const PATIENT_UPDATE_SUCCESS = 'PATIENT_UPDATE_SUCCESS';
export const PATIENT_UPDATE_FAIL = 'PATIENT_UPDATE_FAIL';
export const PATIENT_UPDATE_RESET = 'PATIENT_UPDATE_FAIL';

export const PATIENT_DELETE_REQUEST = 'PATIENT_DELETE_REQUEST';
export const PATIENT_DELETE_SUCCESS = 'PATIENT_DELETE_SUCCESS';
export const PATIENT_DELETE_FAIL = 'PATIENT_DELETE_FAIL';
export const PATIENT_DELETE_RESET = 'PATIENT_DELETE_FAIL';
