// hooks
import { useCallback, useState, useEffect } from 'react';
import { usePatientAuthQuery } from '../hooks/usePatientAuthQuery';
import { useTranslation } from 'react-i18next';
// components
import Loader from '../../../components/Loader';
import { InviteInfo } from './InviteInfo';
import { FormInput } from '../../../components/FormInput';

const ClinicPatientVerificationForm = ({
  verifiedState,
  onNext: _onSubmit,
  isLoading: isSubmitInProgress,
  error: submitError,
}) => {
  const { t } = useTranslation('patientSignup');
  const [{ uidb64, token, patient_code, email }, setState] = useState(verifiedState);
  let data = verifiedState.data;
  let isSuccess = data?.valid_invitation;
  let isError = data?.data?.valid_invitation == false;
  const { mutate, isPending, error } = usePatientAuthQuery();

  const onFieldChange = useCallback((value, fieldId) => {
    setState(prevState => ({ ...prevState, [fieldId]: value }));
  }, []);

  const onSubmit = () => {
    _onSubmit({ uidb64, token, patient_code, email });
  };

  let headerEl;
  if (isError) {
    headerEl = <InviteInfo clinicName="Orthobook" />;
  } else if (isPending) {
    headerEl = <Loader />;
  } else {
    headerEl = isSuccess && (
      <InviteInfo
        isInvitatioValid={data?.valid_invitation}
        clinicName={data['inviter'].first_name + ' ' + data['inviter'].last_name}
      />
    );
  }

  return (
    <div
      className="tw-flex tw-flex-col tw-w-full tw-h-full tw-items-center tw-justify-between tw-py-12 md:tw-pb-36 tw-mx-12"
      style={{ maxWidth: '440px' }}
    >
      <div>{headerEl}</div>

      {isError ? null : (
        <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full">
          <FormInput
            id="email"
            type="email"
            value={isSuccess && data ? data['email'] : null}
            label={t('e-mail')}
            error={submitError.uidb64}
            onChange={onFieldChange}
          />
          <button
            className="tw-w-full tw-bg-lightBlue hover:tw-bg-darkBlue tw-py-6 tw-rounded-20 tw-text-white tw-transition-colors"
            onClick={onSubmit}
            disabled={isSubmitInProgress}
          >
            {isSubmitInProgress ? <Loader size="20px" /> : t('Register')}
          </button>
        </div>
      )}
    </div>
  );
};

export { ClinicPatientVerificationForm };
