// UploadButton.js

import React from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as UploadIcon } from '../../../asset/icons/upload-icon.svg';
import { uploadOtherRecords } from '../../../actions/visitsActions';

function UploadButton(props) {
  const dispatch = useDispatch();

  const { patientId, visitId } = props;

  const DataURIToBlob = dataURI => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  const getFormData = loadedFile => {
    let formData = new FormData();
    let size_limit = 40 * 10 ** 6;

    //Showing alert when file size exceeds limit.
    if (loadedFile.size > size_limit) {
      alert('file is too big! /n File limit is 40Mb.');
      return;
    }

    if (loadedFile?.editedBlob) {
      let file = DataURIToBlob(loadedFile.uri);
      formData.append('record', file, loadedFile.type);
    } else {
      formData.append('record', loadedFile, loadedFile.name);
      const fileType = loadedFile.type.startsWith('image/') ? 'Image' : 'Video';
      formData.append('file_type', fileType);
    }
    return formData;
  };

  const handleFileSelect = event => {
    const loadedFile = event.target.files[0];

    let formData = getFormData(loadedFile);

    if (loadedFile) {
      dispatch(uploadOtherRecords(patientId, visitId, formData));
    }
  };

  return (
    <div className="pt-10">
      <input
        type="file"
        id="fileInput"
        accept="image/*, video/*"
        multiple
        style={{ display: 'none' }}
        onChange={handleFileSelect}
      />
      <label htmlFor="fileInput">
        <UploadIcon className="upload-icon" />
      </label>
    </div>
  );
}

export default UploadButton;
