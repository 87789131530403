// import axios from "axios";
import axios from '../helpers/axiosConfig';
import {
  ANAMNESIS_LIST_REQUEST,
  ANAMNESIS_LIST_SUCCESS,
  ANAMNESIS_LIST_FAIL,
  ANAMNESIS_CREATE_REQUEST,
  ANAMNESIS_CREATE_SUCCESS,
  ANAMNESIS_CREATE_FAIL,
  // ANAMNESIS_CREATE_RESET,
  ANAMNESIS_UPDATE_REQUEST,
  ANAMNESIS_UPDATE_SUCCESS,
  ANAMNESIS_UPDATE_FAIL,
  // ANAMNESIS_UPDATE_RESET,
  T_NOTES_LIST_REQUEST,
  T_NOTES_LIST_SUCCESS,
  T_NOTES_LIST_FAIL,
  T_NOTES_CREATE_REQUEST,
  T_NOTES_CREATE_SUCCESS,
  T_NOTES_CREATE_FAIL,
  // T_NOTES_CREATE_RESET,
  T_NOTES_UPDATE_REQUEST,
  T_NOTES_UPDATE_SUCCESS,
  T_NOTES_UPDATE_FAIL,
  // T_NOTES_UPDATE_RESET,
} from '../constants/notesConstants';

export const getAnamnesisList = id => async (dispatch, getState) => {
  try {
    dispatch({ type: ANAMNESIS_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`/api/patients/profile/${id}/anamnesis`, config);

    dispatch({
      type: ANAMNESIS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ANAMNESIS_LIST_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const createAnamnesis = (id, anamnesis) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ANAMNESIS_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(`/api/patients/profile/${id}/anamnesis/add`, anamnesis, config);

    dispatch({
      type: ANAMNESIS_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ANAMNESIS_CREATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const updateAnamnesis = (patientId, anamnesis) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ANAMNESIS_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(`/api/patients/profile/${patientId}/anamnesis/edit`, anamnesis, config);

    dispatch({
      type: ANAMNESIS_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ANAMNESIS_UPDATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const getTreatmentNotesList = id => async (dispatch, getState) => {
  try {
    dispatch({ type: T_NOTES_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`/api/patients/profile/${id}/notes`, config);

    dispatch({
      type: T_NOTES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: T_NOTES_LIST_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const createTreatmentNotes = (id, notes) => async (dispatch, getState) => {
  try {
    dispatch({
      type: T_NOTES_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(`/api/patients/profile/${id}/notes/add`, notes, config);

    dispatch({
      type: T_NOTES_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: T_NOTES_CREATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const updateTreatmentNotes = (patientId, notes) => async (dispatch, getState) => {
  try {
    dispatch({
      type: T_NOTES_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(`/api/patients/profile/${patientId}/notes/edit`, notes, config);

    dispatch({
      type: T_NOTES_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: T_NOTES_UPDATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

/*
export const clinicsDetails = (id) => async (dispatch, getState) => {
    try{
        
        dispatch({type: CLINIC_PROFILE_REQUEST})

        const {
            userLogin: {userInfo},
        } =getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(
            //`/api/users/profile/clinics/${id}`,
            `/api/users/profile/clinics/${id}`,
            config
        )

        dispatch({
            type: CLINIC_PROFILE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CLINIC_PROFILE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateClinics = (clinic) => async (dispatch, getState) => {
    try{
        dispatch({
            type: CLINIC_UPDATE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } =getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.put(
            `/api/users/profile/clinics/update/${clinic._id}`,
            clinic,
            config
        )

        dispatch({
            type: CLINIC_UPDATE_SUCCESS,
            payload: data
        })
       
    } catch (error) {
        dispatch({
            type: CLINIC_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createClinics = (newClinic) => async (dispatch, getState) => {
    try{
        dispatch({
            type: CLINIC_CREATE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } =getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.post(
            `/api/users/profile/clinics/addnew/`,
            newClinic,
            config
        )

        dispatch({
            type: CLINIC_CREATE_SUCCESS,
            payload:data,
        })

    } catch (error) {
        dispatch({
            type: CLINIC_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

*/
