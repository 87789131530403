import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Row, Col, Table, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';

import RecordField from '../../components/ImageComponent/RecordField';

function DiagnosisTab(props) {
  const { t: tabDiagnosisT } = useTranslation('tabDiagnosis');
  const { diagnosis, setDiagnosis } = props;

  const [malloclusionClass, setMalloclusionClass] = useState('');
  const [overbite, setOverbite] = useState('');
  const [overbiteMeasure, setOverbiteMeasure] = useState('');
  const [overjet, setOverjet] = useState('');
  const [overjetMeasure, setOverjetMeasure] = useState('');
  const [biteRight, setBiteRight] = useState('');
  const [biteLeft, setBiteLeft] = useState('');
  const [biteAnterior, setBiteAnterior] = useState('');
  const [crowdingSuperior, setCrowdingSuperior] = useState(false);
  const [crowdingInferior, setCrowdingInferior] = useState(false);
  const [crowdingSuperior_measure, setCrowdingSuperior_measure] = useState('');
  const [crowdingInferior_measure, setCrowdingInferior_measure] = useState('');
  const [facialSimmetry, setFacialSimmetry] = useState(false);
  const [lipsComptence, setLipsComptence] = useState(false);
  const [profile, setProfile] = useState(false);
  const [nasoLabialAngle, setNasoLabialAngle] = useState(false);

  // const userLogin = useSelector(state => state.userLogin)
  // const { userInfo } = userLogin

  return (
    <div>
      <Row>
        <h5>{tabDiagnosisT('title')}</h5>
      </Row>

      <Form className="py-3">
        <Form.Group controlId="malloclusionClass">
          <Row>
            <h6>{tabDiagnosisT('mallocclusion_row.title')}</h6>
          </Row>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className="mb-3">
              <Form.Check
                label={tabDiagnosisT('mallocclusion_row.class1')}
                name="group1"
                type={type}
                id={`occlusion-1`}
                value={0}
                onChange={(
                  e //setMalloclusionClass(e.target.value)
                ) => setDiagnosis({ ...diagnosis, malloclusion_class: e.target.value })}
              />
              <Form.Check
                label={tabDiagnosisT('mallocclusion_row.class2_1')}
                name="group1"
                type={type}
                id={`occlusion-21`}
                value={1}
                //onChange={(e) => setMalloclusionClass(e.target.value)}
                onChange={(
                  e //setMalloclusionClass(e.target.value)
                ) => setDiagnosis({ ...diagnosis, malloclusion_class: e.target.value })}
              />
              <Form.Check
                label={tabDiagnosisT('mallocclusion_row.class2_2')}
                name="group1"
                type={type}
                id={`occlusion-22`}
                value={2}
                //onChange={(e) => setMalloclusionClass(e.target.value)}
                onChange={(
                  e //setMalloclusionClass(e.target.value)
                ) => setDiagnosis({ ...diagnosis, malloclusion_class: e.target.value })}
              />
              <Form.Check
                label={tabDiagnosisT('mallocclusion_row.class3')}
                name="group1"
                type={type}
                id={`occlusion-3`}
                value={3}
                //onChange={(e) => setMalloclusionClass(e.target.value)}
                onChange={(
                  e //setMalloclusionClass(e.target.value)
                ) => setDiagnosis({ ...diagnosis, malloclusion_class: e.target.value })}
              />
            </div>
          ))}
        </Form.Group>

        <Form.Group controlId="Bite" className="pt-2 mt-3">
          <Form.Label>
            <b>{tabDiagnosisT('bite_row.title')}</b>
          </Form.Label>

          <Table responsive className="text-center ">
            <thead>
              <tr>
                <th> </th>
                <th>{tabDiagnosisT('bite_row.right')}</th>
                <th>{tabDiagnosisT('bite_row.anterior')}</th>
                <th>{tabDiagnosisT('bite_row.left')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-start">{tabDiagnosisT('bite_row.normal')}</td>
                <td>
                  <Form.Check
                    label=""
                    name="groupRight"
                    type={'radio'}
                    id={`R-1`}
                    value={0}
                    onChange={e => setDiagnosis({ ...diagnosis, bite_right: e.target.value })}
                  />
                </td>
                <td>
                  <Form.Check
                    label=""
                    name="groupAnteriorBite"
                    type={'radio'}
                    id={`A-1`}
                    value={0}
                    onChange={e => setDiagnosis({ ...diagnosis, bite_anterior: e.target.value })}
                  />
                </td>
                <td>
                  <Form.Check
                    label=""
                    name="groupLeftBite"
                    type={'radio'}
                    id={`L-1`}
                    value={0}
                    onChange={e => setDiagnosis({ ...diagnosis, bite_left: e.target.value })}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-start">{tabDiagnosisT('bite_row.cross')}</td>
                <td>
                  <Form.Check
                    label=""
                    name="groupRight"
                    type={'radio'}
                    id={`R-21`}
                    value={1}
                    onChange={e => setDiagnosis({ ...diagnosis, bite_right: e.target.value })}
                  />
                </td>
                <td>
                  <Form.Check
                    label=""
                    name="groupAnteriorBite"
                    type={'radio'}
                    id={`A-21`}
                    value={1}
                    onChange={e => setDiagnosis({ ...diagnosis, bite_anterior: e.target.value })}
                  />
                </td>
                <td>
                  <Form.Check
                    label=""
                    name="groupLeftBite"
                    type={'radio'}
                    id={`L-21`}
                    value={1}
                    onChange={e => setDiagnosis({ ...diagnosis, bite_left: e.target.value })}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-start">{tabDiagnosisT('bite_row.scissor')}</td>
                <td>
                  <Form.Check
                    label=""
                    name="groupRight"
                    type={'radio'}
                    id={`R-22`}
                    value={2}
                    onChange={e => setDiagnosis({ ...diagnosis, bite_right: e.target.value })}
                  />
                </td>
                <td></td>
                <td>
                  <Form.Check
                    label=""
                    name="groupLeftBite"
                    type={'radio'}
                    id={`L-22`}
                    value={2}
                    onChange={e => setDiagnosis({ ...diagnosis, bite_left: e.target.value })}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Form.Group>

        <Form.Group controlId="OVB and OVJ" className="pt-2 mt-3">
          <Form.Label>
            <b>{tabDiagnosisT('ovb_ovj_row.title')}</b>
          </Form.Label>

          <Row>
            <Col sm={2} md={2} lg={2}>
              <div inline="true" className="pr-2">
                {tabDiagnosisT('ovb_ovj_row.ovb.title')}
              </div>
            </Col>
            <Col>
              <Form.Check
                inline
                label={tabDiagnosisT('ovb_ovj_row.ovb.normal')}
                name="groupOVB"
                type={'radio'}
                id={`OVB-1`}
                value={0}
                onChange={e => setDiagnosis({ ...diagnosis, overbite: e.target.value })}
              />
            </Col>
            <Col>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('ovb_ovj_row.ovb.deep')}
                name="groupOVB"
                type={'radio'}
                id={`OVB-2`}
                value={1}
                onChange={e => setDiagnosis({ ...diagnosis, overbite: e.target.value })}
              />
            </Col>
            <Col>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('ovb_ovj_row.ovb.open')}
                name="groupOVB"
                type={'radio'}
                id={`OVB-3`}
                value={2}
                onChange={e => setDiagnosis({ ...diagnosis, overbite: e.target.value })}
              />
            </Col>
          </Row>
          <Row>
            <Form.Label column sm={4}>
              {tabDiagnosisT('ovb_ovj_row.ovb.measure')}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                size="sm"
                type="number"
                step=".01"
                placeholder={tabDiagnosisT('ovb_ovj_row.ovb.fVal_unity')}
                value={diagnosis.overbite_measure === null ? '' : diagnosis.overbite_measure}
                //onChange={(e) => setOverbiteMeasure(e.target.value)}
                onChange={e => setDiagnosis({ ...diagnosis, overbite_measure: e.target.value })}
              ></Form.Control>
            </Col>
          </Row>

          <Row>
            <Col sm={2} md={2} lg={2}>
              <div inline="true">{tabDiagnosisT('ovb_ovj_row.ovg.title')}</div>
            </Col>
            <Col sm={3} md={3} lg={3}>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('ovb_ovj_row.ovg.normal')}
                name="groupOVJ"
                type={'radio'}
                id={`OVJ-1`}
                value={0}
                onChange={e => setDiagnosis({ ...diagnosis, overjet: e.target.value })}
              />
            </Col>
            <Col sm={3} md={3} lg={3}>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('ovb_ovj_row.ovg.reduced')}
                name="groupOVJ"
                type={'radio'}
                id={`OVJ-2`}
                value={1}
                onChange={e => setDiagnosis({ ...diagnosis, overjet: e.target.value })}
              />
            </Col>
            <Col sm={3} md={3} lg={3}>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('ovb_ovj_row.ovg.increased')}
                name="groupOVJ"
                type={'radio'}
                id={`OVJ-3`}
                value={2}
                onChange={e => setDiagnosis({ ...diagnosis, overjet: e.target.value })}
              />
            </Col>
          </Row>
          <Row>
            <Form.Label column sm={4}>
              {tabDiagnosisT('ovb_ovj_row.ovg.measure')}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                size="sm"
                type="number"
                step=".01"
                placeholder={tabDiagnosisT('ovb_ovj_row.ovg.fVal_unity')}
                value={diagnosis.overjet_measure === null ? '' : diagnosis.overjet_measure}
                onChange={e => setDiagnosis({ ...diagnosis, overjet_measure: e.target.value })}
              ></Form.Control>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group controlId="Crowding" className="pt-2 mt-3">
          <Form.Label>
            <b>{tabDiagnosisT('crowding_row.title')}</b>
          </Form.Label>

          <Row>
            <Col sm={4} md={4} lg={4}>
              <div inline="true" className="pr-2">
                {tabDiagnosisT('crowding_row.superior.title')}
              </div>
            </Col>
            <Col>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('crowding_row.superior.yes')}
                name="groupCrSup"
                type={'radio'}
                id={`CrSup-1`}
                value={true}
                onChange={e => setDiagnosis({ ...diagnosis, crowding_superior: true })}
              />
            </Col>
            <Col sm={1} md={1} lg={1}>
              |
            </Col>
            <Col>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('crowding_row.superior.no')}
                name="groupCrSup"
                type={'radio'}
                id={`CrSup-2`}
                value={false}
                onChange={e => setDiagnosis({ ...diagnosis, crowding_superior: false })}
              />
            </Col>
          </Row>
          <Row>
            <Form.Label column sm={4}>
              {tabDiagnosisT('crowding_row.superior.measure')}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                size="sm"
                type="number"
                step=".01"
                placeholder={tabDiagnosisT('crowding_row.superior.fVal_unity')}
                value={diagnosis.crowding_superior_measure === null ? '' : diagnosis.crowding_superior_measure}
                onChange={e => setDiagnosis({ ...diagnosis, crowding_superior_measure: e.target.value })}
              ></Form.Control>
            </Col>
          </Row>

          <Row>
            <Col sm={4} md={4} lg={4}>
              <div inline="true" className="pr-2">
                {tabDiagnosisT('crowding_row.inferior.title')}
              </div>
            </Col>
            <Col>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('crowding_row.inferior.yes')}
                name="groupCrInf"
                type={'radio'}
                id={`CrInf-1`}
                value={true}
                onChange={e => setDiagnosis({ ...diagnosis, crowding_inferior: true })}
              />
            </Col>
            <Col sm={1} md={1} lg={1}>
              |
            </Col>
            <Col>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('crowding_row.inferior.no')}
                name="groupCrInf"
                type={'radio'}
                id={`CrInf-2`}
                value={false}
                onChange={e => setDiagnosis({ ...diagnosis, crowding_inferior: false })}
              />
            </Col>
          </Row>
          <Row>
            <Form.Label column sm={4}>
              {tabDiagnosisT('crowding_row.inferior.measure')}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                size="sm"
                type="number"
                step=".01"
                placeholder={tabDiagnosisT('crowding_row.inferior.fVal_unity')}
                value={diagnosis.crowding_inferior_measure === null ? '' : diagnosis.crowding_inferior_measure}
                onChange={e => setDiagnosis({ ...diagnosis, crowding_inferior_measure: e.target.value })}
              ></Form.Control>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group controlId="Simmetry" className="pt-2 mt-3">
          <Form.Label>
            <b>{tabDiagnosisT('facial_row.title')}</b>
          </Form.Label>

          <Row>
            <Col>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('facial_row.simetric')}
                name="groupSimmetry"
                type={'radio'}
                id={`Simmetry-1`}
                value={false}
                onChange={e => setDiagnosis({ ...diagnosis, facial_simmetry: false })}
              />
            </Col>
            <Col sm={1} md={1} lg={1}>
              |
            </Col>
            <Col>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('facial_row.asimmetric')}
                name="groupSimmetry"
                type={'radio'}
                id={`Simmetry-2`}
                value={true}
                onChange={e => setDiagnosis({ ...diagnosis, facial_simmetry: true })}
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group controlId="LipsCompetence" className="pt-2 mt-3">
          <Form.Label>
            <b>{tabDiagnosisT('lipsC_row.title')}</b>
          </Form.Label>

          <Row>
            <Col>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('lipsC_row.competent')}
                name="groupCompetence"
                type={'radio'}
                id={`Competence-1`}
                value={false}
                onChange={e => setDiagnosis({ ...diagnosis, lips_comptence: false })}
              />
            </Col>
            <Col sm={1} md={1} lg={1}>
              |
            </Col>
            <Col>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('lipsC_row.incompetent')}
                name="groupCompetence"
                type={'radio'}
                id={`Competence-2`}
                value={true}
                onChange={e => setDiagnosis({ ...diagnosis, lips_comptence: true })}
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group controlId="Profile" className="pt-2 mt-3">
          <Form.Label>
            <b>{tabDiagnosisT('profile_row.title')}</b>
          </Form.Label>

          <Row>
            <Row>
              <div className="pr-2">{tabDiagnosisT('profile_row.profile.title')}</div>
            </Row>
            <Col>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('profile_row.profile.normal')}
                name="groupProfile"
                type={'radio'}
                id={`Profile-1`}
                value={0}
                onChange={e => setDiagnosis({ ...diagnosis, profile: e.target.value })}
              />
            </Col>
            <Col>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('profile_row.profile.concave')}
                name="groupProfile"
                type={'radio'}
                id={`Profile-2`}
                value={1}
                onChange={e => setDiagnosis({ ...diagnosis, profile: e.target.value })}
              />
            </Col>
            <Col>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('profile_row.profile.convex')}
                name="groupProfile"
                type={'radio'}
                id={`Profile-3`}
                value={2}
                onChange={e => setDiagnosis({ ...diagnosis, profile: e.target.value })}
              />
            </Col>
          </Row>

          <Row>
            <Row>
              <div className="pr-2">{tabDiagnosisT('profile_row.nasoL_angle_row.title')} </div>
            </Row>
            <Col>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('profile_row.nasoL_angle_row.normal')}
                name="groupNLAngle"
                type={'radio'}
                id={`NLAngle-1`}
                value={0}
                onChange={e => setDiagnosis({ ...diagnosis, naso_labial_angle: e.target.value })}
              />
            </Col>
            <Col>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('profile_row.nasoL_angle_row.open')}
                name="groupNLAngle"
                type={'radio'}
                id={`NLAngle-2`}
                value={1}
                onChange={e => setDiagnosis({ ...diagnosis, naso_labial_angle: e.target.value })}
              />
            </Col>
            <Col>
              <Form.Check
                inline="true"
                label={tabDiagnosisT('profile_row.nasoL_angle_row.closed')}
                name="groupNLAngle"
                type={'radio'}
                id={`NLAngle-3`}
                value={2}
                onChange={e => setDiagnosis({ ...diagnosis, naso_labial_angle: e.target.value })}
              />
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </div>
  );
}

export default DiagnosisTab;
