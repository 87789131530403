import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Row, Col, Modal, Card } from 'react-bootstrap';
import { getAnamnesisList, updateAnamnesis } from '../../actions/notesActions';

import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

function AnamnesisTab(props) {
  const { t } = useTranslation('contentTrs');
  const { patientId } = props;

  const dispatch = useDispatch();

  const [upadateAnamnesis, setUpdateAnamnesis] = useState(false);

  const patientAnamnesis = useSelector(state => state.patientAnamnesis);
  const { error: errorVAdd, loading: loadingVadd, anamnesis } = patientAnamnesis;

  const initialNotes = anamnesis ? anamnesis['description'] : '';
  const [anamnesisBody, setAnamnesisBody] = useState(initialNotes);

  // Init Toast Alert
  const [openSnack, setOpenSnack] = useState({
    open: false,
    displayElement: 'hidden',
    vertical: 'top',
    horizontal: 'center',
  });
  const [msg, setMsg] = useState(`Modifiche Salvate`);
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  // End Toast Alert

  useEffect(() => {
    if (upadateAnamnesis) {
      dispatch(getAnamnesisList(patientId));
      setUpdateAnamnesis(false);
    }
  }, [dispatch, upadateAnamnesis]);

  const submitAnamnesis = async e => {
    e.preventDefault();
    let body = { description: anamnesisBody };
    dispatch(updateAnamnesis(patientId, body));
    setUpdateAnamnesis(true);
    setOpenSnack({ open: true, displayElement: 'visible' });
    // console.log(body)
  };

  return (
    <div className="px-2">
      <Row className="text-center">
        <h5>{t('single_patienScreens.anamnesis.title')}</h5>
      </Row>
      {/* <Row className='justify-center' >
            here you can add notes and details of the anamnesis
        </Row> */}
      <Row className="justify-center">
        {anamnesis && (
          <Form onSubmit={submitAnamnesis}>
            <Form.Group className="p-1" controlId="anamnesis_description">
              <Form.Label>{t('single_patienScreens.anamnesis.note')}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={anamnesisBody}
                onChange={e => setAnamnesisBody(e.target.value)}
              />
            </Form.Group>

            <Button type="submit" variant="primary" className="form-button text-end">
              {t('single_patienScreens.anamnesis.update')}
            </Button>
          </Form>
        )}
      </Row>

      <Snackbar
        style={{ visibility: openSnack.displayElement }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          {' '}
          {msg}{' '}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AnamnesisTab;
