import React, { useState, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { deletePatient } from '../../../actions/patientsActions';

function DeleteButton(props) {
  const { patientId, patientFirstName, patientSurname } = props;

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletePhrase, setDeletePhrase] = useState('');
  const [password, setPassword] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const dispatch = useDispatch();

  const { t } = useTranslation('contentTrs');

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleDelete = async () => {
    try {
      await dispatch(deletePatient(patientId, { delete_pass_phrase: deletePhrase, password: password }));
      handleCloseDeleteModal();
    } catch (err) {
      console.log('The error is: ', err);
      setOpenAlert(true);
    }
  };

  const requiredPhrase = `${t('delete')} ${patientFirstName} ${patientSurname}`;

  return (
    <div>
      <Tooltip title={t('patientsDeleteButton.removePatient')}>
        <MoreVertIcon onClick={handleOpenDeleteModal} />
      </Tooltip>
      <Dialog open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <DialogTitle>{t('patientsDeleteButton.deleteDialogTitle')}</DialogTitle>
        <DialogContent>
          <p>
            {t('patientsDeleteButton.deleteConfirmationMessage')}
            <br />
            <br />
            {t('patientsDeleteButton.deleteProceedConfirmation')}
            <br />
            <br />
            <span className="tw-whitespace-pre">{`${t('patientsDeleteButton.deleteConfirmationInstructions')} '${requiredPhrase}'`}</span>
            <br />
          </p>
          <TextField fullWidth type="text" value={deletePhrase} onChange={e => setDeletePhrase(e.target.value)} />
          <br />
          <br />
          <p>
            {t('patientsDeleteButton.confirmPassword')}
            <br />
          </p>
          <TextField
            fullWidth
            label={t('patientsDeleteButton.confirmPassLabel')}
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="primary">
            {t('patientsDeleteButton.cancel')}
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            disabled={deletePhrase !== requiredPhrase || password === ''}
          >
            {t('patientsDeleteButton.delete')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDeleteModal && openAlert}>
        <DialogTitle items-center>{t('patientsDeleteButton.incorrectPass')}</DialogTitle>
        <DialogContent>{t('patientsDeleteButton.incorrectPassMsg')}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCloseAlert}>
            {t('patientsDeleteButton.okMsg')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteButton;
