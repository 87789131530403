import { useQuery } from '@tanstack/react-query';

import axios from '../../../helpers/axiosConfig';

const useClinicInfoQuery = ({ clinicId }) =>
  useQuery({
    queryKey: ['clinic', clinicId],
    queryFn: async () => {
      const response = await axios.get(`api/remote/patient/profile/login/clinic/${clinicId}`);
      return response.data;
    },
  });

export { useClinicInfoQuery };
