import React, { useState } from 'react';
import axios from 'axios';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import RecordField from '../../components/ImageComponent/RecordField';
import fileDownload from 'js-file-download';

function RemoteRecordsTab(props) {
  const { t } = useTranslation('contentTrs');
  const { visitId, deleteImg = () => {}, editImg = () => {}, readOnly = false, editImageProgress } = props;
  const { userInfo } = useSelector(state => state.userLogin);

  const {
    error: errorPP,
    loading: loadingPP,
    success: successPP,
    patient,
  } = useSelector(state => state.patientProfile);

  const { error: errorR, loading: loadingR, success: successR, records } = useSelector(state => state.recordsList);

  const { photosVisits, loading: loadingRemotePhotos } = useSelector(({ remoteVisitsList }) => remoteVisitsList);

  const [image, setImage] = useState();

  const copyArr = [
    {
      subtype_id: 1,
      photo_url: '/records/1.png',
      minimized_photo_url: '/records/1.png',
    },
  ];

  const downloadImg = async recordType => {
    console.log(patient?.id, records.visit_id, recordType);

    try {
      const config = {
        headers: {
          // 'Content-type': 'image/jpeg',
          Authorization: `Bearer ${userInfo.access}`,
        },
        responseType: 'blob',
      };

      const res = await axios.get(
        `/api/patients/profile/${patient?.id}/visits/${records?.visit_id}/download/${recordType}`,
        config
      );

      let filename = 'visit' + records?.visit_num + '_' + recordType + '.' + res.data.type.split('/')[1];
      fileDownload(res.data, filename);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Row>
        <h5>{t('remoteRecords.title')}</h5>
      </Row>

      <Row>
        <h6>{t('single_patienScreens.recordTabCol.extra_oralRecords')}</h6>
      </Row>
      <Row className="justify-content-around" sm={5} md={5} lg={5} xl={5}>
        {photosVisits &&
          ['front_relaxed', 'lateral_relaxed'].map(type => {
            return (
              <div className="imageRectangle shadow my-1 relative p-0" key={Math.random() * 100}>
                <RecordField
                  image={{
                    photo_url: photosVisits[type],
                    minimized_photo_url: photosVisits?.minimized_records && photosVisits?.minimized_records[type],
                  }}
                  editImageProgress={editImageProgress.percentComplete}
                  recordType={type}
                  readOnly={true}
                  downloadImg={downloadImg}
                />
              </div>
            );
          })}
      </Row>

      <Row>
        <h6>{t('single_patienScreens.recordTabCol.intra_oralRecords')}</h6>
      </Row>
      <Row className="justify-content-around" sm={12} md={6} lg={6} xl={6}>
        {['intraoral_occlucion_front', 'intraoral_occlucion_left', 'intraoral_occlucion_right'].map(type => {
          return (
            <div className="imageRectangle shadow my-1 relative p-0" key={Math.random() * 100}>
              <RecordField
                image={{
                  photo_url: photosVisits[type],
                  minimized_photo_url: photosVisits?.minimized_records && photosVisits?.minimized_records[type], //record //dentistData.photo_url
                }}
                editImageProgress={editImageProgress}
                recordType={type}
                readOnly={true}
                downloadImg={downloadImg}
              />
            </div>
          );
        })}
      </Row>
    </div>
  );
}

export default RemoteRecordsTab;
