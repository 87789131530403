import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Button, Row, Col, Image, Card } from 'react-bootstrap';
import './patientscreen.css';

import { dateToHmDMY, dateToDMY, visitDate } from '../../helpers';

import DeleteIcon from '@mui/icons-material/Delete';

import { fetchPhotosVisits } from '../../actions/remoteVisitsActions';
import { addVisit, listVisits, listRecords } from '../../actions/visitsActions';

import { BsCheckCircle, BsXCircle, BsFillCaretRightSquareFill } from 'react-icons/bs';
import { max } from 'moment';
import DeleteVistModal from './component/DeleteVisitModal';
import { areRecordsEmpty } from '../../utilities/RecordTabUtils';

function VisitsTab(props) {
  const { t } = useTranslation('contentTrs');
  const match = useParams();
  const patientId = match.id;
  const { visitId, setVisitId, setVisitType } = props;
  const dispatch = useDispatch();

  const [visitsList, setVisitsList] = useState(null);
  const [viewPatientVisits, setViewPatientVisits] = useState(false);
  const [viewDoctorVisits, setViewDoctorVisits] = useState(false);
  const [showVisitDeleteConfirmationModal, setShowVisitDeleteConfirmationModal] = useState(false);

  const { error: errorVL, loading: loadingVL, success: successVL, visits } = useSelector(state => state.visitsList);
  const {
    error: errorVAdd,
    loading: loadingVadd,
    success: successVadd,
    visit: newVisits,
  } = useSelector(state => state.visitCreate);
  const { error: errorR, loading: loadingR, success: successR, records } = useSelector(state => state.recordsList);

  const [listSort, setListSort] = useState({
    all: true,
    practice: false,
    home: false,
  });

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const { patient } = useSelector(state => state.patientProfile);

  useEffect(() => {
    if (newVisits?.newVisitId) {
      setVisitId(newVisits?.newVisitId);
    }
  }, [successVadd]);

  useEffect(() => {
    if (patientId) {
      let remote = true;
      dispatch(listVisits(patientId, remote));
    }
  }, []);

  useEffect(() => {
    console.log('visitId', visits);
    console.log('visitId', visitId, typeof visitId);
    let visit_ids = [];
    visits?.doctor?.map(({ id }) => {
      visit_ids.push(id);
    });
    console.log(visit_ids.includes(visitId));
    if (visitId == '' || !visit_ids.includes(visitId)) {
      console.log('visits', visits);
      let max_visit_ids = -1;
      visits?.doctor?.map(v => {
        if (v.id > max_visit_ids) {
          max_visit_ids = v.id;
        }
      });

      if (max_visit_ids > -1) {
        setVisitId(max_visit_ids);
      }

      console.log('visit_ids', visitId);
    }
  }, [visits, successVL]);

  useEffect(() => {
    let tempVisits = [];
    if (visits?.doctor && visits?.remote) {
      tempVisits = [
        ...visits?.doctor.map(item => ({
          ...item,
          sortingDate: item.jpg_date,
        })),
        ...visits?.remote.map(item => ({
          ...item,
          sortingDate: item.createdAt,
        })),
      ].sort((a, b) => {
        return new Date(b.sortingDate) - new Date(a.sortingDate);
      });
      setVisitsList(tempVisits);
    } else {
      if (visits?.doctor) {
        tempVisits = visits?.doctor
          .map(item => ({
            ...item,
            sortingDate: item.jpg_date,
          }))
          .sort((a, b) => {
            return new Date(b.sortingDate) - new Date(a.sortingDate);
          });
        setVisitsList(tempVisits);
      }
    }
  }, [visits]);

  const handleSortList = value => {
    if (value === 'practice') {
      setListSort({
        ...listSort,
        all: !listSort.all,
        practice: !listSort.practice,
      });
    }

    if (value === 'home') {
      setListSort({
        ...listSort,
        all: !listSort.all,
        home: !listSort.home,
      });
    }
  };

  const handleDeleteVisit = () => {
    if (records && areRecordsEmpty(records)) {
      setShowVisitDeleteConfirmationModal(true); // Show confirmation modal if records are empty
    } else {
      alert(t('single_patienScreens.visit.delete.deleteVisitError'));
    }
  };

  const HomeVisit = ({ visit }) => {
    return (
      <React.Fragment key={visit.id}>
        <Card
          className={`p-2 mb-1 shadow radius ${visit.id === visitId ? 'active-visit' : ''} at-home`}
          onClick={() => {
            setVisitId(visit.id);
            setVisitType('Remote');
            dispatch(fetchPhotosVisits({ patientId: patient?.id, visitId: visit.id }));
          }}
        >
          <Row>
            <Col>
              <Row>
                <Col className="text-left">
                  {visit.visit_num === 1 ? (
                    <h6>{t('single_patienScreens.visit.firstVisit')}</h6>
                  ) : (
                    visitDate(visits[0]?.doctor.jpg_date, visit.createdAt)
                  )}
                </Col>
                <Col sm={4} md={4} lg={4} className="text-right p-0 m-0" style={{ fontSize: 12 }}>
                  {dateToDMY(visit.createdAt)}
                </Col>
              </Row>
              <Row>
                <Col sm={4} md={4} lg={4} className=" pl-0 ml-0" style={{ fontSize: 8 }}>
                  {visit.is_extraoral ? <BsCheckCircle color={'green'} size={12} /> : <BsXCircle />}{' '}
                  {t('single_patienScreens.visit.externalOral')}
                </Col>
                <Col sm={3} md={3} lg={3} className=" p-0 m-0" style={{ fontSize: 8 }}>
                  {visit.is_itraoral ? <BsCheckCircle color={'green'} size={12} /> : <BsXCircle />}{' '}
                  {t('single_patienScreens.visit.intraOral')}
                </Col>
              </Row>
            </Col>
            <Col sm={2} md={2} lg={2}>
              <span className="align-bottom" onClick={() => setVisitId(visit.id)}>
                {visit.id === visitId && <BsFillCaretRightSquareFill className="align-bottom" color={'#4BC1CB'} />}
              </span>
            </Col>
            {visit.visit_num !== 1 && (
              <Col sm={1} md={1} lg={1} className="d-flex justify-content-end align-items-center">
                <DeleteIcon onClick={handleDeleteVisit} />
              </Col>
            )}
          </Row>
        </Card>
      </React.Fragment>
    );
  };

  const DoctorVisit = ({ visit }) => {
    return (
      <React.Fragment key={visit.id}>
        <Card
          className={`p-2 mb-1 shadow radius ${visit.id === visitId ? 'active-visit' : ''}`}
          onClick={() => {
            setVisitId(visit.id);
            setVisitType('Doctor');
            dispatch(listRecords(patientId, visit.id));
          }}
        >
          <Row>
            <Col>
              <Row>
                <Col className="text-left">
                  {visit.visit_num === 1 ? (
                    <h6>{t('single_patienScreens.visit.firstVisit')}</h6>
                  ) : (
                    visitDate(visits[0]?.doctor.jpg_date, visit.jpg_date)
                  )}
                </Col>
                <Col sm={4} md={4} lg={4} className="text-right p-0 m-0" style={{ fontSize: 12 }}>
                  {dateToDMY(visit.jpg_date)}
                </Col>
              </Row>
              <Row>
                <Col sm={4} md={4} lg={4} className=" pl-0 ml-0" style={{ fontSize: 8 }}>
                  {visit.is_extraoral ? <BsCheckCircle color={'green'} size={12} /> : <BsXCircle />}{' '}
                  {t('single_patienScreens.visit.externalOral')}
                </Col>
                <Col sm={3} md={3} lg={3} className=" p-0 m-0" style={{ fontSize: 8 }}>
                  {visit.is_itraoral ? <BsCheckCircle color={'green'} size={12} /> : <BsXCircle />}{' '}
                  {t('single_patienScreens.visit.intraOral')}
                </Col>
                <Col sm={3} md={3} lg={3} className=" p-0 m-0" style={{ fontSize: 8 }}>
                  {visit.is_xrays ? <BsCheckCircle color={'green'} size={12} /> : <BsXCircle />}{' '}
                  {t('single_patienScreens.visit.x_rays')}
                </Col>
              </Row>
            </Col>
            <Col sm={2} md={2} lg={2}>
              <span className="align-bottom">
                {visit.id === visitId && <BsFillCaretRightSquareFill className="align-bottom" color={'#4BC1CB'} />}
              </span>
            </Col>
            {visit.visit_num !== 1 && (
              <Col sm={1} md={1} lg={1} className="d-flex justify-content-end align-items-center">
                <DeleteIcon onClick={handleDeleteVisit} />
              </Col>
            )}
          </Row>
        </Card>
      </React.Fragment>
    );
  };

  return (
    <div>
      <Row>
        <Col className="align-self-center col-sm-4">
          <h5>{t('single_patienScreens.visit.title')}</h5>
        </Col>

        <Col className="d-flex justify-content-end col-sm-8">
          <button
            type="button"
            onClick={() => setViewDoctorVisits(!viewDoctorVisits)}
            className={`button-sort ${viewDoctorVisits ? 'at-practice-active' : ''} me-2 px-2 py-1`}
          >
            at the Practice
          </button>
          <button
            type="button"
            onClick={() => setViewPatientVisits(!viewPatientVisits)}
            className={`button-sort at-home ${viewPatientVisits ? 'at-home-active' : ''} px-2 py-1`}
          >
            at Home
          </button>
        </Col>
      </Row>

      {visits && (
        <Row className=" justify-content-around m-0 mt-1 pb-3">
          {/* {
                    visits && visits.hasOwnProperty('doctor') && listSort.practice ||
                    visits && visits.hasOwnProperty('doctor') && listSort.all ?
                    ( visits?.doctor?.slice(0).reverse().map( (visit) => {
                        return(
                            <React.Fragment key={visit.id}>
                                <Card 
                                    className={`p-2 mb-1 shadow radius ${visit.id === visitId ? "active-visit" : ""}`} 
                                    onClick={() => {
                                        setVisitId(visit.id)
                                        setVisitType('Doctor')
                                        dispatch(listRecords(patientId,visit.id))
                                    }}
                                >
                                    <Row>
                                    <Col>
                                        <Row>
                                            <Col className='text-left'>
                                                {visit.visit_num ===1
                                                    ? <h6>{t('single_patienScreens.visit.firstVisit')}</h6>
                                                    : visitDate(visits[0]?.doctor.jpg_date, visit.jpg_date)}
                                            </Col>
                                            <Col sm={4} md={4} lg={4} className ='text-right p-0 m-0' style={{ fontSize: 12}}>
                                                {dateToDMY(visit.jpg_date)}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={4} md={4} lg={4} className =' pl-0 ml-0' style={{ fontSize: 8}} >
                                                    {visit.is_extraoral ? <BsCheckCircle color={'green'} size={12}/>
                                                    : <BsXCircle/> } {t('single_patienScreens.visit.externalOral')}
                                            </Col>
                                            <Col sm={3} md={3} lg={3} className =' p-0 m-0' style={{ fontSize: 8}}>
                                                {visit.is_itraoral ? <BsCheckCircle  color={'green'} size={12}/>
                                                    : <BsXCircle/> } {t('single_patienScreens.visit.intraOral')}
                                            </Col>
                                            <Col sm={3} md={3} lg={3} className =' p-0 m-0' style={{ fontSize: 8}}>
                                                {visit.is_xrays ? <BsCheckCircle  color={'green'} size={12}/>
                                                    : <BsXCircle/> } {t('single_patienScreens.visit.x_rays')}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={2} md={2} lg={2}  >
                                        <span className='align-bottom'  >
                                            { visit.id === visitId && (<BsFillCaretRightSquareFill className='align-bottom' color={'#4BC1CB'} />) }
                                        </span>
                                    </Col>
                                    </Row>

                                </Card>

                            </React.Fragment>
                        ) })
                    )
                : null } */}

          {/* {
                    visits && visits.hasOwnProperty('remote') && listSort.home ||
                    visits && visits.hasOwnProperty('remote') && listSort.all ?
                    ( visits?.remote?.slice(0).reverse().map( (visit) => {
                        return(
                            <React.Fragment key={visit.id}>
                                <Card 
                                    className={`p-2 mb-1 shadow radius ${visit.id === visitId ? "active-visit" : ""} at-home`} 
                                    onClick={() => {
                                            setVisitId(visit.id)
                                            setVisitType('Remote')
                                            dispatch(fetchPhotosVisits({ patientId: patient?.id, visitId: visit.id }));
                                        }}
                                >
                                    <Row>
                                    <Col>
                                        <Row>
                                            <Col className='text-left'>
                                                {visit.visit_num ===1
                                                    ? <h6>{t('single_patienScreens.visit.firstVisit')}</h6>
                                                    : visitDate(visits[0]?.doctor.jpg_date, visit.jpg_date)}
                                            </Col>
                                            <Col sm={4} md={4} lg={4} className ='text-right p-0 m-0' style={{ fontSize: 12}}>
                                                {dateToDMY(visit.jpg_date)}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={4} md={4} lg={4} className =' pl-0 ml-0' style={{ fontSize: 8}} >
                                                    {visit.is_extraoral ? <BsCheckCircle color={'green'} size={12}/>
                                                    : <BsXCircle/> } {t('single_patienScreens.visit.externalOral')}
                                            </Col>
                                            <Col sm={3} md={3} lg={3} className =' p-0 m-0' style={{ fontSize: 8}}>
                                                {visit.is_itraoral ? <BsCheckCircle  color={'green'} size={12}/>
                                                    : <BsXCircle/> } {t('single_patienScreens.visit.intraOral')}
                                            </Col>
                                            <Col sm={3} md={3} lg={3} className =' p-0 m-0' style={{ fontSize: 8}}>
                                                {visit.is_xrays ? <BsCheckCircle  color={'green'} size={12}/>
                                                    : <BsXCircle/> } {t('single_patienScreens.visit.x_rays')}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={2} md={2} lg={2}  >
                                        <span className='align-bottom' onClick={() => setVisitId(visit.id)} >
                                            { visit.id === visitId && (<BsFillCaretRightSquareFill className='align-bottom' color={'#4BC1CB'} />) }
                                        </span>
                                    </Col>
                                    </Row>

                                </Card>

                            </React.Fragment>
                        ) })
                    )
                : null } */}

          {/* { visitsList?.map( (visit) => { return( <p> {visit.id} </p>)} ) } */}
          {visitsList?.map(visit => {
            return (
              <React.Fragment key={visit.id}>
                {!visit.jpg_date ? (
                  (viewPatientVisits && viewDoctorVisits) ||
                  (viewPatientVisits && !viewDoctorVisits) ||
                  (!viewPatientVisits && !viewDoctorVisits) ? (
                    <HomeVisit visit={visit}></HomeVisit>
                  ) : null
                ) : (viewPatientVisits && viewDoctorVisits) ||
                  (!viewPatientVisits && viewDoctorVisits) ||
                  (!viewPatientVisits && !viewDoctorVisits) ? (
                  <DoctorVisit visit={visit}></DoctorVisit>
                ) : null}
              </React.Fragment>
            );
          })}
          {
            <DeleteVistModal
              patientId={patient?.id}
              visitId={visitId}
              showVisitDeleteConfirmationModal={showVisitDeleteConfirmationModal}
              setShowVisitDeleteConfirmationModal={setShowVisitDeleteConfirmationModal}
            />
          }
        </Row>
      )}

      <div className="text-center">
        <Button
          className="form-button text-center"
          onClick={() => {
            dispatch(addVisit(patientId));
          }}
        >
          {t('single_patienScreens.visit.addVisit')}
        </Button>
      </div>
    </div>
  );
}

export default VisitsTab;
