import { useCallback } from 'react';

export const FormInput = ({ onChange: _onChange, value, className, id, label, error, disabled, ...inputProps }) => {
  const onChange = useCallback(
    event => {
      const value = event.target.value;
      _onChange?.(value, id, event);
    },
    [_onChange, id]
  );

  return (
    <div className="tw-relative">
      <input
        id={id}
        className={`${disabled ? 'tw-cursor-not-allowed' : ''} tw-block tw-rounded-20 tw-px-4 tw-pb-2.5 tw-pt-4 tw-w-full tw-text-base tw-text-gray-900 tw-bg-white tw-border-solid tw-border-2 ${error ? 'tw-border-red' : 'tw-border-lightBlue'} tw-appearance-none focus:tw-outline-none focus:tw-ring-0 ${!error ? 'focus:tw-border-darkBlue hover:tw-border-darkBlue' : ''} tw-peer ${className}`}
        placeholder=""
        {...inputProps}
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
      <label
        htmlFor={id}
        className={`tw-absolute tw-text-lg ${error ? 'tw-text-red' : 'tw-text-black'} tw-duration-300 tw-transform -tw-translate-y-4 tw-scale-90 tw-top-2 tw-z-10 tw-origin-[0] tw-bg-white tw-px-2 peer-focus:tw-px-2 peer-focus:tw-text-darkBlue peer-placeholder-shown:tw-scale-100 peer-placeholder-shown:tw-px-4 peer-placeholder-shown:-tw-translate-y-1/2 peer-placeholder-shown:tw-top-1/2 peer-focus:tw-top-2 peer-focus:tw-scale-90 peer-focus:-tw-translate-y-4 tw-start-1`}
      >
        {label}
      </label>
      {error ? (
        <p id="filled_error_help" class="tw-mt-2 tw-text-xs tw-text-red">
          {error}
        </p>
      ) : null}
    </div>
  );
};
