import React, { useState, useEffect } from 'react';
// import axios from "axios";
import axios from '../../../../helpers/axiosConfig';

import { useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../components/Loader';
import Message from '../../../../components/Message';
import Clinic from './Clinic';
import AddClinicPopUp from './AddClinicPopUp';
import { useTranslation } from 'react-i18next';
import DownloadImage from '../../../../components/ImageComponent';

import '../settings.css';

import { listClinics, clinicsDetails, updateClinics } from '../../../../actions/clinicsActions';
import { CLINIC_CREATE_RESET, CLINIC_UPDATE_RESET } from '../../../../constants/clinicsConstants';
import { REQUIRED_COUNTRIES } from '../../../../constants/countryOptions';

// Update logo

function ClinicsTab() {
  const { t } = useTranslation('contentTrs');
  const navigate = useNavigate();

  const [updateClinic, setUpdateClinic] = useState(false);
  const [clinicId, setClinicID] = useState(false);
  const [settleData, setSettleData] = useState(false);
  const [logoUploadSuccess, setLogoUploadSuccess] = useState(false);

  const [message, setMessage] = useState('');

  const [clinicName, setClinicName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [ZIP_code, setZIP_code] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [logo, setLogo] = useState('');
  const [title, setTitle] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [specialty, setSpecialty] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const dentistProfile = useSelector(state => state.dentistProfile);
  const { error: errorDentistProfile, loading: lodaingDentistProfile, dentist } = dentistProfile;

  const clinicsList = useSelector(state => state.clinicsList);
  const { error, loading, clinics } = clinicsList;

  const clinicsProfile = useSelector(state => state.clinicsProfile);
  const { error: errorClinic, loading: loadingClinic, clinic } = clinicsProfile;

  const clinicsUpdate = useSelector(state => state.clinicsUpdate);
  const { error: errorUpdating, loading: loadingUpdating, success: successUpdating } = clinicsUpdate;

  const clinicsCreate = useSelector(state => state.clinicsCreate);
  const { error: errorCreate, loading: loadingCreate, success: successCreate } = clinicsCreate;

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    } else {
      dispatch(listClinics());

      if (successCreate) {
        dispatch({ type: CLINIC_CREATE_RESET });
        dispatch(listClinics());
      } else if (logoUploadSuccess && clinicId) {
        setLogoUploadSuccess(false);
        dispatch(listClinics());
        dispatch(clinicsDetails(clinicId));
      } else if ((!clinic && !settleData) || (clinicId && !settleData) || successUpdating) {
        //console.log('calling dispatch')
        //console.log(clinic.id != clinicId)
        //console.log('clinicid:',clinicId) //check which condition keeps being true

        dispatch({ type: CLINIC_UPDATE_RESET });
        dispatch(clinicsDetails(clinicId));
        setSettleData(true);
      } else {
        //console.log('seeting names for clinc')

        setClinicName(clinic.clinic_name);
        setAddressLine1(clinic.adrress_line1);
        setCity(clinic.city);
        setCountry(clinic.country);
        setZIP_code(clinic.ZIP_code);
        setEmail(clinic.email);
        setPhoneNumber(clinic.phone_number);
        setLogo(clinic.logo);
        setTitle(clinic.title);
        setSpecialty(clinic.specialty);
        setFirstName(clinic.first_name);
        setLastName(clinic.last_name);

        //setEmail(user.email)
      }
    }
  }, [dispatch, navigate, userInfo, clinicId, clinic, settleData, successCreate, successUpdating, logoUploadSuccess]);

  const clickHandler = e => {
    setSettleData(false);

    if (!updateClinic) {
      setUpdateClinic(true);
      setClinicID(e.currentTarget.id);
      //console.log('handler open', e.currentTarget.id)
    } else if (clinicId !== '' && e.currentTarget.id === clinicId) {
      setUpdateClinic(false);
      setClinicID(false);
      //console.log('handler close', e.currentTarget.id)
    } else if (clinicId !== '' && e.currentTarget.id !== clinicId) {
      setClinicID(e.currentTarget.id);
      //console.log('handler update', e.currentTarget.id)
    }
  };

  const submitClinicHandler = e => {
    e.preventDefault();

    dispatch(
      updateClinics({
        _id: clinic.id,
        clinic_name: clinicName,
        adrress_line1: addressLine1,
        city: city,
        ZIP_code: ZIP_code,
        country: country,
        email: email,
        phone_number: phoneNumber,
        title: title,
        specialty: specialty,
        first_name: firstName,
        last_name: lastName,
      })
    );
    setMessage('');
  };

  const editImg = async loadedFile => {
    let formData = new FormData();
    //const loadedFile = e.target.files[0];
    formData.append('clinic_id', clinic.id);
    formData.append('logo', loadedFile);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.put('/api/users/profile/clinics/logo/', formData, config);
      setLogoUploadSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Row className="py-2">
      <Col md={6}>
        <Row>
          <Col>
            <h5>{t('settingsPage.clinicPresentation.clinicColTitle')}</h5>
          </Col>
          <Col className="text-end" sm={4} md={4} lg={4} xl={4}>
            <AddClinicPopUp />
          </Col>
        </Row>

        {error && <Message variant="danger">{errorDentistProfile}</Message>}
        {loading && <Loader />}

        {clinics.map(clinic => (
          <Col key={clinic.id} sm={12} md={12} lg={12} xl={12} className="clinic-card">
            <Clinic clinic={clinic} clickHandler={clickHandler}></Clinic>
          </Col>
        ))}
      </Col>

      <Col md={4}>
        {updateClinic ? <h5>{t('settingsPage.clinicPresentation.clinicDetails.sectionTitle')}</h5> : null}
        <div></div>

        {errorUpdating && <Message variant="danger">{errorUpdating}</Message>}
        {loadingUpdating && <Loader />}

        {updateClinic ? (
          <div className="bigLogo position-relative">
            <DownloadImage
              image={{
                photo_url: logo,
                minimized_photo_url: logo, //dentistData.photo_url
              }}
              user={clinic}
              editImg={e => editImg(e)}
            />
          </div>
        ) : null}

        {updateClinic ? (
          <Form onSubmit={submitClinicHandler} className="py-3">
            <Form.Group controlId="clinic_name">
              <Form.Label>{t('settingsPage.clinicPresentation.clinicDetails.clinicName')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('settingsPage.clinicPresentation.clinicDetails.fval_clinicName')}
                value={clinicName}
                onChange={e => setClinicName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="addressLine1">
              <Form.Label>{t('settingsPage.clinicPresentation.clinicDetails.fullAddress')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('settingsPage.clinicPresentation.clinicDetails.fval_fullAddress')}
                value={addressLine1}
                onChange={e => setAddressLine1(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="city">
              <Form.Label>{t('City')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('City')}
                value={city}
                onChange={e => setCity(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="ZIP_code">
              <Form.Label>{t('ZIP Code')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('ZIP Code')}
                value={ZIP_code}
                onChange={e => setZIP_code(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="Country">
              <Form.Label>{t('authPages.registerNewAccount.section3_profil.country')}</Form.Label>
              <Form.Select
                required
                placeholder={t('authPages.registerNewAccount.section3_profil.fval_country')}
                value={country}
                onChange={e => setCountry(e.target.value)}
              >
                {REQUIRED_COUNTRIES.map(country => (
                  <option value={country.code} key={country.code}>
                    {country.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>{t('settingsPage.clinicPresentation.clinicDetails.email')}</Form.Label>
              <Form.Control
                type="email"
                placeholder={t('settingsPage.clinicPresentation.clinicDetails.fval_email')}
                value={email}
                onChange={e => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="phoneNumber">
              <Form.Label>{t('settingsPage.clinicPresentation.clinicDetails.phoneNumber')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('settingsPage.clinicPresentation.clinicDetails.fval_phoneNumber')}
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="title">
              <Form.Label>{t('settingsPage.clinicPresentation.clinicDetails.titleQualification')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('settingsPage.clinicPresentation.clinicDetails.fval_title')}
                value={title}
                onChange={e => setTitle(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="firstName">
              <Form.Label>{t('settingsPage.clinicPresentation.clinicDetails.firstName')}</Form.Label>
              <Form.Control
                type="name"
                placeholder={t('settingsPage.clinicPresentation.clinicDetails.fval_fName')}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="lastName">
              <Form.Label>{t('settingsPage.clinicPresentation.clinicDetails.lastName')}</Form.Label>
              <Form.Control
                type="name"
                placeholder={t('settingsPage.clinicPresentation.clinicDetails.fval_lName')}
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="specialty">
              <Form.Label>{t('settingsPage.clinicPresentation.clinicDetails.speciality')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('settingsPage.clinicPresentation.clinicDetails.fval_specialty')}
                value={specialty}
                onChange={e => setSpecialty(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type="submit" variant="primary" className="form-button">
              {t('settingsPage.clinicPresentation.clinicDetails.buttonUpdate')}
            </Button>
          </Form>
        ) : null}
      </Col>
    </Row>
  );
}

export default ClinicsTab;
