import axios from 'axios';
import axiosInstance from '../helpers/axiosConfig';
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  DENTIST_REGISTER_REQUEST,
  DENTIST_REGISTER_SUCCESS,
  DENTIST_REGISTER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  // USER_UPDATE_PROFILE_RESET,
  DENTIST_PROFILE_REQUEST,
  DENTIST_PROFILE_SUCCESS,
  DENTIST_PROFILE_FAIL,
  // DENTIST_PROFILE_RESET ,
  DENTIST_UPDATE_PROFILE_REQUEST,
  DENTIST_UPDATE_PROFILE_SUCCESS,
  DENTIST_UPDATE_PROFILE_FAIL,
  // DENTIST_UPDATE_PROFILE_RESET ,
  DENTIST_INVOICING_REQUEST,
  DENTIST_INVOICING_SUCCESS,
  DENTIST_INVOICING_FAIL,
  // DENTIST_INVOICING_RESET ,
  DENTIST_UPDATE_INVOICING_REQUEST,
  DENTIST_UPDATE_INVOICING_SUCCESS,
  DENTIST_UPDATE_INVOICING_FAIL,
  // DENTIST_UPDATE_INVOICING_RESET ,
} from '../constants/userConstants';

export const login = (email, password) => async dispatch => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await axios.post('/api/users/login/', { email: email, password: password }, config);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const logout = () => dispatch => {
  localStorage.removeItem('userInfo');
  // window.localStorage.clear()
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
  window.location.reload(true);
};

export const refreshToken = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: REFRESH_TOKEN_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data: tokens } = await axios.post('/api/users/login/refresh/', { refresh: userInfo.refresh }, config); // return new access and refresh tokens

    const data = userInfo;
    data.refresh = tokens.refresh;
    data.access = tokens.access;

    dispatch({
      type: REFRESH_TOKEN_SUCCESS,
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
    //console.log('triggered refresh', tokens)
  } catch (error) {
    //console.log('fail', error)
    dispatch(logout());
  }
};

export const register = (name, email, password) => async dispatch => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await axios.post('/api/users/register/', { name: name, email: email, password: password }, config);

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const registerDentist = formData => async dispatch => {
  try {
    dispatch({
      type: DENTIST_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await axios.post('/api/users/registerDentist/', formData, config);

    dispatch({
      type: DENTIST_REGISTER_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: DENTIST_REGISTER_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

// //
// Use Axios refresh token interceptor for the next calls
// //

export const getUserDetails = id => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axiosInstance.get(
      //`/api/users/${id}`,
      `/api/users/profile/`,
      config
    );

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const updateUserProfile = user => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axiosInstance.put(`/api/users/profile/update/`, user, config);

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    });
    console.log(data);
    console.log(userInfo);

    // dispatch({
    //     type: USER_LOGIN_SUCCESS,
    //     payload: data
    // })
    dispatch(refreshToken());

    userInfo.name = data.name; //This line needs to be checked again: you should not alter element from state, you should use useState()

    //localStorage.setItem('userInfo.name', JSON.stringify(data.name))
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const getDentistProfile = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: DENTIST_PROFILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axiosInstance.get(`/api/users/profile/dentist/`, config);

    dispatch({
      type: DENTIST_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DENTIST_PROFILE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const updateDentistProfile = dentist => async (dispatch, getState) => {
  try {
    dispatch({
      type: DENTIST_UPDATE_PROFILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axiosInstance.put(`/api/users/profile/dentist/update/`, dentist, config);

    dispatch({
      type: DENTIST_UPDATE_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DENTIST_UPDATE_PROFILE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const getDentistInvoicing = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: DENTIST_INVOICING_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axiosInstance.get(`/api/users/profile/invoicing/`, config);

    dispatch({
      type: DENTIST_INVOICING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DENTIST_INVOICING_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const updateDentistInvoicing = invoicing => async (dispatch, getState) => {
  try {
    dispatch({
      type: DENTIST_UPDATE_INVOICING_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axiosInstance.put(`/api/users/profile/invoicing/update/`, invoicing, config);

    dispatch({
      type: DENTIST_UPDATE_INVOICING_SUCCESS,
      payload: data,
    });
    console.log(invoicing);
    console.log(data);
  } catch (error) {
    dispatch({
      type: DENTIST_UPDATE_INVOICING_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};
