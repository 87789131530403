import { SIGNUP_STEPS } from './signupSteps';

import { ClinicPatientVerificationForm } from '../components/ClinicPatientVerificationForm';
import { TermsAndConditionForm } from '../components/TermsAndConditionForm';
import { EmailVerificationForm } from '../components/EmailVerificationForm';
import { LoginAgain } from '../components/LoginAgain';
import { PatientRegisterForm } from '../components/PatientRegisterForm';

export const SIGNUP_STEPS_TO_COMPONENT = {
  [SIGNUP_STEPS.CLINIC_PATIENT_VERIFICATION]: ClinicPatientVerificationForm,
  [SIGNUP_STEPS.TERMS_AND_CONDITION_VERIFICATION]: TermsAndConditionForm,
  [SIGNUP_STEPS.EMAIL_VERIFICATION]: EmailVerificationForm,
  [SIGNUP_STEPS.LOGIN]: LoginAgain,
  [SIGNUP_STEPS.PATIENT_REGISTER_FORM]: PatientRegisterForm,
};
