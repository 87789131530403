// hooks
import { useCallback, useState } from 'react';
import { useClinicInfoQuery } from '../hooks/useClinicInfoQuery';
import { useTranslation } from 'react-i18next';

// components
import Loader from '../../../components/Loader';
import { ClinicInfo } from './ClinicInfo';
import { FormInput } from '../../../components/FormInput';

const PasswordVerificationForm = ({
  verifiedState,
  onNext: _onSubmit,
  isLoading: isSubmitInProgress,
  error: submitError,
}) => {
  const { t } = useTranslation('patientSignup');
  const [{ clinicId, patientId, birthDate, emailId, password, confirmPassword }, setState] = useState(verifiedState);
  const { data, error, isLoading } = useClinicInfoQuery({ clinicId });

  const onFieldChange = useCallback((value, fieldId) => {
    setState(prevState => ({ ...prevState, [fieldId]: value }));
  }, []);

  const onSubmit = () => {
    _onSubmit({ clinicId, patientId, birthDate, emailId, password, confirmPassword });
  };

  let headerEl;
  if (error) {
    headerEl = <ClinicInfo clinicName="Orthobook" />;
  } else if (isLoading) {
    headerEl = <Loader />;
  } else {
    headerEl = <ClinicInfo clinicImageUrl={data.logo} clinicName={data['clinic_name']} />;
  }

  return (
    <div
      className="tw-flex tw-flex-col tw-w-full tw-h-full tw-items-center tw-justify-between tw-py-12 md:tw-pb-36 tw-mx-12"
      style={{ maxWidth: '440px' }}
    >
      <div>{headerEl}</div>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full">
        <FormInput
          id="emailId"
          type="text"
          value={emailId}
          label={t('Email')}
          error={submitError.emailId}
          onChange={onFieldChange}
        />
        <FormInput
          id="password"
          type="password"
          value={password}
          label={t('Password')}
          error={submitError.password}
          onChange={onFieldChange}
        />
        <FormInput
          id="confirmPassword"
          type="password"
          value={confirmPassword}
          label={t('Confirm Password')}
          error={submitError.confirmPassword}
          onChange={onFieldChange}
        />
        <button
          className="tw-w-full tw-bg-lightBlue hover:tw-bg-darkBlue tw-py-6 tw-rounded-20 tw-text-white tw-transition-colors"
          onClick={onSubmit}
          disabled={isSubmitInProgress}
        >
          {isSubmitInProgress ? <Loader /> : t('Register')}
        </button>
      </div>
    </div>
  );
};

export { PasswordVerificationForm };
