// lib
import { useCallback } from 'react';
import FilerobotImageEditor, { TABS } from 'react-filerobot-image-editor';

// hooks
import { useVisibilityToggle } from '../../hooks/useVisibilityToggle';
import { useTranslation } from 'react-i18next';

// components
import { Button, Modal, Image } from 'react-bootstrap';

// const
import { base_URL_media } from '../../helpers/axiosConfig';

// CSS
import '../componentsCSS/Components.css';

const fileRobotAnnotations = {
  fill: 'rgba(0, 0, 0, 0)',
  stroke: 'rgba(0, 0, 255, 1)',
  strokeWidth: 2,
};
const fileRobotTabs = [TABS.ADJUST, TABS.FINETUNE, TABS.RESIZE, TABS.ANNOTATE, TABS.WATERMARK];

export default function ImageEditorModal(props) {
  const { t } = useTranslation('contentTrs');
  const { image, editImg, isVisible = false, handleClose: _handleClose, recordType } = props;

  const { isVisible: isImageEditorVisible, show: showImageEditor, hide: hideImageEditor } = useVisibilityToggle(false);

  const handleClose = useCallback(() => {
    _handleClose();
    hideImageEditor();
  }, [_handleClose, hideImageEditor]);

  const saveEditedImage = useCallback(
    editedImageObject => {
      const file = {
        uri: editedImageObject.imageBase64,
        type: editedImageObject.mimeType,
        fileName: editedImageObject.name,
        editedBlob: true,
      };
      editImg(file, recordType);
    },
    [editImg, recordType]
  );

  const addDefaultImageName = useCallback(
    editedImageObject => {
      if (!editedImageObject.name) {
        editedImageObject.name = recordType;
      }
    },
    [recordType]
  );

  return (
    <>
      <Modal
        show={isVisible}
        onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={false}
        className="p-2 modal_img"
        size={isImageEditorVisible ? 'lg' : 'sm'}
      >
        {!isImageEditorVisible && <Modal.Header closeButton />}
        <Modal.Body>
          {isImageEditorVisible ? (
            <FilerobotImageEditor
              source={`${image.photo_url}${'&t=' + Math.random()}`}
              onBeforeSave={addDefaultImageName}
              onSave={saveEditedImage}
              onClose={handleClose}
              annotationsCommon={fileRobotAnnotations}
              tabsIds={fileRobotTabs}
            />
          ) : (
            <Image src={`${base_URL_media}${image.photo_url}`} className="modal_img_size" fluid />
          )}
        </Modal.Body>

        {!isImageEditorVisible && (
          <Modal.Footer>
            <Button variant="secondary baseButton" onClick={showImageEditor}>
              {t('addNew_patienScreen.add_step2.tabRecords.uploadPopup.buttonEdit')}
            </Button>

            <Button variant="secondary" onClick={handleClose}>
              {t('addNew_patienScreen.add_step2.tabRecords.uploadPopup.buttonClose')}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}
