import {
  DENTIST_CLINICS_REQUEST,
  DENTIST_CLINICS_SUCCESS,
  DENTIST_CLINICS_FAIL,
  CLINIC_PROFILE_REQUEST,
  CLINIC_PROFILE_SUCCESS,
  CLINIC_PROFILE_FAIL,
  CLINIC_CREATE_REQUEST,
  CLINIC_CREATE_SUCCESS,
  CLINIC_CREATE_FAIL,
  CLINIC_CREATE_RESET,
  CLINIC_UPDATE_REQUEST,
  CLINIC_UPDATE_SUCCESS,
  CLINIC_UPDATE_FAIL,
  CLINIC_UPDATE_RESET,
} from '../constants/clinicsConstants';

export const clinicsListReducer = (state = { clinics: [] }, action) => {
  switch (action.type) {
    case DENTIST_CLINICS_REQUEST:
      return { loading: true, clinics: [] };

    case DENTIST_CLINICS_SUCCESS:
      return { loading: false, clinics: action.payload };

    case DENTIST_CLINICS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const clinicsProfileReducer = (state = { clinic: {} }, action) => {
  switch (action.type) {
    case CLINIC_PROFILE_REQUEST:
      return { loading: true, ...state };

    case CLINIC_PROFILE_SUCCESS:
      return { loading: false, clinic: action.payload };

    case CLINIC_PROFILE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const clinicsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CLINIC_UPDATE_REQUEST:
      return { loading: true };

    case CLINIC_UPDATE_SUCCESS:
      return { loading: false, success: true };

    case CLINIC_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case CLINIC_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const clinicsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CLINIC_CREATE_REQUEST:
      return { loading: true };

    case CLINIC_CREATE_SUCCESS:
      return { loading: false, success: true, clinic: action.payload };

    case CLINIC_CREATE_FAIL:
      return { loading: false, error: action.payload };

    case CLINIC_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

/*
export const dentistUpdateProfileReducer = (state = {  }, action) => {
    switch(action.type){
        case DENTIST_UPDATE_PROFILE_REQUEST:
            return { loading: true }

        case DENTIST_UPDATE_PROFILE_SUCCESS:
            return {loading: false, success: true }

        case DENTIST_UPDATE_PROFILE_FAIL:
            return { loading: false, error: action.payload }

        case DENTIST_UPDATE_PROFILE_RESET:
            return { }
        
        default:
            return state
    }
}

/*
export const dentistInvoicingReducer = (state = { invoicing: {} }, action) => {
    switch(action.type){
        case DENTIST_INVOICING_REQUEST:
            return { loading: true }

        case DENTIST_INVOICING_SUCCESS:
            return {loading: false, invoicing: action.payload}

        case DENTIST_INVOICING_FAIL:
            return { loading: false, error: action.payload }
        
        case DENTIST_INVOICING_RESET:
            return { invoicing: {} }
            
        default:
            return state
    }
}

export const dentistUpdateInvoicingReducer = (state = {  }, action) => {
    switch(action.type){
        case DENTIST_UPDATE_INVOICING_REQUEST:
            return { loading: true }

        case DENTIST_UPDATE_INVOICING_SUCCESS:
            return {loading: false, success: true }

        case DENTIST_UPDATE_INVOICING_FAIL:
            return { loading: false, error: action.payload }

        case DENTIST_UPDATE_INVOICING_RESET:
            return { }
        
        default:
            return state
    }
}

*/
