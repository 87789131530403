import React, { useEffect, useState } from 'react';
import StripeSettingsElement from '../../utilities/StripeSettingsElement';
import { useTranslation } from 'react-i18next';

//import PresentationSettings from "./components/PresentationSettings";
import ProfileTab from './components/ProfileSettings/ProfileTab';
import ClinicsTab from './components/PresentationSettings/ClinicsTab';
import Subscription from './components/SubscriptionAndBilling/Subscription';

import Statistics from './components/Statistics/Statistics';
import TermsOfService from './components/TermsOfService';

import Blog from './components/Blog';
import { Tabs, Tab, Box, Container } from '@mui/material';
import { TabContext, TabPanel, TabList } from '@mui/lab';

// todo: core-ui CSS import
import '../../scss/coreUI.scss';

const SettingsScreen = props => {
  const { intl, location } = props;
  const [activeTab, setActiveTab] = useState('1');
  const { t } = useTranslation('contentTrs');
  //const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {}, []);

  return (
    <Container maxWidth="lg">
      <Box sx={{ width: '100%', typography: 'body1', alignContent: 'center' }}>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t('settingsPage.profil.tabTitle')} value="1" />
              <Tab label={t('settingsPage.clinicPresentation.tabTitle')} value="2" />
              <Tab label={'Blog'} value="3" />
              <Tab label={t('settingsPage.suscription.tabTitle')} value="4" />
              <Tab label={t('settingsPage.statistics.tabTitle')} value="5" />
              <Tab label={t('settingsPage.termOfServices.tabTitle')} value="6" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ProfileTab />
          </TabPanel>
          <TabPanel value="2">
            <ClinicsTab />
          </TabPanel>
          <TabPanel value="3">
            <Blog />
          </TabPanel>
          <TabPanel value="4">
            {' '}
            <StripeSettingsElement>
              <Subscription />
            </StripeSettingsElement>{' '}
          </TabPanel>
          <TabPanel value="5">
            <Statistics />
          </TabPanel>
          <TabPanel value="6">
            <TermsOfService />
          </TabPanel>
        </TabContext>
      </Box>
    </Container>
  );
};

export default SettingsScreen;
