import { useMutation } from '@tanstack/react-query';

import axios from '../../../helpers/axiosConfig';

const useResendVerificationMailMutation = ({ emailId, translationFn }) =>
  useMutation({
    mutationFn: () => {
      const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}');

      if (!userInfo.access) {
        return Promise.reject(translationFn('Invalid patient token!'));
      }

      return axios.post(
        '/api/send-email-validation/',
        {
          from_page: 'Patient_web_doctor_invite',
          email: emailId,
        },
        {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.access}`,
          },
        }
      );
    },
  });

export { useResendVerificationMailMutation };
