import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  DENTIST_REGISTER_REQUEST,
  DENTIST_REGISTER_SUCCESS,
  DENTIST_REGISTER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_RESET,
  DENTIST_PROFILE_REQUEST,
  DENTIST_PROFILE_SUCCESS,
  DENTIST_PROFILE_FAIL,
  DENTIST_PROFILE_RESET,
  DENTIST_UPDATE_PROFILE_REQUEST,
  DENTIST_UPDATE_PROFILE_SUCCESS,
  DENTIST_UPDATE_PROFILE_FAIL,
  DENTIST_UPDATE_PROFILE_RESET,
  DENTIST_INVOICING_REQUEST,
  DENTIST_INVOICING_SUCCESS,
  DENTIST_INVOICING_FAIL,
  DENTIST_INVOICING_RESET,
  DENTIST_UPDATE_INVOICING_REQUEST,
  DENTIST_UPDATE_INVOICING_SUCCESS,
  DENTIST_UPDATE_INVOICING_FAIL,
  DENTIST_UPDATE_INVOICING_RESET,
} from '../constants/userConstants';

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };

    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };

    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const refreshTokenReducer = (state = {}, action) => {
  switch (action.type) {
    case REFRESH_TOKEN_REQUEST:
      return { loading: true };

    case REFRESH_TOKEN_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };

    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };

    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };

    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const dentistRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case DENTIST_REGISTER_REQUEST:
      return { loading: true };

    case DENTIST_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };

    case DENTIST_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };

    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };

    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case USER_DETAILS_RESET:
      return { user: {} };

    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };

    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true };

    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };

    case USER_UPDATE_PROFILE_RESET:
      return {};

    default:
      return state;
  }
};

export const dentistProfileReducer = (state = { dentist: {} }, action) => {
  switch (action.type) {
    case DENTIST_PROFILE_REQUEST:
      return { loading: true };

    case DENTIST_PROFILE_SUCCESS:
      return { loading: false, dentist: action.payload };

    case DENTIST_PROFILE_FAIL:
      return { loading: false, error: action.payload };

    case DENTIST_PROFILE_RESET:
      return { dentist: {} };

    default:
      return state;
  }
};

export const dentistUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case DENTIST_UPDATE_PROFILE_REQUEST:
      return { loading: true };

    case DENTIST_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true };

    case DENTIST_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };

    case DENTIST_UPDATE_PROFILE_RESET:
      return {};

    default:
      return state;
  }
};

export const dentistInvoicingReducer = (state = { invoicing: {} }, action) => {
  switch (action.type) {
    case DENTIST_INVOICING_REQUEST:
      return { loading: true };

    case DENTIST_INVOICING_SUCCESS:
      return { loading: false, invoicing: action.payload };

    case DENTIST_INVOICING_FAIL:
      return { loading: false, error: action.payload };

    case DENTIST_INVOICING_RESET:
      return { invoicing: {} };

    default:
      return state;
  }
};

export const dentistUpdateInvoicingReducer = (state = {}, action) => {
  switch (action.type) {
    case DENTIST_UPDATE_INVOICING_REQUEST:
      return { loading: true };

    case DENTIST_UPDATE_INVOICING_SUCCESS:
      return { loading: false, success: true };

    case DENTIST_UPDATE_INVOICING_FAIL:
      return { loading: false, error: action.payload };

    case DENTIST_UPDATE_INVOICING_RESET:
      return {};

    default:
      return state;
  }
};
