// import axios from "axios";
import axios from '../helpers/axiosConfig';
import {
  DIAGNOSIS_INFO_REQUEST,
  DIAGNOSIS_INFO_SUCCESS,
  DIAGNOSIS_INFO_FAIL,

  // DIAGNOSIS_DETAILS_REQUEST,
  // DIAGNOSIS_DETAILS_SUCCESS,
  // DIAGNOSIS_DETAILS_FAIL,
  DIAGNOSIS_CREATE_REQUEST,
  DIAGNOSIS_CREATE_SUCCESS,
  DIAGNOSIS_CREATE_FAIL,
  // DIAGNOSIS_CREATE_RESET,
  DIAGNOSIS_UPDATE_REQUEST,
  DIAGNOSIS_UPDATE_SUCCESS,
  DIAGNOSIS_UPDATE_FAIL,
  // DIAGNOSIS_UPDATE_RESET,
} from '../constants/diagnosisConstants';

// These is a collection of Redux-free actions

export const getDiagnosis = patientId => async (dispatch, getState) => {
  try {
    dispatch({ type: DIAGNOSIS_INFO_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`/api/patients/profile/${patientId}/diagnosis/`, config);
    //console.log(data)

    dispatch({
      type: DIAGNOSIS_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DIAGNOSIS_INFO_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const createDiagnosis = (patientId, diagnosis) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DIAGNOSIS_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(`/api/patients/profile/${patientId}/diagnosis/addfirstvisit`, diagnosis, config);

    dispatch({
      type: DIAGNOSIS_CREATE_SUCCESS,
      //payload:data,
    });
  } catch (error) {
    dispatch({
      type: DIAGNOSIS_CREATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const updateDiagnosis = (patientId, diagnosis) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DIAGNOSIS_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(`/api/patients/profile/${patientId}/diagnosis/edit`, diagnosis, config);

    dispatch({
      type: DIAGNOSIS_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DIAGNOSIS_UPDATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

/*
export const clinicsDetails = (id) => async (dispatch, getState) => {
    try{
        
        dispatch({type: CLINIC_PROFILE_REQUEST})

        const {
            userLogin: {userInfo},
        } =getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(
            //`/api/users/profile/clinics/${id}`,
            `/api/users/profile/clinics/${id}`,
            config
        )

        dispatch({
            type: CLINIC_PROFILE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CLINIC_PROFILE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateClinics = (clinic) => async (dispatch, getState) => {
    try{
        dispatch({
            type: CLINIC_UPDATE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } =getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.put(
            `/api/users/profile/clinics/update/${clinic._id}`,
            clinic,
            config
        )

        dispatch({
            type: CLINIC_UPDATE_SUCCESS,
            payload: data
        })
       
    } catch (error) {
        dispatch({
            type: CLINIC_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createClinics = (newClinic) => async (dispatch, getState) => {
    try{
        dispatch({
            type: CLINIC_CREATE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } =getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.post(
            `/api/users/profile/clinics/addnew/`,
            newClinic,
            config
        )

        dispatch({
            type: CLINIC_CREATE_SUCCESS,
            payload:data,
        })

    } catch (error) {
        dispatch({
            type: CLINIC_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

*/
