import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Row, Col, Image, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import instance from '../../helpers/axiosConfig';
import RecordField from '../../components/ImageComponent/RecordField';
import { base_URL_media, base_URL_static_asset } from '../../helpers/axiosConfig';

function TreatmentsTab(props) {
  const { t } = useTranslation('contentTrs');
  const { intl, chosenTreatments, setChosenTreatments, children, proposedBlock } = props;

  const [treatmentsList, setTreatmentList] = useState();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const listRecords = async userInfo => {
    //const { userLogin: {userInfo}, } = getState()

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`/api/lookup/treatments/list`, config);

    setTreatmentList(data);
    //console.log(data)
  };

  useEffect(() => {
    listRecords(userInfo);
    //console.log(treatmentsList)
  }, [userInfo]);

  return (
    <div>
      <Row>
        <h5>{t('addNew_patienScreen.add_step2.tabTreatments.title')}</h5>
      </Row>

      <Row>
        <h6>{t('addNew_patienScreen.add_step2.tabTreatments.main_SubTitle')}</h6>
      </Row>

      <Row className="treatmentOptionImages justify-content-around m-0 mt-1 pb-3">
        {treatmentsList
          ? treatmentsList.map(treatment => {
              return (
                <React.Fragment key={treatment.id}>
                  {treatment.is_main && (
                    <Col
                      sm={5}
                      className={
                        chosenTreatments.has(treatment.id)
                          ? 'treatmentOptionImage mt-3 --active'
                          : 'treatmentOptionImage mt-3'
                      }
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        const set = new Set(chosenTreatments);
                        if (set.has(treatment.id)) {
                          set.delete(treatment.id);
                        } else {
                          set.add(treatment.id);
                        }
                        setChosenTreatments(set);
                      }}
                    >
                      <Image src={`${base_URL_media}${treatment.icon}`} style={{ maxWidth: 65, maxHeight: 65 }} />
                      <div className="font-weight-bold font-lg mt-2">{treatment.treatment_name}</div>
                    </Col>
                  )}
                </React.Fragment>
              );
            })
          : null}
      </Row>

      <Row>
        <h6>{t('addNew_patienScreen.add_step2.tabTreatments.others_SubTitle')}</h6>
      </Row>

      <Row className="treatmentOptionImages justify-content-around m-0 mt-1 pb-3">
        {treatmentsList
          ? treatmentsList.map(treatment => {
              return (
                <React.Fragment key={treatment.id}>
                  {!treatment.is_main && (
                    <Col
                      sm={5}
                      className={
                        chosenTreatments.has(treatment.id)
                          ? 'treatmentOptionImage mt-3 --active'
                          : 'treatmentOptionImage mt-3'
                      }
                      onClick={() => {
                        const set = new Set(chosenTreatments);
                        if (set.has(treatment.id)) {
                          set.delete(treatment.id);
                        } else {
                          set.add(treatment.id);
                        }
                        setChosenTreatments(set);
                      }}
                    >
                      <Image src={`${base_URL_media}${treatment.icon}`} style={{ maxWidth: 65, maxHeight: 65 }} />
                      <div className="font-weight-bold font-lg mt-2">{treatment.treatment_name}</div>
                    </Col>
                  )}
                </React.Fragment>
              );
            })
          : null}
      </Row>
    </div>
  );
}

export default TreatmentsTab;
