import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { productListReducer } from './reducers/productReducers';
import {
  userLoginReducer,
  userRegisterReducer,
  dentistRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  dentistProfileReducer,
  dentistUpdateProfileReducer,
  dentistInvoicingReducer,
  dentistUpdateInvoicingReducer,
} from './reducers/userReducers';
import {
  clinicsListReducer,
  clinicsProfileReducer,
  clinicsUpdateReducer,
  clinicsCreateReducer,
} from './reducers/clinicsReducers';
import {
  patientsListReducer,
  patientCreateReducer,
  patientProfileReducer,
  patientUpdateReducer,
  patientDeleteReducer,
} from './reducers/patientsReducers';
import {
  visitsListReducer,
  visitCreateReducer,
  recordsListReducer,
  visitsUpdateReducer,
} from './reducers/visitsReducers';
import { diagnosisInfoReducer, diagnosisUpdateReducer } from './reducers/diagnosisReducers';
import { treatmentInfoReducer } from './reducers/treatmentReducers';
import { patientAnamnesisReducer, treatmentNotesReducer } from './reducers/notesReducers';
import { paySubscriptionReducer } from './reducers/subscriptionReducer';
import { notificationsChatReducer, currentChatReducer } from './reducers/notificationsReducer';
import { remoteVisitsReducer } from './reducers/remoteVisitsReducer';

const reducer = combineReducers({
  productList: productListReducer,

  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,

  dentistRegister: dentistRegisterReducer,
  dentistProfile: dentistProfileReducer,
  dentistUpdateProfile: dentistUpdateProfileReducer,
  dentistInvoicing: dentistInvoicingReducer,
  dentistUpdateInvoicing: dentistUpdateInvoicingReducer,

  paySubscription: paySubscriptionReducer,

  clinicsList: clinicsListReducer,
  clinicsProfile: clinicsProfileReducer,
  clinicsUpdate: clinicsUpdateReducer,
  clinicsCreate: clinicsCreateReducer,

  patientsList: patientsListReducer,
  patientCreate: patientCreateReducer,
  patientProfile: patientProfileReducer,
  patientUpdate: patientUpdateReducer,

  visitCreate: visitCreateReducer,
  visitsList: visitsListReducer,
  visitUpdate: visitsUpdateReducer,
  // visitsListWithType: visitsListWithTypeReducer,
  recordsList: recordsListReducer,

  diagnosisInfo: diagnosisInfoReducer,
  diagnosisUpdate: diagnosisUpdateReducer,

  treatmentInfo: treatmentInfoReducer,

  patientAnamnesis: patientAnamnesisReducer,
  treatmentNotes: treatmentNotesReducer,

  notificationsChat: notificationsChatReducer,
  currentChat: currentChatReducer,
  remoteVisitsList: remoteVisitsReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
